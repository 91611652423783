import React, { Component } from "react";
import {
  ButtonGroup,
  Button,
  CircularProgress,
  FormLabel,
  IconButton,
} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import RankingItem from "./RankingItem";
import RefreshIcon from "@material-ui/icons/Refresh";
import { authAction } from "../../actions/auth-action";
import language from "../../helpers/language";
import { friendServices } from "../../services/friendService";
import { API } from "../../services/api";
import { FormatColorReset } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";

class Ranking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfLoad: 0,
      selected: "all",
      localLoading: false,
      dataAll: [],
      rankingAllPage: 1,
      rankingAllHasMore: true,
      dataTeam: [],
      dataFriend: [],
      dataMe: [],
    };
  }

  componentDidMount() {
    if (this.props.match.params.id && this.props.online) {
      this.loadLocalRankingAll();
      this.loadLocalRankingFriend();
      this.loadLocalRankingMe();
    }
  }

  onScroll = (e) => {
    if (
      e.target.scrollTop >=
        e.target.scrollHeight - e.target.offsetHeight - 300 &&
      this.state.rankingAllHasMore &&
      !this.state.localLoading
    ) {
      this.loadLocalRankingAll();
    }
  };

  loadLocalRankingAll = (refresh) => {
    this.setState({ localLoading: true });
    let page = this.state.rankingAllPage;
    if (refresh) page = 1;
    API.get(
      "/activities/ranking/all?sort=rank&order=1&limit=10&event_id=" +
        this.props.match.params.id +
        "&page=" +
        page
    ).then((res) => {
      if (!res.msg)
        this.setState({
          dataAll: refresh ? res.docs : this.state.dataAll.concat(res.docs),
          rankingAllHasMore: res.hasNextPage,
          rankingAllPage: page + 1,
          localLoading: false,
          numberOfLoad: this.state.numberOfLoad + 1,
        });
      else this.setState({ localLoading: false });
    });
  };

  loadLocalRankingTeam = () => {
    this.setState({ localLoading: true });
    API.get(
      "/activities/ranking/team?sort=rank&order=1&event_id=" +
        this.props.match.params.id
    ).then((res) => {
      if (!res.msg)
        this.setState({
          dataTeam: res.docs,
          localLoading: false,
          numberOfLoad: this.state.numberOfLoad + 1,
        });
      else this.setState({ localLoading: false });
    });
  };

  loadLocalRankingFriend = () => {
    this.setState({ localLoading: true });
    API.get(
      "/activities/ranking/friends?sort=rank&order=1&event_id=" +
        this.props.match.params.id
    ).then((res) => {
      if (!res.msg)
        this.setState({
          dataFriend: res.docs,
          localLoading: false,
          numberOfLoad: this.state.numberOfLoad + 1,
        });
      else this.setState({ localLoading: false });
    });
  };

  loadLocalRankingMe = () => {
    this.setState({ localLoading: true });
    API.get(
      "/activities/ranking/me?sort=rank&order=1&event_id=" +
        this.props.match.params.id
    ).then((res) => {
      if (!res.msg)
        this.setState({
          dataMe: res.docs,
          localLoading: false,
          numberOfLoad: this.state.numberOfLoad + 1,
        });
      else this.setState({ localLoading: false });
    });
  };

  selectType = (type) => {
    this.setState({ selected: type });
  };

  handleRequestAddFriend = (id) => {
    let languageText = this.props.profile ? this.props.profile.language : "EN";
    Swal.fire({
      text: language(languageText, "add_as_friend") + "?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: language(languageText, "Ok"),
      confirmButtonColor: "#e60003",
      cancelButtonColor: "#707070",
      cancelButtonText: language(languageText, "Cancel"),
    }).then((result) => {
      if (result.value) {
        this.setState({ showLoading: true });
        friendServices
          .addFriends(id)
          .then((res) => {
            this.setState({ showLoading: false });
            if (res && res.errors) {
              Swal.fire({
                text: language(languageText, "label_request_friend_failed"),
                icon: "error",
                confirmButtonColor: "#e60003",
              });
            } else {
              this.props.getProfileFriendItem();
              Swal.fire({
                text: language(languageText, "label_request_friend_successful"),
                icon: "info",
                confirmButtonColor: "#e60003",
              });
            }
          })
          .catch((err) => {
            this.setState({ showLoading: false });
            Swal.fire({
              text: language(languageText, "label_request_friend_failed"),
              icon: "error",
              confirmButtonColor: "#e60003",
            });
          });
      }
    });
  };

  refreshRanking = () => {
    if (this.props.online) {
      const { selected } = this.state;
      if (selected === "all") this.loadLocalRankingAll(true);
      if (selected === "team") this.loadLocalRankingTeam(true);
      else if (selected === "friends") this.loadLocalRankingFriend(true);
      else if (selected === "me") this.loadLocalRankingMe(true);
    }
  };

  render() {
    const { profile, online, event, userId } = this.props;
    const {
      selected,
      numberOfLoad,
      localLoading,
      dataAll,
      dataTeam,
      dataFriend,
      dataMe,
      rankingAllHasMore,
      rankingAllPage,
    } = this.state;
    const { selectType, refreshRanking, loadLocalRankingAll, onScroll } = this;
    let dataRankings, hasMore;
    if (selected === "all") {
      dataRankings = dataAll;
      hasMore = rankingAllHasMore;
    } else if (selected === "friends") {
      dataRankings = dataFriend;
      hasMore = FormatColorReset;
    } else if (selected === "me") {
      dataRankings = dataMe;
      hasMore = false;
    } else if (selected === "team") {
      dataRankings = dataTeam;
      hasMore = false;
    }
    return (
      <div className="container text-center pt-4 pb-4 overrideBackgroundTab">
        {event ? <h4 className="text-center">{event.name}</h4> : null}
        <div className="d-flex align-items-center">
          <div className="w-100">
            <ButtonGroup color="primary" fullWidth>
              <Button
                className="w-50"
                variant={selected === "all" ? "contained" : ""}
                onClick={() => selectType("all")}
              >
                {language(profile.language, "all")}
              </Button>
              <Button
                className="w-50"
                variant={selected === "friends" ? "contained" : ""}
                onClick={() => selectType("friends")}
              >
                {language(profile.language, "friends")}
              </Button>
              <Button
                className="w-50"
                variant={selected === "me" ? "contained" : ""}
                onClick={() => selectType("me")}
              >
                {language(profile.language, "me")}
              </Button>
            </ButtonGroup>
          </div>
          <IconButton>
            <Link
              to={`/ranking-search/${this.props.match.params.id}`}
              className="d-flex align-items-center"
              style={{ color: "rgb(112, 112, 112)" }}
            >
              <SearchIcon />
            </Link>
          </IconButton>
        </div>
        <div key={numberOfLoad}>
          {localLoading && online ? (
            <CircularProgress className="mt-3" />
          ) : null}
          <InfiniteScroll
            dataLength={dataRankings ? dataRankings.length : 0}
            next={loadLocalRankingAll}
            refreshFunction={refreshRanking}
            hasmore={hasMore}
            pullDownToRefresh
            scrollableTarget="app-content"
            onScroll={onScroll}
            pullDownToRefreshThreshold={100}
            pullDownToRefreshContent={
              <RefreshIcon fontSize="large" color="action" />
            }
            releaseToRefreshContent={
              <RefreshIcon fontSize="large" color="primary" />
            }
            loader={<CircularProgress />}
          >
            {Array.isArray(dataRankings)
              ? dataRankings.map((item, index) => (
                  <RankingItem
                    key={index}
                    data={item}
                    type={selected}
                    unit={profile.unit}
                    languageText={profile.language}
                    handleRequestAddFriend={this.handleRequestAddFriend}
                    showAddFriend={selected === "all" ? true : false}
                    eventId={this.props.match.params.id}
                    userId={userId}
                    profile={profile}
                  />
                ))
              : null}
          </InfiniteScroll>
          {localLoading && rankingAllPage !== 1 && online ? (
            <CircularProgress className="mt-3" />
          ) : null}
          {!localLoading && (!dataRankings || dataRankings.length === 0) && (
            <div className="row">
              <div className="col-12">
                <div className="mt-3">
                  <FormLabel className="label-input">
                    {language(profile.language, "no_record_found")}
                  </FormLabel>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const { rankingReducer, authReducer } = state;
  let event = state.eventReducer.myEvents.find(
    (el) => el._id === props.match.params.id
  );
  return {
    ...rankingReducer,
    online: authReducer.online,
    profile: authReducer.profile,
    userId: authReducer.userID,
    event: event,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProfileFriendItem: () => dispatch(authAction.getProfileFriendItem()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Ranking);
