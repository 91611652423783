import React, {Component} from 'react';
import {connect} from "react-redux";
import { Card , Button } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import * as moment from 'moment';
import language from '../../helpers/language';

class EventItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            goRegister: false,
        }

        this.handleRegisterEvent = this.handleRegisterEvent.bind(this);
    }

    handleRegisterEvent = () => {
        this.setState({ goRegister: true})
    }

    render() {
        const { data, profile } = this.props;
        let languageText = profile ? profile.language : 'EN';
        const currentDate = moment();
        let disabledRegister = false;
        let lbRegister = language(languageText,"register");
        let isRegistered = false;
        if(profile.events.find((el)=> el._id===data._id)) {
            disabledRegister = true;
            isRegistered = true;
            lbRegister = language(languageText,"registered");
        }
        else if (currentDate.isAfter(moment(data.startDate),'day') && currentDate.isBefore(moment(data.endDate),'day')){
            disabledRegister = false;
            lbRegister = language(languageText,"register");
        }
        if (this.state.goRegister) {
            return (
                <Redirect to={{
                    pathname: '/eventRegistration',
                    state: { data: data }
                }}/>
            )
        }
        return (
            <Card variant="outlined" className="mt-2 p-2">
                <div className="d-block">
                    <h6 className="text-left align-self-center">{data.name}</h6>
                    <div className="d-flex justify-content-between">
                        <span className="text-left m-0 align-self-center ">{moment(data.startDate).format('DD/MM/YYYY') + ' - ' + moment(data.endDate).format('DD/MM/YYYY')}</span>
                        
                        <Button variant="contained" color="primary" className={isRegistered ? "btn-registered" : "btn-success"} onClick={ () => this.handleRegisterEvent()} disabled={disabledRegister}>{lbRegister}</Button>
                    </div>
                </div>
            </Card >
        )
    }
}

function mapStateToProps(state) {
    return {...state.eventReducer, 
        profile: state.authReducer.profile}
}

export default connect(mapStateToProps)(EventItem);