
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Table, TableHead, TableBody, TableRow, TableCell, CardContent } from '@material-ui/core';
import Select from 'react-select';
import { reportAction } from '../../../actions/report-action';
import language from '../../../helpers/language';
import countryList from 'react-select-country-list';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import numeral from 'numeral';

class Reports extends Component {

    constructor(props){
        super(props)
        const eventId = props.match.params.event_id
        this.state = {
            reportType:{value: eventId ? 2 : 1, label: language(this.props.languagetext, eventId ? "report_2" : "report_1")},
            detail:null
        }
        this.optionsCountries = countryList().setLabel("TW", "Taiwan").data
    }

    componentDidMount(){
        this.props.getReport(1,this.props.match.params.event_id)
        if(this.props.languagetext === 'DE')
            numeral.locale('de');
        else numeral.locale('en')
    }

    changeReportType = (data)=>{
        this.setState({ reportType:data, detail:null })
        this.props.getReport(data.value,this.props.match.params.event_id)
    }

    displayCountry = (id)=>{
        let country =  this.optionsCountries.find((el)=>el.value===id)
        if(country)
            return country.label
        else return ''
    }

    displaySum = (obj)=>{
        let keys = Object.keys(obj)
        let sum = 0
        keys.forEach((key)=>{
            if(key!=='_id' && key!=='Business Partner')
                sum = sum+ obj[key]
        })
        return sum
    }

    displayColumnSum = (key) => {
        let sum = 0
        this.props.reports.forEach((report) => {
            if(key) {
                if(report[key])
                sum = sum+report[key]
            } else {
                let keys = Object.keys(report)
                keys.forEach((key)=>{
                    if(key!=='_id' && key!=='Business Partner')
                        sum = sum+ report[key]
                })
            }
        })
        return this.numberFormat(sum)
    }
    
    numberFormat = (num)=>{
        return numeral(num).format('0,0.[0]')
    }

    viewDetail = (country,key)=>{
        this.setState({ detail:{ country,key }})
        this.props.getReportUsers(this.state.reportType.value,country,key,this.props.match.params.event_id)
    }

    render() {
        const { languagetext, loading } = this.props;
        const { reportType, detail } = this.state;
        const { displayCountry, displaySum, displayColumnSum, viewDetail, changeReportType, numberFormat } = this
        const eventId = this.props.match.params.event_id
        return (
            <CardContent>
                {loading?<div className="overlay-loading text-center"><span className="spinner-1 inline-spinner "/></div>:null}
                <div style={{width:'300px',zIndex:'900'}}>
                    <h4>{this.props.match.params.event_name}</h4>
                    {!detail?
                        <Select
                            name="paginationSize"
                            options={[
                                ...(!eventId ? [{label: language(languagetext, "report_1"), value: 1}] : []),
                                ...(eventId ? [{label: language(languagetext, "report_2"), value: 2}] : []),
                                {label: language(languagetext, "report_3"), value: 3},
                                {label: language(languagetext, "report_4"), value: 4}
                            ]}
                            value={reportType}
                            onChange={changeReportType}
                        />:
                        <Button color="primary" variant="contained"  onClick={()=>this.setState({ detail:null })}>
                            <ArrowBackIosIcon/>
                        </Button>
                    }
                </div>
                <div style={{overflow:'auto',height: '80vh', maxWidth:"100vw"}}>
                    {!detail?
                        <Table stickyHeader >
                            <TableHead>
                                <TableRow>
                                    <TableCell>{language(languagetext,"country")}</TableCell>
                                    <TableCell>{language(languagetext, "customer")}</TableCell>
                                    <TableCell>{language(languagetext,"staff")}</TableCell>
                                    <TableCell>{language(languagetext, "supplier")}</TableCell>
                                    <TableCell>{language(languagetext,"family")}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.reports
                                .sort((a,b)=>displayCountry(a._id).localeCompare(displayCountry(b._id)))
                                .map((report) => (
                                <TableRow hover key={report._id}>
                                    <TableCell>{displayCountry(report._id)}</TableCell>
                                    <TableCell>
                                        <span className="text-primary" style={{cursor:'pointer'}} onClick={()=>viewDetail(report._id,'Customer')}>
                                            {report['Customer']?numberFormat(report['Customer']):0}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <span className={"text-primary"} style={{cursor:'pointer'}} onClick={()=>viewDetail(report._id,'Staff')}>
                                            {report['Staff']?numberFormat(report['Staff']):0}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <span className={"text-primary"} style={{cursor:'pointer'}} onClick={()=>viewDetail(report._id,'Supplier')}>
                                            {report['Supplier']?numberFormat(report['Supplier']):0}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <span className={"text-primary"} style={{cursor:'pointer'}} onClick={()=>viewDetail(report._id,'Family')}>
                                            {report['Family']?numberFormat(report['Family']):0}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <span className={"text-primary"} style={{cursor:'pointer'}} onClick={()=>viewDetail(report._id)}>
                                            {numberFormat(displaySum(report))}
                                        </span>
                                    </TableCell>
                                </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <span className={"text-primary"} style={{cursor:'pointer'}} onClick={()=>viewDetail(undefined, 'Customer')}>
                                            {displayColumnSum('Customer')}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <span className={"text-primary"} style={{cursor:'pointer'}} onClick={()=>viewDetail(undefined, 'Staff')}>
                                            {displayColumnSum('Staff')}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <span className={"text-primary"} style={{cursor:'pointer'}} onClick={()=>viewDetail(undefined, 'Supplier')}>
                                            {displayColumnSum('Supplier')}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <span className={"text-primary"} style={{cursor:'pointer'}} onClick={()=>viewDetail(undefined, 'Family')}>
                                            {displayColumnSum('Family')}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <span className={"text-primary"} style={{cursor:'pointer'}} onClick={()=>viewDetail(undefined, undefined)}>
                                            {displayColumnSum()}
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>:
                        <Table stickyHeader >
                            <TableHead>
                                <TableRow>
                                    <TableCell>{language(languagetext,"country")}</TableCell>
                                    <TableCell>{language(languagetext, "display_name")}</TableCell>
                                    <TableCell>{language(languagetext,"company")}</TableCell>
                                    <TableCell>{language(languagetext, "email")}</TableCell>
                                    <TableCell>{language(languagetext,"user_type")}</TableCell>
                                    {reportType.value === 4 && (
                                        <TableCell>{language(languagetext,"distance")}</TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.users.map((user) => (
                                    <TableRow hover key={user._id}>
                                        <TableCell>{displayCountry(user.country)}</TableCell>
                                        <TableCell>{user.displayName}</TableCell>
                                        <TableCell>{user.company}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{language(languagetext,user.userType.toLowerCase())}</TableCell>
                                        {reportType.value === 4 && <TableCell>{parseFloat(user.totalDistance).toFixed(1)}km</TableCell>}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    }
                </div>
            </CardContent>
        )};
};

function mapStateToProps(state) {
    return {...state.reportReducer}
}

function mapDispatchToProps(dispatch) {
    return {
        getReport:(type,event)=>dispatch(reportAction.getReport(type,event)),
        getReportUsers:(type,country,userType,event)=>dispatch(reportAction.getReportUsers(type,country,userType,event))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Reports);