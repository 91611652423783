import React, { Component } from 'react';
import { CardContent, Table, TableBody, TableCell, TableHead, TableRow, Typography, Button, Tooltip, IconButton, Paper } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
//import EditIcon from '@material-ui/icons/Edit';
import language from '../../../helpers/language';
import Swal from 'sweetalert2';

export default class NotificationsTable extends Component{

  showItemDetail = (item)=>{
      return Swal.fire({
        title:item.name,
        html: item.message,
        showConfirmButton:false,
        showCloseButton:true
      })
  }

  render() {
    let languagetext = this.props.languagetext ? this.props.languagetext : 'DE';
    return (
        <CardContent className="">
          <div className="table-content d-block d-lg-none" style={{maxHeight: 'calc(100vh - (220px))'}}>
            {this.props.datas && this.props.datas.map(item => {
              return (
                <Paper key={item._id} elevation={3} className="mb-2 p-2 w-100 d-flex justify-content-between">
                  <div className="d-flex flex-column justify-content-center" onClick={()=>this.showItemDetail(item)} style={{cursor:'pointer'}}>
                    <div>{item.name}</div>
                  </div>
                  <div className="d-flex p-2">
                    <IconButton color="primary" onClick={() => this.props.onClickDeleteCommunicate(item._id)}><DeleteIcon/></IconButton>
                  </div>
                </Paper>
              )
            })}
            </div>
            <div className="table-content d-none d-lg-block" style={{maxHeight: 'calc(100vh - (220px))'}}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{language(languagetext, "no")}</TableCell>
                    <TableCell>{language(languagetext, "name")}</TableCell>
                    <TableCell>{language(languagetext, "type")}</TableCell>
                    <TableCell>{language(languagetext, "receiver_type")}</TableCell>
                    <TableCell>{language(languagetext, "actions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.datas && this.props.datas.map((item, index) => (
                    <TableRow
                      className=""
                      hover
                      key={item._id}
                    >
                      <TableCell>
                        {(this.props.page-1)*this.props.limit + index +1}
                      </TableCell>
                      <TableCell onClick={()=>this.showItemDetail(item)}>
                        <div className="">
                          <Typography variant="body1">{item.name}</Typography>
                        </div>
                      </TableCell>
                      <TableCell>{item.select==='notification'?language(languagetext, "notification"):language(languagetext, "email")}</TableCell>
                      <TableCell>
                        {item.type==='user' ? 
                          language(languagetext, "to_all_registered_users") : 
                          item.type==='event'?
                          language(languagetext, "to_all_registered_competitors_of") : 
                          item.type==='email' ? language(languagetext, "other_email") : 
                          item.type==='all' ? language(languagetext, "all"): '' }</TableCell>
                      <TableCell className="">
                        {/* <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group"> */}
                          {/* <Tooltip
                              id="tooltip-top"
                              title="Edit Communicate"
                              placement="top"
                            >
                            <Button onClick={() => (this.props.onClickEditCommunicate(item, false))}><EditIcon/></Button>
                          </Tooltip> */}
                          <Tooltip
                            id="tooltip-top"
                            title={language(languagetext, "delete_communicate")}
                            placement="top"
                          >
                            <Button onClick={() => this.props.onClickDeleteCommunicate(item._id)}><DeleteIcon/></Button>
                          </Tooltip>
                        {/* </ButtonGroup> */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
        </CardContent>
    )};
};
