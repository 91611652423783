import  {API} from '.';

export const eventServices = {
    getAllEvents,
    getEventByID,
    getActivityByID,
    registerEvent,
    deleteEvent,
    createEvent,
    updateEvent
};
   
function getAllEvents(page=1, limit=50) {
    let url = '/events/all?sort=endDate&order=-1&page=' + page + '&limit=' + limit;
    return API.get(url)
}
 
function getEventByID(id) {
    let url = '/events/' + id;
    return API.get(url)
}
  
function getActivityByID(id) {
    let url = '/activities/' + id;
    return API.get(url)
}
  
function registerEvent(data) {
    let url = '/profile/registerEvent';
    let param = {
        eventId: data._id
    }
    return API.post(url, {}, param)
}

function deleteEvent(id) {
    let url = '/events/' + id;
    return API.del(url)
}

function createEvent(data) {
    let url = '/events';
    return API.post(url, {}, data)
}

function updateEvent(data) {
    let url = '/events/' + data._id;
    return API.patch(url, {}, data)
}
