import React, {Component} from 'react';
import { FormLabel, CircularProgress } from '@material-ui/core';
import {connect} from "react-redux";
import moment from 'moment';
import EventItem from './EventItem';
import { eventAction } from '../../actions/event-action';
import language from '../../helpers/language';

class ActiveEvents extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: false,
            listEvents: []
        }
    }

    componentDidMount(){
        this.getDataEvents();
    }
    
    getDataEvents = () => {
        this.setState({
            showLoading: true
        })
        this.props.dispatch(eventAction.getAllEvents()).then(res => {
            this.setState({
                showLoading: false                
            })
            if (res && res.errors) {
                console.log('error here: ', res.errors)
            }
            else {
                this.setState({
                    listEvents: res.docs                
                })
            }
        }).catch(err => {
            console.log('error here: ', err);
            this.setState({
                showLoading: false                
            })
        })
    }

    render() {
        const { showLoading, listEvents } = this.state
        const { myEvents } = this.props
        const currentDate = moment();
        let languageText = this.props.profile ? this.props.profile.language : 'EN';
        let activeEvents = listEvents && listEvents.length > 0 ? listEvents.filter(e => currentDate.isBefore(moment(e.endDate))) : []
        return (
            <div className="container text-center pt-4">
                {showLoading?<CircularProgress className="mt-3"/>:null}
                {activeEvents.map((item,index)=> <EventItem key={index} data={item} myEvents={myEvents}/>)}
                {!showLoading && activeEvents.length === 0 &&
                    <div className="row">
                        <div className="col-12">
                            <div className="mt-3">
                                <FormLabel className="label-input">{language(languageText,"no_record_found")}</FormLabel>                                    
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {...state.eventReducer, 
        profile: state.authReducer.profile}
}

export default connect(mapStateToProps)(ActiveEvents);