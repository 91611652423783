import { API } from '../services/api';
import { saveState } from '../helpers/toastr';

const refreshNewsfeedAll = () => {
  return (dispatch,getState) => {
    dispatch({ type: 'REFRESH_NEWSFEED_ALL' })
    return API.get('/activities/newFeedsAll',{ page:1, limit:10 }).then((res)=>{
      if(res.docs){
        dispatch(refreshNewsfeedAllSuccess(res));
        saveState(getState())
      }
      else
        dispatch(refreshNewsfeedAllFail(res.errors.msg.replace(/_/g, ' ')))
    }).catch((error)=>{
      dispatch(refreshNewsfeedAllFail('Failed to load activity!'))
    })
  };
}

const refreshNewsfeedAllSuccess = (data) => {
  return {
    type: 'REFRESH_NEWSFEED_ALL_SUCCESS',
    payload: { data: data }
  }
}

const refreshNewsfeedAllFail = () => {
  return {
    type: 'REFRESH_NEWSFEED_ALL_FAIL',
    payload: { error: 'error' }
  }
}

const loadNewsfeedAll = (params = {}) => {
    return (dispatch,getState) => {
      dispatch({ type: 'LOAD_NEWSFEED_ALL' })
      API.get('/activities/newFeedsAll',params).then((res)=>{
        if(res.docs){
          dispatch(loadNewsfeedAllSuccess(res));
          saveState(getState())
        }
        else
          dispatch(loadNewsfeedAllFail(res.errors.msg.replace(/_/g, ' ')))
      }).catch((error)=>{
        dispatch(loadNewsfeedAllFail('Failed to load activity!'))
      })
    };
  }
  
const loadNewsfeedAllSuccess = (data) => {
  return {
    type: 'LOAD_NEWSFEED_ALL_SUCCESS',
    payload: { data: data }
  }
}

const loadNewsfeedAllFail = () => {
  return {
    type: 'LOAD_NEWSFEED_ALL_FAIL',
    payload: { error: 'error' }
  }
}

const refreshNewsfeedFriend = () => {
  return (dispatch,getState) => {
    dispatch({ type: 'REFRESH_NEWSFEED_FRIEND' })
    return API.get('/activities/newFeedsFriend',{ page:1, limit:10 }).then((res)=>{
      if(res.docs){
        dispatch(refreshNewsfeedFriendSuccess(res));
        saveState(getState())
      }
      else
        dispatch(refreshNewsfeedFriendFail(res.errors.msg.replace(/_/g, ' ')))
    }).catch((error)=>{
      dispatch(refreshNewsfeedFriendFail('Failed to load activity!'))
    })
  };
}

const refreshNewsfeedFriendSuccess = (data) => {
  return {
    type: 'REFRESH_NEWSFEED_FRIEND_SUCCESS',
    payload: { data: data }
  }
}

const refreshNewsfeedFriendFail = () => {
  return {
    type: 'REFRESH_NEWSFEED_FRIEND_FAIL',
    payload: { error: 'error' }
  }
}

const loadNewsfeedFriend = (params= {}) => {
  return (dispatch,getState) => {
    dispatch({ type: 'LOAD_NEWSFEED_FRIEND' })
    API.get('/activities/newFeedsFriend',params).then((res)=>{
      if(res.docs){
        dispatch(loadNewsfeedFriendSuccess(res));
        saveState(getState())
      }else
        dispatch(loadNewsfeedFriendFail(res.errors.msg.replace(/_/g, ' ')))
    }).catch((error)=>{
      dispatch(loadNewsfeedFriendFail('Failed to load activity!'))
    })
  };
}

const loadNewsfeedFriendSuccess = (data) => {
  return {
    type: 'LOAD_NEWSFEED_FRIEND_SUCCESS',
    payload: { data: data }
  }
}

const loadNewsfeedFriendFail = () => {
  return {
    type: 'LOAD_NEWSFEED_FRIEND_FAIL',
    payload: { error: 'error' }
  }
}

const createComment = (params= {}) => {
  return (dispatch,getState) => {
    dispatch({ type: 'CREATE_COMMENT' })
    return API.post('/comments',{},params).then((res)=>{
      if(res){
        dispatch(createCommentSuccess(res));
        saveState(getState())
      }else
        dispatch(createCommentFail(res.errors.msg.replace(/_/g, ' ')))
    }).catch((error)=>{
      dispatch(createCommentFail('Failed to create comment!'))
    })
  };
}

const createCommentSuccess = (data) => {
  return {
    type: 'CREATE_COMMENT_SUCCESS',
    payload: { data: data }
  }
}

const createCommentFail = () => {
  return {
    type: 'CREATE_COMMENT_FAIL',
    payload: { error: 'error' }
  }
}

const like = (id) => {
  return (dispatch,getState) => {
    dispatch({ type: 'LIKE_NEWSFEED' })
    return API.post('/likes/like',{},{ activityId:id }).then((res)=>{
      if(res){
        dispatch(likeSuccess(res,id));
        saveState(getState())
      }else
        dispatch(likeFail(res.errors.msg.replace(/_/g, ' ')))
    }).catch((error)=>{
      dispatch(likeFail('Failed to like news!'))
    })
  };
}

const likeSuccess = (data,id) => {
  return {
    type: 'LIKE_NEWSFEED_SUCCESS',
    payload: { data: data, id:id }
  }
}

const likeFail = () => {
  return {
    type: 'LIKE_NEWSFEED_FAIL',
    payload: { error: 'error' }
  }
}

const unlike = (id) => {
  return (dispatch,getState) => {
    dispatch({ type: 'UNLIKE_NEWSFEED' })
    return API.post('/likes/unlike',{},{ activityId:id }).then((res)=>{
      if(res){
        dispatch(unlikeSuccess(res,id));
        saveState(getState())
      }else
        dispatch(unlikeFail(res.errors.msg.replace(/_/g, ' ')))
    }).catch((error)=>{
      dispatch(unlikeFail('Failed to unlike news!'))
    })
  };
}

const unlikeSuccess = (data,id) => {
  return {
    type: 'UNLIKE_NEWSFEED_SUCCESS',
    payload: { data: data, id:id }
  }
}

const unlikeFail = () => {
  return {
    type: 'UNLIKE_NEWSFEED_FAIL',
    payload: { error: 'error' }
  }
}

const loadNewsfeed = (id) => {
  return (dispatch,getState) => {
    dispatch({ type: 'LOAD_NEWSFEED' })
    return API.get('/activities/'+id).then((res)=>{
      if(res._id){
        dispatch(loadNewsfeedSuccess(res));
        saveState(getState())
      }
      else
        dispatch(loadNewsfeedFail(res.errors.msg.replace(/_/g, ' ')))
    }).catch((error)=>{
      dispatch(loadNewsfeedFail('Failed to load activity!'))
    })
  };
}

const loadNewsfeedSuccess = (data) => {
  return {
    type: 'LOAD_NEWSFEED_SUCCESS',
    payload: { data: data }
  }
}

const loadNewsfeedFail = () => {
  return {
    type: 'LOAD_NEWSFEED_FAIL',
    payload: { error: 'error' }
  }
}

export const newsfeedAction = {
  refreshNewsfeedAll,
  loadNewsfeedAll,
  refreshNewsfeedFriend,
  loadNewsfeedFriend,
  createComment,
  like,
  unlike,
  loadNewsfeed
};
    