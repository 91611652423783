import  {API} from '.';

export const userManagementServices = {
    register,
    updateProfile,
    login,
    verifyEmail,
    forgotPassword,
    resetPassword,
    changePassword,
    getProfile,
    uploadAvatar,
    getAllUsers,
    getUserDetail,
    updateUser,
    deleteUser,
    outUser,
    createUser
};
   

// our put method that uses our backend api
// to create new query into our data base
function register(data, language='de'){
    let url = '/users/auth/register';
    return API.post(url, {}, data, null, language)
}

function login(data, language='de') {
    let url = '/users/auth/login';
    return API.post(url, {}, data, null, language)
}

function uploadAvatar(data) {
    let url = '/profile/avatar';
    return API.post(url, {}, data)
}

function updateProfile(data) {
    let url = '/profile';
    return API.patch(url, {}, data)
}

function verifyEmail(data, language='de') {
    let url = '/users/auth/verify';
    return API.post(url, {}, data, null, language)
}

function forgotPassword(data, language='de') {
    let url = '/users/auth/forgot';
    return API.post(url, {}, data, null, language)
}

function resetPassword(data, language='de') {
    let url = '/users/auth/reset';
    return API.post(url, {}, data, null, language)
}

function changePassword(data) {
    let url = '/profile/changePassword';
    return API.post(url, {}, data)
}

function getProfile() {
    let url = '/profile';
    return API.get(url)
}

function getAllUsers(keySearch='', page=1, limit=50) {
    let url = '/users?filter=' + keySearch +'&fields=firstName,lastName,email&page=' + page + '&limit=' + limit + '&sort=givenName&order=-1';
    return API.get(url)
}

function updateUser(data) {
    let url = '/users/' + data._id;
    return API.patch(url, {}, data)
}

function createUser(data) {
    let url = '/users';
    return API.post(url, {}, data)
}

function deleteUser(id) {
    let url = '/users/'+id
    return API.del(url)
}

function outUser(id) {
    let url = '/users/'
    return API.del(url)
}

function getUserDetail(id) {
    let url = '/users/' + id;
    return API.get(url)
}

