import  {API} from '.';

export const communicateServices = {
    getAllCommunicates,
    deleteCommunicate,
    createCommunicate,
    updateCommunicate
};
   
function getAllCommunicates(keySearch, page=1, limit=50) {
    let url = '/communicates?filter=' + keySearch +'&fields=name,message&page=' + page + '&limit=' + limit;
    return API.get(url)
}
 
function deleteCommunicate(id) {
    let url = '/communicates/' + id;
    return API.del(url)
}

function createCommunicate(data) {
    let url = '/communicates';
    return API.post(url, {}, data)
}

function updateCommunicate(data) {
    let url = '/communicates/' + data._id;
    return API.patch(url, {}, data)
}
