import {eventConstant} from '../constants/eventConstant';
import moment from 'moment';

const defaultState = {
    dataEvents:[],
    myEvents: [],
    eventDetail: {},
    loading:false,
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case eventConstant.GET_ALL_EVENTS_REQUEST:
            return {...state,loading:true }
        case eventConstant.GET_ALL_EVENTS_SUCCESS:
            let dataEvents = {
                ...action.payload.data,
                docs:action.payload.data.docs.sort((a,b)=>{
                    if(moment(a.endDate).startOf('day').isBefore(moment(b.endDate).startOf('day')))
                        return 1
                    else if(moment(a.endDate).startOf('day').isSame(moment(b.endDate).startOf('day')))
                        return 0
                    else return -1
                }) 
            }
            return {...state,
                dataEvents: dataEvents,
                loading:false 
            }
        case eventConstant.GET_ALL_EVENTS_FAILURE:
            return {...state, loading:false }
            
        case eventConstant.GET_EVENT_BY_ID_REQUEST:
            return {...state,loading:true }
        case eventConstant.GET_EVENT_BY_ID_SUCCESS:
            return {...state,
                eventDetail: action.payload.data,
                loading:false 
            }
        case eventConstant.GET_EVENT_BY_ID_FAILURE:
            return {...state, loading:false }

        case eventConstant.GET_MY_EVENT_REQUEST:
            return {...state,loading:true }
        case eventConstant.GET_MY_EVENT_SUCCESS:
            return {...state,
                myEvents: action.payload.data ? action.payload.data.sort((a,b)=>{
                    if(moment(a.endDate).startOf('day').isBefore(moment(b.endDate).startOf('day')))
                        return 1
                    else if(moment(a.endDate).startOf('day').isSame(moment(b.endDate).startOf('day')))
                        return 0
                    else return -1
                }) : [],
                loading:false 
            }
        case "GET_PROFILE_SUCCESS":
            return {...state,
                myEvents: action.user.events ? action.user.events.sort((a,b)=>{
                    if(moment(a.endDate).startOf('day').isBefore(moment(b.endDate).startOf('day')))
                        return 1
                    else if(moment(a.endDate).startOf('day').isSame(moment(b.endDate).startOf('day')))
                        return 0
                    else return -1
                }) : [],
                loading:false 
            }
        case eventConstant.GET_MY_EVENT_FAILURE:
            return {...state, loading:false }

        case eventConstant.REGISTER_EVENT_REQUEST:
            return {...state,loading:true }
        case eventConstant.REGISTER_EVENT_SUCCESS:
            let listTemp = state.myEvents || [];
            listTemp.push(action.payload.data);
            return {...state,
                myEvents: listTemp.sort((a,b)=>{
                    if(moment(a.endDate).startOf('day').isBefore(moment(b.endDate).startOf('day')))
                        return 1
                    else if(moment(a.endDate).startOf('day').isSame(moment(b.endDate).startOf('day')))
                        return 0
                    else return -1
                }),
                loading:false 
            }
        case eventConstant.REGISTER_EVENT_FAILURE:
            return {...state, loading:false }
    
        default:
            return state
    }
}
  