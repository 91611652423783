import React, {Component} from 'react';
import { friendServices } from '../../services/friendService';
import { Card, Avatar, Grid, Button, ButtonGroup } from '@material-ui/core';
import IconAvatar from "../../assets/avatar_icon.png";
import { Link } from "react-router-dom";
import language from '../../helpers/language';
import Swal from 'sweetalert2';

export default class FriendItem extends Component {

    checkOutApp = (displayName)=>{
        if(displayName && (displayName.toLowerCase() === 'anonymous' || displayName.toLowerCase() === 'logged out'))
            return true
        else return false
    }

    setData = (data)=>{
        localStorage.setItem('competitor',JSON.stringify({...data,isFriend:"YES"}))
    }

    handleUnFriend = (event) => {
        event.stopPropagation()
        this.setState({ showLoading: true })
        let languageText = this.props.languageText ? this.props.languageText : 'EN';
        friendServices.unFriend(this.props.data._id).then(res => {
            this.setState({ showLoading: false })
            if (res && res.errors) {
                this.props.removeFriendItemFailed()
                Swal.fire({
                    text: language(languageText,"label_delete_friend_failed"),
                    icon: 'error',
                    confirmButtonColor: '#e60003',
                })
            }
            else {
                this.props.removeFriendItemSuccess(this.props.data._id)
                Swal.fire({
                    text: language(languageText,"label_delete_friend_successful"),
                    icon: 'info',
                    confirmButtonColor: '#e60003',
                })
            }
        }).catch(err => {
            this.setState({ showLoading: false })
            this.props.removeFriendItemFailed()
            Swal.fire({
                text: language(languageText,"label_delete_friend_failed"),
                icon: 'error',
                confirmButtonColor: '#e60003',
            })
        })
    }

    render() {
        const { data, myId, languageText } = this.props
        const { handleUnFriend } = this
        let friend = data.receiver
        if(data.receiver._id===myId)
            friend = data.sender
        return (
            <Card variant="outlined" className="mt-2 p-2">
                <Grid container alignItems="center">
                    <Grid container item xs={3}>
                        <Link to={friend.activityShare==='Private'?"#":"/competitor/"+friend._id} className="d-flex" onClick={()=>this.setData(friend)}>
                            <Avatar alt="Avatar " src={friend.avatar ? friend.avatar : IconAvatar} className="newsfeed-avatar mr-2 ml-2" />
                        </Link>
                    </Grid>
                    <Grid container item xs={6}>
                        <Link to={friend.activityShare==='Private'?"#":"/competitor/"+friend._id} className="d-flex" onClick={()=>this.setData(friend)}>
                        <div className="info d-flex justify-content-between w-100 flex-column">
                            <h6 className="text-left text-dark m-0" style={{wordBreak: 'break-word'}}>
                                {this.checkOutApp(friend.displayName)?language(languageText,"logged_out"):friend.activityShare==='Private'?'Anonymous':
                                    friend.displayName?friend.displayName:<span>{friend.firstName} {friend.lastName}</span>
                                }
                            </h6>
                        </div>
                        </Link>
                    </Grid>
                    <Grid container item xs={3}>
                        <ButtonGroup className="w-100" size="small" color="primary">
                            <Button  className="w-100" onClick={handleUnFriend}>{language(languageText,"delete")}</Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Card >
        )
    }
}
