import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchInput from '../../../components/SearchInput';
import {connect} from "react-redux";
import language from '../../../helpers/language';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  row: {
    height: '42px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

function NotificationsToolbar(props) {
  const { handleClickAdd, className, keySearch, inputKeySearch, handleSearch, ...rest } = props;

  const classes = useStyles();
  let languagetext = props.languagetext ? props.languagetext : 'DE';
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <form onSubmit={handleSearch} style={{display: 'contents'}}>
          <SearchInput
            className={classes.searchInput}
            placeholder={language(languagetext, "search_communication")}
            value={keySearch}
            onChange={inputKeySearch}
            onBlur={handleSearch}
          />
        </form>
        <Button
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleClickAdd}
        >
          {language(languagetext, "add_new")}
        </Button>
      </div>
    </div>
  );
};

NotificationsToolbar.propTypes = {
  className: PropTypes.string
};

function mapStateToProps(state) {
  return {
      userInfo: state.authReducer.userInfo
  };
}

export default connect(mapStateToProps)(NotificationsToolbar);
