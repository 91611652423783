
import React, { Component } from 'react';
import {connect} from "react-redux";
import Swal from 'sweetalert2';
import ReactModal from 'react-modal';
import Select from 'react-select';
import { DatePicker } from '@material-ui/pickers';
import EventsTable from './EventsTable';
import EventsToolbar from './EventsToolbar';
import { Button, InputLabel, Input, FormLabel, Divider } from '@material-ui/core';
import {eventAction} from '../../../actions/event-action';
import {eventServices} from '../../../services/eventService';
import moment from 'moment';
import language from '../../../helpers/language';

class EventList extends Component {
  constructor(props) {
      super(props)

      this.state = {
        showFormDetail: false,
        isCreate: true,
        listEvents: [],
        page: 1,
        limit: 10,
        totalPages: 0,
        totalDocs: 0,
        pageInput: 1,
        limitSelected: {value: 10, label: language('DE', "show_10_per_page")},
        default: {
          event: {
              _id: '',
              name: '',
              startDate: moment(),
              endDate: moment(),
              description: '',
              status: 1
          }
        },
        _id: '',
        name: '',
        maximumKm:100,
        maximumMile:parseFloat((100*0.621371192).toFixed(2)),
        startDate: moment(),
        endDate: moment(),
        description: '',
        status: 1
      }
  }

  componentDidMount(){
    this.loadDataEvents(this.state.page, this.state.limit);
  }

  handleChangeLanguage = ()=>{
    const languagetext = this.props.languagetext;
    let temp = {};
    let limit = this.state.limitSelected.value;
    if (limit === 10) {
        temp = {value: 10, label: language(languagetext, "show_10_per_page")}
    }
    else if (limit === 25) {
        temp = {value: 25, label: language(languagetext, "show_25_per_page")}
    }
    else if (limit === 50) {
        temp = {value: 50, label: language(languagetext, "show_50_per_page")}
    }
    this.setState({limitSelected: temp})
}

  loadDataEvents = (page, limit) => {
    this.setState({showLoading: true});
    const { languagetext } = this.props;
    this.props.dispatch(eventAction.getAllEvents(page, limit)).then(response => {
        this.setState({showLoading: false});
        if(response && response.errors) {
            this.setState({
                listEvents: [],
                totalDocs: 0,
                totalPages: 0,
                page: 1,
                pageInput: 1,
                limit: 10
            })
        } else {
            let temp = {};
            if (limit === 10) {
                temp = {value: 10, label: language(languagetext, "show_10_per_page")}
            }
            else if (limit === 25) {
                temp = {value: 25, label: language(languagetext, "show_25_per_page")}
            }
            else if (limit === 50) {
                temp = {value: 50, label: language(languagetext, "show_50_per_page")}
            }
            this.setState({
                listEvents: response.docs,
                totalDocs: response.totalDocs,
                totalPages: response.totalPages,
                page: page,
                pageInput: page,
                limit: limit,
                limitSelected: temp
            })
        }
    }).catch(err => {
        this.setState({
            showLoading: false,
            listEvents: [],
            totalDocs: 0,
            totalPages: 0,
            page: 1,
            pageInput: 1,
            limit: 10
        });
        console.log('error is here: ', err)
    });
  }

  openModalDetail = (data = {}, isCreate = true) => {
    if (isCreate) data = this.state.default.event;
    let dataTemp = data;

    this.setState({
        isCreate: isCreate,
        _id: dataTemp._id,
        name: dataTemp.name,
        startDate: dataTemp.startDate,
        endDate: dataTemp.endDate,
        description: dataTemp.description,
        status: dataTemp.status,
        maximumKm:dataTemp.maximumKm?dataTemp.maximumKm:100,
        maximumMile:dataTemp.maximumMile?dataTemp.maximumMile:parseFloat((100*0.621371192).toFixed(2))
    }, function() { this.setState({showFormDetail: true});});
  }

  closeEventModal = () => {
    this.setState({
        showFormDetail: false
    });
}

  saveEventForm = (e) => {
    e.preventDefault();
    const { _id, name, description, startDate, endDate, status, maximumKm, maximumMile } = this.state;
    let eventInfo = {
        _id: _id,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        name: name,
        description: description,
        status: status,
        maximumKm:maximumKm,
        maximumMile:maximumMile
    };
    if (_id) {
        //edit
        this.setState({showLoading: true})
        eventServices.updateEvent(eventInfo).then(res => {
            this.setState({showFormDetail: false})
            this.loadDataEvents(1, this.state.limit)
        })
    }
    else {
        //create
        this.setState({showLoading: true});
        delete eventInfo._id;
        eventServices.createEvent(eventInfo).then(res => {
            this.setState({showFormDetail: false})
            this.loadDataEvents(1, this.state.limit)
        })
    }
  }

  confirmDeleteRow = (id) => {
    if (!id || id < 1) return;

    const { languagetext } = this.props;
    Swal.fire({
      title: '',
      text: language(languagetext,"delete_item_confirmation"),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: language(languagetext,"yes"),
      cancelButtonText: language(languagetext,"no"),
      confirmButtonColor: '#e60003',
      cancelButtonColor: '#707070',
    }).then((result) => {
      if (result.value) {
        this.setState({showLoading: true})
          eventServices.deleteEvent(id).then(res => {
            this.loadDataEvents(1, this.state.limit)
        })
      }
    })
  }


inputName = (e) => {
  let strValue = String(e.target.value);
    this.setState({
        name: strValue,
        validateEventNameNull: false
    })
}

inputDescription = (e) => {
  let strValue = String(e.target.value);
    this.setState({
        description: strValue
    })
}

inputMaximumKm = (e) =>{
    this.setState({
        maximumKm: e.target.value,
        maximumMile:parseFloat((e.target.value*0.621371192).toFixed(2))
    });
}

inputMaximumMile = (e) =>{
    this.setState({
        maximumMile: e.target.value,
        maximumKm:parseFloat((e.target.value*1.609344).toFixed(2))
    });
}

inputStartDate = (e) => {
  let strValue = e.target?e.target.value:moment(e);
    this.setState({
        startDate: strValue,
        validateStartDateNull: false
    })
}

inputEndDate = (e) => {
  let strValue = e.target?e.target.value:moment(e);
    this.setState({
        endDate: strValue,
        validateEndDateNull: false
    })
}

    //Pagination
    PaginationSizeChange = (value) => {
        if (this.state.limit === value) return;

        // update the list with limit
        if (!value || isNaN(value)) value = 10;

        this.loadDataEvents(1, value);
    }

    handleOnClickPrevious = (e) => {
        if (this.state.page > 1) {
            var prevPageNum = parseInt(this.state.page) - 1;

        this.loadDataEvents(prevPageNum, this.state.limit);
        }
    }

    handleOnClickNext = (e) => {
        if (this.state.page < this.state.totalPages) {
            var nextPageNum = parseInt(this.state.page) + 1;

        this.loadDataEvents(nextPageNum, this.state.limit);
        }
    }

    handlePaginationInputChange = (e) => {
        const { value } = e.target;
        this.setState({
            pageInput: value
        })
    }

    handleKeyPressPaginationInput = (e) => {
        var totalPages = this.state.totalPages

        if (e.key === 'Enter' || e.key === 'enter') {
            if (e.target.value) {
                let value = Number(e.target.value);
                if (value < 1) value = 1;
                else if (value > totalPages) value = totalPages;

                this.loadDataEvents(value, this.state.limit);
            }
        }
    }

    handlePaginationInput = (e) => {
        var totalPages = this.state.totalPages

        if (e.target.value) {
            let value = Number(e.target.value);
            if (value < 1) value = 1;
            else if (value > totalPages) value = totalPages;

            this.loadDataEvents(value, this.state.limit);
        }
    }

    handleOnDeleteEvent = (e) => {
        Swal.fire({
            title: language(this.props.languagetext,"delete_event"),
            text: language(this.props.languagetext,"delete_event_confirmation"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: language(this.props.languagetext,"yes"),
            cancelButtonText: language(this.props.languagetext,"no"),
            confirmButtonColor: '#e60003',
            cancelButtonColor: '#707070',
        }).then((result) => {	
            if (result.value) {
                this.setState({showLoading: true})
                eventServices.deleteEvent(this.state._id).then(res => {
                    this.loadDataEvents(1, this.state.limit)
                }).finally(() => {
                    this.setState({
                        showFormDetail: false
                    });
                })
            } 
        })
    }

  render() {
  const { listEvents, showLoading, page, limit, totalPages, name, description, maximumKm, maximumMile, startDate, endDate } = this.state;
  const { languagetext } = this.props;

  let disabledButton = false;
  var preClass = '';
  var nextClass = '';
  if (page < 2) {
      preClass = 'disabled';
  }
  if (page >= totalPages) {
      nextClass = 'disabled';
  }

  return (
    <div className=''>
        {
            showLoading &&
            <div className="overlay-loading text-center"><span className="spinner-1 inline-spinner "/>
            </div>
        }
      <EventsToolbar languagetext={languagetext} onClickAddNew={this.openModalDetail} />
      <div className="" style={{height: 'auto', maxWidth:"100vw"}}>
        <div className="d-flex">
            <ReactModal
                isOpen={this.state.showFormDetail}
                contentLabel="User Detail"
                className="visible"
                overlayClassName="sweet-overlay"
                style={{
                    content: {display: 'block', marginTop: '50px', backgroundColor: 'initial', outline: 'none'},
                    overlay: {opacity: '1.07', display: 'block'},
                    zIndex: '100!important'
                }}
                shouldFocusAfterRender={false}
                shouldReturnFocusAfterClose={false}
                shouldCloseOnEsc={true}
                role="dialog" >
                  <div className="modal-dialog modal-xl">
                    <form onSubmit={e => this.saveEventForm(e)} ref={c => { this.form = c }}>
                        <div className="modal-content">
                            <div className="modal-header d-flex align-items-center">
                                <h4 className="modal-title">
                                    <span onClick={this.closeEventModal} className="close-modal">
                                        <i className="fa fa-angle-left mr-3"></i>
                                    </span>
                                    {language(languagetext, "event_details")}
                                </h4>
                            </div>
                            <div className="modal-body">
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <FormLabel className="fz-14 font-weight-500">{language(languagetext, "event_name")}<span className="text-danger">(*)</span></FormLabel>
                                            <Input
                                              fullWidth
                                              label="Event name"
                                              margin="dense"
                                              name="eventName"
                                              onChange={this.inputName}
                                              required
                                              value={name}
                                              variant="outlined"
                                              placeholder={language(languagetext, "event_name")}
                                            />
                                            {this.state.validateEventNameNull && <small className="d-block invalid-feedback">{language(languagetext, "event_name_require")}</small>}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <FormLabel className="fz-14 font-weight-500">{language(languagetext,"description")}</FormLabel>
                                            <Input
                                              fullWidth
                                              label="Description"
                                              margin="dense"
                                              name="description"
                                              onChange={this.inputDescription}
                                              //required
                                              value={description}
                                              variant="outlined"
                                              placeholder={language(languagetext,"description")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <FormLabel className="fz-14 font-weight-500">{language(languagetext, "maximum_km")}</FormLabel>
                                            <Input
                                              fullWidth
                                              label="Maximum Km"
                                              margin="dense"
                                              name="maximumKm"
                                              onChange={this.inputMaximumKm}
                                              //required
                                              value={maximumKm}
                                              variant="outlined"
                                              placeholder={language(languagetext, "maximum_km")}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <FormLabel className="fz-14 font-weight-500">{language(languagetext, "maximum_mile")}</FormLabel>
                                            <Input
                                              fullWidth
                                              label="Maximum Mile"
                                              margin="dense"
                                              name="maximumMile"
                                              onChange={this.inputMaximumMile}
                                              //required
                                              value={maximumMile}
                                              variant="outlined"
                                              placeholder={language(languagetext, "maximum_km")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <FormLabel className="fz-14 font-weight-500">{language(languagetext, "start_date")}</FormLabel>
                                            <DatePicker format="DD/MM/YYYY" className="w-100" 
                                            cancelLabel={<Button type="button" variant="contained" color="primary" className="btn-gray">{language(languagetext, "cancel")}</Button>} 
                                            okLabel={<Button type="button" variant="contained" color="secondary" className="backgroundColorPrimaryOverride">{language(languagetext, "ok")}</Button>}
                                            value={startDate} onChange={this.inputStartDate}/>
                                            {this.state.validateStartDateNull && <small className="d-block invalid-feedback">Start date is required</small>}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <FormLabel className="fz-14 font-weight-500">{language(languagetext, "end_date")}</FormLabel>
                                            <DatePicker format="DD/MM/YYYY" className="w-100" 
                                            cancelLabel={<Button type="button" variant="contained" color="primary" className="btn-gray">{language(languagetext, "cancel")}</Button>} 
                                            okLabel={<Button type="button" variant="contained" color="secondary" className="backgroundColorPrimaryOverride">{language(languagetext, "ok")}</Button>}
                                            value={endDate} onChange={this.inputEndDate}/>
                                            {this.state.validateEndDateNull && <small className="d-block invalid-feedback">End date is required</small>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Divider />
                            <div className="mb-3 mt-3 text-center">
                                <Button type="submit" variant="contained" color="primary" className="mr-3 backgroundColorPrimaryOverride" disabled={disabledButton}>{language(languagetext, "save")}</Button>
                                <Button type="button" variant="contained" color="secondary" className="mr-3 btn-gray" onClick={this.closeEventModal}>{language(languagetext, "cancel")}</Button>
                                {this.state._id && <Button type="button" variant="text" color="secondary" className="mr-3" onClick={this.handleOnDeleteEvent}>{language(languagetext, "delete")}</Button>}
                            </div>
                        </div>
                    </form>
                </div>
            </ReactModal>
        </div>
        <div className="content-table-top">
            <div className="row">
                <div className="col-xs-12 col-sm-12">
                    <div className="table-top-pagination pagination-top">
                        <div className="pagination-size">
                            <Select
                                name="paginationSize"
                                options={[
                                    {label: language(languagetext, "show_10_per_page"), value: 10},
                                    {label: language(languagetext, "show_25_per_page"), value: 25},
                                    {label: language(languagetext, "show_50_per_page"), value: 50},
                                ]}
                                value={this.state.limitSelected}
                                onChange={(p) => {
                                    this.PaginationSizeChange(p.value);
                                }}
                            />
                        </div>
                        <ul className="pagination">
                            <li><span className="btn p-btn" onClick={this.handleOnClickPrevious}
                                        disabled={(preClass === 'disabled') ? 'disabled' : ''}>&lt;</span>
                            </li>
                            <li className="option-control">
                                <input type="number" name="currentPage"
                                        onChange={this.handlePaginationInputChange}
                                        onKeyPress={this.handleKeyPressPaginationInput}
                                        value={totalPages === 0 ? 0 : this.state.pageInput}
                                        onBlur={this.handlePaginationInput}
                                        className="pagination-input form-control"/> {language(languagetext, "myActivity.of")} {totalPages} {language(languagetext, "pages")}
                                <span className="error"></span>
                            </li>
                            <li><span className="btn p-btn" onClick={this.handleOnClickNext}
                                        disabled={(nextClass === 'disabled') ? 'disabled' : ''}>&gt;</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <EventsTable languagetext={languagetext} events={listEvents} page={page} limit={limit} onClickEditEvent={this.openModalDetail} onClickDeleteEvent={this.confirmDeleteRow}/>
        {
            (!listEvents || listEvents.length === 0) &&
            <div className="row">
                <div className="col-12">
                    <div className="form-group text-center mt-3">
                        <InputLabel className="label-input">{language(languagetext, "no_record_found")}</InputLabel>
                    </div>
                </div>
            </div>
        }
      </div>
    </div>
  )};
};

function mapStateToProps(state) {
    return {
        userInfo: state.authReducer.userInfo
    };
}

export default connect(mapStateToProps)(EventList);

