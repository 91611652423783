import {searchConstant} from '../constants/searchConstant';

const defaultState = {
    searchResults:[],
    loading:false,
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case searchConstant.SEARCH_KEY_REQUEST:
            return {...state,loading:true }
        case searchConstant.SEARCH_KEY_SUCCESS:
            return {...state,
                searchResults: action.payload.data,
                loading:false 
            }
        case searchConstant.SEARCH_KEY_FAILURE:
            return {...state, loading:false }

        default:
            return state
    }
}
  