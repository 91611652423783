import React from 'react';
import {
    Card, CardContent, CardActions, Grid,
    Button, TextField, Dialog, DialogContent, InputLabel,
    DialogTitle, FormLabel, FormControl, FormControlLabel, Checkbox, RadioGroup, Radio, CircularProgress
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CreatableSelect from 'react-select/creatable';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import language from '../../../helpers/language';

import { API } from '../../../services/api';

const components = {
    DropdownIndicator: null,
};

const createOption = (label) => ({
    label,
    value: label,
});

export default class EventDialog extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            eventsLoading: false,
            eventsData: [],
            usersLoading: false,
            usersData: [],
            eventName: '',
            description: '',
            select: 'notification',
            type: 'all',
            eventsSelected: [],
            usersSelected: [],
            inputEmail: '',
            emails: [],
            error: ''
        }
    }

    componentDidMount(){
        this.initData();
    }
    
    initData = () => {
        API.get('users').then((res) => {
            if (res.docs)
                this.setState({ usersData: res.docs})
        });
        API.get('events').then((res) => {
            if (res.docs)
                this.setState({ eventsData: res.docs})
        });
    }

    handleChange = event => {
        console.log(event.target)
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    };

    timeoutUserSearch = null
    searchUser = (e) => {
        clearTimeout(this.timeoutUserSearch)
        let filter = e.target.value
        this.setState({ usersLoading: true, usersData: [] })
        this.timeoutUserSearch = setTimeout(() => {
            API.get('users', { page: 1, limit: 100, filter: filter, fields: 'email,firstName,lastName' }).then((res) => {
                if (res.docs)
                    this.setState({ usersData: res.docs, usersLoading: false })
            })
        }, 300)
    }

    handleUserSelected = (value) => {
        //let emails = value && value.map(x => x.email);
        this.setState({
            usersSelected: value
        })
    }

    handleEventSelected = (value) => {
        let ids = value && value.map(x => x._id);
        this.setState({
            eventsSelected: ids
        })
    }

    renderUserSelect = () => {
        const { searchUser } = this
        const { usersLoading, usersData } = this.state
        let languagetext = this.props.languagetext ? this.props.languagetext : 'DE';
        return (
            <Autocomplete multiple options={usersData} disableCloseOnSelect loading={usersLoading}
                onChange={(event, value) => this.handleUserSelected(value)}
                getOptionLabel={option => option.firstName + " " + option.lastName}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        <Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} checked={selected} />{option.firstName} {option.lastName}
                    </React.Fragment>
                )}
                renderInput={params => (
                    <TextField {...params} variant="outlined" label="Select user" placeholder={language(languagetext, "users")} margin="dense" onChange={searchUser} className="z-input"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {usersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>)
                        }}
                    />
                )}
            />
        )
    }

    timeoutEventSearch = null
    searchEvent = (e) => {
        clearTimeout(this.timeoutEventSearch)
        let filter = e.target.value
        this.setState({ eventsLoading: true, eventsData: [] })
        this.timeoutEventSearch = setTimeout(() => {
            API.get('events', { page: 1, limit: 100, filter: filter, fields: 'name' }).then((res) => {
                if (res.docs)
                    this.setState({ eventsData: res.docs, eventsLoading: false })
            })
        }, 300)
    }

    renderEventSelect = () => {
        const { searchEvent } = this
        const { eventsLoading, eventsData } = this.state
        let languagetext = this.props.languagetext ? this.props.languagetext : 'DE';
        return (
            <Autocomplete multiple options={eventsData} disableCloseOnSelect loading={eventsLoading}
                onChange={(event, value) => this.handleEventSelected(value)}
                getOptionLabel={option => option.name}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        <Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} checked={selected} />{option.name}
                    </React.Fragment>
                )}
                renderInput={params => (
                    <TextField {...params} variant="outlined" label="Select event" placeholder={language(languagetext, "events_text")} margin="dense" onChange={searchEvent} className="z-input"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {eventsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>)
                        }}
                    />
                )}
            />
        )
    }

    handleChangeCreatableSelect = (emails, actionMeta) => {
        let email = [];
        if (emails) email = emails;
        this.setState({ emails: email });
    };

    handleInputChange = (inputEmail) => {
        this.setState({ inputEmail });
    };
    handleKeyDown = (event) => {
        let emails = this.state.emails;
        let inputEmail = this.state.inputEmail;
        if (!inputEmail) return;

        switch (event.key) {
            case 'Enter': 
                if (!this.isValid(inputEmail)) {
                    this.setState({
                        inputEmail: ""
                    });
                    return;
                };
                emails.push(createOption(inputEmail));
                this.setState({
                    inputEmail: '',
                    emails: emails,
                });
                event.preventDefault();
                break
            case 'Tab':
                if (!this.isValid(inputEmail)) {
                    this.setState({
                        inputEmail: ""
                    });
                    return;
                };
                emails.push(createOption(inputEmail));
                this.setState({
                    inputEmail: '',
                    emails: emails,
                });
                event.preventDefault();
                break
            default:
        }
    };

    isValid(email) {
        let error = null;
        if (this.isInList(email)) {
            error = `${email} has already been added.`;
        }
        if (!this.isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }
        if (error) {
            this.setState({ error });
            return false;
        }
        return true;
    }

    isInList(email) {
        let emails = this.state.emails;
        let filter = emails.filter(e => e.value === email)
        if (filter.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    isEmail(email) {
        return /[\w\d-]+@[\w\d]+\.[\w\d-]+/.test(email);
    }

    renderEmailSelect = () => {
        const { inputEmail, emails } = this.state;
        let languagetext = this.props.languagetext ? this.props.languagetext : 'DE';
        return (
            <CreatableSelect
                className="z-input"
                components={components}
                inputValue={inputEmail}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={this.handleChangeCreatableSelect}
                onInputChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
                placeholder={language(languagetext, "place_holder_for_emails_input")}
                value={emails}
            />
        )
    }

    handleResetForm = (e) => {
        e.preventDefault();
        this.setState({
            eventName: '',
            description: '',
            select: 'notification',
            type: 'all',
            eventsSelected: [],
            usersSelected: [],
            inputEmail: '',
            emails: [],
            error: ''
        })
    }

    handleSaveForm = (e) => {
        e.preventDefault();

        let type = this.state.type;
        let tempSelect = this.state.select;
        let dataSelected = [];
        if (type === 'event') {
            dataSelected = this.state.eventsSelected
        }
        else if (type === 'user') {
            let tempUsers = this.state.usersSelected;
            if (tempSelect === 'notification') {
                let users = tempUsers?tempUsers.map(x => x._id):[];
                dataSelected = users;
            }
            else {
                let users = tempUsers?tempUsers.map(x => x.email):[];
                dataSelected = users;
            }
        }
        else if (type === 'email') {
            let tempEmails = this.state.emails
            let emailSelected = tempEmails && tempEmails.map(x => x.value);
            dataSelected = emailSelected ? emailSelected : []
        }
        let data = {
            name: this.state.eventName,
            message: this.state.description,
            select: tempSelect,
            type: type,
            data: dataSelected
        }

        this.props.handleSave(data);
    }

    render() {
        const { handleClose } = this.props;
        let languagetext = this.props.languagetext ? this.props.languagetext : 'DE';
        const { handleChange, renderUserSelect, renderEventSelect, renderEmailSelect } = this;
        let values = this.state
        let disabledSave = false;
        if (!values.eventName || values.eventName === '' || !values.description || values.description === '') {
            disabledSave = true;
        }
        console.log(values)
        let config={resize_minHeight: 300}
        return (
            <Dialog open={true} onClose={handleClose}>
                <DialogTitle>{language(languagetext, "add_new_communication")}</DialogTitle>
                <DialogContent>
                    <Card>
                        <form autoComplete="off">
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item md={12} xs={12}>
                                        <TextField fullWidth label="Name" name="eventName" margin="dense" required onChange={handleChange} value={values.eventName} variant="outlined" className="z-input"/>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        {/* <TextField fullWidth multiline rows={3} label="Description" name="description" margin="dense" required onChange={handleChange} value={values.description} variant="outlined" /> */}
                                        <InputLabel className="label-input">{language(languagetext, "description")}</InputLabel>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            config={config}
                                            data={values.description}
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                this.setState({ description: data });
                                            } }
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">{language(languagetext, "select")}</FormLabel>
                                            <RadioGroup name="select" value={values.select} onChange={handleChange} row>
                                                <FormControlLabel value="notification" control={<Radio />} label="Notification" />
                                                <FormControlLabel value="email" control={<Radio />} label="Email" />
                                                {/* <FormControlLabel value="user" control={<Radio />} label="User" /> */}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">{language(languagetext, "type")}</FormLabel>
                                            <RadioGroup name="type" value={values.type} onChange={handleChange} row>
                                                <FormControlLabel value="all" control={<Radio />} label="All" />
                                                <FormControlLabel value="event" control={<Radio />} label="Event" />
                                                <FormControlLabel value="user" control={<Radio />} label="User" />
                                                {values.select === "email" && <FormControlLabel value="email" control={<Radio />} label="Email" />}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        {values.type === "user" ? renderUserSelect() : null}
                                        {values.type === "event" ? renderEventSelect() : null}
                                        {values.type === "email" && renderEmailSelect()}
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions className="d-block text-center">
                                <Button color="primary" variant="contained" className="mr-2" disabled={disabledSave} onClick={(e) => this.handleSaveForm(e)}>{language(languagetext, "send")}</Button>
                                <Button variant="contained" className="mr-2" onClick={(e) => this.handleResetForm(e)}>{language(languagetext, "reset")}</Button>
                                <Button color="secondary" variant="contained" onClick={this.props.handleClose}>{language(languagetext, "cancel")}</Button>
                            </CardActions>
                        </form>
                    </Card>
                </DialogContent>
            </Dialog>
        )
    }
}
