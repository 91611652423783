import React, { Component } from 'react';
import moment from 'moment';
import { Link } from "react-router-dom";
import { Paper, Table, CardContent, TableBody, TableCell, TableHead, TableRow, Typography, Tooltip, Button, IconButton, ButtonGroup } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {connect} from "react-redux";
import language from '../../../helpers/language';
import AssessmentIcon from '@material-ui/icons/Assessment';

class EventsTable extends Component{
  render() {
    let languageText = this.props.languagetext ? this.props.languagetext : 'DE';
    return (
        <CardContent>
            <div className="table-content d-block d-lg-none" style={{maxHeight: 'calc(100vh - (220px))'}}>
            {this.props.events && this.props.events.map(event => {
              return (
                <Paper key={event._id} elevation={3} className="mb-2 p-2 w-100 d-flex justify-content-between">
                  <div>
                    <Link to={"/eventDetail/"+event._id+"/"+event.name}>{event.name}</Link>
                    <div>{language(languageText, "time")}: {moment(event.startDate).format('DD/MM/YYYY')} - {moment(event.endDate).format('DD/MM/YYYY')}</div>
                    <div>{language(languageText, "distance")}: {event.maximumKm} {language(languageText, "km")} | {event.maximumMile} {language(languageText, "mile")}</div>
                  </div>
                  <div className="d-flex p-2">
                    <IconButton color="primary" onClick={() => (this.props.onClickEditEvent(event, false))}><EditIcon/></IconButton>
                    <IconButton color="secondary" onClick={() => this.props.onClickDeleteEvent(event._id)}><DeleteIcon/></IconButton>
                  </div>
                </Paper>
              )
            })}
            </div>
            <div className="table-content d-none d-lg-block" style={{maxHeight: 'calc(100vh - (220px))'}} >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{language(languageText,"no")}</TableCell>
                    <TableCell>{language(languageText, "name")}</TableCell>
                    <TableCell>{language(languageText,"description")}</TableCell>
                    <TableCell>{language(languageText, "start_date")}</TableCell>
                    <TableCell>{language(languageText, "end_date")}</TableCell>
                    <TableCell>{language(languageText, "distance")}</TableCell>
                    <TableCell>{language(languageText, "actions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.events && this.props.events.map((event, index) => (
                    <TableRow
                      className=""
                      hover
                      key={event._id}
                    >
                      <TableCell>
                        {(this.props.page-1)*this.props.limit + index +1}
                      </TableCell>
                      <TableCell>
                        <div className="">
                          <span onClick={() => (this.props.onClickEditEvent(event, false))} className="btn-link d-flex">
                              <Typography variant="body1">{event.name}</Typography>
                            </span>
                        </div>
                      </TableCell>
                      <TableCell>{event.description}</TableCell>
                      <TableCell>{moment(event.startDate).format('DD/MM/YYYY')}</TableCell>
                      <TableCell>{moment(event.endDate).format('DD/MM/YYYY')}</TableCell>
                      <TableCell><div>{event.maximumKm} {language(languageText, "km")}</div> <div>{event.maximumMile} {language(languageText, "mile")}</div></TableCell>
                      <TableCell className="">
                      <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                        <Tooltip
                            id="tooltip-top"
                            title={language(languageText, "edit_event")}
                            placement="top"
                          >
                          <Button onClick={() => (this.props.onClickEditEvent(event, false))}><EditIcon/></Button>
                        </Tooltip>
                          <Tooltip
                          id="tooltip-top"
                          title={language(languageText, "report")}
                          placement="top"
                          >
                            <Button component={Link} to={"/report/"+event._id+"/"+event.name}><AssessmentIcon/></Button>
                          </Tooltip>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
        </CardContent>
    )};
};

function mapStateToProps(state) {
  return {
      userInfo: state.authReducer.userInfo
  };
}

export default connect(mapStateToProps)(EventsTable);
