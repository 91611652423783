const defaultState = {
    newsfeedsAll:[],
    newsfeedsAllPage:0,
    newsfeedsAllHasMore:true,
    loadingNewsfeedAll:false,
    newsfeedsFriend:[],
    newsfeedsFriendPage:0,
    newsfeedsFriendHasMore:true,
    loadingNewsfeedFriend:false,
    loading:false
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'REFRESH_NEWSFEED_ALL':
            return {...state,loadingNewsfeedAll:true }
        case 'REFRESH_NEWSFEED_ALL_SUCCESS':
            return {...state,
                newsfeedsAll: action.payload.data.docs,
                newsfeedsAllPage:1,
                newsfeedsAllHasMore:action.payload.data.hasNextPage,
                loadingNewsfeedAll:false 
            }
        case 'REFRESH_NEWSFEED_ALL_FAIL':
            return {...state, loadingNewsfeedAll:false }
        case 'LOAD_NEWSFEED':
            return {...state,loading:true }
        case 'LOAD_NEWSFEED_SUCCESS':{
            let new_newsfeedAll = state.newsfeedAll?[...state.newsfeedAll]:[]
            let is_new1 = true
            new_newsfeedAll = new_newsfeedAll.map((el)=>{
                if(el._id===action.payload.data._id){
                    is_new1 = false
                    return action.payload.data
                }
                return el
            })
            if(is_new1) new_newsfeedAll = new_newsfeedAll.concat(action.payload.data)

            let new_newsfeedFriend = state.newsfeedsFriend?[...state.newsfeedsFriend]:[]
            new_newsfeedFriend = new_newsfeedFriend.map((el)=>{
                if(el._id===action.payload.data._id)
                    return action.payload.data
                return el
            })

            return {...state,
                newsfeedsAll: new_newsfeedAll,
                newsfeedsFriend: new_newsfeedFriend,
                loading: false
            }
        }case 'LOAD_NEWSFEED_FAIL':
            return {...state, loadingl:false }
        case 'LOAD_NEWSFEED_ALL':
            return {...state,loadingNewsfeedAll:true }
        case 'LOAD_NEWSFEED_ALL_SUCCESS':
            return {...state,
                newsfeedsAll: state.newsfeedsAll.concat(action.payload.data.docs),
                newsfeedsAllPage:action.payload.data.hasNextPage?state.newsfeedsAllPage+1:state.newsfeedsAllPage,
                newsfeedsAllHasMore:action.payload.data.hasNextPage,
                loadingNewsfeedAll:false 
            }
        case 'LOAD_NEWSFEED_ALL_FAIL':
            return {...state, loadingNewsfeedAll:false }
        case 'REFRESH_NEWSFEED_FRIEND':
            return {...state,loadingNewsfeedFriend:true }
        case 'REFRESH_NEWSFEED_FRIEND_SUCCESS':
            return {...state,
                newsfeedsFriend: action.payload.data.docs,
                newsfeedsFriendPage:1,
                newsfeedsFriendHasMore:action.payload.data.hasNextPage,
                loadingNewsfeedFriend:false 
            }
        case 'REFRESH_NEWSFEED_FRIEND_FAIL':
            return {...state, loadingNewsfeedFriend:false }
        case 'LOAD_NEWSFEED_FRIEND':
            return {...state,loadingNewsfeedFriend:true }
        case 'LOAD_NEWSFEED_FRIEND_SUCCESS':
            return {...state,
                newsfeedsFriend: state.newsfeedsFriend.concat(action.payload.data.docs),
                newsfeedsFriendPage:action.payload.data.hasNextPage?state.newsfeedsFriendPage+1:state.newsfeedsFriendPage,
                newsfeedsFriendHasMore:action.payload.data.hasNextPage,
                loadingNewsfeedFriend:false 
            }
        case 'LOAD_NEWSFEED_FRIEND_FAIL':
            return {...state, loadingNewsfeedFriend:false }
        case 'CREATE_COMMENT':
            return {...state}
        case 'CREATE_COMMENT_SUCCESS':
            return {...state,
                newsfeedsAll: state.newsfeedsAll.map((el)=>{
                    if(el._id === action.payload.data.activityId)
                        return {...el,comments:el.comments?[action.payload.data,...el.comments]:[action.payload.data]}
                    else return el
                }),
                newsfeedsFriend: state.newsfeedsFriend.map((el)=>{
                    if(el._id === action.payload.data.activityId)
                        return {...el,comments:el.comments?[action.payload.data,...el.comments]:[action.payload.data]}
                    else return el
                })
            }
        case 'CREATE_COMMENT_FAIL':
            return {...state}
        case 'LIKE_NEWSFEED_SUCCESS':
            return {...state,
                newsfeedsAll: state.newsfeedsAll.map((el)=>{
                    if(el._id === action.payload.id)
                        return {...el,...action.payload.data}
                    else return el
                }),
                newsfeedsFriend: state.newsfeedsFriend.map((el)=>{
                    if(el._id === action.payload.id)
                        return {...el,...action.payload.data}
                    else return el
                })
            }
        case 'UNLIKE_NEWSFEED_SUCCESS':
            return {...state,
                newsfeedsAll: state.newsfeedsAll.map((el)=>{
                    if(el._id === action.payload.id)
                        return {...el,...action.payload.data}
                    else return el
                }),
                newsfeedsFriend: state.newsfeedsFriend.map((el)=>{
                    if(el._id === action.payload.id)
                        return {...el,...action.payload.data}
                    else return el
                })
            }
        default:
            return state
    }
}
  