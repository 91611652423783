import eng from "../constants/language/eng"
import ger from "../constants/language/ger"

function languageHelper(code,key){
    let keys = key.split('.')
    let data = eng
    if(code==="DE")
        data = ger
    keys.forEach((k)=>{
        data = data[k]?data[k]:key
    })

    return data
}

export default languageHelper