import React, {Component} from 'react';
import { FormLabel, CircularProgress } from '@material-ui/core';
import {connect} from "react-redux";
import TeamItem from './TeamItem';
import { teamAction } from '../../actions/team-action';
import language from '../../helpers/language';

class Teams extends Component {

    componentDidMount(){
        this.getDataTeams();
    }
    
    getDataTeams = () => {
        this.props.dispatch(teamAction.getTeams())
    }

    render() {
        let { loading, teams} = this.props
        let languageText = this.props.profile ? this.props.profile.language : 'EN';
        return (
            <div className="container text-center pt-4">
                {loading?<CircularProgress className="mt-3"/>:null}
                {teams.map((item,index)=> <TeamItem key={index} data={item}/>)}
                {!loading && teams.length === 0 &&
                    <div className="row">
                        <div className="col-12">
                            <div className="mt-3">
                                <FormLabel className="label-input">{language(languageText,"no_record_found")}</FormLabel>                                    
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {...state.teamReducer, 
        profile: state.authReducer.profile}
}

export default connect(mapStateToProps)(Teams);