import {friendConstant} from '../constants/friendConstant';

const defaultState = {
    myFriends: [],
    loading:false,
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case friendConstant.GET_MY_FRIEND_REQUEST:
            return {...state,loading:true }
        case friendConstant.GET_MY_FRIEND_SUCCESS:
            return {...state,
                myFriends: action.payload.data,
                loading:false 
            }
        case friendConstant.GET_MY_FRIEND_FAILURE:
            return {...state, loading:false }

        default:
            return state
    }
}
  