import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Avatar, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirm } from '../../helpers/toastr';
import { notificationAction } from '../../actions/notification-action';

class NotificationItem extends Component {

    onClick = () => {
        let data = this.props.data;
        this.props.handleClick(data);
    }
    confirmDelete = () => {
        confirm(() => {
            this.props.deleteNotification(this.props.data._id);
        }, this.props.languageText)
    }
    render() {
        let data = this.props.data;
        console.log(data)
        return (
            <Card variant="outlined" className="mt-2 p-1 mb-2" >
                <div className="d-flex flex-row">
                    <Avatar alt="Avatar " src={data.sender?data.sender.avatar:''} className="mt-1 mr-2" />
                    <div className="d-flex flex-column justify-content-center" onClick={this.onClick} style={{flexGrow:1}}>
                        {data && <h6 className="text-left header-title text-dark d-flex flex-column justify-content-center mb-0">{data.message}</h6>}
                    </div>
                    <div className="align-self-end">
                        <IconButton onClick={this.confirmDelete} className="color-gray"><DeleteIcon /></IconButton>
                    </div>
                </div>
            </Card >
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteNotification: (id) => dispatch(notificationAction.deleteNotification(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationItem);