import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SearchInput from '../../../components/SearchInput';
import {connect} from "react-redux";
import language from '../../../helpers/language';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  row: {
    height: '42px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

function UsersToolbar(props) {
  const { className, keySearch, inputKeySearch, handleSearch, onClickAddNew, ...rest } = props;

  const classes = useStyles();
  let languageText = props.languagetext ? props.languagetext : 'DE';
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <form onSubmit={handleSearch} style={{display: 'contents'}}>
          <SearchInput
            className={classes.searchInput}
            placeholder={language(languageText,"search_user")}
            value={keySearch}
            onChange={inputKeySearch}
            onBlur={handleSearch}
          />
        </form>
        {/*<Button variant="contained" color="primary" startIcon={<AddIcon/>} onClick={onClickAddNew}>*/}
        {/*  {language(languageText, "add_new")}*/}
        {/*</Button>*/}
      </div>
    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

function mapStateToProps(state) {
  return {
      userInfo: state.authReducer.userInfo
  };
}

export default connect(mapStateToProps)(UsersToolbar);
