import React, {Component} from 'react';
import { rankingServices } from '../../services/rankingService';
import {connect} from "react-redux";
//import { Slider } from '@material-ui/core';
import * as moment from 'moment';
import language from '../../helpers/language';
import IconRuns from "../../assets/icon_runs_the_world.png";

import numeral from 'numeral';

class MyActivity extends Component {

    constructor(props){
        super(props)
        this.state = {
            rank: 'N/A',
            totalMember: null,
            totalMemberRunKm:0,
            totalMemberRunMile:0,
            distanceKm:0,
            distanceMile:0
        }
    }

    componentDidMount(){
        rankingServices.getMyEventRanking(this.props.match.params.event_id).then((res)=>{
            if(res.creatorId)
                this.setState({ rank:res.rank, totalMember:res.totalMember, totalMemberRunKm:res.totalMemberRunKm, totalMemberRunMile:res.totalMemberRunMile, distanceKm:res.distanceKm, distanceMile:res.distanceMile })
        })
        if(this.props.profile.language === 'DE')
            numeral.locale('de');
        else numeral.locale('en')
    }

    numberFormat = (num)=>{
        return numeral(num).format('0,0.[0]')
    }

    render() {
        const { profile, event } = this.props
        const { rank, totalMember, totalMemberRunKm, totalMemberRunMile, distanceKm, distanceMile } = this.state
        const { numberFormat } = this
        let indiviualProgress = profile.unit==="KM"?distanceKm:distanceMile

        let totalMemberRun = profile.unit==="KM"?totalMemberRunKm:totalMemberRunMile

        let total_challange_distance = profile.unit==="KM"?event.maximumKm:event.maximumMile
        let now = moment()
        let startDate = moment (event.startDate)
        let endDate = moment(event.endDate)
        let totalDay = moment.duration(startDate.diff(endDate),'days')
        let day = moment.duration(startDate.diff(now),'days')
        let targetProgress = profile.unit==="KM"?(event.maximumKm*day/totalDay):(event.maximumMile*day/totalDay)
        let remainingDays = 0
        if(now.isBefore(endDate))
            remainingDays = endDate.diff(now,'days')
        if(now.isAfter(endDate)) {
            targetProgress = profile.unit==="KM"?event.maximumKm:event.maximumMile
        }
        return (
            <div className="container pt-3">
                <h4 className="mb-3 text-center">{event.name}</h4>
                <div className="d-flex justify-content-center">
                    <img alt="" src={IconRuns} style={{width: '220px', height: '85px', marginBottom: '4rem'}} />
                </div>
                <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column justify-content-center w-50">{language(profile.language,"myActivity.individual_ranking")}</div>
                    <div className="d-flex w-50 justify-content-between">
                        <div className="read-only-value w-25">{rank}</div>
                        <div className="d-flex flex-column justify-content-center">{language(profile.language,"myActivity.of")}</div>
                        <div className="read-only-value w-25">{totalMember?totalMember:event.users.length}</div>
                    </div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex flex-column justify-content-center w-50">{language(profile.language,"myActivity.individual_progress")}</div>
                    <div className="read-only-value w-50">{numberFormat(indiviualProgress)} {profile.unit==="KM"?"kms":"miles"}</div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex flex-column justify-content-center w-50">{language(profile.language,"myActivity.challange_progress")}</div>
                    <div className="read-only-value w-50">{numberFormat(totalMemberRun)} {profile.unit==="KM"?"kms":"miles"}</div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex flex-column justify-content-center w-50">{language(profile.language,"myActivity.event_total_distance")}</div>
                    <div className="read-only-value w-50">{numberFormat(total_challange_distance)} {profile.unit==="KM"?"kms":"miles"}</div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex flex-column justify-content-center w-50">{language(profile.language,"myActivity.ls_total")}</div>
                    <div className="read-only-value w-50">{(totalMemberRun*100/total_challange_distance).toFixed(0)} %</div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex flex-column justify-content-center w-50">{language(profile.language,"myActivity.target")}</div>
                    <div className="read-only-value w-50">{numberFormat(targetProgress)} {profile.unit==="KM"?"kms":"miles"}</div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex flex-column justify-content-center w-50">{language(profile.language,"myActivity.ls")}</div>
                    <div className="read-only-value w-50">{(totalMemberRun*100/targetProgress).toFixed(0)} %</div>
                </div>
                {/*
                <div className="mt-3">
                    <div className="pl-3 pr-3">
                        <div className="position-relative">
                            <Slider className="mt-5 gray-slider" style={{zIndex:2}}
                                value={[0,Number(totalMemberRun)]}
                                disabled
                                step={1}
                                marks={marks}
                                max={profile.unit==="KM"?Number(event.maximumKm):Number(event.maximumMile)}
                                valueLabelDisplay="on"
                            />
                            <Slider className="mt-5 position-absolute red-slider w-100" style={{left:'0px'}}
                                value={[0,Number(targetProgress)]}
                                disabled
                                step={1}
                                max={profile.unit==="KM"?Number(event.maximumKm):Number(event.maximumMile)}
                                valueLabelDisplay="off"
                            />
                        </div>
                    </div>
                </div>
                */}
                <div className="d-flex justify-content-between mt-3 pb-3">
                    <div className="d-flex flex-column justify-content-center">{language(profile.language,"myActivity.remaining_days")}</div>
                    <div className="read-only-value w-50">{remainingDays}</div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state,props) {
    let event = state.authReducer.profile.events.find((el)=> el._id===props.match.params.event_id)
    let activities = [...state.activityReducer.drafts,...state.activityReducer.activities].filter((el)=>el.eventId===props.match.params.event_id)
    return {
        profile:state.authReducer.profile,
        activities:activities,
        event:event?event:{ users:[] }
    }
}

export default connect(mapStateToProps)(MyActivity);
