import  {API} from '.';

export const friendServices = {
    getMyFriends,
    addFriends,
    unFriend,
    acceptFriendRequest,
    rejectFriendRequest,
    getCompetitorRanking
};
   
function getMyFriends(page=1, limit=10) {
    let url = '/friends/myFriends?page=' + page + '&limit=' + limit;
    return API.get(url)
}
 
function addFriends(id) {
    let url = '/friends/addFriend';
    let param = {
        receiver: id
    }
    return API.post(url, {}, param)
}

function unFriend(id) {
    let url = '/friends/deleteFriend/'+id;
    return API.del(url, {})
}

function acceptFriendRequest(id){
    return API.patch('/friends/acceptFriend/'+id)
}

function rejectFriendRequest(id){
    return API.del('/friends/rejectFriend/'+id)
}

function getCompetitorRanking(id,event_id){
    return API.get('/users/competitor/'+id+'?eventId='+event_id)
}
 
