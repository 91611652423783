import React, {Component} from 'react';
import {connect} from "react-redux";
import Container from '@material-ui/core/Container';
import { Button, Input, InputLabel } from '@material-ui/core';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import {validator} from "../../helpers";
import {userManagementServices} from '../../services';
import FlagEn from "../../assets/flag-english.jpg";
import FlagGe from "../../assets/flag-Germany.jpg";
import language from '../../helpers/language';

class ResetPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            showLoading: false,
            validator: {
                email: false,
                emptyEmail: false
            },
            language: 'DE',
            isSuccess: false,
            checkExistEmail: false
        }

        this.handleResetPassword = this.handleResetPassword.bind(this);
        this.handleBlurEmail = this.handleBlurEmail.bind(this);
        this.inputEmail = this.inputEmail.bind(this);
    }

    componentDidMount(){
        let pre_language = localStorage.getItem('pre_language');
        if (!pre_language || pre_language === '') pre_language = 'EN';
        localStorage.setItem('pre_language', pre_language)
        this.setState({language: pre_language === 'EN' ? 'DE' : 'EN'});
    }

    handleChangeLanguage = ()=>{
        let currentLanguage = this.state.language;
        this.setState({language:currentLanguage === 'EN' ? 'DE' : 'EN'});
        localStorage.setItem('pre_language', currentLanguage)
    }

    handleResetPassword = (e) => {
        const {email} = this.state;
        let languagetext=this.state.language;
        let lbEmailNotFound = language(languagetext, "label_email_not_found");
        let lbResetPasswordFailed = language(languagetext, "label_password_reset_failed");
        // Check email exist?
        let data ={
            email: email
        }
        this.setState({showLoading: true})
        userManagementServices.forgotPassword(data, languagetext==='EN'?'en':'de').then(response => {
            this.setState({showLoading: false})
            if (response && response.msg) {
                Swal.fire({
                    text: language(languagetext,response.msg),
                    icon: 'info',
                    confirmButtonColor: '#e60003',
                }).then(response => {
                    this.props.history.push('/login');
                })
            }
            else if (response.errors && response.errors.msg){
                let labelText = response.errors.msg.replace(/_/g, ' ')
                Swal.fire({
                    text: labelText,
                    icon: 'error',
                    confirmButtonColor: '#e60003',
                })
            }
            else {
                Swal.fire({
                    text: lbEmailNotFound,
                    icon: 'error',
                    confirmButtonColor: '#e60003',
                })
            }
        }).catch(err => {
            this.setState({showLoading: false})
            Swal.fire({
                text: lbResetPasswordFailed,
                icon: 'error',
                confirmButtonColor: '#e60003',
            })
        });
    }

    inputEmail(e) {
        let strValue = String(e.target.value);
        let vali = this.state.validator;
        vali.emptyEmail = false;
        this.setState({
            email: strValue.trim(),
            isSuccess: false,
            checkExistEmail: false   ,
            validator: vali
        })
        if (validator.isEmail(strValue)) {
            this.setState({validator: {...vali, email: false}});
        }
        else {
            this.setState({validator: {...vali, email: true}});
        }
    }

    handleBlurEmail = () => {
        let email = this.state.email;
        let vali = this.state.validator;
        vali.email = false;
        if (email.trim() === '') {
            vali.emptyEmail = true;
            this.setState({validator: vali});
        }
    }

    render() {
        const { email, validator } = this.state;
        let languagetext=this.state.language;
        let lbRequestPasswordReset = language(languagetext, "request_password_reset");
        let lbYourRegisteredEmail = language(languagetext, "your_registered_email");
        let lbCancel = language(languagetext, "cancel");
        let lbInvalidEmail = language(languagetext, "invalid_email_address");
        let lbEmailRequired = language(languagetext, "email_is_required");

        let disabledButton = false;
        if(email === '' || validator.email || validator.emptyEmail) {
            disabledButton = true;
        }
        return (
            <Container maxWidth="sm">
                <div className="content-login">
                    {
                        this.state.showLoading &&
                        <div className="overlay-loading text-center"><span className="spinner-1 inline-spinner "/>
                        </div>
                    }
                    <div className="app-header-public">
                        <div onClick={this.handleChangeLanguage}>
                            <span className="switch-lang"><img alt="" src={language === 'EN' ? FlagGe : FlagEn} /></span>
                        </div>
                    </div>
                    <div className="col-12 text-center align-items-center px-0 mt-3 mb-3">
                        <h2 className="page-title mb-0 text-uppercase font-weight-600 fz-24 text-center">{lbRequestPasswordReset}</h2>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <InputLabel className="label-input">{lbYourRegisteredEmail} <span className="text-danger">(*)</span></InputLabel>
                                <Input
                                    type="email"
                                    id="email"
                                    className="form-control"
                                    onChange={this.inputEmail}
                                    onBlur={this.handleBlurEmail}
                                    value={this.state.email}
                                    placeholder={lbYourRegisteredEmail}
                                />
                                {this.state.validator.emptyEmail ? <small className="d-block invalid-feedback">{lbEmailRequired}</small> : ''}
                                {this.state.validator.email ? <small className="d-block invalid-feedback">{lbInvalidEmail}</small> : ''}
                                {/* {this.state.checkExistEmail ? <small className="d-block invalid-feedback">Unfortunately, the email you entered was not found.</small> : ''} */}
                                {/* {this.state.isSuccess ? <small className="d-block">Please check your email to reset your password.</small> : ''} */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="text-center">
                                <Button type="button" id="btnReset" variant="contained" color="primary" className="mr-2 mb-2 btn-success" disabled={disabledButton} onClick={() => this.handleResetPassword()}>{lbRequestPasswordReset}</Button>
                                <Link to="/" className="btn btn-gray mb-2" >
                                    {lbCancel}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}

function mapStateToProps(state) {
    const {authReducer} = state;
    return {authReducer}
}

export default connect(mapStateToProps)(ResetPassword);
