let isSubscribed = false;
var swRegistration = null;
var askTimeOut = null;
let applicationKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;

// Url Encription
function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

// Send request to database for add new subscriber
function saveSubscription(subscription) {
    let xmlHttp = new XMLHttpRequest();
    xmlHttp.open("POST", process.env.REACT_APP_API_URL + "/subscribe");
    xmlHttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xmlHttp.setRequestHeader("userId", localStorage.getItem('user_ID'));
    xmlHttp.onreadystatechange = function () {
        if (xmlHttp.readyState !== 4) return;
        if (xmlHttp.status !== 200 && xmlHttp.status !== 304) {
            console.log('HTTP error ' + xmlHttp.status, null);
        } else {
            console.log("User subscribed to server");
        }
    };
    xmlHttp.send(JSON.stringify(subscription));
}

export default function register() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register(`${process.env.REACT_APP_PUBLIC_URL}/service-worker.js`)
        .then(function(registration) {
            swRegistration = registration;
            swRegistration.pushManager.getSubscription().then(function (subscription) {
                if(!localStorage.getItem('user_ID') && subscription !== null){
                    subscription.unsubscribe().then(()=>{
                        console.log('unsubcribed')
                    }) 
                }
                isSubscribed = !(subscription === null);
                if (isSubscribed && localStorage.getItem('user_ID')) {
                    console.log('User is subscribed 1');
                } else {
                    askTimeOut = setInterval(()=>{
                        if(localStorage.getItem('user_ID')){
                            swRegistration.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey: urlB64ToUint8Array(applicationKey)}).then(function (subscription) {
                                console.log(subscription);
                                console.log('User is subscribed 2');
                                saveSubscription(subscription);
                                isSubscribed = true;
                            }).catch(function (err) {
                                console.log('Failed to subscribe user: ', err);
                            })
                            clearInterval(askTimeOut)
                        }
                    },3000)
                }
            })
            console.log('Push notification worker registration successful');
        }).catch(function(err) {
          console.log('Whoops. Service worker registration failed, error:', err);
        });
    }
}
