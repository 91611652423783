import {commnunicateConstant} from '../constants/communicateConstant';

const defaultState = {
    dataCommunicates:[],
    communicateDetail: {},
    loading:false,
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case commnunicateConstant.GET_ALL_COMMUNICATE_REQUEST:
            return {...state,loading:true }
        case commnunicateConstant.GET_ALL_COMMUNICATE_SUCCESS:
            return {...state,
                dataCommunicates: action.payload.data,
                loading:false 
            }
        case commnunicateConstant.GET_ALL_COMMUNICATE_FAILURE:
            return {...state, loading:false }
            
            
        case commnunicateConstant.CREATE_COMMUNICATE_REQUEST:
            return {...state,loading:true }
        case commnunicateConstant.CREATE_COMMUNICATE_SUCCESS:
            return {...state,
                communicateDetail: action.payload.data,
                loading:false 
            }
        case commnunicateConstant.CREATE_COMMUNICATE_FAILURE:
            return {...state, loading:false }
            
            
        case commnunicateConstant.DELETE_COMMUNICATE_REQUEST:
            return {...state,loading:true }
        case commnunicateConstant.DELETE_COMMUNICATE_SUCCESS:
            let oldData = state.dataCommunicates;
            oldData = oldData.filter(x => x._id !== action.payload.id)
            return {...state,
                dataCommunicates: oldData,
                loading:false 
            }
        case commnunicateConstant.DELETE_COMMUNICATE_FAILURE:
            return {...state, loading:false }
            
        default:
            return state
    }
}
  