import React, {Component} from 'react';
import { ButtonGroup, Button, CircularProgress, Paper } from '@material-ui/core';
import {connect} from "react-redux";
import InfiniteScroll from 'react-infinite-scroll-component';
import { newsfeedAction } from '../../actions/newsfeed-action';
import { API } from '../../services/api';
import Feed from './Feed';
import language from '../../helpers/language';

import RefreshIcon from '@material-ui/icons/Refresh';

class NewsFeed extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected:'friend',
            numberOfLoad:0,
            isRefresh:true,
            feed:null
        }
        this.scroll = 0
    }
    
    componentDidMount() {
        let selected = window.localStorage.getItem('newsfeedFilter')
        if(selected)
           this.setState({ selected: selected })
        if(!this.props.loadingNewsfeedAll && !this.props.loadingNewsfeedFriend){
            let initialScrollY = window.localStorage.getItem('initialScrollY_newsfeed')
            if(initialScrollY){
                setTimeout(()=>{
                    document.getElementById('app-content').scrollTop = initialScrollY
                    window.localStorage.removeItem('initialScrollY_newsfeed')
                },200)
            }else{
                this.props.refreshNewsfeedAll()
                this.props.refreshNewsfeedFriend()
            }
        }
        API.get('/activities/newFeedsAdmin').then((res)=>{
            if(res.docs && res.docs[0])
                this.setState({ feed:{ title:res.docs[0].title, notice:res.docs[0].notice } })
        })
    }

    componentWillUnmount(){
        window.localStorage.setItem('newsfeedFilter',this.state.selected)
    }

    saveScroll = ()=>{
        window.localStorage.setItem('initialScrollY_newsfeed',this.scroll)
    }

    onScrollNewsfeedAll = (e)=>{
        this.scroll = e.target.scrollTop
        if(e.target.scrollTop  >= (e.target.scrollHeight - e.target.offsetHeight - 300) && this.props.newsfeedsAllHasMore && !this.props.loadingNewsfeedAll && this.props.online)
            this.loadMoreNewsfeedAll()
    }

    onScrollNewsfeedFriend = (e)=>{
        this.scroll = e.target.scrollTop
        if(e.target.scrollTop  >= (e.target.scrollHeight - e.target.offsetHeight - 300) && this.props.newsfeedsFriendHasMore && !this.props.loadingNewsfeedFriend && this.props.online)
            this.loadMoreNewsfeedFriend()
    }

    selectType = (type)=>{
        this.setState({ selected:type })
    }

    loadMoreNewsfeedAll = ()=>{
        const { loadMoreNewsfeedAll, newsfeedsAllPage, online } = this.props
        if(online){
            loadMoreNewsfeedAll({page:newsfeedsAllPage+1,limit:10})
            this.setState({ numberOfLoad: this.state.numberOfLoad+1, isRefresh:false})
        }
    }

    refreshNewsfeedAll = ()=>{
        const { refreshNewsfeedAll, online, loadingNewsfeedAll } = this.props
        if(online && !loadingNewsfeedAll){
            refreshNewsfeedAll()
            this.setState({ numberOfLoad: this.state.numberOfLoad+1, isRefresh:true })
        }
    }

    renderNewsfeedAll = ()=>{
        const { loadingNewsfeedAll, newsfeedsAllHasMore, newsfeedsAll, createComment, profile, online, like, unlike, userID } = this.props
        const { loadMoreNewsfeedAll, refreshNewsfeedAll, onScrollNewsfeedAll, saveScroll } = this
        const { numberOfLoad, isRefresh } = this.state
        return(
            <div key={'newsfeedFriend-'+numberOfLoad}>
                {(loadingNewsfeedAll && isRefresh && online)?<CircularProgress className="mt-3"/>:null}
                <InfiniteScroll dataLength={newsfeedsAll.length} next={loadMoreNewsfeedAll} refreshFunction={refreshNewsfeedAll} hasmore={newsfeedsAllHasMore}
                    pullDownToRefresh 
                    scrollableTarget="app-content"
                    onScroll={onScrollNewsfeedAll}
                    pullDownToRefreshThreshold={100}
                    pullDownToRefreshContent={<RefreshIcon fontSize="large" color="action"/>}
                    releaseToRefreshContent={<RefreshIcon fontSize="large" color="primary"/>}
                    loader={<CircularProgress/>}>
                    {newsfeedsAll.map((newfeed)=> 
                        <div key={newfeed._id} onClick={saveScroll}>
                            <Feed 
                                newsfeed={newfeed} 
                                submit={createComment} 
                                profile={profile} 
                                like={like} 
                                unlike={unlike} 
                                userID={userID} 
                                isEventEnded={newfeed.isEventEnded}
                            />
                        </div>
                    )}
                </InfiniteScroll>
                {(loadingNewsfeedAll && !isRefresh && online)?<CircularProgress className="mt-3"/>:null}
            </div>
        )
    }

    loadMoreNewsfeedFriend = ()=>{
        const { loadMoreNewsfeedFriend, newsfeedsFriendPage, online } = this.props
        if(online){
            loadMoreNewsfeedFriend({page:newsfeedsFriendPage+1,limit:10})
            this.setState({ numberOfLoad: this.state.numberOfLoad+1, isRefresh:false})
        }
    }

    refreshNewsfeedFriend = ()=>{
        const { refreshNewsfeedFriend, online, loadingNewsfeedFriend } = this.props
        if(online && !loadingNewsfeedFriend){
            refreshNewsfeedFriend()
            this.setState({ numberOfLoad: this.state.numberOfLoad+1, isRefresh: true})
        }
    }

    renderNewsfeedFriend = ()=>{
        const { newsfeedsFriend, loadingNewsfeedFriend, newsfeedsFriendHasMore, createComment, profile, online, like, unlike, userID } = this.props
        const { loadMoreNewsfeedFriend, refreshNewsfeedFriend, onScrollNewsfeedFriend, saveScroll } = this
        const { numberOfLoad, isRefresh } = this.state
        return(
            <div key={'newsfeedFriend-'+numberOfLoad}>
                {(loadingNewsfeedFriend && isRefresh && online)?<CircularProgress className="mt-3"/>:null}
                <InfiniteScroll dataLength={newsfeedsFriend.length} next={loadMoreNewsfeedFriend} refreshFunction={refreshNewsfeedFriend} hasmore={newsfeedsFriendHasMore}
                    pullDownToRefresh 
                    scrollableTarget="app-content"
                    onScroll={onScrollNewsfeedFriend}
                    pullDownToRefreshThreshold={100}
                    pullDownToRefreshContent={<RefreshIcon fontSize="large" color="action"/>}
                    releaseToRefreshContent={<RefreshIcon fontSize="large" color="primary"/>}
                    loader={<CircularProgress/>}>
                    {newsfeedsFriend.map((newfeed)=> 
                        <div key={newfeed._id} onClick={saveScroll}>
                            <Feed 
                                userID={userID} 
                                newsfeed={newfeed} 
                                submit={createComment} 
                                profile={profile} 
                                like={like} 
                                unlike={unlike} 
                            />
                        </div>
                    )}
                </InfiniteScroll>
                {(loadingNewsfeedFriend && !isRefresh && online)?<CircularProgress className="mt-3"/>:null}
            </div>
        )
    }

    render() {
        const { selected, feed } = this.state
        const { profile } = this.props
        const { selectType, renderNewsfeedAll, renderNewsfeedFriend } = this
        return (
            <div className="container text-center pt-4 pb-4 overrideBackgroundTab">
                <ButtonGroup color="primary" fullWidth className="mb-2">
                    <Button className="w-50" variant={selected==="all"?"contained":""} onClick={()=>selectType('all')}>{language(profile.language,"newsfeed.filter_all_button")}</Button>
                    <Button className="w-50" variant={selected==="friend"?"contained":""} onClick={()=>selectType('friend')}>{language(profile.language,"newsfeed.filter_friends_button")}</Button>
                </ButtonGroup>
                {feed?
                <Paper elevation={3} className="color-red p-2" style={{position: 'sticky', top:'4px',zIndex:100, minHeight:'180px'}}>
                    <h6 className="text-left text-red" style={{wordBreak:'break-word'}}><b>ZwickRoell runs the world</b></h6>
                    <div className="text-left" style={{wordBreak: 'break-word', color: "#262626", fontSize:'1rem',whiteSpace: 'pre'}}>
                        {feed.notice}
                    </div>
                </Paper>:null
                }
                {selected==='all'?renderNewsfeedAll():null}
                {selected==='friend'?renderNewsfeedFriend():null}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {...state.authReducer,...state.newsfeedReducer}
}

function mapDispatchToProps(dispatch) {
    return {
        refreshNewsfeedAll: ()=> dispatch(newsfeedAction.refreshNewsfeedAll()),
        loadMoreNewsfeedAll: (params)=> dispatch(newsfeedAction.loadNewsfeedAll(params)),
        refreshNewsfeedFriend: ()=> dispatch(newsfeedAction.refreshNewsfeedFriend()),
        loadMoreNewsfeedFriend:(params)=> dispatch(newsfeedAction.loadNewsfeedFriend(params)),
        createComment: (params)=> dispatch(newsfeedAction.createComment(params)),
        like: (id) => dispatch(newsfeedAction.like(id)),
        unlike: (id) => dispatch(newsfeedAction.unlike(id))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(NewsFeed);