export const rankingConstant = {
    REFRESH_RANKING_ALL_REQUEST: 'REFRESH_RANKING_ALL_REQUEST',
    REFRESH_RANKING_ALL_SUCCESS: 'REFRESH_RANKING_ALL_SUCCESS',
    REFRESH_RANKING_ALL_FAILURE: 'REFRESH_RANKING_ALL_FAILURE',

    LOAD_MORE_RANKING_ALL_REQUEST: 'LOAD_MORE_RANKING_ALL_REQUEST',
    LOAD_MORE_RANKING_ALL_SUCCESS: 'LOAD_MORE_RANKING_ALL_SUCCESS',
    LOAD_MORE_RANKING_ALL_FAILURE: 'LOAD_MORE_RANKING_ALL_FAILURE',
    
    REFRESH_RANKING_FRIENDS_REQUEST: 'REFRESH_RANKING_FRIENDS_REQUEST',
    REFRESH_RANKING_FRIENDS_SUCCESS: 'REFRESH_RANKING_FRIENDS_SUCCESS',
    REFRESH_RANKING_FRIENDS_FAILURE: 'REFRESH_RANKING_FRIENDS_FAILURE',

    LOAD_MORE_RANKING_FRIENDS_REQUEST: 'LOAD_MORE_RANKING_FRIENDS_REQUEST',
    LOAD_MORE_RANKING_FRIENDS_SUCCESS: 'LOAD_MORE_RANKING_FRIENDS_SUCCESS',
    LOAD_MORE_RANKING_FRIENDS_FAILURE: 'LOAD_MORE_RANKING_FRIENDS_FAILURE',
    
    REFRESH_RANKING_ME_REQUEST: 'REFRESH_RANKING_ME_REQUEST',
    REFRESH_RANKING_ME_SUCCESS: 'REFRESH_RANKING_ME_SUCCESS',
    REFRESH_RANKING_ME_FAILURE: 'REFRESH_RANKING_ME_FAILURE',

    LOAD_MORE_RANKING_ME_REQUEST: 'LOAD_MORE_RANKING_ME_REQUEST',
    LOAD_MORE_RANKING_ME_SUCCESS: 'LOAD_MORE_RANKING_ME_SUCCESS',
    LOAD_MORE_RANKING_ME_FAILURE: 'LOAD_MORE_RANKING_ME_FAILURE',
}