import React, { Component } from "react";
import { Card, Avatar, IconButton, Popover } from "@material-ui/core";
import { Link } from "react-router-dom";
import PersonAdd from "@material-ui/icons/PersonAdd";
import IconAvatar from "../../assets/avatar_icon.png";
import language from "../../helpers/language";
import numeral from "numeral";

export default class RankingItem extends Component {
  state = {
    anchorEl: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  componentDidMount() {
    if (this.props.languageText === "DE") numeral.locale("de");
    else numeral.locale("en");
  }

  numberFormat = (num) => {
    return numeral(num).format("0,0.[0]");
  };

  checkOutApp = (displayName) => {
    if (
      displayName &&
      (displayName.toLowerCase() === "anonymous" ||
        displayName.toLowerCase() === "logged out")
    )
      return true;
    else return false;
  };

  viewName = () => {
    //const { data, userId } = this.props;
    const { data, languageText } = this.props;
    let name = data.creatorId.displayName;
    //if(data.creatorId.activityShare === 'Private' && data.creatorId._id !== userId)
    //    name = 'Anonymous'
    //if(data.creatorId.activityShare === 'Friends' && data.isFriend !=='YES')
    //    name = 'Anonymous'
    if (this.checkOutApp(data.creatorId.displayName))
      name = language(languageText, "logged_out");
    return name;
  };

  teamSortname = (name) => {
    let words = name.split(" ");
    if (words.length >= 2)
      return (words[0].slice(0, 1) + words[1].slice(0, 1)).toUpperCase();
    else return words[0].slice(0, 2).toUpperCase();
  };

  setData = (data) => {
    localStorage.setItem("competitor", JSON.stringify({ ...data }));
  };

  handlePopoverClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { data, languageText, showAddFriend, eventId, userId, type } =
      this.props;
    const { numberFormat, checkOutApp, viewName, teamSortname } = this;
    let name = viewName();
    return (
      <Card variant="outlined" className="mt-2 p-1">
        <div className="d-flex">
          <Link
            to={
              name === language(languageText, "logged_out") || type === "team"
                ? "#"
                : "/competitor/" + data.creatorId._id + "/" + eventId
            }
            className="align-self-center"
            onClick={() =>
              this.setData({ ...data.creatorId, isFriend: data.isFriend })
            }
          >
            <div className="number-ranking">{data.rank}</div>
          </Link>
          <Link
            to={
              name === language(languageText, "logged_out") || type === "team"
                ? "#"
                : "/competitor/" + data.creatorId._id + "/" + eventId
            }
            className="newsfeed-avatar ml-1 align-self-center"
            onClick={() =>
              this.setData({ ...data.creatorId, isFriend: data.isFriend })
            }
          >
            {type !== "team" ? (
              <Avatar
                alt="Avatar"
                src={data.creatorId.avatar ? data.creatorId.avatar : IconAvatar}
                className="mt-1"
              />
            ) : (
              <Avatar alt="Avatar" className="mt-1">
                {teamSortname(name)}
              </Avatar>
            )}
          </Link>
          <Link
            to={
              name === language(languageText, "logged_out") || type === "team"
                ? "#"
                : "/competitor/" + data.creatorId._id + "/" + eventId
            }
            className="text-left text-dark header-title align-self-center pt-2 pr-1"
            style={{ width: "40%", wordBreak: "break-word" }}
            onClick={() =>
              this.setData({ ...data.creatorId, isFriend: data.isFriend })
            }
          >
            <h6>{name}</h6>
          </Link>
          <Link
            to={
              name === language(languageText, "logged_out") || type === "team"
                ? "#"
                : "/competitor/" + data.creatorId._id + "/" + eventId
            }
            className="text-success align-self-center pt-2"
            onClick={() =>
              this.setData({ ...data.creatorId, isFriend: data.isFriend })
            }
          >
            <h6 className="color-red" style={{ whiteSpace: "nowrap" }}>
              {this.props.unit === "KM"
                ? numberFormat(data.distanceKm) +
                  " " +
                  language(languageText, "km")
                : numberFormat(data.distanceMile) +
                  " " +
                  language(languageText, "mile")}
            </h6>
          </Link>
          {showAddFriend &&
            data.isFriend === "NO" &&
            userId !== data.creatorId._id &&
            !checkOutApp(data.creatorId.displayName) &&
            (data.creatorId.acceptFriendship ? (
              <IconButton
                className={"pr-0 pt-0 pb-0 pl-2 color-red"}
                onClick={() =>
                  this.props.handleRequestAddFriend(data.creatorId._id)
                }
              >
                <PersonAdd />
              </IconButton>
            ) : (
              <>
                <IconButton
                  className={"pr-0 pt-0 pb-0 pl-2"}
                  onClick={this.handlePopoverClick}
                  aria-describedby={this.id}
                >
                  <PersonAdd />
                </IconButton>
                <Popover
                  id={
                    Boolean(this.state.anchorEl)
                      ? "no-friend-requests"
                      : undefined
                  }
                  open={Boolean(this.state.anchorEl)}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handlePopoverClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <div className="p-2 bg-secondary text-white">
                    {language(languageText, "friendship_request_not_allowed")}
                  </div>
                </Popover>
              </>
            ))}
        </div>
      </Card>
    );
  }
}
