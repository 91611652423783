import React, {Component} from 'react';
import {connect} from "react-redux";
import { Card , Button } from '@material-ui/core';
import { teamAction } from '../../actions/team-action';
import language from '../../helpers/language';

class TeamItem extends Component {

    handleRegisterEvent = () => {
      this.props.registerTeam(this.props.data._id)
    }

    handleUnRegisterEvent = () => {
        this.props.registerTeam(this.props.data._id)
    }

    render() {
        const { data, profile } = this.props;
        let languageText = this.props.profile ? this.props.profile.language : 'EN';
        let isRegistered = profile.teamId === data._id;
        return (
            <Card variant="outlined" className="mt-2 p-2">
                <div className="d-block">
                    <h6 className="text-left align-self-center">{data.name}</h6>
                    <div className="d-flex justify-content-between">
                        <span className="text-left m-0 align-self-center ">{data.description}</span>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            className={isRegistered ? "btn-registered" : "btn-success"} 
                            disabled={data._id && !isRegistered} 
                            onClick={isRegistered?this.handleUnRegisterEvent:this.handleRegisterEvent}>
                                {isRegistered?language(languageText,"registered"):language(languageText,"register")}
                        </Button>
                    </div>
                </div>
            </Card >
        )
    }
}

function mapStateToProps(state) {
    console.log(state)
    return {...state.eventReducer, 
        profile: state.authReducer.profile}
}

function mapDispatchToProps(dispatch) {
    return {
        registerTeam: (id) => dispatch(teamAction.registerTeam(id)),
        leaveTeam:()=> dispatch(teamAction.leaveTeam())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(TeamItem);