import React, { Component } from 'react';
import { CircularProgress, FormLabel, Button } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from "react-redux";
import NotificationItem from './NotificationItem'
import RefreshIcon from '@material-ui/icons/Refresh';
import { notificationAction } from '../../actions/notification-action';
import language from '../../helpers/language';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirm } from '../../helpers/toastr';

class Notifications extends Component {
    constructor(props) {
        super(props)
        this.state = {
            numberOfLoad: 0,
            loading:false
        }
    }

    componentDidMount() {
        // this.refresh();
    }

    refresh = () => {
        this.setState({ numberOfLoad: this.state.numberOfLoad + 1 })
        if (this.props.online && !this.props.loadingNotification) {
            this.props.loadNotification()
        }
    }

    handleClick = (data) => {
        switch (data.type.toString()) {
            case '1000':
                this.props.history.push({
                    pathname: '/notificationDetail',
                    data: data
                })

                break;
            case '1001': /*NEW_EVENT:*/
                this.props.history.push({
                    pathname: '/active-events'
                })
                break;
            case '1002': /*NEW_ACTIVITY*/
                this.props.history.push({
                    pathname: '/newsfeed/' + data.modelId.toString()
                })
                break;
            case '1003': /*NEW_COMMENT*/
                this.props.history.push({
                    pathname: '/newsfeed/'+ data.modelId.toString()
                })
                break;
            case '1004': /*NEW_LIKE*/
                this.props.history.push({
                    pathname: '/newsfeed/' + data.modelId.toString()
                })
                break;
            case '1005': /*NEW_REQUEST_FRIEND*/
                this.props.history.push({
                    pathname: '/myfriends',
                    tab: 'request'
                })
                break;
            case '1006': /*ACCEPT_FRIEND*/
                this.props.history.push({
                    pathname: '/myfriends'
                })
                break;
            case '1007': /*REJECT_FRIEND*/
                this.props.history.push({
                    pathname: '/myfriends'
                })
                break;
            default:
                this.props.history.push({
                    pathname: '/'
                })
        }
    }

    deleteAll = ()=>{
        confirm(()=>{
            this.setState({ loading:true })
            this.props.deleteAllNotification().then(()=>{
                this.setState({ loading:false })
            })
        }, this.props.profile.language,language(this.props.profile.language,'confirm_delete_all_notification'))
    }

    
    render() {
        const { numberOfLoad, loading } = this.state;
        const { notifications, loadingNotification, profile, online } = this.props
        return (
            <div className="container text-center pt-4 pb-5">
                <div>
                    {(loadingNotification && online) ? <CircularProgress className="mt-3" /> : null}
                    <InfiniteScroll key={'notify-' + numberOfLoad} dataLength={notifications?notifications.length:0} refreshFunction={this.refresh} hasmore={false}
                        pullDownToRefresh
                        scrollableTarget="app-content"
                        pullDownToRefreshThreshold={100}
                        pullDownToRefreshContent={<RefreshIcon fontSize="large" color="action" />}
                        releaseToRefreshContent={<RefreshIcon fontSize="large" color="primary" />}
                        loader={<CircularProgress />}>
                        {notifications && notifications.map((item) => <NotificationItem key={item._id} data={item} languageText={profile.language} handleClick={this.handleClick} />)}
                    </InfiniteScroll>
                    {!loadingNotification && notifications && notifications.length === 0 &&
                        <div className="row">
                            <div className="col-12">
                                <div className="mt-3">
                                    <FormLabel className="label-input">{language(profile.language, "no_record_found")}</FormLabel>
                                </div>
                            </div>
                        </div>
                    }
                    {notifications.length !== 0 ?
                    <div className="w-100 text-center" style={{position:'absolute',bottom:'70px'}}>
                        <Button className="backgroundColorPrimaryOverride" variant="contained" color="secondary" startIcon={loading?<CircularProgress size={20}/>:<DeleteIcon fontSize="small"/>} style={{fontSize:'10px',marginLeft:'-25px'}} onClick={this.deleteAll} disabled={loading}>
                            {language(profile.language, "clear_all")}
                        </Button>
                    </div>:null
                    }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { notificationReducer, authReducer } = state;
    return {
        ...notificationReducer,
        notifications: notificationReducer.notifications.docs?notificationReducer.notifications.docs:[],
        profile: authReducer.profile,
        online: authReducer.online,
        loadingNotification: notificationReducer.loadingNotification
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadNotification: () => dispatch(notificationAction.getAllNotification()),
        deleteAllNotification: () => dispatch(notificationAction.deleteNotificationAll())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
