import { API } from '../services/api';
import { toastr } from '../helpers/toastr';
import language from '../helpers/language';

const getReport = (type,eventId= null) =>{
  return (dispatch,getState) => {
    dispatch({ type: 'GET_REPORT' })
    let lang = getState().authReducer.profile.language
    let url = '/users/report/countryAndType?type='+type
    if(eventId)
      url = url+'&&eventId='+eventId
    return API.get(url,null).then((res)=>{
      if(res.errors){
        toastr('error',language(lang,'teams.get_report_failed'))
        dispatch(getReportFail(res.errors.msg.replace(/_/g, ' ')))
      }else{
        //toastr('success',language(lang,'teams.get_teams_success'))
        dispatch(getReportSuccess(res));
      }
    }).catch((error)=>{
      toastr('error',language(lang,'teams.get_report_failed'))
      dispatch(getReportFail())
    })
  };
}

const getReportSuccess = (data) => {
  return { type: 'GET_REPORT_SUCCESS', payload: { data: data } }
}

const getReportFail = (error) => {
  return { type: 'GET_REPORT_FAIL' }
}

const getReportUsers = (type,country,userType,eventId= null) =>{
  return (dispatch,getState) => {
    dispatch({ type: 'GET_REPORT_USERS' })
    let lang = getState().authReducer.profile.language
    let url = '/users/report/countryAndTypeDetails?type='+type
    if (country) {
      url += '&country=' + country
    }
    if (userType) {
      url += '&userType=' + userType
    }
    if(eventId)
      url = url+'&&eventId='+eventId
    return API.get(url,null).then((res)=>{
      if(res.errors){
        toastr('error',language(lang,'teams.get_report_failed'))
        dispatch(getReportUsersFail(res.errors.msg.replace(/_/g, ' ')))
      }else{
        //toastr('success',language(lang,'teams.get_teams_success'))
        dispatch(getReportUsersSuccess(res));
      }
    }).catch((error)=>{
      toastr('error',language(lang,'teams.get_report_failed'))
      dispatch(getReportUsersFail())
    })
  };
}

const getReportUsersSuccess = (data) => {
  return { type: 'GET_REPORT_USERS_SUCCESS', payload: { data: data } }
}

const getReportUsersFail = (error) => {
  return { type: 'GET_REPORT_USERS_FAIL' }
}

export const reportAction = {
    getReport,
    getReportUsers,
};
      