import { userManagementServices } from '../services';
import { API } from '../services/api';
import {userManagementConstant} from '../constants/userManagementConstant';
import { saveState } from '../helpers/toastr';

const authentication = (email,password) => {
  return (dispatch,getState) => {
    dispatch({
      type: 'AUTH',
      payload: { email:email, password:password }
    })
    // setTimeout(() => {
    //   dispatch(authenticationSuccess());
    // }, 3000);
    return userManagementServices.login({ email:email, password:password })
            .then(response => {
                console.log(response)
                if(response && response.token) {              
                    localStorage.setItem('user_role', response.user.role)      
                    localStorage.setItem('auth_token',response.token)
                    localStorage.setItem('user_ID',response.user._id)
                    localStorage.setItem('user_avatar',response.user.avatar)
                    localStorage.setItem('user_name',response.user.displayName ? response.user.displayName : response.user.firstName + ' ' + response.user.lastName)
                    dispatch(authenticationSuccess(response))
                    saveState(getState())
                    return response
                } else {
                    dispatch(authenticationFail());
                    return response;
                }
            })
  };
}

const authenticationSuccess = (response) => {
  return {
    type: 'AUTH_SUCCESS',
    payload: { token: response.token },
    user: response.user
  }
}

const authenticationFail = () => {
  return {
    type: 'AUTH_FAIL',
    payload: { error: 'error' }
  }
}

function logout(link = null) {
  return dispatch => {
      dispatch(request())      
      localStorage.removeItem('auth_token');
      localStorage.removeItem('user_ID');
      localStorage.removeItem('user_avatar');
      localStorage.removeItem('user_name');
      localStorage.clear();
      dispatch(success())      
      return {success: true, authed: false}
      function request() { return { type: 'LOGOUT_REQUEST' } }
      function success() {
        localStorage.removeItem('store')
        if(link)
            setTimeout(()=>{
                window.location.href = link
                window.location.reload()
            },200)
        else
            setTimeout(()=>{window.location.reload()},200)
        return {type: 'LOGOUT_SUCCESS'}
      }
  }
}

function register(data, language='de') {
  return dispatch => {
      dispatch(request())
      return userManagementServices.register(data, language)
          .then(response => {
              if(response && response.token) {
                  dispatch(success())
                  return response
              } else {
                  dispatch(failure(response.errors));
                  return response;
              }
          })
      function request() { return { type: userManagementConstant.REGISTER_REQUEST } }
      function success() {
          return {type: userManagementConstant.REGISTER_SUCCESS}
      }
      function failure(error) {
          return {type: userManagementConstant.REGISTER_FAILURE, error}
      }
  }
}

function getProfile() {
    return (dispatch,getState) => {
        dispatch(request())
        return userManagementServices.getProfile()
            .then(response => {
                if(response.email) {
                      dispatch(success(response))
                      saveState(getState())
                      return response;
                } else {
                      dispatch(failure(response.errors));
                      return response;
                }
            })
        function request() { return { type: userManagementConstant.GET_PROFILE_REQUEST } }
        function success(user) {
            return {type: userManagementConstant.GET_PROFILE_SUCCESS, user: user}
        }
        function failure(error) {
            return {type: userManagementConstant.GET_PROFILE_FAILURE, error}
        }
    }
}

function getProfileEvent(){
    return (dispatch,getState) => {
        dispatch({ type: 'GET_PROFILE_EVENT' })
        return API.get('/profile/events').then(response => {
            if(response.Status===200) {
                delete(response.Status)
                dispatch({ type: 'GET_PROFILE_EVENT_SUCCESS', payload:response })
                saveState(getState())
                return response;
            } else {
                dispatch({ type: 'GET_PROFILE_EVENT_FAIL' });
                return response;
            }
        })
    }  
}

function getProfileActivity(){
    return (dispatch,getState) => {
        dispatch({ type: 'GET_PROFILE_ACTIVITIES' })
        return API.get('/profile/myActivities').then(response => {
            if(response.Status===200) {
                delete(response.Status)
                dispatch({ type: 'GET_PROFILE_ACTIVITIES_SUCCESS', payload:response })
                saveState(getState())
                return response;
            } else {
                dispatch({ type: 'GET_PROFILE_ACTIVITIES_FAIL' });
                return response;
            }
        })
    }  
}

function getProfileFriendItem(){
    return (dispatch,getState) => {
        dispatch({ type: 'GET_PROFILE_FRIEND_ITEM' })
        let p1 = API.get('/profile/friends')
        let p2 = API.get('/profile/waitingAcceptFriends')
        let p3 = API.get('/profile/sendingRequestFriends')
        return Promise.all([p1,p2,p3]).then(response => {
            let valid = true
            response.map((el)=>{
                if(el.Status!==200)
                    valid = false
                return null
            })
            if(valid) {
                delete(response.Status)
                dispatch({ type: 'GET_PROFILE_FRIEND_ITEM_SUCCESS', payload:response })
                saveState(getState())
                return response;
            } else {
                dispatch({ type: 'GET_PROFILE_FRIEND_ITEM_FAIL' });
                return response;
            }
        })
    }  
}

function updateProfile(data) {
  return (dispatch,getState) => {
      dispatch(request())
      return userManagementServices.updateProfile(data)
          .then(response => {
              if(response && response.Status === 200) {
                  dispatch(success())
                  saveState(getState())
                  return response
              } else {
                  dispatch(failure(response.errors));
                  throw response;
              }
          })

      function request() { return { type: userManagementConstant.UPDATE_USER_REQUEST } }
      function success() {
        return {type: userManagementConstant.UPDATE_USER_SUCCESS}
      }
      function failure(error) {
          return {type: userManagementConstant.UPDATE_USER_FAILURE, error}
      }
  }
}

function changePassword(data) {
    return dispatch => {
        dispatch(request())
        return userManagementServices.changePassword(data)
            .then(response => {
                if(response && response.errors) {
                    dispatch(failure(response.errors));
                    return response;
                } else {
                    dispatch(success())
                    return response
                }
            })
        function request() { return { type: userManagementConstant.CHANGE_PASSWORD_REQUEST } }
        function success() {
            return {type: userManagementConstant.CHANGE_PASSWORD_SUCCESS}
        }
        function failure(error) {
            return {type: userManagementConstant.CHANGE_PASSWORD_FAILURE, error}
        }
    }
}

function resetPassword(data, language='de') {
    return dispatch => {
        dispatch(request())
        return userManagementServices.resetPassword(data, language)
            .then(response => {
                if(response) {
                    dispatch(success())
                    return response
                } else {
                    dispatch(failure(response.errors));
                    return response;
                }
            })
        function request() { return { type: userManagementConstant.RESET_PASSWORD_REQUEST } }
        function success() {
            return {type: userManagementConstant.RESET_PASSWORD_SUCCESS}
        }
        function failure(error) {
            return {type: userManagementConstant.RESET_PASSWORD_FAILURE, error}
        }
    }
}
 
function forgotPassword(data, language='de') {
    return dispatch => {
        dispatch(request())
        return userManagementServices.forgotPassword(data, language)
            .then(response => {
                if(response && response.token) {
                    dispatch(success())
                    return response
                } else {
                    dispatch(failure(response.errors));
                    return response;
                }
            })
        function request() { return { type: userManagementConstant.RESET_PASSWORD_REQUEST } }
        function success() {
            return {type: userManagementConstant.RESET_PASSWORD_SUCCESS}
        }
        function failure(error) {
            return {type: userManagementConstant.RESET_PASSWORD_FAILURE, error}
        }
    }
}
  
function getAllUsers(keySearch, page, limit) {
    return dispatch => {
        dispatch(request())
        return userManagementServices.getAllUsers(keySearch, page, limit)
            .then(response => {
                if(response && response.errors) {
                    dispatch(failure(response.errors));
                    return response;
                } else {
                    console.log(response)
                    dispatch(success(response))
                    return response
                }
            })
        function request() { return { type: userManagementConstant.GET_ALL_USERS_REQUEST } }
        function success(response) {
            return {type: userManagementConstant.GET_ALL_USERS_SUCCESS, data: response.docs}
        }
        function failure(error) {
            return {type: userManagementConstant.GET_ALL_USERS_FAILURE, error}
        }
    }
}
  
function getUserDetail(data) {
    return dispatch => {
        dispatch(request())
        return userManagementServices.getUserDetail(data)
            .then(response => {
                if(response && response.errors) {
                    dispatch(failure(response.errors));
                    return response;
                } else {
                    dispatch(success(response))
                    return response
                }
            })
        function request() { return { type: userManagementConstant.GET_USER_DETAIL_REQUEST } }
        function success(response) {
            return {type: userManagementConstant.GET_USER_DETAIL_SUCCESS, data: response}
        }
        function failure(error) {
            return {type: userManagementConstant.GET_USER_DETAIL_FAILURE, error}
        }
    }
}
  
function updateUserDetail(data) {
    return dispatch => {
        dispatch(request())
        return userManagementServices.updateUser(data)
            .then(response => {
                if(response && response.errors) {
                    dispatch(failure(response.errors));
                    return response;
                } else {
                    dispatch(success(response))
                    return response
                }
            })
        function request() { return { type: userManagementConstant.UPDATE_USER_DETAIL_REQUEST } }
        function success(response) {
            return {type: userManagementConstant.UPDATE_USER_DETAIL_SUCCESS, data: response}
        }
        function failure(error) {
            return {type: userManagementConstant.UPDATE_USER_DETAIL_FAILURE, error}
        }
    }
}
   
function deleteUser(data) {
    return dispatch => {
        dispatch(request())
        return userManagementServices.deleteUser(data)
            .then(response => {
                if(response && response.errors) {
                    dispatch(failure(response.errors));
                    return response;
                } else {
                    dispatch(success())
                    return response
                }
            })
        function request() { return { type: userManagementConstant.DELETE_USER_REQUEST } }
        function success() {
            return {type: userManagementConstant.DELETE_USER_SUCCESS}
        }
        function failure(error) {
            return {type: userManagementConstant.DELETE_USER_FAILURE, error}
        }
    }
}

function deleteUserFriendShipItem(id) {
    return (dispatch,getState)=>{
        dispatch({ type: userManagementConstant.DELETE_USER_FRIENDSHIP_ITEM, payload: { id:id } })
        saveState(getState())
    }
}

function changeOnlineStatus(status){
    return { type:'CHANGE_ONLINE_STATUS', data:status }
}
export const authAction = {
    authentication,
    logout,
    register,
    updateProfile,
    getProfile,
    getProfileEvent,
    getProfileActivity,
    getProfileFriendItem,
    changePassword,
    forgotPassword,
    resetPassword,
    getAllUsers,
    getUserDetail,
    updateUserDetail,
    deleteUser,
    deleteUserFriendShipItem,
    changeOnlineStatus
};
  