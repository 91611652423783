import React, { useEffect } from "react"
import { connect } from "react-redux"

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Divider,
  AppBar,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  Typography
} from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Link, Route, Redirect } from "react-router-dom"

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import IconAvatar from "../assets/avatar_icon.png"
import MenuIcon from "@material-ui/icons/Menu"
import NotificationsIcon from "@material-ui/icons/Notifications"
import PeopleAltIcon from "@material-ui/icons/PeopleAlt"
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew"
import EventIcon from "@material-ui/icons/Event"
import EventNoteIcon from "@material-ui/icons/EventNote"
import AssessmentIcon from "@material-ui/icons/Assessment"
import DashboardIcon from "@material-ui/icons/Dashboard"

import { authAction } from "../actions/auth-action"
import language from "../helpers/language"
import FlagEn from "../assets/flag-english.jpg"
import FlagGe from "../assets/flag-Germany.jpg"

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    ...(process.env.REACT_APP_BUILD === "production"
      ? {
          backgroundColor: "#e60003"
        }
      : {
          backgroundColor: "#2563eb"
        })
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1
  }
}))

function AdminRoutes(props) {
  console.log("app_adminRoutes", props)
  const { container, path, exact, authed, headerTitle, location, back } = props
  const userAvatar = localStorage.getItem("user_avatar")
  const userName = localStorage.getItem("user_name")
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [languageText, setLanguageText] = React.useState("DE")

  useEffect(() => {
    let pre_language = localStorage.getItem("pre_language_admin")
    if (!pre_language || pre_language === "") pre_language = "EN"
    localStorage.setItem("pre_language_admin", pre_language)
    setLanguageText(pre_language === "EN" ? "DE" : "EN")
  }, [])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  function handleLogout() {
    if (props.authed) {
      props.dispatch(authAction.logout())
    }
  }

  const handleChangeLanguage = () => {
    let currentLanguage = languageText
    setLanguageText(currentLanguage === "EN" ? "DE" : "EN")
    localStorage.setItem("pre_language_admin", currentLanguage)
  }

  const goBack = () => {
    window.history.back()
  }

  const drawer = (
    <div>
      <div />
      <List>
        <ListItem className="text-center flex-column colum-avatar">
          <Avatar className="app-avatar" src={userAvatar ? userAvatar : IconAvatar} />
          <h4>
            <b>{userName ? userName : "User name"}</b>
          </h4>
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/dashboard" selected={path === "/dashboard"}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText>{language(languageText, "dashboard")}</ListItemText>
        </ListItem>
        <ListItem button component={Link} to="/report" selected={path === "/report"}>
          <ListItemIcon>
            <AssessmentIcon />
          </ListItemIcon>
          <ListItemText>{language(languageText, "report")}</ListItemText>
        </ListItem>
        <ListItem button component={Link} to="/events" selected={path === "/events"}>
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          <ListItemText>{language(languageText, "events_text")}</ListItemText>
        </ListItem>
        <ListItem button component={Link} to="/users" selected={path === "/users"}>
          <ListItemIcon>
            <PeopleAltIcon />
          </ListItemIcon>
          <ListItemText>{language(languageText, "users")}</ListItemText>
        </ListItem>
        <ListItem button component={Link} to="/communicates" selected={path === "/communicates"}>
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText>{language(languageText, "communications")}</ListItemText>
        </ListItem>
        <ListItem onClick={handleLogout} style={{ cursor: "pointer" }}>
          <ListItemIcon>
            <PowerSettingsNew />
          </ListItemIcon>
          <ListItemText>{language(languageText, "logout")}</ListItemText>
        </ListItem>
      </List>
    </div>
  )

  if (!authed) return <Redirect to="/login" />
  else {
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className="ml-3">
            {back ? (
              <IconButton edge="start" color="inherit" onClick={goBack}>
                <ArrowBackIosIcon />
              </IconButton>
            ) : null}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" align="center">
              {language(languageText, location.headerTitle ? location.headerTitle : headerTitle)}
            </Typography>
            <div className="app-header-public">
              <div onClick={handleChangeLanguage}>
                <span className="switch-lang" style={{ top: "1.2rem" }}>
                  <img alt="" src={languageText === "EN" ? FlagGe : FlagEn} />
                </span>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content + " p-xl-4"}>
          <div className={classes.toolbar} />
          <Route
            path={path}
            exact={exact}
            component={(propsCom) => <props.component languagetext={languageText} {...propsCom} />}
            showLabels
          />
        </main>
      </div>
    )
  }
}

// class AdminRoutes extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             languageText: 'DE',
//         }
//     }

//     componentDidMount(){
//         let pre_language = localStorage.getItem('pre_language_admin');
//         if (!pre_language || pre_language === '') pre_language = 'EN';
//         localStorage.setItem('pre_language_admin', pre_language)
//         this.setState({languageText: pre_language === 'EN' ? 'DE' : 'EN'});
//     }

//     handleChangeLanguage = ()=>{
//         let currentLanguage = this.state.languageText;
//         this.setState({languageText:currentLanguage === 'EN' ? 'DE' : 'EN'});
//         localStorage.setItem('pre_language_admin', currentLanguage)
//     }

//     handleDrawerToggle = () => {
//         const [mobileOpen, setMobileOpen] = React.useState(false);
//         setMobileOpen(!mobileOpen);
//     };

//     handleLogout = () => {
//         if (this.props.authed) {
//             this.props.dispatch(authAction.logout());
//         }
//     }

//     goBack = ()=>{
//         window.history.back();
//     }

//     render() {
//     const { container, path, exact, component, authed, headerTitle, location, back } = this.props;
//     const userAvatar = localStorage.getItem('user_avatar');
//     const userName = localStorage.getItem('user_name');
//     //const [mobileOpen, ] = React.useState(false);

//     const {languageText} = this.state;

//     const drawer = (
//         <div>
//             <div/>
//             <List>
//                 <ListItem className="text-center flex-column colum-avatar">
//                     <Avatar className="app-avatar" src={userAvatar ? userAvatar : IconAvatar} />
//                     <h4><b>{userName ? userName : 'User name'}</b></h4>
//                 </ListItem>
//                 <Divider />
//                 <ListItem button component={Link} to="/dashboard" selected={path === "/dashboard"}>
//                     <ListItemIcon><DashboardIcon /></ListItemIcon>
//                     <ListItemText>{language(languageText, 'dashboard')}</ListItemText>
//                 </ListItem>
//                 <ListItem button component={Link} to="/events" selected={path === "/events"}>
//                     <ListItemIcon><EventIcon /></ListItemIcon>
//                     <ListItemText>{language(languageText, 'events_text')}</ListItemText>
//                 </ListItem>
//                 <ListItem button component={Link} to="/users" selected={path === "/users"}>
//                     <ListItemIcon><PeopleAltIcon /></ListItemIcon>
//                     <ListItemText>{language(languageText, 'users')}</ListItemText>
//                 </ListItem>
//                 <ListItem button component={Link} to="/communicates" selected={path === "/communicates"}>
//                     <ListItemIcon><NotificationsIcon /></ListItemIcon>
//                     <ListItemText>{language(languageText, 'communications')}</ListItemText>
//                 </ListItem>
//                 <ListItem onClick={this.handleLogout} style={{cursor:'pointer'}}>
//                     <ListItemIcon><PowerSettingsNew /></ListItemIcon>
//                     <ListItemText>{language(languageText, 'logout')}</ListItemText>
//                 </ListItem>
//             </List>
//         </div>
//     );

//     if (!authed)
//         return <Redirect to='/login' />
//     else {
//         return (
//             <div className="">
//                 <CssBaseline />
//                 <AppBar position="fixed" className="appBar" style={{width: `calc(100% - 240px)`, marginLeft: '240px',}}>{/* className={classes.appBar} */}
//                     <Toolbar className="ml-3">
//                         {back?
//                         <IconButton edge="start" color="inherit" onClick={this.goBack}>
//                             <ArrowBackIosIcon />
//                         </IconButton>:null
//                         }
//                         <IconButton
//                             color="inherit"
//                             aria-label="open drawer"
//                             edge="start"
//                             onClick={this.handleDrawerToggle}
//                             /* className={classes.menuButton} */
//                         >
//                             <MenuIcon />
//                         </IconButton>
//                         <Typography variant="h6" align="center">{language(languageText, location.headerTitle ? location.headerTitle : headerTitle)}</Typography>
//                         <div className="app-header-public">
//                             <div onClick={this.handleChangeLanguage}>
//                                 <span className="switch-lang" style={{top: '1.2rem'}}><img src={languageText === 'EN' ? FlagGe : FlagEn} /></span>
//                             </div>
//                         </div>
//                     </Toolbar>
//                 </AppBar>
//                 <nav className="" aria-label="mailbox folders">{/* className={classes.drawer} */}
//                     {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
//                     <Hidden smUp implementation="css">
//                         <Drawer
//                             container={container}
//                             variant="temporary"
//                             //anchor={theme.direction === 'rtl' ? 'right' : 'left'}
//                             //open={mobileOpen}
//                             onClose={this.handleDrawerToggle}
//                             // classes={{
//                             //     paper: classes.drawerPaper,
//                             // }}
//                             ModalProps={{
//                                 keepMounted: true, // Better open performance on mobile.
//                             }}
//                         >
//                             {drawer}
//                         </Drawer>
//                     </Hidden>
//                     <Hidden xsDown implementation="css">
//                         <Drawer
//                             // classes={{
//                             //     paper: classes.drawerPaper,
//                             // }}
//                             variant="permanent"
//                             open
//                         >
//                             {drawer}
//                         </Drawer>
//                     </Hidden>
//                 </nav>
//                 <main className=' p-xl-4' style={{flexGrow: 1, width: 'calc(100% - 240px)', marginLeft: '240px'}}> {/* className={classes.content+' p-xl-4'} */}
//                     <div style={{minHeight: '64px'}} /> {/*  className={classes.toolbar} */}
//                     <Route path={path} exact={exact} component={(propsCom) => <this.props.component languageText={this.state.languageText} {...propsCom}/>} showLabels/>
//                 </main>
//             </div>
//         );
//         }
//     }
// }

function mapStateToProps(state) {
  return { ...state.authReducer }
}

export default connect(mapStateToProps)(AdminRoutes)
