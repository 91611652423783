import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Button, FormLabel, Input } from '@material-ui/core/';
import language from '../../../helpers/language';
import { API } from '../../../services/api';
import ReactModal from 'react-modal';
import Swal from 'sweetalert2';

class Dashboard extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading:false,
            openForm:false,
            adminFeed:{
                title:'abc',
                notice:''
            }
        }
    }

    componentDidMount(){
        if(this.state.loading === false){
            this.setState({ loading:true })
            API.get('activities/newFeedsAdmin').then((res)=>{
                this.setState({ loading:false })
                if(res.docs && res.docs[0])
                    this.setState({ adminFeed:{ id:res.docs[0]._id, title:res.docs[0].title, notice:res.docs[0].notice } })
            }).catch(()=>{
                this.setState({ loading:false })
            })
        }
    }

    downloadExcel = ()=>{
        if(this.state.loading === false){
            this.setState({ loading:true })
            API.get('users/download/all').then(()=>{
                const link = document.createElement('a');
                link.href = API.baseURL+'/public/5aa1c2c35ef7a4e97b5e995a.xlsx';
                link.setAttribute('download',{});
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({ loading:false })
            }).catch(()=>{
                this.setState({ loading:false })
            })
        }
    }

    inputFeed = (e,name)=>{
        this.setState({ adminFeed:{...this.state.adminFeed,[name]:e.target.value } })
    }

    updateFeed = ()=>{
        const { languagetext } = this.props;
        this.setState({ loading:true })
        if(!this.state.adminFeed.id)
            API.post('activities/adminCreate',{},this.state.adminFeed).then((res)=>{
                this.setState({ loading:false })
                if(res._id){
                    this.setState({ 
                        adminFeed:{
                            id:res._id,
                            title:res.title,
                            notice:res.notice
                        },
                        openForm:false
                    })
                    Swal.fire({
                        title: '',
                        text: language(languagetext, 'save_admin_notice_success'),
                        icon: 'success',
                        confirmButtonColor: '#e60003',
                        confirmButtonText: language(languagetext, 'ok')
                    })
                }
            }).catch(()=>{
                this.setState({ loading:false })
            })
        else
            API.patch('activities/adminUpdate/'+this.state.adminFeed.id,{},this.state.adminFeed).then((res)=>{
                this.setState({ loading:false })
                if(res._id){
                    this.setState({ 
                        adminFeed:{
                            id:res._id,
                            title:res.title,
                            notice:res.notice
                        },
                        openForm:false
                    })
                    Swal.fire({
                        title: '',
                        text: language(languagetext, 'save_admin_notice_success'),
                        icon: 'success',
                        confirmButtonColor: '#e60003',
                        confirmButtonText: language(languagetext, 'ok')
                    })
                }
            }).catch(()=>{
                this.setState({ loading:false })
            })
    }

    deleteFeed = ()=>{
        const { languagetext } = this.props;
        this.setState({ loading:true })
        API.del('activities/adminDelete/'+this.state.adminFeed.id).then((res)=>{
            this.setState({ 
                loading:false,
                adminFeed:{
                    title:'abc',
                    notice:''
                },
                openForm:false
            })
            Swal.fire({
                title: '',
                text: language(languagetext, 'delete_admin_notice_success'),
                icon: 'success',
                confirmButtonColor: '#e60003',
                confirmButtonText: language(languagetext, 'ok')
            })
        }).catch(()=>{
            this.setState({ loading:false })
        })
    }

    render() {
        const { languagetext } = this.props;
        const { loading, openForm, adminFeed } = this.state;
        return (
            <div style={{ margin: '20px', align: 'center' }}>
                <ReactModal
                    isOpen={openForm}
                    contentLabel="Notice"
                    className="visible"
                    overlayClassName="sweet-overlay"
                    style={{
                        content: {display: 'block', marginTop: '50px', backgroundColor: 'initial', outline: 'none'},
                        overlay: {opacity: '1.07', display: 'block'},
                        zIndex: '100!important'
                    }}
                    ariaHideApp={false}
                    shouldFocusAfterRender={false}
                    shouldReturnFocusAfterClose={false}
                    shouldCloseOnEsc={true}
                    role="dialog" >
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header d-flex align-items-center">
                                <h4 className="modal-title">
                                    <span onClick={()=>this.setState({ openForm:false })} className="close-modal">
                                        <i className="fa fa-angle-left mr-3"></i>
                                    </span>
                                    {language(languagetext, "notice")}
                                </h4>
                            </div>
                            <div className="modal-body">
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <FormLabel className="fz-14 font-weight-500">{language(languagetext, "notice")}<span className="text-danger">(*)</span> ({language(languagetext, "max200Char")})</FormLabel>
                                                <Input
                                                fullWidth
                                                multiline
                                                rows={5}
                                                margin="dense"
                                                maxLength="200" 
                                                required
                                                value={adminFeed.notice}
                                                onChange={(e)=>this.inputFeed(e,'notice')}
                                                variant="outlined"
                                                disabled={loading}
                                                placeholder={language(languagetext, "notice")}
                                                />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 mt-3 text-center">
                                <Button variant="contained" color="primary" className="mr-3 backgroundColorPrimaryOverride" disabled={!adminFeed.notice || adminFeed.notice?.length >200 || loading} onClick={this.updateFeed}>{language(languagetext, "save")}</Button>
                                <Button variant="contained" color="primary" className="mr-3 backgroundColorPrimaryOverride" disabled={!adminFeed.id || loading} onClick={this.deleteFeed}>{language(languagetext, "delete")}</Button>
                                <Button variant="contained" color="secondary" className="mr-3 btn-gray" onClick={()=>this.setState({ openForm:false })}>{language(languagetext, "cancel")}</Button>
                            </div>
                        </div>
                    </div>
                </ReactModal>
                <Grid container spacing={10} style={{ textAlign: "center", marginTop: "40px" }}>
                    <Grid item xs={12} md={3}>
                        <Button className="das-btn" onClick={()=>this.setState({ openForm:true })}>{language(languagetext, "notice")}</Button>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Link to="/users">
                            <Button className="das-btn">{language(languagetext, "users")}</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Link to="/events">
                            <Button className="das-btn">{language(languagetext, "events_text")}</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Link to="/communicates">
                            <Button className="das-btn">{language(languagetext, "communications")}</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button className="das-btn" onClick={this.downloadExcel}>{language(languagetext, "export_excel")}</Button>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const { authReducer } = state;
    return {
        authReducer: authReducer
    };
}

export default connect(mapStateToProps)(Dashboard);
