import React, { Component } from 'react';
import { connect } from "react-redux";
import { ButtonGroup, Button, CircularProgress, FormLabel } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import RefreshIcon from '@material-ui/icons/Refresh';
import FriendItem from './FriendItem';
import FriendshipItem from './FriendShipItem';
import { authAction } from '../../actions/auth-action';
import language from '../../helpers/language';

class MyFriends extends Component {
    constructor(props) {
        super(props)
        this.state = {
            numberOfLoad: 0,
            selected: 'friends',
        }
    }

    componentDidMount(){
        if(this.props.location.search)
            this.selectType('request')
        else if (this.props.history.location && this.props.history.location.tab)
            this.selectType(this.props.history.location.tab)
    }

    selectType = (type) => {
        this.setState({ selected: type });
    }

    removeFriendItem = (id)=> {
        this.props.deleteUserFriendShipItem(id)
    }

    refreshData = () => {
        this.setState({ numberOfLoad: this.state.numberOfLoad + 1 })
        if (this.props.online && !this.props.loadingFriendItem) {
            this.props.loadProfileFriendItem()
        }
    }

    render() {
        const { numberOfLoad, selected } = this.state;
        const { profile, loadingFriendItem, online } = this.props
        let languageText = this.props.profile ? this.props.profile.language : 'EN';
        let requestFriends = profile.waitingAcceptFriends.map((el) => { return { ...el.sender, requestId: el._id } })
        let pendingRequests = profile.sendingRequestFriends.map((el)=> { return {...el.receiver, requestId:el._id } })
        return (
            <div className="container text-center pt-4 pb-4 overrideBackgroundTab" key={numberOfLoad} >
                <ButtonGroup color="primary" fullWidth>
                    <Button className="w-50" variant={selected === "friends" ? "contained" : ""} onClick={() => this.selectType('friends')}>{language(profile.language, "friends")}</Button>
                    <Button className="w-50" variant={selected === "pending" ? "contained" : ""} onClick={() => this.selectType('pending')}>{language(profile.language, "pending")}</Button>
                    <Button className="w-50" variant={selected === "request" ? "contained" : ""} onClick={() => this.selectType('request')}>{language(profile.language, "request")}</Button>
                </ButtonGroup>
                <div style={{pointerEvents:loadingFriendItem?'none':''}}>
                {(online && loadingFriendItem) ? <CircularProgress className="mt-3" /> : null}
                {selected === "friends" && !loadingFriendItem && <InfiniteScroll dataLength={profile.friends.length} refreshFunction={this.refreshData} hasmore={false}
                    pullDownToRefresh
                    scrollableTarget="app-content"
                    pullDownToRefreshThreshold={100}
                    pullDownToRefreshContent={<RefreshIcon fontSize="large" color="action" />}
                    releaseToRefreshContent={<RefreshIcon fontSize="large" color="primary" />}
                    loader={<CircularProgress />}>
                    {profile.friends.map((item, index) => <FriendItem myId={this.props.userID} key={index} data={item} languageText={profile.language} removeFriendItemFailed={this.refreshData} removeFriendItemSuccess={this.removeFriendItem}/>)}
                </InfiniteScroll>}
                {selected === "pending" && !loadingFriendItem &&<InfiniteScroll key={'ranking-2-' + numberOfLoad} dataLength={pendingRequests.length} refreshFunction={this.refreshData} hasmore={false}
                    pullDownToRefresh
                    scrollableTarget="app-content"
                    pullDownToRefreshThreshold={100}
                    pullDownToRefreshContent={<RefreshIcon fontSize="large" color="action" />}
                    releaseToRefreshContent={<RefreshIcon fontSize="large" color="primary" />}
                    loader={<CircularProgress />}>
                    {pendingRequests.map((item) => <FriendshipItem key={item._id} data={item} languageText={profile.language} addFriendSuccess={this.refreshData} removeFriendItemSuccess={this.removeFriendItem} pending={true} />)}
                </InfiniteScroll>}
                {selected === "request" && !loadingFriendItem && <InfiniteScroll key={'ranking-' + numberOfLoad} dataLength={requestFriends.length} refreshFunction={this.refreshData} hasmore={false}
                    pullDownToRefresh
                    scrollableTarget="app-content"
                    pullDownToRefreshThreshold={100}
                    pullDownToRefreshContent={<RefreshIcon fontSize="large" color="action" />}
                    releaseToRefreshContent={<RefreshIcon fontSize="large" color="primary" />}
                    loader={<CircularProgress />}>
                    {requestFriends.map((item) => <FriendshipItem key={item._id} data={item} languageText={profile.language} addFriendSuccess={this.refreshData} removeFriendItemSuccess={this.removeFriendItem} />)}
                </InfiniteScroll>}
                </div>
                {!loadingFriendItem && selected === "friends" && profile.friends.length === 0 &&
                    <div className="row">
                        <div className="col-12">
                            <div className="mt-3">
                                <FormLabel className="label-input">{language(languageText, "no_record_found")}</FormLabel>
                            </div>
                        </div>
                    </div>
                }
                {!loadingFriendItem && selected === "request" && requestFriends.length === 0 &&
                    <div className="row">
                        <div className="col-12">
                            <div className="mt-3">
                                <FormLabel className="label-input">{language(languageText, "no_record_found")}</FormLabel>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state.authReducer,
        profile: state.authReducer.profile,
        online: state.authReducer.online
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadProfileFriendItem: ()=> dispatch(authAction.getProfileFriendItem()),
        deleteUserFriendShipItem: (id) => dispatch(authAction.deleteUserFriendShipItem(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MyFriends);