import React, {Component} from 'react';
import { CircularProgress } from '@material-ui/core';
import {connect} from "react-redux";
import Feed from './Feed';
import { newsfeedAction } from '../../actions/newsfeed-action';

class NewsFeedDetail extends Component {

    componentDidMount() {
        setTimeout(()=>{
            if(!this.props.newsfeed)
            this.props.loadNewsfeed(this.props.match.params.id)
        },500)
    }

    render() {
        const { profile, createComment, like, unlike, newsfeed, loading, userID } = this.props
        return (
            <div className="container text-center pt-4 pb-4 overrideBackgroundTab">
                {loading?<CircularProgress className="mt-3"/>:null}
                {newsfeed?<Feed userID={userID} showFull={true} newsfeed={newsfeed} submit={createComment} profile={profile} like={like} unlike={unlike} isEventEnded={newsfeed.isEventEnded} />:null}
            </div>
        )
    }
}

function mapStateToProps(state,props) {
    let newsfeed = null
    newsfeed = state.newsfeedReducer.newsfeedsAll.find((el)=>el._id === props.match.params.id)
    if(!newsfeed)
        newsfeed = state.newsfeedReducer.newsfeedsFriend.find((el)=>el._id === props.match.params.id)
    return {...state.authReducer,...state.newsfeedReducer, newsfeed:newsfeed}
}

function mapDispatchToProps(dispatch){
    return {
        loadNewsfeed:(id)=> dispatch(newsfeedAction.loadNewsfeed(id)),
        createComment: (params)=> dispatch(newsfeedAction.createComment(params)),
        like: (id) => dispatch(newsfeedAction.like(id)),
        unlike: (id) => dispatch(newsfeedAction.unlike(id))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(NewsFeedDetail);