import React, { Component } from 'react';
import * as moment from 'moment';
import numeral from 'numeral';

export default class CountDownTo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index:0
        }
        this.interval = setInterval(()=>{
            this.setState({ index: this.state.index+1 })
        },1000)
    }

    componentWillUnmount(){
        clearInterval(this.interval)
    }

    render() {
        const { to } = this.props
        let duration = moment.duration(moment(to).diff(moment()))
        return (
            <span>
                {numeral(duration.asDays()).format('00')+' days'}&nbsp;
                {numeral(duration.hours()).format('00')}:
                {numeral(duration.minutes()).format('00')}:
                {numeral(duration.seconds()).format('00')}
            </span>
        );
    }
}
