import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es/object';
import React from 'react';
import ReactDOM from 'react-dom';
// Required for Redux store setup
import { Provider } from 'react-redux'
import configureStore from './store';

import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import pushNotificationWorker from './pushNotificationWorker';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import './assets/css/bootstrap.css';
import numeral from 'numeral';

numeral.register('locale', 'de', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
    },
    currency: {
      symbol: '$'
    }
});

ReactDOM.render(
  <Provider store={configureStore()}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <App/>
    </MuiPickersUtilsProvider>
  </Provider>,
  document.getElementById('root')
);

registerServiceWorker();
pushNotificationWorker();
