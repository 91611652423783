import React, {Component} from 'react';
import { Card , Button, Grid, FormLabel, CircularProgress } from '@material-ui/core';
import {connect} from "react-redux";
import { Link, Redirect } from 'react-router-dom';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { eventAction } from '../../actions/event-action';
import language from '../../helpers/language';
import { authAction } from '../../actions/auth-action';

class EventRegistration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            eventID: '',
            eventName: '',
            startDate: '',
            endDate: '',
            eventDetail: {},
            showLoading: false,
            registerSuccess: false
        }

        this.handleRegisterEvent = this.handleRegisterEvent.bind(this);
    }

    componentDidMount(){
        if (this.props.location && this.props.location.state && this.props.location.state.data) {
            let data = this.props.location.state.data;
            if (data) {
                this.setState({
                    eventDetail: data,
                    eventID: data._id,
                    eventName: data.name,
                    startDate: moment(data.startDate).format('DD/MM/YYYY'),
                    endDate: moment(data.endDate).format('DD/MM/YYYY')
                })
            }
        }

    }

    handleRegisterEvent = () => {
        const {eventID, eventDetail} = this.state;
        let languageText = this.props.profile ? this.props.profile.language : 'EN';
        if (eventID) {
            this.setState({
                showLoading: true,
                registerSuccess: false
            })
            this.props.dispatch(eventAction.registerEvent(eventDetail)).then(res => {
                this.props.dispatch(authAction.getProfileEvent())
                this.setState({
                    showLoading: false
                })
                if (res && res.errors) {
                    Swal.fire({
                        text: language(languageText,"label_registration_failed"),
                        icon: 'error',
                        confirmButtonColor: '#e60003',
                        confirmButtonText: language(languageText, 'ok')
                    })
                }
                else {
                    Swal.fire({
                        text: language(languageText,"label_registration_successful"),
                        icon: 'info',
                        confirmButtonColor: '#e60003',
                        confirmButtonText: language(languageText, 'ok')
                    }).then(res => {
                        this.setState({registerSuccess: true})
                    })
                }
            }).catch(err => {
                console.log('error here: ', err)
                this.setState({
                    showLoading: false
                })
                // Swal.fire({
                //     text: language(languageText,"label_registration_failed"),
                //     icon: 'error',
                // })
            })
        }
    }

    render() {
        const {eventName, startDate, endDate, showLoading, registerSuccess} = this.state;
        let languageText = this.props.profile ? this.props.profile.language : 'EN';
        if (registerSuccess) {
            return (
                <Redirect to='/progress'/>
            )
        }
        return (
                <div className="container text-center pt-4">
                    <Card variant="outlined" className="mt-3 p-2">
                        <Grid container alignItems="center" className="p-3">
                            <Grid container item>
                                <FormLabel>
                                    <b>{language(languageText,"event") + ': ' + eventName}</b>
                                </FormLabel>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" className="p-3">
                            <Grid container item xs={6}>
                                <FormLabel>
                                    {language(languageText,"start") + ': ' + startDate}
                                </FormLabel>
                            </Grid>
                            <Grid container item xs={6}>
                                <FormLabel>
                                    {language(languageText,"end") + ': ' + endDate}
                                </FormLabel>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" className="p-0 d-block text-center">
                            <Button type="button" id="btnRegisterEvent" variant="contained" color="primary" className="mr-3 btn-success" onClick={this.handleRegisterEvent}>{language(languageText,"register")}</Button>
                            <Link to="/active-events" className="btn btn-secondary" >
                                {language(languageText,"cancel")}
                            </Link>
                        </Grid>
                    </Card >
                {showLoading?<CircularProgress className="mt-3"/>:null}
                </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        profile: state.authReducer.profile}
}

export default connect(mapStateToProps)(EventRegistration);
