import * as React from 'react';
import { connect } from "react-redux";

function UserDetail(props) {
    console.log('events_props', props)

    return (
        <div className="container text-center pt-4">
            <h3>User Detail</h3>
        </div>
    )
}



function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);