import { Button, Divider, FormLabel, Input, InputLabel } from '@material-ui/core';
import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { connect } from "react-redux";
import Select from 'react-select';
import countryList from 'react-select-country-list';
import Swal from 'sweetalert2';
import { validator } from "../../../helpers";
import language from '../../../helpers/language';
import { userManagementServices } from '../../../services/userManagementService';
import UsersTable from './UsersTable';
import UsersToolbar from './UsersToolbar';

const optionsRoles = [
    {value: 'admin', label: 'Admin'},
    {value: 'user', label: 'User'}
]

const optionsCountries = countryList().setLabel("TW", "Taiwan").data

class UserList extends Component {
  constructor(props) {
      super(props)

      this.state = {
        showFormDetail: false,
        isCreate: true,
        keySearch: '',
        userDetail: {},
        listUsers: [],
        page: 1,
        limit: 10,
        totalPages: 0,
        totalDocs: 0,
        pageInput: 1,
        limitSelected: {value: 10, label: language('DE', "show_10_per_page")},
        passwordConfirm: '',
        password: '',
        default: {
          user: {
              _id: '',
              firstName: '',
              lastName: '',
              displayName: '',
              email: '',
              countrySelected: {value: '', label: ''},
              userTypeSelected: {value: '', label: ''},
              roleSelected: {value: '', label: ''}
          }
        },
        validateFirstNameNull: false,
        validateLastNameNull: false,
        validateEmailNull: false,
        validateEmailExist: false,
        validateEmailFormat: false,
        validateCountryNull: false,
        validateUserTypeNull: false,
        validateRoleNull: false,
        validatePasswordFormat: false,
        validatePasswordNull: false,
        validatePasswordConfirmationNull: false,
        validatePasswordConfirmationMatch: false,
        showLoading: false,
        userTypeSelected: {value: '', label: ''},
        roleSelected: {value: '', label: ''},
        countrySelected: {value: 'DE', label: 'Germany'},
      }
  }

  componentDidMount(){
    this.loadDataUsers(this.state.keySearch, this.state.page, this.state.limit);
  }

  loadDataUsers = (keySearch, page, limit) => {
    this.setState({showLoading: true});
    const { languagetext } = this.props;
    userManagementServices.getAllUsers(keySearch, page, limit).then(response => {
        this.setState({showLoading: false});
        if(response && response.errors) {
            this.setState({
                listUsers: [],
                totalDocs: 0,
                totalPages: 0,
                page: 1,
                pageInput: 1,
                limit: 10
            })
        } else {
            let temp = {};
            if (limit === 10) {

                temp = {value: 10, label: language(languagetext, "show_10_per_page")}
            }
            else if (limit === 25) {

                temp = {value: 25, label: language(languagetext, "show_25_per_page")}
            }
            else if (limit === 50) {

                temp = {value: 50, label: language(languagetext, "show_50_per_page")}
            }
            this.setState({
                listUsers: response.docs,
                totalDocs: response.totalDocs,
                totalPages: response.totalPages,
                page: page,
                pageInput: page,
                limit: limit,
                limitSelected: temp
            })
        }
    }).catch(err => {
        this.setState({
            showLoading: false,
            listUsers: [],
            totalDocs: 0,
            totalPages: 0,
            page: 1,
            pageInput: 1,
            limit: 10
        });
        console.log('error is here: ', err)
    });
  }

  openModalDetail = (data = {}, isCreate = true) => {
    let languagetext = this.props.languagetext ? this.props.languagetext : 'DE';
    let optionsUserTypes = [
        {value:'Customer', label:language(languagetext, "customer") },
        {value:'Staff', label: language(languagetext, "staff") },
        {value:'Supplier', label:language(languagetext, "supplier") },
        {value:'Family', label: language(languagetext, "family") }
    ]
    if (isCreate) data = this.state.default.user;
    if (data && data.country) {
        let tempCountry = optionsCountries.find(x => x.value === data.country)
        data.countrySelected = tempCountry
    }
    if (data && data.userType) {
        let tempType = optionsUserTypes.find(x => x.value === data.userType)
        data.userTypeSelected = tempType
    }
    if (data && data.role) {
        let tempRole = optionsRoles.find(x => x.value === data.role)
        data.roleSelected = tempRole
    }

    this.setState({
        isCreate: isCreate,
        userDetail: data,
        // password: data.password,
        // passwordConfirm: data.password
    }, function() { this.setState({showFormDetail: true});});
  }

  closeUserModal = () => {
    this.setState({
        showFormDetail: false
    });
}

  saveUserForm = (e) => {
    e.preventDefault();
    let userInfo = this.state.userDetail;
    userInfo.password = this.state.password;
    if (userInfo.countrySelected && userInfo.countrySelected.value) {
        userInfo.country = userInfo.countrySelected.value;
    }
    if (userInfo.userTypeSelected && userInfo.userTypeSelected.value) {
        userInfo.userType = userInfo.userTypeSelected.value;
    }
    if (userInfo.roleSelected && userInfo.roleSelected.value) {
        userInfo.role = userInfo.roleSelected.value;
    }
    if (userInfo._id) {
        //edit
        this.setState({showLoading: true})
        userManagementServices.updateUser(userInfo).then(res => {
            this.setState({showFormDetail: false})
            this.loadDataUsers(this.state.keySearch, 1, this.state.limit)
        })
    }
    else {
        //create
        this.setState({showLoading: true})
        userManagementServices.createUser(userInfo).then(res => {
            this.setState({showFormDetail: false})
            this.loadDataUsers(this.state.keySearch, 1, this.state.limit)
        })
    }
  }

  confirmUpdateStatus = (data) => {
    if (!data._id || data._id < 1) return;

    let languagetext = this.props.languagetext ? this.props.languagetext : 'DE';
    Swal.fire({
        title: '',
        text: language(languagetext,"change_status_user_confirmation"),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: language(languagetext,"yes"),
        cancelButtonText: language(languagetext,"no"),
        confirmButtonColor: '#e60003',
        cancelButtonColor: '#707070',
      }).then((result) => {
        if (result.value) {
          this.setState({showLoading: true})
          let dataUpdate = data;
          dataUpdate.status = !data.status;
          userManagementServices.updateUser(dataUpdate).then(res => {
              this.loadDataUsers(this.state.keySearch, 1, this.state.limit)
          })
        }
      })
}

  confirmDeleteRow = (id) => {
    const { languagetext } = this.props;
    Swal.fire({
        title: '',
        text: language(languagetext,"delete_user_confirmation"),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: language(languagetext,"yes"),
        cancelButtonText: language(languagetext,"no"),
        confirmButtonColor: '#e60003',
        cancelButtonColor: '#707070',
    }).then((result) => {
      if (result.value) {
        this.setState({showLoading: true})
        userManagementServices.deleteUser(id).then(res => {
            this.loadDataUsers(this.state.keySearch, this.state.page, this.state.limit)
        })
      }
    })
  }

  handleSearchAction = (e) => {
    e.preventDefault();
    this.loadDataUsers(this.state.keySearch, 1, this.state.limit);
  }

  inputKeySearch = (e) => {
    let strValue = String(e.target.value);
    this.setState({ keySearch: strValue})
  }

  handleChangeRole = value => {
        let user = this.state.userDetail;
        user.roleSelected = value;
        let validateNull = value.value === '' ? true : false;
        this.setState({ userDetail: user, validateRoleNull: validateNull })
    }

handleChangeUserType = value => {
    let user = this.state.userDetail;
    user.userTypeSelected = value;
    let validateNull = value.value === '' ? true : false;
    this.setState({ userDetail: user, validateUserTypeNull: validateNull })
}

handleChangeCountry = value => {
    let user = this.state.userDetail;
    user.countrySelected = value;
    let validateNull = value.value === '' ? true : false;
    this.setState({ userDetail: user, validateCountryNull: validateNull })
}


inputPassword = (e) => {
    let strValue = String(e.target.value);
    this.setState({
        password: strValue.trim(),
        validatePasswordNull: false
    })
    if (validator.checkPasswordValid(strValue)) {
        this.setState({validatePasswordFormat: false});
    }
    else {
        this.setState({validatePasswordFormat: true});
    }
}

inputPasswordConfirm = (e) => {
    let strValue = String(e.target.value);
    this.setState({
        passwordConfirm: strValue.trim(),
        validatePasswordConfirmationNull: false
    })
    if (this.state.password === strValue) {
        this.setState({validatePasswordConfirmationMatch: false});
    }
    else {
        this.setState({validatePasswordConfirmationMatch: true});
    }
}

inputEmail = (e) => {
    let strValue = String(e.target.value);
    let user = this.state.userDetail;
    user.email = strValue.trim()
    if (strValue.trim().length <= 30) {
        this.setState({
            userDetail: user,
            validateEmailNull: false
        })
        if (validator.isEmail(strValue)) {
            this.setState({validateEmailFormat: false});
        }
        else {
            this.setState({validateEmailFormat: true});
        }
    }
}

inputFirstName = (e) => {
    let strValue = String(e.target.value);
    let user = this.state.userDetail;
    user.firstName = strValue;
    if (strValue.length <= 30) {
        this.setState({
            userDetail: user,
            validateFirstNameNull: false
        })
    }
}

inputLastName = (e) => {
    let strValue = String(e.target.value);
    let user = this.state.userDetail;
    user.lastName = strValue;
    if (strValue.length <= 30) {
        this.setState({
            userDetail: user,
            validateLastNameNull: false
        })
    }
}

handleBlurEmail = () => {
    let user = this.state.userDetail;
    if (user.email.trim() === '') {
        this.setState({validateEmailNull: true, validateEmailFormat: false})
    }
}

handleBlurGivenName = () => {
    let user = this.state.userDetail;
    if (user.firstName.trim() === '') {
        this.setState({validateFirstNameNull: true})
    }
}

handleBlurLastName = () => {
    let user = this.state.userDetail;
    if (user.lastName.trim() === '') {
        this.setState({validateLastNameNull: true})
    }
}

handleBlurPassword = () => {
    if (this.state.password.trim() === '') {
        this.setState({validatePasswordNull: true, validatePasswordFormat: false})
    }
}

handleBlurPasswordConfirm = () => {
    if (this.state.passwordConfirm.trim() === '') {
        this.setState({validatePasswordConfirmationNull: true, validatePasswordConfirmationMatch: false})
    }
}

    //Pagination
    PaginationSizeChange = (value) => {
        if (this.state.limit === value) return;

        // update the list with limit
        if (!value || isNaN(value)) value = 10;

        this.loadDataUsers(this.state.keySearch, 1, value);
    }

    handleOnClickPrevious = (e) => {
        if (this.state.page > 1) {
            var prevPageNum = parseInt(this.state.page) - 1;

        this.loadDataUsers(this.state.keySearch, prevPageNum, this.state.limit);
        }
    }

    handleOnClickNext = (e) => {
        if (this.state.page < this.state.totalPages) {
            var nextPageNum = parseInt(this.state.page) + 1;

        this.loadDataUsers(this.state.keySearch, nextPageNum, this.state.limit);
        }
    }

    handlePaginationInputChange = (e) => {
        const { value } = e.target;
        this.setState({
            pageInput: value
        })
    }

    handleKeyPressPaginationInput = (e) => {
        var totalPages = this.state.totalPages

        if (e.key === 'Enter' || e.key === 'enter') {
            if (e.target.value) {
                let value = Number(e.target.value);
                if (value < 1) value = 1;
                else if (value > totalPages) value = totalPages;

                this.loadDataUsers(this.state.keySearch, value, this.state.limit);
            }
        }
    }

    handlePaginationInput = (e) => {
        var totalPages = this.state.totalPages

        if (e.target.value) {
            let value = Number(e.target.value);
            if (value < 1) value = 1;
            else if (value > totalPages) value = totalPages;

            this.loadDataUsers(this.state.keySearch, value, this.state.limit);
        }
    }

  render() {
  const { userDetail, listUsers, showLoading, page, limit, totalPages, password, passwordConfirm,
        validateEmailFormat, validatePasswordFormat, validatePasswordConfirmationMatch, keySearch } = this.state;
  let disabledButton = false;
  if (userDetail.firstName === '' || userDetail.lastName === '' || userDetail.email === '' || password === '' || passwordConfirm === ''
        || validateEmailFormat || validatePasswordFormat || validatePasswordConfirmationMatch) {
      disabledButton = true
  }
  var preClass = '';
  var nextClass = '';
  if (page < 2) {
      preClass = 'disabled';
  }
  if (page >= totalPages) {
      nextClass = 'disabled';
  }
  let languagetext = this.props.languagetext ? this.props.languagetext : 'DE';

  let optionsUserTypes = [
    {value:'Customer', label:language(languagetext, "customer") },
    {value:'Staff', label: language(languagetext, "staff") },
    {value:'Supplier', label:language(languagetext, "supplier") },
    {value:'Family', label: language(languagetext, "family") }
  ]
  return (
    <div className=''>
        {
            showLoading &&
            <div className="overlay-loading text-center"><span className="spinner-1 inline-spinner "/>
            </div>
        }
      <UsersToolbar keySearch={keySearch} languagetext={languagetext} inputKeySearch={this.inputKeySearch} handleSearch={this.handleSearchAction} onClickAddNew={this.openModalDetail} />
      <div className="" style={{height: 'auto'}}>
        <div className="d-flex">
            <ReactModal
                isOpen={this.state.showFormDetail}
                contentLabel="User Detail"
                className="visible"
                overlayClassName="sweet-overlay"
                style={{
                    content: {display: 'block', marginTop: '50px', backgroundColor: 'initial', outline: 'none'},
                    overlay: {opacity: '1.07', display: 'block'}
                }}
                shouldFocusAfterRender={false}
                shouldReturnFocusAfterClose={false}
                shouldCloseOnEsc={true}
                role="dialog" >
                <div className="modal-dialog modal-xl">
                    {/* <form onSubmit={e => this.saveUserForm(e)} ref={c => { this.form = c }}> */}
                        <div className="modal-content">
                            <div className="modal-header d-flex align-items-center">
                                <h4 className="modal-title">
                                    <span onClick={this.closeUserModal} className="close-modal">
                                        <i className="fa fa-angle-left mr-3"></i>
                                    </span>
                                    {language(languagetext, "user_details")}
                                </h4>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <FormLabel className="fz-14 font-weight-500">{language(languagetext, "given_name")} <span className="text-danger">(*)</span></FormLabel>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                onChange={this.inputFirstName}
                                                onBlur={this.handleBlurGivenName}
                                                value={userDetail.firstName}
                                                placeholder={language(languagetext, "given_name")}
                                            />
                                            {this.state.validateFirstNameNull && <small className="d-block invalid-feedback">{language(languagetext, "given_name_is_required")}</small>}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <FormLabel className="fz-14 font-weight-500">{language(languagetext, "last_name")} <span className="text-danger">(*)</span></FormLabel>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                onChange={this.inputLastName}
                                                onBlur={this.handleBlurLastName}
                                                value={userDetail.lastName}
                                                placeholder={language(languagetext, "last_name")}
                                            />
                                            {this.state.validateLastNameNull && <small className="d-block invalid-feedback">{language(languagetext, "last_name_is_required")}</small>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <FormLabel className="fz-14 font-weight-500">{language(languagetext, "email")} <span className="text-danger">(*)</span></FormLabel>
                                            <Input
                                                type="text"
                                                id="email"
                                                className="form-control"
                                                onChange={this.inputEmail}
                                                onBlur={this.handleBlurEmail}
                                                value={userDetail.email}
                                                placeholder={language(languagetext, "email")}
                                            />
                                            {this.state.validateEmailNull ? <small className="d-block invalid-feedback">{language(languagetext, "email_is_required")}</small> : ''}
                                            {this.state.validateEmailFormat ? <small className="d-block invalid-feedback">{language(languagetext, "invalid_email_address")}</small> : ''}
                                            {this.state.validateEmailExist ? <small className="d-block invalid-feedback">{language(languagetext, "email_already_exists")}</small> : ''}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <FormLabel className="fz-14 font-weight-500">{language(languagetext, "country")} <span className="text-danger">(*)</span></FormLabel>
                                            <Select
                                                options={optionsCountries}
                                                value={userDetail.countrySelected}
                                                onChange={this.handleChangeCountry}
                                            />
                                            {this.state.validateCountryNull && <small className="d-block invalid-feedback">{language(languagetext, "country_is_required")}</small>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <FormLabel className="fz-14 font-weight-500">{language(languagetext,"user_type")} <span className="text-danger">(*)</span></FormLabel>
                                            <Select
                                                options={optionsUserTypes}
                                                value={userDetail.userTypeSelected}
                                                onChange={this.handleChangeUserType}
                                            />
                                            {this.state.validateUserTypeNull && <small className="d-block invalid-feedback">{language(languagetext, "user_type_is_required")}</small>}
                                        </div>
                                    </div>
                                    {/*
                                    <div className="col-6">
                                        <div className="form-group">
                                            <FormLabel className="fz-14 font-weight-500">{language(languagetext, "role")} <span className="text-danger">(*)</span></FormLabel>
                                            <Select
                                                options={optionsRoles}
                                                value={userDetail.roleSelected}
                                                onChange={this.handleChangeRole}
                                            />
                                            {this.state.validateRoleNull && <small className="d-block invalid-feedback">{language(languagetext, "role_is_required")}</small>}
                                        </div>
                                    </div>
                                    */}
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <FormLabel className="fz-14 font-weight-500">{language(languagetext, "password")} <span className="text-danger">(*)</span></FormLabel>
                                            <Input
                                                type="password"
                                                className="form-control"
                                                onChange={this.inputPassword}
                                                onBlur={this.handleBlurPassword}
                                                value={this.state.password}
                                                placeholder={language(languagetext, "password")}
                                            />
                                            {this.state.validatePasswordNull ? <small className="d-block invalid-feedback">{language(languagetext, "password_is_required")}</small> : ''}
                                            {this.state.validatePasswordFormat ? <small className="d-block invalid-feedback">{language(languagetext, "label_password_invalid")}</small> : ''}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <FormLabel className="fz-14 font-weight-500">{language(languagetext, "password_confirmation")} <span className="text-danger">(*)</span></FormLabel>
                                            <Input
                                                type="password"
                                                className="form-control"
                                                onChange={this.inputPasswordConfirm}
                                                onBlur={this.handleBlurPasswordConfirm}
                                                value={this.state.passwordConfirm}
                                                placeholder={language(languagetext, "password_confirmation")}
                                            />
                                            {this.state.validatePasswordConfirmationNull ? <small className="d-block invalid-feedback">{language(languagetext, "password_confirmation_is_required")}</small> : ''}
                                            {this.state.validatePasswordConfirmationMatch ? <small className="d-block invalid-feedback">{language(languagetext, "password_and_confirmation_does_not_match")}</small> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Divider />
                            <div className="mb-3 mt-3 text-center">
                                <Button type="button" variant="contained" color="primary" className="mr-3 backgroundColorPrimaryOverride" disabled={disabledButton} onClick={e => this.saveUserForm(e)}>{language(languagetext,"save")}</Button>
                                <Button type="button" variant="contained" color="secondary" className="mr-3 btn-gray" onClick={this.closeUserModal}>{language(languagetext,"cancel")}</Button>
                            </div>
                        </div>
                    {/* </form> */}
                </div>
            </ReactModal>
        </div>
        <div className="content-table-top">
            <div className="row">
                <div className="col-xs-12 col-sm-12">
                    <div className="table-top-pagination pagination-top">
                        <div className="pagination-size">
                            <Select
                                name="paginationSize"
                                options={[
                                    {label: language(languagetext, "show_10_per_page"), value: 10},
                                    {label: language(languagetext, "show_25_per_page"), value: 25},
                                    {label: language(languagetext, "show_50_per_page"), value: 50},
                                ]}
                                value={this.state.limitSelected}
                                onChange={(p) => {
                                    this.PaginationSizeChange(p.value);
                                }}
                            />
                        </div>
                        <ul className="pagination">
                            <li><span className="btn p-btn" onClick={this.handleOnClickPrevious}
                                        disabled={(preClass === 'disabled') ? 'disabled' : ''}>&lt;</span>
                            </li>
                            <li className="option-control">
                                <input type="number" name="currentPage"
                                        onChange={this.handlePaginationInputChange}
                                        onKeyPress={this.handleKeyPressPaginationInput}
                                        value={totalPages === 0 ? 0 : this.state.pageInput}
                                        onBlur={this.handlePaginationInput}
                                        className="pagination-input form-control"/> {language(languagetext, "myActivity.of")} {totalPages} {language(languagetext, "pages")}
                                <span className="error"></span>
                            </li>
                            <li><span className="btn p-btn" onClick={this.handleOnClickNext}
                                        disabled={(nextClass === 'disabled') ? 'disabled' : ''}>&gt;</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <UsersTable languagetext={languagetext} users={listUsers} onClickEditUser={this.openModalDetail} onClickDeleteUser={this.confirmDeleteRow} confirmUpdateStatus={this.confirmUpdateStatus}
            page={page} limit={limit}/>
        {
            (!listUsers || listUsers.length === 0) &&
            <div className="row">
                <div className="col-12">
                    <div className="form-group text-center mt-3">
                        <InputLabel className="label-input">{language(languagetext, "no_record_found")}</InputLabel>
                    </div>
                </div>
            </div>
        }
      </div>
    </div>
  )};
};

function mapStateToProps(state) {
    return {
        //userInfo: state.authReducer.userInfo
    };
  }

export default connect(mapStateToProps)(UserList);
