import { searchServices } from "../services";
import { searchConstant } from "../constants/searchConstant";

function getDataSearch(keyword, page, limit) {
  return (dispatch) => {
    dispatch(request());
    return searchServices
      .getDataSearch(keyword, page, limit)
      .then((response) => {
        if (response && !response.docs) {
          dispatch(failure(response.errors));
          console.log("Error Response Status", response);
          return response;
        } else {
          dispatch(success(response));
          return response;
        }
      });
    function request() {
      return { type: searchConstant.SEARCH_KEY_REQUEST };
    }
    function success(data) {
      return {
        type: searchConstant.SEARCH_KEY_SUCCESS,
        payload: { data: data },
      };
    }
    function failure(error) {
      return { type: searchConstant.SEARCH_KEY_FAILURE, error };
    }
  };
}

function getRankingSearch(event_id, keyword, page, limit) {
  return (dispatch) => {
    dispatch(request());
    return searchServices
      .getRankingSearch(event_id, keyword, page, limit)
      .then((response) => {
        if (response && response.errors) {
          dispatch(failure(response.errors));
          console.log("Error Response Status", response);
          return response;
        } else {
          dispatch(success(response));
          return response;
        }
      });
    function request() {
      return { type: searchConstant.SEARCH_RANKING_KEY_REQUEST };
    }
    function success(data) {
      return {
        type: searchConstant.SEARCH_RANKING_KEY_SUCCESS,
        payload: { data: data },
      };
    }
    function failure(error) {
      return { type: searchConstant.SEARCH_RANKING_KEY_FAILURE, error };
    }
  };
}

export const searchAction = {
  getDataSearch,
  getRankingSearch,
};
