import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Route, Switch ,Redirect, HashRouter as Router} from 'react-router-dom';
import * as moment from 'moment';

import './App.css';
import routes from './components/Routers';
import PublicRoute from './components/PublicRoute';
import PrivateRoute from './components/PrivateRoute';
import AdminRoutes from './components/AdminRoutes';

import { authAction } from './actions/auth-action';
import { newsfeedAction } from './actions/newsfeed-action';
import { activityAction } from './actions/activity-action';
import { notificationAction } from './actions/notification-action';

class App extends Component {
    db
    initTimeout

    publicRoutes = (routes, hasEvent)=>{
        let result = routes.filter((item)=> !item.auth).map((route, index) => {
            return <PublicRoute key={index} path={route.path} exact={route.exact} component={route.main} hasEvent={hasEvent}/>                                        
        });
        return result 
    }

    privateRoutes = (routes)=>{
        var result = routes.filter((item)=> item.auth && !item.admin).map((route, index) => {
            return <PrivateRoute key={index} headerTitle={route.headerTitle} back={route.back} backUrl={route.backUrl} isProfile={route.isProfile} path={route.path} exact={route.exact} component={route.main}/>
        });
        return result
    }

    adminRoutes = (routes)=>{
        var result = routes.filter((item)=> item.auth && item.admin).map((route, index) => {
            return <AdminRoutes key={index} headerTitle={route.headerTitle} back={route.back} isProfile={route.isProfile} path={route.path} exact={route.exact} component={route.main}/>
        });
        return result
    }

    componentDidMount(){
        document.getElementById('pre-loading').style.display = 'none'
        if(this.props.authed && this.props.user_role === "user"){
            this.init()
            try{
                setTimeout(()=>{
                    let dbRequest = indexedDB.open("appDb")
                    dbRequest.onsuccess = (event)=>{ 
                        this.db = event.target.result
                        this.checkNotification()
                        setInterval(()=>{ this.checkNotification() },10000)
                    }
                },10000)
            }catch(err){
                console.log(err)
            }
        }
        this.props.connectionStatus(window.navigator.onLine)
        window.addEventListener('online', () => {
            this.props.connectionStatus(true)
            this.init()
         });
         
        window.addEventListener('offline', () => {
            this.props.connectionStatus(false)
        });

        window.addEventListener('hashchange',()=>{
            if (window.location.hash === '#/notification') {
                this.props.setNotificationStatus(null)
                try{
                    let transaction = this.db.transaction(["notification"], "readwrite")
                    let objectStore = transaction.objectStore("notification")
                    objectStore.delete("notification_status")
                }catch(error){}
            }
            if (window.location.hash.indexOf('#/newsfeed')===-1)
                window.localStorage.removeItem('newsfeedFilter')
        })

        let { authed } = this.props
        let token = localStorage.getItem('auth_token')
        let expireDate = localStorage.getItem('expireTokenDate')
        let expired = moment().isSameOrAfter(moment(expireDate))
        if (token && !expired && authed) {
            if(this.props.user_role === "user") this.init()
        }else if(authed){
            this.props.logout()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if((!prevProps.user_role && this.props.user_role === "user") || (!prevProps.authed && this.props.authed && this.props.user_role === "user"))
            this.init()
    }

    init = () =>{
        if(this.props.online){
            clearTimeout(this.initTimeout)
            this.initTimeout = setTimeout(() => {
                this.props.loadProfile()
                this.props.loadProfileEvent()
                this.props.loadProfileActivity()
                this.props.loadProfileFriendItem()
                this.props.refreshNewsfeedAll()
                this.props.refreshNewsfeedFriend()
                this.props.loadNotification()
                if(this.props.drafts && this.props.drafts.length)
                    this.props.sendCreateDraftActivity(this.props.drafts)
            }, 300);
        }
    }

    checkNotification = ()=>{
        try{
            if(this.db){
                let transaction = this.db.transaction(["notification"], "readwrite")
                let objectStore = transaction.objectStore("notification")
                let request = objectStore.get("notification_status")
                request.onerror = (event)=>{
                    this.props.setNotificationStatus(null)
                };

                request.onsuccess = (event)=>{
                    if(event.target.result)
                        this.props.setNotificationStatus('new_notification')
                    else
                        this.props.setNotificationStatus(null)
                };
            }
        }catch(error){
            console.log(error)
        }
    }

    render() {
        return (
            <Router>
                <Switch>
                    { this.publicRoutes(routes, this.props.hasEvent) }
                    { (this.props.user_role === "user") && this.privateRoutes(routes) }
                    { this.props.user_role === "admin" && this.adminRoutes(routes) }
                    <Route component={PageNotFound} />
                </Switch>
            </Router>
        );
    }
}

class PageNotFound extends Component {
    render(){
        return <Redirect to='/login'/>
    }
}

const mapStateToProps = (state) => {
    const { authed, role, profile, online } = state.authReducer
    return { authed:authed, user_role: role, profile:profile, drafts: state.activityReducer.drafts, online:online }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        connectionStatus: (status)=> dispatch(authAction.changeOnlineStatus(status)),
        loadProfile: ()=> dispatch(authAction.getProfile()),
        loadProfileEvent: ()=> dispatch(authAction.getProfileEvent()),
        loadProfileActivity: ()=> dispatch(authAction.getProfileActivity()),
        loadProfileFriendItem: ()=> dispatch(authAction.getProfileFriendItem()),
        logout: (link = null)=> dispatch(authAction.logout(link)),
        refreshNewsfeedAll: () => dispatch(newsfeedAction.refreshNewsfeedAll()),
        refreshNewsfeedFriend: () => dispatch(newsfeedAction.refreshNewsfeedFriend()),
        sendCreateDraftActivity: (data) => dispatch(activityAction.sendCreateDraftActivity(data)),
        loadNotification: () => dispatch(notificationAction.getAllNotification()),
        setNotificationStatus: (status)=> dispatch(notificationAction.setNotificationStatus(status))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
