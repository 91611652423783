import React, { Component } from 'react';

import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export default class ImageInput extends Component {

    openFileInput = ()=>{
        this.refs.file.click()
    }

    onFileChange = (e)=>{
        const { onChange, multi } = this.props
        let files = e.target.files
        if(multi){
            let filesResult = []
            for(let i = 0;i<files.length;i++){
                let reader = new FileReader()
                reader.onload = ()=> {
                    filesResult.push(reader.result)
                    if(filesResult.length === files.length)
                        onChange({target:{ value: filesResult }})
                }
                reader.readAsDataURL(files[i])
            }
        }else{
            let reader = new FileReader()
            reader.onload = ()=> {
                onChange({target:{ value: reader.result }})
            }
            if(e.target.files[0])
                reader.readAsDataURL(e.target.files[0])
        }
    }

    displaySingleImage = ()=>{
        const { src } = this.props
        const { openFileInput } = this
        return src?<img alt="" src={src} className="file-input-img" onClick={openFileInput}/>:<CropOriginalIcon style={{ fontSize: 150 }} onClick={openFileInput}/>
    }

    displayMultiImage = ()=>{
        const { src, deleteImage, max } = this.props
        const { openFileInput } = this
        return (
            <div className="d-flex flex-wrap justify-content-center">
                {src?src.map((s,index)=>{
                    return (
                        <div key={index} className="position-relative">
                            <HighlightOffIcon className="text-danger position-absolute" style={{right:'20px', top:'20px', fontSize:'30px'}} onClick={()=>deleteImage(index)}/>
                            <img alt="" src={s} className="file-input-img mt-2 mr-2"/>
                        </div>
                    )
                }):null}
                {src.length<max?<CropOriginalIcon style={{ fontSize: 140 }} className="file-input-img mt-2 mr-2" onClick={openFileInput}/>:null}
            </div>
        )
    }

    render() {
        const { onFileChange, displayMultiImage, displaySingleImage } = this
        const { multi } = this.props
        return (
            <div className="d-inline-block">
                <input className="d-none" ref="file" type="file" accept="image/*" onChange={onFileChange} multiple={multi}/>
                {multi?displayMultiImage():displaySingleImage()}
            </div>
        );
    }

}
