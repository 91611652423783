import strValidator from 'validator';


var customValidator = {
    isValidMaxLength,
    checkPasswordValid
}

export const validator = Object.assign({}, strValidator, customValidator);

function isValidMaxLength(value, maxValue) {
    return (value.length <= maxValue ? true: false);
}

function checkPasswordValid(password) {
    //A valid password contains only characters, but no spaces, digits and has a length between 5 and 20
    if (password.length < 5 || password.length > 20) {
        return false;
    }
    const re = new RegExp(/\s/);
    return !re.test(password);
}