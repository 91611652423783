import  {API} from '.';

export const rankingServices = {
    getDataRankingALL,
    getDataRankingFriends,
    getDataRankingMe,
    getMyEventRanking
};
   
function getDataRankingALL() {
    let url = '/activities/ranking/all';
    return API.get(url)
}
 
function getDataRankingFriends() {
    let url = '/activities/ranking/friends';
    return API.get(url)
}
 
function getDataRankingMe() {
    let url = '/activities/ranking/me';
    return API.get(url)
}

function getMyEventRanking(event_id){
    let url = '/users/event/'+event_id+'/ranking'
    return API.get(url)
}


