import {eventServices} from '../services';
import {userManagementServices} from '../services';
import {eventConstant} from '../constants/eventConstant';

function getAllEvents(page=1, limit=50) {
  return dispatch => {
      dispatch(request())
      return eventServices.getAllEvents(page, limit)
          .then(response => {
              if(response && response.errors) {
                dispatch(failure(response.errors));
                return response;
              } else {
                dispatch(success(response))
                return response
              }
          })
      function request() { return { type: eventConstant.GET_ALL_EVENTS_REQUEST } }
      function success(data) {
          return {
              type: eventConstant.GET_ALL_EVENTS_SUCCESS,
              payload: { data: data }
            }
      }
      function failure(error) {
          return {type: eventConstant.GET_ALL_EVENTS_FAILURE, error}
      }
  }
}

function getEventByID(eventID) {
    return dispatch => {
        dispatch(request())
        return eventServices.getEventByID(eventID)
            .then(response => {
                if(response && response.errors) {
                  dispatch(failure(response.errors));
                  return response;
                } else {
                  dispatch(success(response))
                  return response
                }
            })
        function request() { return { type: eventConstant.GET_EVENT_BY_ID_REQUEST } }
        function success(data) {
            return {
                type: eventConstant.GET_EVENT_BY_ID_SUCCESS,
                payload: { data: data }
              }
        }
        function failure(error) {
            return {type: eventConstant.GET_EVENT_BY_ID_FAILURE, error}
        }
    }
  }
  
function getMyEvents() {
    return dispatch => {
        dispatch(request())
        return userManagementServices.getProfile()
            .then(response => {
                if(response && response.errors) {
                  dispatch(failure(response.errors));
                  return response;
                } else {
                  dispatch(success(response))
                  return response
                }
            })
        function request() { return { type: eventConstant.GET_MY_EVENT_REQUEST } }
        function success(data) {
            return {
                type: eventConstant.GET_MY_EVENT_SUCCESS,
                payload: { data: data.events }
              }
        }
        function failure(error) {
            return {type: eventConstant.GET_MY_EVENT_FAILURE, error}
        }
    }
  }
  
function registerEvent(data) {
  return dispatch => {
      dispatch(request())
      return eventServices.registerEvent(data)
          .then(response => {
              if(response && response.errors) {
                dispatch(failure(response.errors));
                return response;
              } else {
                dispatch(success(data))
                return response
              }
          })
      function request() { return { type: eventConstant.REGISTER_EVENT_REQUEST } }
      function success(data) {
          return {
              type: eventConstant.REGISTER_EVENT_SUCCESS,
              payload: { data: data }
            }
      }
      function failure(error) {
          return {type: eventConstant.REGISTER_EVENT_FAILURE, error}
      }
  }
}

export const eventAction = {
    getAllEvents,
    getEventByID,
    getMyEvents,
    registerEvent
};
  