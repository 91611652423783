const defaultState = {
    reports:[],
    users:[],
    loading:false
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'GET_REPORT':
            return {...state,reports:[],loading:true }
        case 'GET_REPORT_SUCCESS':{
            return {...state, reports: action.payload.data, loading:false }
        }case 'GET_REPORT_FAIL':
            return {...state, loading:false }
        case 'GET_REPORT_USERS':
            return {...state,users:[],loading:true }
        case 'GET_REPORT_USERS_SUCCESS':{
            return {...state, users: action.payload.data, loading:false }
        }case 'GET_REPORT_USERS_FAIL':
            return {...state, loading:false }
        default:
            return state
    }
}
  