import React, { Component } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SearchIcon from "@material-ui/icons/Search";
import Sidebar from "./Sidebar";
import language from "../helpers/language";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isAuth: true,
    };
  }

  toggleDrawer = () => {
    this.setState({ open: !this.state.open });
  };

  goBack = () => {
    if (this.props.backUrl) window.location.href = this.props.backUrl;
    else window.history.back();
  };

  render() {
    const { headerTitle, back, profile } = this.props;
    const { open } = this.state;
    const { toggleDrawer, goBack } = this;
    let title = headerTitle ? headerTitle : "";
    let languageText = profile ? profile.language : "EN";
    return (
      <AppBar
        className={
          process.env.REACT_APP_BUILD === "development"
            ? "backgroundColorPrimaryOverrideDevelop"
            : "backgroundColorPrimaryOverride"
        }
      >
        <Toolbar>
          {back ? (
            <IconButton edge="start" color="inherit" onClick={goBack}>
              <ArrowBackIosIcon />
            </IconButton>
          ) : null}
          <IconButton edge="start" color="inherit" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className="header-title">
            {language(languageText, title)}
          </Typography>
          <IconButton color="inherit">
            <Link to="/search" className="text-white">
              <SearchIcon />
            </Link>
          </IconButton>
        </Toolbar>
        <Drawer open={open} onClose={toggleDrawer}>
          <Sidebar {...this.props} />
        </Drawer>
      </AppBar>
    );
  }
}
