import { API } from '../services/api';
import { toastr } from '../helpers/toastr';
import language from '../helpers/language';

const getTeams = () =>{
  return (dispatch,getState) => {
    dispatch({ type: 'GET_TEAMS' })
    let lang = getState().authReducer.profile.language
    return API.get('/teams/all?sort=createdAt&order=1',null).then((res)=>{
      if(res.errors){
        toastr('error',language(lang,'teams.get_teams_failed'))
        dispatch(getTeamsFail(res.errors.msg.replace(/_/g, ' ')))
      }else{
        //toastr('success',language(lang,'teams.get_teams_success'))
        dispatch(getTeamsSuccess(res));
      }
    }).catch((error)=>{
      toastr('error',language(lang,'teams.get_teams_failed'))
      dispatch(getTeamsFail())
    })
  };
}

const getTeamsSuccess = (data) => {
  return { type: 'GET_TEAMS_SUCCESS', payload: { data: data } }
}

const getTeamsFail = (error) => {
  return { type: 'GET_TEAMS_FAIL' }
}

const registerTeam = (id) => {
  return (dispatch) => {
    return API.post('/profile/registerTeam',{},{ teamId:id }).then(response => {
       if(response.Status===200)
        dispatch({ type: 'TEAM_REGISTERED', payload:{ data:id }})
    })
  }
}

const leaveTeam = () => {
  return (dispatch) => {
    return API.post('/profile/leaveTeam',{},{}).then(response => {
       if(response.Status===200)
        dispatch({ type: 'LEAVE_TEAM_SUCCESS' })
    })
  }
}

const createTeam = (data) => {
  return (dispatch) => {
    dispatch({ type: 'CREATE_TEAM'})
    return API.post('/teams',{},data).then(response => {
      if(response._id)
        dispatch({ type: 'CREATE_TEAM_SUCCESS', payload:{ data:response }})
      else
        dispatch({ type: 'CREATE_TEAM_FAIL'})
    })
  }
}

const updateTeam = (data) => {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_TEAM'})
    return API.patch('/teams/'+data._id,{},data).then(response => {
      console.log(response)
      if(response._id)
        dispatch({ type: 'UPDATE_TEAM_SUCCESS', payload:{ data:response }})
      else
        dispatch({ type: 'UPDATE_TEAM_FAIL'})
    })
  }
}

const deleteTeam = (data) => {
  return (dispatch) => {
    dispatch({ type: 'DELETE_TEAM'})
    return API.del('/teams/'+data._id).then(response => {
      if(response.Status===200)
        dispatch({ type: 'DELETE_TEAM_SUCCESS', payload:{ data:data }})
      else
        dispatch({ type: 'DELETE_TEAM_FAIL'})
    })
  }
}

export const teamAction = {
    getTeams,
    registerTeam,
    leaveTeam,
    createTeam,
    updateTeam,
    deleteTeam
};
      