import { notificationServices } from '../services';
import { notificationConstant } from '../constants/notificationConstant';
import { saveState } from '../helpers/toastr';

function getAllNotification() {
    return (dispatch,getState) => {
        dispatch(request())
        return notificationServices.getNotifications()
            .then(response => {
                if(!response.msg) {
                  dispatch(success(response))
                  saveState(getState())
                  return response
                } else {
                  dispatch(failure(response.errors));
                  return response;
            }
          })
        function request() { return { type: notificationConstant.GET_ALL_NOTIFICATION_REQUEST } }
        function success(data) {
            return {
              type: notificationConstant.GET_ALL_NOTIFICATION_SUCCESS,
              payload: { data: data }
            }
        }
        function failure(error) {
          return {type: notificationConstant.GET_ALL_NOTIFICATION_FAILURE, error}
        }
    }
}

function deleteNotification(id) {
  return (dispatch,getState) => {
      dispatch(request())
      return notificationServices.deleteNotification(id)
          .then(response => {
              if(response.msg && response.msg === "DELETED") {
                dispatch(getAllNotification())
                dispatch(success(response))
                saveState(getState())
                return response
              } else {
                dispatch(failure(response.errors));
                return response;
          }
        })
      function request() { return { type: notificationConstant.DELETE_NOTIFICATION_REQUEST } }
      function success(data) {
          return {
            type: notificationConstant.DELETE_NOTIFICATION_SUCCESS,
            payload: { data: data }
          }
      }
      function failure(error) {
        return {type: notificationConstant.DELETE_NOTIFICATION_FAILURE, error}
      }
  }
}

function deleteNotificationAll() {
  return (dispatch,getState) => {
      return notificationServices.deleteNotificationAll().then(response => {
        dispatch(success(response))
        saveState(getState())
        return response 
      })
      function success(data) {
        return { type: "DELETE_NOTIFICATION_ALL_SUCCESS" }
      }
  }
}

function setNotificationStatus(status){
  return { type: 'SET_NOTIFICATION_STATUS', status:status }
}

export const notificationAction = {
    getAllNotification, deleteNotification, setNotificationStatus, deleteNotificationAll
};