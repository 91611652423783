export const commnunicateConstant = {
    GET_ALL_COMMUNICATE_REQUEST: 'GET_ALL_COMMUNICATE_REQUEST',
    GET_ALL_COMMUNICATE_SUCCESS: 'GET_ALL_COMMUNICATE_SUCCESS',
    GET_ALL_COMMUNICATE_FAILURE: 'GET_ALL_COMMUNICATE_FAILURE',

    GET_COMMUNICATE_BY_ID_REQUEST: 'GET_COMMUNICATE_BY_ID_REQUEST',
    GET_COMMUNICATE_BY_ID_SUCCESS: 'GET_COMMUNICATE_BY_ID_SUCCESS',
    GET_COMMUNICATE_BY_ID_FAILURE: 'GET_COMMUNICATE_BY_ID_FAILURE',
    
    CREATE_COMMUNICATE_REQUEST: 'CREATE_COMMUNICATE_REQUEST',
    CREATE_COMMUNICATE_SUCCESS: 'CREATE_COMMUNICATE_SUCCESS',
    CREATE_COMMUNICATE_FAILURE: 'CREATE_COMMUNICATE_FAILURE',
    
    UPDATE_COMMUNICATE_REQUEST: 'UPDATE_COMMUNICATE_REQUEST',
    UPDATE_COMMUNICATE_SUCCESS: 'UPDATE_COMMUNICATE_SUCCESS',
    UPDATE_COMMUNICATE_FAILURE: 'UPDATE_COMMUNICATE_FAILURE',
    
    DELETE_COMMUNICATE_REQUEST: 'DELETE_COMMUNICATE_REQUEST',
    DELETE_COMMUNICATE_SUCCESS: 'DELETE_COMMUNICATE_SUCCESS',
    DELETE_COMMUNICATE_FAILURE: 'DELETE_COMMUNICATE_FAILURE',
}