
import React, { Component } from 'react';
import { connect } from "react-redux";
import Swal from 'sweetalert2';
import ReactModal from 'react-modal';
import { Button, Table, TableHead, TableBody, TableRow, TableCell, CardContent, FormLabel, Input } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { teamAction } from '../../../actions/team-action';
import language from '../../../helpers/language';

class Teams extends Component {

    constructor(props){
        super(props)
        this.state = {
            openForm:false,
            teamForm:{
                name:'',
                description:''
            },
        }
    }

    componentDidMount(){
        this.props.getTeams()
    }

    openCreate = ()=>{
        this.setState({
            openForm:true,
            teamForm:{
                name:'',
                description:''
            }
        })
    }

    openEdit = (data)=>{
        this.setState({
            openForm:true,
            teamForm:{
                _id:data._id,
                name:data.name,
                description:data.description
            }
        })
    }

    closeModal = ()=>{
        this.setState({
            openForm:false,
            teamForm:{
                name:'',
                description:''
            }
        })
    }
    
    editForm = (event)=>{
        this.setState({
            teamForm:{...this.state.teamForm,[event.target.name]:event.target.value}
        })
    }

    submit = (e)=>{
        e.preventDefault();
        this.setState({ openForm:false })
        if(this.state.teamForm._id)
            this.props.updateTeam(this.state.teamForm)
        else
            this.props.createTeam(this.state.teamForm)
    }

    deleteTeam = (data)=>{
        const { languagetext } = this.props;
        Swal.fire({
            title: '',
            text: language(languagetext,"delete_item_confirmation"),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: language(languagetext,"yes"),
            cancelButtonText: language(languagetext,"no"),
            confirmButtonColor: '#e60003',
            cancelButtonColor: '#707070',
          }).then((result) => {
            if (result.value) {
              this.props.deleteTeam(data)
            }
          })
    }

    render() {
        const { languagetext, loading } = this.props;
        const { openForm, teamForm } = this.state;
        const { openCreate, openEdit, closeModal, editForm, submit, deleteTeam } = this
        return (
            <CardContent>
                {loading?<div className="overlay-loading text-center"><span className="spinner-1 inline-spinner "/></div>:null}
                <ReactModal
                        isOpen={openForm}
                        contentLabel="Team Detail"
                        className="visible"
                        overlayClassName="sweet-overlay"
                        style={{
                            content: {display: 'block', marginTop: '50px', backgroundColor: 'initial', outline: 'none'},
                            overlay: {opacity: '1.07', display: 'block'},
                            zIndex: '100!important'
                        }}
                        ariaHideApp={false}
                        shouldFocusAfterRender={false}
                        shouldReturnFocusAfterClose={false}
                        shouldCloseOnEsc={true}
                        role="dialog" >
                        <div className="modal-dialog modal-xl">
                            <form onSubmit={e => this.saveEventForm(e)} ref={c => { this.form = c }}>
                                <div className="modal-content">
                                    <div className="modal-header d-flex align-items-center">
                                        <h4 className="modal-title">
                                            <span onClick={this.closeEventModal} className="close-modal">
                                                <i className="fa fa-angle-left mr-3"></i>
                                            </span>
                                            {language(languagetext, "team_details")}
                                        </h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <FormLabel className="fz-14 font-weight-500">{language(languagetext, "name")}<span className="text-danger">(*)</span></FormLabel>
                                                    <Input
                                                    fullWidth
                                                    label="Team name"
                                                    margin="dense"
                                                    name="name"
                                                    required
                                                    value={teamForm.name}
                                                    onChange={editForm}
                                                    variant="outlined"
                                                    placeholder={language(languagetext, "team_name")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <FormLabel className="fz-14 font-weight-500">{language(languagetext, "description")}<span className="text-danger">(*)</span></FormLabel>
                                                        <Input
                                                        fullWidth
                                                        label="Team description"
                                                        margin="dense"
                                                        name="description"
                                                        required
                                                        value={teamForm.description}
                                                        onChange={editForm}
                                                        variant="outlined"
                                                        placeholder={language(languagetext, "team_description")}
                                                        />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 mt-3 text-center">
                                        <Button type="submit" variant="contained" color="primary" className="mr-3 backgroundColorPrimaryOverride" disabled={!teamForm.name || !teamForm.description} onClick={submit}>{language(languagetext, "save")}</Button>
                                        <Button type="button" variant="contained" color="secondary" className="mr-3 btn-gray" onClick={closeModal}>{language(languagetext, "cancel")}</Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </ReactModal>
                <Button color="primary" variant="contained" startIcon={<AddIcon />} onClick={openCreate}>
                {language(languagetext, "add_new")}
                </Button>
                <div style={{height: 'auto', maxWidth:"100vw"}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{language(languagetext,"no")}</TableCell>
                                <TableCell>{language(languagetext, "name")}</TableCell>
                                <TableCell>{language(languagetext,"description")}</TableCell>
                                <TableCell>{language(languagetext, "actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.teams.map((team, index) => (
                            <TableRow hover key={team._id}>
                                <TableCell>{index+1}</TableCell>
                                <TableCell>{team.name}</TableCell>
                                <TableCell>{team.description}</TableCell>
                                <TableCell>
                                    <Button variant="contained" color="primary" onClick={()=>openEdit(team)}><EditIcon/></Button>
                                    <Button className="ml-2" variant="contained" color="secondary" onClick={()=>deleteTeam(team)}><DeleteIcon/></Button>
                                </TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </CardContent>
        )};
};

function mapStateToProps(state) {
    return {...state.teamReducer}
}

function mapDispatchToProps(dispatch) {
    return {
        getTeams:()=>dispatch(teamAction.getTeams()),
        createTeam: (data)=>dispatch(teamAction.createTeam(data)),
        updateTeam: (data)=>dispatch(teamAction.updateTeam(data)),
        deleteTeam: (data)=>dispatch(teamAction.deleteTeam(data))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Teams);