import React, {Component} from 'react';
import { Link } from "react-router-dom";
import {connect} from "react-redux";
import { confirm } from '../../helpers/toastr';

import { activityAction } from '../../actions/activity-action';

import { IconButton, Paper, CircularProgress } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import * as moment from 'moment';

class Activity extends Component {

    confirmDelete = ()=>{
        confirm(()=>{
            if(this.props.activity.draft)
                this.props.deleteDraftActivity(this.props.activity)
            else
                this.props.deleteActivity(this.props.activity)
        }, this.props.profile.language)
    }

    truncateText = (str)=>{
        if(str.length>20)
            return str.substring(0, 19)+'...'
        else return str
    }

    render(){
        const { event, activity, profile, action } = this.props
        const { confirmDelete } = this;
        return(
            <Paper elevation={3} className="mb-2 p-2 w-100 childActivity">
                <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column justify-content-center" style={{flexGrow:1}}>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column justify-content-center text-left">
                                <div className="text-right color-gray">{moment(activity.date).format('DD/MM/YYYY')}</div>
                            </div>
                            {activity.draft?<div className="d-flex flex-column justify-content-center text-warning">draft</div>:null}
                            <div className="d-flex flex-column justify-content-center">
                                {profile.unit==="KM"?<div className="text-right color-red">{activity.distanceKm} km</div>:null}
                                {profile.unit!=="KM"?<div className="text-right color-red">{activity.distanceMile} miles</div>:null}
                            </div>
                        </div>
                    </div>
                    {(activity.draft && activity.loading)?<IconButton color="primary"><CircularProgress size={20}/></IconButton>:
                        action?<div>
                            <IconButton onClick={confirmDelete} className="color-gray"><DeleteIcon/></IconButton>
                            <IconButton component={Link} to={"/activity-form/"+event._id+"/"+activity._id}  className="color-gray"><EditIcon/></IconButton>
                        </div>:null
                    }
                </div>
            </Paper>
        )
    }
}

function mapStateToProps(state) {
    return {...state.activityReducer,profile:state.authReducer.profile}
}

function mapDispatchToProps(dispatch) {
    return {
        deleteDraftActivity:(data) => dispatch(activityAction.deleteDraftActivity(data)),
        deleteActivity: (data) => dispatch(activityAction.deleteActivity(data))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Activity);