import Swal from 'sweetalert2';
import language from './language';

export const toastr = (type, message) => Swal.fire({
  icon: type,
  title: message,
  toast: true,
  position: 'top-end',
  confirmButtonColor: '#e60003',
  showConfirmButton: false,
  timerProgressBar: true,
  timer: 3000
});

export const confirm = (cb, languageText,textOp = '') => {
  let title = 'Are you sure?';
  let text = "You won't be able to revert this!";
  let confirmButtonText = 'Confirm';
  let cancelButtonText = 'Cancel';
  if (languageText) {
    title = language(languageText, 'are_you_sure');
    text = textOp?textOp:language(languageText, 'no_revert');
    confirmButtonText = language(languageText, 'ok');
    cancelButtonText = language(languageText, 'cancel')
  }
  return Swal.fire({
    title: title,
    text: text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#e60003',
    cancelButtonColor: '#707070',
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    reverseButtons: true
  }).then((result) => {
    if (result.value)
      cb()
  })
}

export const popup = (text,type,cb = null) => {
  return Swal.fire({
    text: text,
    icon: type,
    confirmButtonColor: '#e60003',
  }).then(()=>{
    if(cb) return cb()
  })
}

var delaySaveState
export const saveState = (state) => {
  clearTimeout(delaySaveState)
  delaySaveState = setTimeout(() => {
    try{
      localStorage.setItem('store', JSON.stringify(state))
    }catch(err){
    }
  }, 2000)
}
