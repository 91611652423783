export const eventConstant = {
    GET_ALL_EVENTS_REQUEST: 'GET_ALL_EVENTS_REQUEST',
    GET_ALL_EVENTS_SUCCESS: 'GET_ALL_EVENTS_SUCCESS',
    GET_ALL_EVENTS_FAILURE: 'GET_ALL_EVENTS_FAILURE',

    GET_EVENT_BY_ID_REQUEST: 'GET_EVENT_BY_ID_REQUEST',
    GET_EVENT_BY_ID_SUCCESS: 'GET_EVENT_BY_ID_SUCCESS',
    GET_EVENT_BY_ID_FAILURE: 'GET_EVENT_BY_ID_FAILURE',
    
    GET_MY_EVENT_REQUEST: 'GET_MY_EVENT_REQUEST',
    GET_MY_EVENT_SUCCESS: 'GET_MY_EVENT_SUCCESS',
    GET_MY_EVENT_FAILURE: 'GET_MY_EVENT_FAILURE',
    
    REGISTER_EVENT_REQUEST: 'REGISTER_EVENT_REQUEST',
    REGISTER_EVENT_SUCCESS: 'REGISTER_EVENT_SUCCESS',
    REGISTER_EVENT_FAILURE: 'REGISTER_EVENT_FAILURE',
}