import axios from 'axios';

var baseURL = process.env.REACT_APP_API_URL;

export const API = {
    baseURL,
    get, post, patch, del,
    download, downloadDepartment
};

var defaultContentType = 'application/json; charset=utf-8';

var requestConfig = {
    url: '/',
    method: 'GET',
    baseURL: baseURL,
    headers: {
        common: {
            'Content-Type': defaultContentType,
        }
    },
    params: {},
    data: {},
    dataType: 'json',
    timeout: 1500000,
    withCredentials: true,
    // `auth` indicates that HTTP Basic auth should be used, and supplies credentials.
    // This will set an `Authorization` header, overwriting any existing
    // `Authorization` custom headers you have set using `headers`.
    // auth: {
    //     // username: 'admin@tinhvan.com',
    //     // password: 'Tinhvan@123'
    // },
    responseType: 'json', // default

    // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
    xsrfCookieName: 'XSRF-TOKEN', // default

    // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
    xsrfHeaderName: 'X-XSRF-TOKEN', // default

    // `onUploadProgress` allows handling of progress events for uploads
    onUploadProgress: function (progressEvent) {
        // Do whatever you want with the native progress event
    },

    // `onDownloadProgress` allows handling of progress events for downloads
    onDownloadProgress: function (progressEvent) {
        // Do whatever you want with the native progress event
    },
    maxContentLength: 2000,
    validateStatus: function (status) {
        return status < 500;
    }

};

// function _setHeader() {
//     var loggedUser = JSON.parse(localStorage.getItem('user'));

//     if(loggedUser) requestConfig.headers.common['Authorization'] = 'Bearer ' + loggedUser.Access_token;
// }

function get(url, params = {}, data = {}, headers = null) {

    requestConfig.url = url;
    requestConfig.method = 'GET';
    requestConfig.params = params;
    requestConfig.data = data;
    requestConfig.responseType = 'json';

    // request header
    if(headers !== null) {
        requestConfig.headers.get = headers;
    }
    //_setHeader();
    requestConfig.headers.common.Authorization = 'Bearer ' + localStorage.getItem("auth_token")
    return axios(requestConfig)
        .then(function (response) {
            if (response.data) {
                response.data.Status = response.status;
                return response.data;
            }
            else {
                if(response.status === 401){
                    localStorage.clear()
                    window.location.reload()
                }
                return 'Please try again later';
            }
        })
        .catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                //console.log('error.response.data', error.response.data);
                //console.log('error.response.status', error.response.status);
                //console.log('error.response.headers', error.response.headers);

                return error.response;
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log('error.request', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

            //console.log('error.config', error.config);
            return error;
        });
}

function post(url, params = {}, data = {}, headers = null, language='de') {
    requestConfig.url = url;
    requestConfig.method = 'POST';
    requestConfig.params = params;
    requestConfig.data = data;
    requestConfig.responseType = 'json';

    // request header8
    if(headers !== null) {
        requestConfig.headers.post = headers;
    }
    //_setHeader();
    requestConfig.headers.common.Authorization = 'Bearer ' + localStorage.getItem("auth_token")
    requestConfig.headers.common['Accept-Language'] = language
    return axios(requestConfig)
        .then(function (response) {
            if (response.data) {
                response.data.Status = response.status;
                return response.data;
            }
            else {
                throw response;
            }
        })
        .catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                //console.log('error', error);
                //console.log('error.response.data', error.response.data);
                //console.log('error.response.status', error.response.status);
                //console.log('error.response.headers', error.response.headers);

                if (!error.response.data) error.response.data = {};
                error.response.data.Status = error.response.status;
                throw error.response.data;
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                throw error.request;
            } else {
                // Something happened in setting up the request that triggered an Error
                throw error;
            }
        });
}

function patch(url, params = {}, data = {}, headers = null) {
    requestConfig.url = url;
    requestConfig.method = 'PATCH';
    requestConfig.params = params;
    requestConfig.data = data;
    requestConfig.responseType = 'json';

    // request header8
    if(headers !== null) {
        requestConfig.headers.post = headers;
    }
    //_setHeader();
    requestConfig.headers.common.Authorization = 'Bearer ' + localStorage.getItem("auth_token")
    return axios(requestConfig)
        .then(function (response) {
            if (response.data) {
                response.data.Status = response.status;
                return response.data;
            }
            else {
                throw response;
            }
        })
        .catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                //console.log('error', error);
                //console.log('error.response.data', error.response.data);
                //console.log('error.response.status', error.response.status);
                //console.log('error.response.headers', error.response.headers);

                if (!error.response.data) error.response.data = {};
                error.response.data.Status = error.response.status;
                throw error.response.data;
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                throw error.request;
            } else {
                // Something happened in setting up the request that triggered an Error
                throw error;
            }
        });
}

function del(url, params = {}, data = {}, headers = null) {
    requestConfig.url = url;
    requestConfig.method = 'DELETE';
    requestConfig.params = params;
    requestConfig.data = data;
    requestConfig.responseType = 'json';

    // request header
    if(headers !== null) {
        requestConfig.headers.post = headers;
    }
    //_setHeader();
    requestConfig.headers.common.Authorization = 'Bearer ' + localStorage.getItem("auth_token")
    return axios(requestConfig)
        .then(function (response) {
            if (response.data) {
                response.data.Status = response.status;
                return response.data;
            }
            else {
                throw response;
            }
        })
        .catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                //console.log('error.response.data', error.response.data);
                //console.log('error.response.status', error.response.status);
                //console.log('error.response.headers', error.response.headers);

                error.response.data.Status = error.response.status;
                throw error.response.data;
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                throw error.request;
            } else {
                // Something happened in setting up the request that triggered an Error
                throw error;
            }
        });
}

function download(url, options = {}) {
    if(!options.method) requestConfig.method = 'POST'; //method: 'GET', params: {}, data: {}, fileName: 'download'
    if(!options.data) requestConfig.data = options.data;
    if(!options.fileName) requestConfig.fileName = 'download';
    requestConfig.url = url;
    requestConfig.responseType = 'blob'
    requestConfig = {...requestConfig, ...options}
    requestConfig.headers.common.Authorization = 'Bearer ' + localStorage.getItem("auth_token")
    return axios(requestConfig)
            .then( response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', options.fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch( e => {
                console.log('error download', e)
            });
}

function downloadDepartment(url, options = {}) {
    if(!options.method) requestConfig.method = 'POST'; //method: 'GET', params: {}, data: {}, fileName: 'download'
    if(!options.data) requestConfig.data = options.data;
    if(!options.fileName) requestConfig.fileName = 'download';
    requestConfig.url = url;
    requestConfig.params = {};
    requestConfig.responseType = 'blob'
    requestConfig = {...requestConfig, ...options}
    requestConfig.headers.common.Authorization = 'Bearer ' + localStorage.getItem("auth_token")
    return axios(requestConfig)
            .then( response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', options.fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch( e => {
                console.log('error download', e)
            });
}


// function getDownloadLink(fileName, params) {
//     return appConstants.API_BASEURL + '/Common/GetFile?path=' + btoa(fileName);
// }
