import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Input, InputLabel } from '@material-ui/core';
import Swal from 'sweetalert2';
import {connect} from "react-redux";
import {validator} from "../../helpers";
import {authAction} from '../../actions/auth-action';
import language from '../../helpers/language';

class ChangePassword extends Component {
    constructor(props) {
        super(props)


        this.state = {
            oldPassword: '',
            passWord: '',
            passWordConfirm: '',
            validator: {
                oldPassword: false,
                passWord: false,
                passWordConfirm: false,
                emptyOldPassword: false,
                emptyPassword: false,
                emptyPasswordConfirm: false,
            },
            showLoading: false,
            goLogin: false,
            showConfirmLogin: false,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.inputOldPassword = this.inputOldPassword.bind(this);
        this.inputPassword = this.inputPassword.bind(this);
        this.inputPasswordConfirm = this.inputPasswordConfirm.bind(this);
        this.handleBlurOldPassword = this.handleBlurOldPassword.bind(this);
        this.handleBlurPassword = this.handleBlurPassword.bind(this);
        this.handleBlurPasswordConfirm = this.handleBlurPasswordConfirm.bind(this);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { passWord} = this.state;

        let vali = {
            passWord: false,
            passWordConfirm: false
        }
        this.setState({ validator: vali, showLoading: true});

        //Call api change password
        let data = {
            oldPassword: this.state.oldPassword,
            newPassword: passWord
        }
        let languageText = this.props.profile ? this.props.profile.language : 'EN';
        console.log('changePassword', languageText)
        let changePasswordSuccess = language(languageText, 'label_change_password_successful');
        let changePasswordFailed = language(languageText, 'label_change_password_failed');
        console.log('changePassword', changePasswordSuccess)
        console.log('changePassword', changePasswordFailed)

        this.props.dispatch(authAction.changePassword(data)).then(response => {
            this.setState({showLoading: false})
            if(response && !response.errors) {
                Swal.fire({
                    title: '',
                    text: changePasswordSuccess,
                    icon: 'success',
                    confirmButtonColor: '#e60003',
                    confirmButtonText: language(languageText, 'ok')
                })
            } else {
                Swal.fire({
                    title: '',
                    text: changePasswordFailed,
                    icon: 'error',
                    confirmButtonColor: '#e60003',
                    confirmButtonText: language(languageText, 'ok')
                })
            }
        }).catch(err => {
            this.setState({
                showLoading: false
            })
            Swal.fire({
                title: '',
                text: changePasswordFailed,
                icon: 'error',
                confirmButtonColor: '#e60003',
                confirmButtonText: language(languageText, 'ok')
            })
        });
    }

    inputOldPassword(e) {
        let strValue = String(e.target.value);
        let vali = this.state.validator;
        vali.emptyOldPassword = false
        this.setState({
            oldPassword: strValue.trim(),
            validator: vali
        })
        if (validator.checkPasswordValid(strValue)) {
            this.setState({validator: {...vali, oldPassword: false}});
        }
        else {
            this.setState({validator: {...vali, oldPassword: true}});
        }
    }

    inputPassword(e) {
        let strValue = String(e.target.value);
        let vali = this.state.validator;
        vali.emptyPassword = false;
        this.setState({
            passWord: strValue.trim(),
            validator: vali
        })
        if (validator.checkPasswordValid(strValue)) {
            this.setState({validator: {...vali, passWord: false}});
        }
        else {
            this.setState({validator: {...vali, passWord: true}});
        }
    }

    inputPasswordConfirm(e) {
        let strValue = String(e.target.value);
        let vali = this.state.validator;
        vali.emptyPasswordConfirm = false;
        this.setState({
            passWordConfirm: strValue.trim(),
            validator: vali
        })
        if (this.state.passWord === strValue) {
            this.setState({validator: {...vali, passWordConfirm: false}});
        }
        else {
            this.setState({validator: {...vali, passWordConfirm: true}});
        }
    }

    handleBlurOldPassword = () => {
        let oldPassword = this.state.oldPassword;
        let vali = this.state.validator;
        if (oldPassword.trim() === '') {
            this.setState({validator: {...vali, emptyOldPassword: true, oldPassword: false}});
        }
    }

    handleBlurPassword = () => {
        let passWord = this.state.passWord;
        let vali = this.state.validator;
        if (passWord.trim() === '') {
            this.setState({validator: {...vali, emptyPassword: true, passWord: false}});
        }
    }

    handleBlurPasswordConfirm = () => {
        let passWordConfirm = this.state.passWordConfirm;
        let vali = this.state.validator;
        if (passWordConfirm.trim() === '') {
            this.setState({validator: {...vali, emptyPasswordConfirm: true, passWordConfirm: false}});
        }
    }

    render() {
        const { oldPassword, passWord, passWordConfirm, goLogin } = this.state;
        let disabledButton = false;
        if(oldPassword === '' || passWord === '' || passWordConfirm === '' || validator.oldPassword || validator.passWord || validator.passWordConfirm) {
            disabledButton = true;
        }

        let languageText = this.props.profile ? this.props.profile.language : 'EN';
        if (goLogin) {
            return (
                <Redirect to='/'/>
            )
        }
        else {
            return (
                // <Container maxWidth="sm">
                <div className="container pt-4">
                    {
                        this.state.showLoading &&
                        <div className="overlay-loading text-center"><span className="spinner-1 inline-spinner "/>
                        </div>
                    }
                    <div className=" mt-3 mb-3">
                        {/* <div className="col-12 align-items-center px-0 mt-3 mb-3 text-center">
                            <h2 className="page-title mb-0 text-uppercase font-weight-600 fz-24 text-center">Password change</h2>
                        </div> */}
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <InputLabel className="label-input">{language(languageText,"your_current_password")} <span className="text-danger">(*)</span></InputLabel>
                                    <Input
                                        type="password"
                                        id="currentPassword"
                                        className="form-control"
                                        onChange={this.inputOldPassword}
                                        onBlur={this.handleBlurOldPassword}
                                        value={this.state.oldPassword}
                                        placeholder={language(languageText,"your_current_password")}
                                    />
                                    {this.state.validator.emptyOldPassword ? <small className="d-block invalid-feedback">{language(languageText,"current_password_is_required")}</small> : ''}
                                    {this.state.validator.oldPassword ? <small className="d-block invalid-feedback">{language(languageText,"label_password_invalid")}</small> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <InputLabel className="label-input">{language(languageText,"your_new_password")} <span className="text-danger">(*)</span></InputLabel>
                                    <Input
                                        type="password"
                                        id="newPassword"
                                        className="form-control"
                                        onChange={this.inputPassword}
                                        onBlur={this.handleBlurPassword}
                                        value={this.state.passWord}
                                        placeholder={language(languageText,"your_new_password")}
                                    />
                                    {this.state.validator.emptyPassword ? <small className="d-block invalid-feedback">{language(languageText,"new_password_is_required")}</small> : ''}
                                    {this.state.validator.passWord ? <small className="d-block invalid-feedback">{language(languageText,"label_password_invalid")}</small> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <InputLabel className="label-input">{language(languageText,"password_confirmation")} <span className="text-danger">(*)</span></InputLabel>
                                    <Input
                                        type="password"
                                        id="passwordConfirm"
                                        className="form-control"
                                        onChange={this.inputPasswordConfirm}
                                        onBlur={this.handleBlurPasswordConfirm}
                                        value={this.state.passWordConfirm}
                                        placeholder={language(languageText,"password_confirmation")}
                                    />
                                    {this.state.validator.emptyPasswordConfirm ? <small className="d-block invalid-feedback">{language(languageText,"password_confirmation_is_required")}</small> : ''}
                                    {this.state.validator.passWordConfirm ? <small className="d-block invalid-feedback">{language(languageText,"password_and_confirmation_does_not_match")}</small> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className=" mr-3 text-center">
                                    <Button type="button" id="btnChangePass" variant="contained" color="primary" className="mr-3 btn-success" disabled={disabledButton} onClick={this.handleSubmit}>{language(languageText,"change")}</Button>
                                    {/* <Button type="button" variant="contained">Cancel</Button> */}
                                    <Link to="/profile" className="btn btn-secondary" >
                                    {language(languageText,"cancel")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </Container> */}
                </div>
        )}
    }
}

function mapStateToProps(state) {
    const {authReducer} = state;
    return {
        authReducer: authReducer,
        profile: authReducer.profile
    }
}

export default connect(mapStateToProps)(ChangePassword);
