import React, { Component } from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";

import { authAction } from "../../actions/auth-action";

import {
  ButtonGroup,
  FormLabel,
  Button,
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TimelineIcon from "@material-ui/icons/Timeline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import Activity from "./Activity";
import language from "../../helpers/language";
import * as moment from "moment";
import CountDownTo from "../../components/CountDownTo";

class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfLoad: 0,
      activeTab: null,
    };
  }

  refresh = (e) => {
    if (this.props.online && !this.props.loadingEvent) {
      this.setState({ numberOfLoad: this.state.numberOfLoad + 1 });
      this.props.loadProfileEvent();
    }
  };

  togglePanel = (id) => {
    if (this.state.activeTab === id) this.setState({ activeTab: null });
    else this.setState({ activeTab: id });
  };

  render() {
    const { refresh, togglePanel } = this;
    const { numberOfLoad, activeTab } = this.state;
    const { drafts, loadingEvent, profile, activities } = this.props;
    return (
      <div key={numberOfLoad} className="container text-center pt-4 pb-4">
        {loadingEvent ? <CircularProgress className="mt-3" /> : null}
        <InfiniteScroll
          dataLength={profile.events.length}
          refreshFunction={refresh}
          hasmore={false}
          pullDownToRefresh
          scrollableTarget="app-content"
          pullDownToRefreshThreshold={100}
          pullDownToRefreshContent={
            <RefreshIcon fontSize="large" color="action" />
          }
          releaseToRefreshContent={
            <RefreshIcon fontSize="large" color="primary" />
          }
          loader={<CircularProgress />}
        >
          {profile.events.map((event) => {
            let is_future_event = moment().isBefore(
              moment(event.startDate).startOf("day")
            );
            let is_pass_event = moment().isAfter(
              moment(event.endDate).startOf("day")
            );
            let is_active_event =
              moment().isSameOrBefore(moment(event.endDate).startOf("day")) &&
              moment().isSameOrAfter(moment(event.startDate).startOf("day"));
            return (
              <div key={event._id} className="progressScreen">
                <ExpansionPanel
                  expanded={activeTab === event._id}
                  onChange={() => togglePanel(event._id)}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  >
                    <div className="progressTitle text-center">
                      <p className="mb-1 d-inline-block">
                        <b>{event.name}</b>
                      </p>
                      {is_active_event ? (
                        <p>
                          <CountDownTo to={event.endDate} />
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="progressBtn text-center">
                      {is_active_event ? (
                        <ButtonGroup
                          size="small"
                          aria-label="small outlined button group"
                          className="groupBtn"
                        >
                          <Button component={Link} to={"/ranking/" + event._id}>
                            <div className="d-flex flex-column justify-content-center">
                              <EqualizerIcon className="w-auto" />
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  fontSize: "11px",
                                }}
                              >
                                {language(profile.language, "position")}
                              </span>
                            </div>
                          </Button>
                          <Button
                            component={Link}
                            to={"/my-activity/" + event._id}
                          >
                            <div className="d-flex flex-column justify-content-center">
                              <TimelineIcon className="w-auto" />
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  fontSize: "11px",
                                }}
                              >
                                {language(profile.language, "progress_btn")}
                              </span>
                            </div>
                          </Button>
                          <Button
                            component={Link}
                            to={"/activity-form/" + event._id}
                          >
                            <div className="d-flex flex-column justify-content-center">
                              <AddCircleOutlineIcon className="w-auto" />
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  fontSize: "11px",
                                }}
                              >
                                {language(profile.language, "new_activity")}
                              </span>
                            </div>
                          </Button>
                        </ButtonGroup>
                      ) : null}
                      {is_pass_event ? (
                        <ButtonGroup
                          size="small"
                          aria-label="small outlined button group"
                          className="groupBtn"
                        >
                          <Button component={Link} to={"/ranking/" + event._id}>
                            <div className="d-flex flex-column justify-content-center">
                              <EqualizerIcon className="w-auto" />
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  fontSize: "11px",
                                }}
                              >
                                {language(profile.language, "position")}
                              </span>
                            </div>
                          </Button>
                          <Button
                            component={Link}
                            to={"/my-activity/" + event._id}
                          >
                            <div className="d-flex flex-column justify-content-center">
                              <TimelineIcon className="w-auto" />
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  fontSize: "11px",
                                }}
                              >
                                {language(profile.language, "progress_btn")}
                              </span>
                            </div>
                          </Button>
                        </ButtonGroup>
                      ) : null}
                    </div>
                    {is_future_event ? (
                      <small className="text-warning">
                        {language(
                          profile.language,
                          "newsfeed.challenge_start_at"
                        )}{" "}
                        {moment(event.startDate).format("DD/MM/YYYY")}
                      </small>
                    ) : null}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className="d-block">
                    {[...drafts, ...activities]
                      .filter((el) => el.eventId === event._id)
                      .map((activity) => {
                        return (
                          <Activity
                            key={activity._id}
                            event={event}
                            activity={activity}
                            action={is_active_event}
                          />
                        );
                      })}
                    {!loadingEvent &&
                    ![...drafts, ...activities].filter(
                      (el) => el.eventId === event._id
                    ).length ? (
                      <div className="row">
                        <div className="col-12">
                          <div className="mt-2">
                            <FormLabel className="label-input">
                              {language(profile.language, "no_record_found")}
                            </FormLabel>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            );
          })}
        </InfiniteScroll>
        {!loadingEvent && !profile.events.length ? (
          <div className="row">
            <div className="col-12">
              <div className="mt-3">
                {/*<FormLabel className="label-input">{language(profile.language,"no_record_found")}</FormLabel>    */}
                <Button
                  component={Link}
                  to={"/active-events"}
                  className="backgroundColorPrimaryOverride"
                >
                  {language(profile.language, "go_to_active_challange")}
                </Button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.activityReducer,
    ...state.eventReducer,
    ...state.activityReducer,
    ...state.authReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadProfileEvent: () => dispatch(authAction.getProfileEvent()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Progress);
