import {
  CircularProgress,
  IconButton,
  Input,
  InputLabel,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { authAction } from "../../actions/auth-action";
import { searchAction } from "../../actions/search-action";
import language from "../../helpers/language";
import { friendServices } from "../../services/friendService";
import RankingItem from "../Ranking/RankingItem";

class Search extends Component {
  constructor(props) {
    super(props);
    let data =
      props.searchResults && props.searchResults.docs
        ? props.searchResults.docs
        : [];
    if (props.history && props.history.action === "PUSH") data = [];
    this.state = {
      textSearch: "",
      page: 1,
      limit: 10,
      listSearchResults: data,
      showLoading: false,
      numberOfLoad: 0,
      hasMore: false,
      isRefresh: true,
    };
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
    this.inputSearch = this.inputSearch.bind(this);
  }

  handleSubmitSearch = (event) => {
    event.preventDefault();
    this.doSearch(this.state.textSearch.trim(), 1, this.state.limit);
  };

  doSearch = (keyword, page, limit) => {
    this.setState({ showLoading: true });
    if (this.state.textSearch.trim().length > 0) {
      this.props
        .dispatch(
          searchAction.getRankingSearch(
            this.props.match.params.id,
            keyword,
            page,
            limit
          )
        )
        .then((res) => {
          if (res && res.errors) {
            this.setState({
              showLoading: false,
              listSearchResults: [],
              numberOfLoad: this.state.numberOfLoad + 1,
              page: page,
              hasMore: false,
            });
          } else {
            let tempData = this.state.listSearchResults;
            let dataResults = page === 1 ? res : tempData.concat(res);
            this.setState({
              showLoading: false,
              listSearchResults: dataResults,
              numberOfLoad: this.state.numberOfLoad + 1,
              page: page,
              hasMore: res.hasNextPage,
            });
          }
        })
        .catch((err) => {
          this.setState({
            showLoading: false,
            listSearchResults: [],
            numberOfLoad: this.state.numberOfLoad + 1,
            page: page,
            hasMore: false,
          });
          console.log("error here: ", err);
        });
    } else {
      this.setState({
        showLoading: false,
        listSearchResults: [],
        page: page,
        hasMore: false,
      });
    }
  };

  onScroll = (e) => {
    if (
      e.target.scrollTop >=
        e.target.scrollHeight - e.target.offsetHeight - 300 &&
      this.state.hasMore &&
      !this.state.showLoading
    ) {
      this.loadMore();
    }
  };

  loadMore = () => {
    const { limit, page, textSearch } = this.state;
    this.setState({ isRefresh: false });
    this.doSearch(textSearch.trim(), page + 1, limit);
  };

  refreshData = () => {
    this.setState({ isRefresh: true });
    this.doSearch(this.state.textSearch.trim(), 1, this.state.limit);
  };

  inputSearch(e) {
    let strValue = String(e.target.value);
    this.setState({
      textSearch: strValue,
    });
  }

  isAlreadyFriend = (id) => {
    let { profile } = this.props;
    console.log(profile);
    if (
      profile.sendingRequestFriends
        .map((item) => item.receiver._id)
        .includes(id) ||
      profile.waitingAcceptFriends
        .map((item) => item.receiver._id)
        .includes(id) ||
      profile.friends.map((item) => item.receiver._id).includes(id) ||
      profile.friends.map((item) => item.sender._id).includes(id)
    ) {
      return "YES";
    }
    return "NO";
  };

  handleRequestAddFriend = (id) => {
    let languageText = this.props.profile ? this.props.profile.language : "EN";
    Swal.fire({
      text: language(languageText, "add_as_friend") + "?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: language(languageText, "Ok"),
      confirmButtonColor: "#e60003",
      cancelButtonColor: "#707070",
      cancelButtonText: language(languageText, "Cancel"),
    }).then((result) => {
      if (result.value) {
        this.setState({ showLoading: true });
        friendServices
          .addFriends(id)
          .then((res) => {
            this.setState({ showLoading: false });
            if (res && res.errors) {
              Swal.fire({
                text: language(languageText, "label_request_friend_failed"),
                icon: "error",
                confirmButtonColor: "#e60003",
              });
            } else {
              Swal.fire({
                text: language(languageText, "label_request_friend_successful"),
                icon: "info",
                confirmButtonColor: "#e60003",
              }).then((result) => {
                this.props.dispatch(authAction.getProfileFriendItem());
              });
            }
          })
          .catch((err) => {
            console.error("Error while adding friend: ", err);
            this.setState({ showLoading: false });
            Swal.fire({
              text: language(languageText, "label_request_friend_failed"),
              icon: "error",
              confirmButtonColor: "#e60003",
            });
          });
      }
    });
  };

  render() {
    let { listSearchResults, hasMore, showLoading, isRefresh, numberOfLoad } =
      this.state;
    let { profile, userId } = this.props;

    let languageText = this.props.profile ? this.props.profile.language : "EN";
    return (
      <div className="container text-center pt-4" key={"abc" + numberOfLoad}>
        <div className="row">
          <div className="col-12">
            <form>
              <div className="input-group form-inline">
                <IconButton
                  type="submit"
                  id="submit_search"
                  className="align-self-center"
                  style={{ position: "absolute", zIndex: 2 }}
                  onClick={this.handleSubmitSearch}
                >
                  <SearchIcon />
                </IconButton>
                <Input
                  type="text"
                  id="search_string"
                  className="form-control pl-5"
                  onChange={this.inputSearch}
                  value={this.state.textSearch}
                  placeholder={language(languageText, "search_string")}
                />
              </div>
            </form>
          </div>
        </div>
        {showLoading && (isRefresh || this.state.page === 1) ? (
          <CircularProgress className="mt-3" />
        ) : null}

        {listSearchResults && listSearchResults.length > 0
          ? listSearchResults.map((item, index) => (
              <RankingItem
                key={index}
                data={{
                  ...item,
                  creatorId: item.user,
                  isFriend: this.isAlreadyFriend(item.user._id),
                }}
                handleRequestAddFriend={this.handleRequestAddFriend}
                eventId={this.props.match.params.id}
                unit={profile.unit}
                languageText={profile.language}
                showAddFriend={true}
                userId={userId}
              />
            ))
          : null}
        {showLoading && !isRefresh ? (
          <CircularProgress className="mt-3" />
        ) : null}
        {listSearchResults.length === 0 && !showLoading && (
          <div className="row">
            <div className="col-12">
              <div className="form-group mt-3">
                <InputLabel className="label-input">
                  {language(languageText, "no_results_found")}
                </InputLabel>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { searchReducer, authReducer } = state;
  return {
    ...searchReducer,
    profile: authReducer.profile,
    userId: authReducer.userID,
  };
}

export default connect(mapStateToProps)(Search);
