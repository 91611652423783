import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
//import logger from 'redux-logger'
import rootReducer from './reducers/rootReducer';

export default function configureStore(initialState={}) {
  const persistedStore = localStorage.getItem('store') ? JSON.parse(localStorage.getItem('store')): {}
  return createStore(
    rootReducer,
    persistedStore,
    applyMiddleware(thunk)
  );
}
