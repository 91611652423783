import { rankingConstant } from '../constants/rankingConstant';

const defaultState = {
    dataRankingAll:[],
    loadingRankingAll:false,
    dataRankingFriend:[],
    loadingRankingFriend:false,
    dataRankingMe:[],
    loadingRankingMe:false
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case rankingConstant.REFRESH_RANKING_ALL_REQUEST:
            return {...state,loadingRankingAll:true }
        case rankingConstant.REFRESH_RANKING_ALL_SUCCESS:
            return {...state, dataRankingAll: action.payload.data, loadingRankingAll:false }
        case rankingConstant.REFRESH_RANKING_ALL_FAILURE:
            return {...state, loadingRankingAll:false }
        case rankingConstant.REFRESH_RANKING_FRIENDS_REQUEST:
            return {...state, loadingRankingFriend:true }
        case rankingConstant.REFRESH_RANKING_FRIENDS_SUCCESS:
            return {...state, dataRankingFriend:action.payload.data, loadingRankingFriend:false }
        case rankingConstant.REFRESH_RANKING_FRIENDS_FAILURE:
            return {...state, loadingRankingFriend:false }
        case rankingConstant.REFRESH_RANKING_ME_REQUEST:
            return {...state, loadingRankingMe:true }
        case rankingConstant.REFRESH_RANKING_ME_SUCCESS:
            return {...state, dataRankingMe:action.payload.data, loadingRankingMe:false }
        case rankingConstant.REFRESH_RANKING_ME_FAILURE:
            return {...state, loadingRankingMe:false }
        default:
            return state
    }
}
  