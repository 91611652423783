import React, {Component} from 'react';
import {connect} from "react-redux";
import Container from '@material-ui/core/Container';
import { Button, Input, InputLabel } from '@material-ui/core';
import Swal from 'sweetalert2';
import { Link, Redirect } from 'react-router-dom';
import {validator} from "../../helpers";
import {authAction} from '../../actions/auth-action'
import FlagEn from "../../assets/flag-english.jpg";
import FlagGe from "../../assets/flag-Germany.jpg";
import language from '../../helpers/language';

class ResetPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            password: '',
            passwordConfirm: '',
            validator: {
                password: false,
                passwordConfirm: false,
                emptyPassword: false,
                emptyPasswordConfirm: false,
            },
            language: 'DE',
            showLoading: false,
            isSuccess: false
        }

        this.handleResetPassword = this.handleResetPassword.bind(this);
        this.inputPassword = this.inputPassword.bind(this);
        this.inputPasswordConfirm = this.inputPasswordConfirm.bind(this);
        this.handleBlurPassword = this.handleBlurPassword.bind(this);
        this.handleBlurPasswordConfirm = this.handleBlurPasswordConfirm.bind(this);
    }

    componentDidMount(){
        let pre_language = localStorage.getItem('pre_language');
        if (!pre_language || pre_language === '') pre_language = 'EN';
        localStorage.setItem('pre_language', pre_language)
        this.setState({language: pre_language === 'EN' ? 'DE' : 'EN'});
    }

    handleChangeLanguage = ()=>{
        let currentLanguage = this.state.language;
        this.setState({language:currentLanguage === 'EN' ? 'DE' : 'EN'});
        localStorage.setItem('pre_language', currentLanguage)
    }

    handleResetPassword = (e) => {
        const {password} = this.state;
        const id = this.props.match.params.id;
        let languagetext=this.state.language;
        let lbResetFailed = language(languagetext, "label_reset_password_failed");

        this.setState({
            isSuccess: true
        })
        let data ={
            id: id,
            password: password
        }
        this.props.dispatch(authAction.resetPassword(data, language==='EN'?'en':'de')).then(response => {
            if (response && response.msg) {
                let labelText = response.msg.replace(/_/g, ' ')
                Swal.fire({
                    text: labelText,
                    icon: 'info',
                    confirmButtonColor: '#e60003',
                    confirmButtonText: language(languagetext, 'ok')
                }).then(result => {
                    if (result.value) {
                        this.setState({isSuccess: true});
                        this.props.history.push('/login');
                      }
                })
            }
            else if (response.errors && response.errors.msg){
                let labelText = response.errors.msg.replace(/_/g, ' ')
                Swal.fire({
                    text: labelText,
                    icon: 'error',
                    confirmButtonColor: '#e60003',
                    confirmButtonText: language(languagetext, 'ok')
                })
            }
            else {
                Swal.fire({
                    text: lbResetFailed,
                    icon: 'error',
                    confirmButtonColor: '#e60003',
                    confirmButtonText: language(languagetext, 'ok')
                })
            }
        }).catch(err => {
            Swal.fire({
                text: lbResetFailed,
                icon: 'error',
                confirmButtonColor: '#e60003',
                confirmButtonText: language(languagetext, 'ok')
            })
        });
    }

    inputPassword(e) {
        let strValue = String(e.target.value);
        let vali = this.state.validator;
        vali.emptyPassword = false;
        this.setState({
            password: strValue.trim(),
            validator: vali
        })
        if (validator.checkPasswordValid(strValue)) {
            this.setState({validator: {...vali, password: false}});
        }
        else {
            this.setState({validator: {...vali, password: true}});
        }
    }

    inputPasswordConfirm(e) {
        let strValue = String(e.target.value);
        let vali = this.state.validator;
        vali.emptyPasswordConfirm = false;
        this.setState({
            passwordConfirm: strValue.trim(),
            validator: vali
        })
        if (this.state.password === strValue) {
            this.setState({validator: {...vali, passwordConfirm: false}});
        }
        else {
            this.setState({validator: {...vali, passwordConfirm: true}});
        }
    }

    handleBlurPassword = () => {
        let password = this.state.password;
        let vali = this.state.validator;
        if (password.trim() === '') {
            this.setState({validator: {...vali, emptyPassword: true, password: false}});
        }
    }

    handleBlurPasswordConfirm = () => {
        let passwordConfirm = this.state.passwordConfirm;
        let vali = this.state.validator;
        if (passwordConfirm.trim() === '') {
            this.setState({validator: {...vali, emptyPasswordConfirm: true, passwordConfirm: false}});
        }
    }

    render() {
        const { password, passwordConfirm, validator, isSuccess } = this.state;
        let languagetext=this.state.language;
        let lbPasswordChange = language(languagetext, "password_change");
        let lbNewPassword = language(languagetext, "your_new_password");
        let lbPasswordConfirmation = language(languagetext, "password_confirmation");
        let lbChange = language(languagetext, "change");
        let lbCancel = language(languagetext, "cancel");
        let lbValidatePassword = language(languagetext, "label_password_invalid");
        let lbValidatePasswordConfirmation = language(languagetext, "password_and_confirmation_does_not_match");
        let lbPasswordRequired = language(languagetext, "new_password_is_required");
        let lbPasswordConfirmationRequired = language(languagetext, "password_confirmation_is_required");

        let disabledButton = false;
        if(password === '' || passwordConfirm === '' || validator.password || validator.passwordConfirm) {
            disabledButton = true;
        }
        if (isSuccess) {
            return <Redirect to='/login'/>
        }
        return (
            <Container maxWidth="sm">
                <div className="container pt-4">
                {
                    this.state.showLoading &&
                    <div className="overlay-loading text-center"><span className="spinner-1 inline-spinner "/>
                    </div>
                }
                <div className="app-header-public">
                    <div onClick={this.handleChangeLanguage}>
                        <span className="switch-lang"><img alt="" src={language === 'EN' ? FlagGe : FlagEn} /></span>
                    </div>
                </div>
                <div className="col-12 text-center align-items-center px-0 mt-3 mb-3">
                    <h2 className="page-title mb-0 text-uppercase font-weight-600 fz-24 text-center">{lbPasswordChange}</h2>
                </div>
                <div className=" mt-3 mb-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <InputLabel className="label-input">{lbNewPassword} <span className="text-danger">(*)</span></InputLabel>
                                <Input
                                    type="password"
                                    id="password"
                                    className="form-control"
                                    onChange={this.inputPassword}
                                    onBlur={this.handleBlurPassword}
                                    value={this.state.password}
                                    placeholder={lbNewPassword}
                                />
                                {this.state.validator.emptyPassword ? <small className="d-block invalid-feedback">{lbPasswordRequired}</small> : ''}
                                {this.state.validator.password ? <small className="d-block invalid-feedback">{lbValidatePassword}</small> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <InputLabel className="label-input">{lbPasswordConfirmation} <span className="text-danger">(*)</span></InputLabel>
                                <Input
                                    type="password"
                                    id="passwordConfirm"
                                    className="form-control"
                                    onChange={this.inputPasswordConfirm}
                                    onBlur={this.handleBlurPasswordConfirm}
                                    value={this.state.passwordConfirm}
                                    placeholder={lbPasswordConfirmation}
                                />
                                {this.state.validator.emptyPasswordConfirm ? <small className="d-block invalid-feedback">{lbPasswordConfirmationRequired}</small> : ''}
                                {this.state.validator.passwordConfirm ? <small className="d-block invalid-feedback">{lbValidatePasswordConfirmation}</small> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className=" mr-3 text-center">
                                <Button type="button" id="btnReset" variant="contained" className="mr-3 btn-success" disabled={disabledButton} onClick={() => this.handleResetPassword()}>{lbChange}</Button>
                                {/* <Button type="button" variant="contained">{lbCancel}</Button> */}
                                <Link to="/login" className="btn btn-gray">
                                    {lbCancel}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
        )
    }
}

function mapStateToProps(state) {
    const {authReducer} = state;
    return {authReducer}
}

export default connect(mapStateToProps)(ResetPassword);
