import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

class PublicRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'DE',
        }
    }

    // handleChangeLanguage = ()=>{
    //     let currentLanguage = this.state.language;
    //     this.setState({language:currentLanguage === 'EN' ? 'DE' : 'EN'});
    // }

    render() {
        const { path, exact, authed, hasEvent, role, firstTimeLogin, token } = this.props;
        if (authed && token) {
            if (role === "admin") {
                return <Redirect to="/dashboard" />
            } else if (role === "user") {
                if (firstTimeLogin) {
                    return <Redirect to="/profile" />
                } else {
                    if (hasEvent) {
                        return <Redirect to='/progress' />
                    } else
                        return <Redirect to='/active-events' />
                }
            }
        }
        return (
            <div>
                {/* <div className="app-header-public">
                    <HeaderPublic language={this.state.language} handleChangeLanguage={this.handleChangeLanguage}/>
                </div> */}
                <div className="app-public-content" id="app-public-content">
                    <Route path={path} exact={exact} component={(props) => <this.props.component language={this.state.language} {...props} />} showLabels />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { ...state.authReducer }
}

export default connect(mapStateToProps)(PublicRoute);