import {communicateServices} from '../services';
import {commnunicateConstant} from '../constants/communicateConstant';

function getAllCommunicates(keySearch='', page=1, limit=50) {
  return dispatch => {
      dispatch(request())
      return communicateServices.getAllCommunicates(keySearch, page, limit)
          .then(response => {
              if(response && response.errors) {
                dispatch(failure(response.errors));
                return response;
              } else {
                dispatch(success(response))
                return response
              }
          })
      function request() { return { type: commnunicateConstant.GET_ALL_COMMUNICATE_REQUEST } }
      function success(data) {
          return {
              type: commnunicateConstant.GET_ALL_COMMUNICATE_SUCCESS,
              payload: { data: data }
            }
      }
      function failure(error) {
          return {type: commnunicateConstant.GET_ALL_COMMUNICATE_FAILURE, error}
      }
  }
}

function createCommunicate(data) {
  return dispatch => {
      dispatch(request())
      return communicateServices.createCommunicate(data)
          .then(response => {
              if(response && response.errors) {
                dispatch(failure(response.errors));
                return response;
              } else {
                dispatch(success(data))
                return response
              }
          })
      function request() { return { type: commnunicateConstant.CREATE_COMMUNICATE_REQUEST } }
      function success(data) {
          return {
              type: commnunicateConstant.CREATE_COMMUNICATE_SUCCESS,
              payload: { data: data }
            }
      }
      function failure(error) {
          return {type: commnunicateConstant.CREATE_COMMUNICATE_FAILURE, error}
      }
  }
}

function deleteCommunicate(id) {
    return dispatch => {
        dispatch(request())
        return communicateServices.deleteCommunicate(id)
            .then(response => {
                if(response && response.errors) {
                  dispatch(failure(response.errors));
                  return response;
                } else {
                  dispatch(success(id))
                  return response
                }
            })
        function request() { return { type: commnunicateConstant.DELETE_COMMUNICATE_REQUEST } }
        function success(id) {
            return {
                type: commnunicateConstant.DELETE_COMMUNICATE_SUCCESS,
                payload: { id: id }
              }
        }
        function failure(error) {
            return {type: commnunicateConstant.DELETE_COMMUNICATE_FAILURE, error}
        }
    }
  }
  
export const communicateAction = {
    getAllCommunicates,
    createCommunicate,
    deleteCommunicate
};
  