import { API } from ".";

export const searchServices = {
  getDataSearch,
  getRankingSearch,
};

function getDataSearch(keySearch, page, limit) {
  let url =
    "/users?filter=" +
    keySearch +
    "&fields=firstName,lastName,displayName,email&page=" +
    page +
    "&limit=" +
    limit +
    "&sort=displayName&order=-1";
  return API.get(url);
}

function getRankingSearch(event_id, keySearch, page, limit) {
  let url = `/activities/ranking/${event_id}/search?name=${keySearch}&page=${page}&limit=${limit}`;
  return API.get(url);
}
