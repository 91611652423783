import cookie from 'react-cookies';
import {userManagementConstant} from '../constants/userManagementConstant';

const defaultState = {
    authed: localStorage.getItem('auth_token')?true:false,
    token: '',
    userID:'',
    role: localStorage.getItem("user_role"),
    userInfo: {},
    profile:{
        events:[],
        friends:[],
        waitingAcceptFriends:[],
        sendingRequestFriends:[]
    },
    listUsers: [],
    hasEvent: false,
    loading:false,
    loadingEvent:false,
    loadingActivity:false,
    loadingFriendItem:false,
    sendingRegister: false,
    sendingUsers: false,
    sendingUpdate: false,
    sendingDelete: false,
    success: false,
    error: '',
    online:true,
    firstTimeLogin: false
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'AUTH':
            return {...state, loading:true }
        case 'AUTH_SUCCESS':
            // localStorage.setItem('auth_token',action.payload.token)
            // localStorage.setItem('user_ID',action.user._id)
            return {...state, 
                authed:true, 
                loading:false, 
                userInfo: action.user, 
                hasEvent: action.user && action.user.events && action.user.events.length > 0,
                firstTimeLogin: action.user.firstTimeLogin,
                token: action.payload.token, role: action.user.role,
                userID: action.user._id
            }
        case 'AUTH_FAIL':
            //Swal.fire('ERROR',action.payload.error,'error')
            return {...state, authed:false, loading:false }
        case 'LOGOUT_REQUEST':
            return {...state, loading:true }
        case 'LOGOUT_SUCCESS':
            // localStorage.removeItem('auth_token')
            // localStorage.removeItem('user_ID')
            return {...state, authed:false, loading:false, token: null }
        case 'LOGOUT_FAIL':
            //Swal.fire('ERROR',action.payload.error,'error')
            return {...state, authed:true, loading:false }

        case userManagementConstant.RESET_PASSWORD_REQUEST: 
            return {...state, error: '', loading:true }
        case userManagementConstant.RESET_PASSWORD_SUCCESS: 
            return {...state, success: true, loading:false }
        case userManagementConstant.RESET_PASSWORD_FAILURE:
            return {...state, success: false, error: action.error, loading:false }
       
        case userManagementConstant.REGISTER_REQUEST: 
            return {...state, sendingRegister: true, error: '', loading:true }
        case userManagementConstant.REGISTER_SUCCESS: 
            return {...state, sendingRegister: false, success: true, auth: true, loading:false }
        case userManagementConstant.REGISTER_FAILURE:
            return {...state, sendingRegister: false, success: false, error: action.error, loading:false }
       
        case userManagementConstant.UPDATE_USER_REQUEST:
            return {...state, sendingUpdate: true, error: ''}                
        case userManagementConstant.UPDATE_USER_SUCCESS:
            return {...state, sendingUpdate: false}        
        case userManagementConstant.UPDATE_USER_FAILURE:
            return {...state, sendingUpdate: false, error: action.error}

        case userManagementConstant.GET_PROFILE_REQUEST:
            return {...state, sendingUpdate: true, error: '', loading:true}                
        case userManagementConstant.GET_PROFILE_SUCCESS:{
            let events = [...state.profile.events]
            let friends = [...state.profile.friends]
            let waitingAcceptFriends = [...state.profile.waitingAcceptFriends]
            let sendingRequestFriends = [...state.profile.sendingRequestFriends]
            return {...state, sendingUpdate: false, 
                profile: {...state.profile,...action.user,events,friends,waitingAcceptFriends,sendingRequestFriends}, 
                loading:false
            }   
        }     
        case userManagementConstant.GET_PROFILE_FAILURE:
            return {...state, sendingUpdate: false, error: action.error, loading:false}

        case userManagementConstant.GET_ALL_USERS_REQUEST:
            return {...state, sendingUpdate: true, error: ''}                
        case userManagementConstant.GET_ALL_USERS_SUCCESS:
            return {...state, sendingUpdate: false, listUsers: action.data}        
        case userManagementConstant.GET_ALL_USERS_FAILURE:
            return {...state, sendingUpdate: false, error: action.error}
    
        case userManagementConstant.GET_USER_DETAIL_REQUEST:
            return {...state, sendingUpdate: true, error: ''}                
        case userManagementConstant.GET_USER_DETAIL_SUCCESS:
            return {...state, sendingUpdate: false, userInfo: action.data}        
        case userManagementConstant.GET_USER_DETAIL_FAILURE:
            return {...state, sendingUpdate: false, error: action.error}
    
        case userManagementConstant.UPDATE_USER_DETAIL_REQUEST:
            return {...state, sendingUpdate: true, error: ''}                
        case userManagementConstant.UPDATE_USER_DETAIL_SUCCESS:
            return {...state, sendingUpdate: false}        
        case userManagementConstant.UPDATE_USER_DETAIL_FAILURE:
            return {...state, sendingUpdate: false, error: action.error}

        case userManagementConstant.DELETE_USER_REQUEST:
            return {...state, sendingUpdate: true, error: ''}                
        case userManagementConstant.DELETE_USER_SUCCESS:
            return {...state, sendingUpdate: false}        
        case userManagementConstant.DELETE_USER_FAILURE:
            return {...state, sendingUpdate: false, error: action.error}
        case userManagementConstant.DELETE_USER_FRIENDSHIP_ITEM:
            return {...state, profile:{
                ...state.profile,
                waitingAcceptFriends: state.profile.waitingAcceptFriends.filter((el)=>el._id!==action.payload.id),
                sendingRequestFriends: state.profile.sendingRequestFriends.filter((el)=>el._id!==action.payload.id),
                friends: state.profile.friends.filter((el)=>el._id!==action.payload.id)
            }}
        case 'CHANGE_ONLINE_STATUS':
            return {...state, online:action.data}
        case 'TEAM_REGISTERED':
            return {...state, profile:{...state.profile,teamId:action.payload.data }}
        case 'LEAVE_TEAM_SUCCESS':
            return {...state, profile:{...state.profile,teamId:'' }}
        case 'GET_PROFILE_EVENT':
            return {...state,loadingEvent:true}
        case 'GET_PROFILE_EVENT_SUCCESS':
            return {...state,loadingEvent:false,profile:{...state.profile,events:action.payload}}
        case 'GET_PROFILE_FAIL':
            return {...state,loadingEvent:false}
        case 'GET_PROFILE_ACTIVITIES':
            return {...state,loadingActivity:true}
        case 'GET_PROFILE_ACTIVITIES_SUCCESS':
            return {...state,loadingActivity:false,profile:{...state.profile,myActivities:action.payload}}
        case 'GET_PROFILE_ACTIVITIES_FAIL':
            return {...state,loadingActivity:false}
        case 'GET_PROFILE_FRIEND_ITEM':
            return {...state,loadingFriendItem:true}
        case 'GET_PROFILE_FRIEND_ITEM_SUCCESS':
            return {...state,loadingFriendItem:false,
                profile:{
                    ...state.profile,
                    friends:action.payload[0],
                    waitingAcceptFriends:action.payload[1],
                    sendingRequestFriends:action.payload[2]
                }
            }
        case 'GET_PROFILE_FRIEND_ITEM_FAIL':
            return {...state,loadingFriendItem:false}
        default:
            return state
    }
  }
  