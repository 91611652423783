import React, { Component } from 'react';
import { connect } from "react-redux";
import { notificationAction } from '../../actions/notification-action';


class NotificationDetail extends Component {

    componentDidMount(){
        if(this.props.location.search){
            this.props.loadNotification()
        }
    }

    rawHTML = (data, className = "") =>
        <div className={className} dangerouslySetInnerHTML={{ __html: data.replace(/\n/g, '<br />') }} />

    render() {
        let data = this.props.notification?this.props.notification:this.props.history.location.data
        console.log('notification_detail', data)
        return (
            <div className="container text-center pt-4">
                 {data?<div className="">
                        <h5 className="text-left header-title">{data.message}</h5>
                        {this.rawHTML(data.description,"text-left")}
                    </div>:null}
            </div>
        )
    }
}

function mapStateToProps(state,props) {
    let notification = null
    try{
        let notifications = state.notificationReducer.notifications.docs
        notification = notifications.find((el)=>props.location.search === '?id='+el._id)
    }catch(err){}
    return {
        notification:notification
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadNotification: () => dispatch(notificationAction.getAllNotification())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(NotificationDetail);