import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import {Button, Input, InputLabel} from '@material-ui/core';
import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';
import Swal from 'sweetalert2';
import ReactModal from "react-modal";
import Select from 'react-select';
import countryList from 'react-select-country-list';
import {validator} from "../../helpers";
import {connect} from "react-redux";
import {authAction} from '../../actions/auth-action';
import FlagEn from "../../assets/flag-english.jpg";
import FlagGe from "../../assets/flag-Germany.jpg";
import language from '../../helpers/language';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

class Register extends Component {
    constructor(props) {
        super(props)

        this.optionsCountries = countryList().setLabel("TW", "Taiwan").data

        this.state = {
            passWord: '',
            passWordConfirm: '',
            givenName: '',
            lastName: '',
            type: '',
            email: '',
            isTerm: false,
            showTermsAndConditions: false,
            optionsCountries: this.optionsCountries,
            countrySelected: {value: 'DE', label: 'Germany'},
            validator: {
                passWord: false,
                passWordConfirm: false,
                givenName: false,
                lastName: false,
                email: false,
                country: false,
                isExist: false,
                emptyEmail: false,
                emptyGivenName: false,
                emptyLastName: false,
                emptyPassword: false,
                emptyPasswordConfirm: false
            },
            showAlert: false,
            alertTitle: '',
            alertText: '',
            showConfirmLogin: false,
            language: 'DE',
            goLogin: false,
            visibleCPassword:false,
            visiblePassword:false,
            visibleButton:true
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeCountry = this.handleChangeCountry.bind(this);
        this.handleChangeTerm = this.handleChangeTerm.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.goLoginPage = this.goLoginPage.bind(this);
        this.clearData = this.clearData.bind(this);
        this.inputGivenName = this.inputGivenName.bind(this);
        this.inputLastName = this.inputLastName.bind(this);
        this.inputPassword = this.inputPassword.bind(this);
        this.inputPasswordConfirm = this.inputPasswordConfirm.bind(this);
        this.inputEmail = this.inputEmail.bind(this);
        this.handleBlurEmail = this.handleBlurEmail.bind(this);
        this.handleBlurGivenName = this.handleBlurGivenName.bind(this);
        this.handleBlurLastName = this.handleBlurLastName.bind(this);
        this.handleBlurPassword = this.handleBlurPassword.bind(this);
        this.handleBlurPasswordConfirm = this.handleBlurPasswordConfirm.bind(this);
    }

    componentDidMount() {
        let pre_language = localStorage.getItem('pre_language');
        if (!pre_language || pre_language === '') pre_language = 'EN';
        localStorage.setItem('pre_language', pre_language)
        this.setState({language: pre_language === 'EN' ? 'DE' : 'EN'});
        let el = document.getElementById('app-public-content')
        this.updateButtonStatus()
        el.addEventListener('scroll',this.updateButtonStatus)
    }

    componentWillUnmount(){
        let el = document.getElementById('app-public-content')
        el.removeEventListener('scroll',this.updateButtonStatus)
    }

    updateButtonStatus = ()=>{
        let el = document.getElementById('app-public-content')
        if(el.scrollTop> el.scrollHeight - el.offsetHeight-70 )
            this.setState({ visibleButton: true })
        else
            this.setState({ visibleButton: false })
    }

    handleChangeLanguage = () => {
        let currentLanguage = this.state.language;
        this.setState({language: currentLanguage === 'EN' ? 'DE' : 'EN'});
        localStorage.setItem('pre_language', currentLanguage)
    }

    handleSubmit = (e) => {
        e.preventDefault();
        var temp = this;
        const {passWord, givenName, lastName, email, countrySelected, type} = temp.state;
        let languagetext=this.state.language;

        let vali = {
            isExist: false,
            passWord: false,
            givenName: false,
            lastName: false,
            email: false,
            country: false
        }
        temp.setState({validator: vali});

        const data = {
            email: email.trim(),
            password: passWord.trim(),
            firstName: givenName.trim(),
            lastName: lastName.trim(),
            userType: type,
            country: countrySelected.value
        }
        let lbReadyRegistered = language(languagetext, "label_ready_registered");
        let lbCompleteRegister = language(languagetext, "label_complete_register");
        let lbRegistrationFailed = language(languagetext, "label_registration_failed");
        temp.props.dispatch(authAction.register(data, languagetext === 'EN' ? 'en' : 'de')).then(response => {
            if (response && response.errors && response.errors.msg && response.errors.msg === 'EMAIL_ALREADY_EXISTS') {
                Swal.fire({
                    title: '',
                    text: lbReadyRegistered,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#e60003',
                    confirmButtonText: language(languagetext, 'ok'),
                    cancelButtonColor: '#707070',
                    cancelButtonText: language(languagetext, 'cancel')
                }).then((result) => {
                    if (result.value) {
                        temp.setState({
                            goLogin: true
                        })
                    }
                })
            } else {
                if (response && response.user) {
                    Swal.fire({
                        title: '',
                        text: lbCompleteRegister,
                        icon: 'success',
                        confirmButtonColor: '#e60003',
                        confirmButtonText: language(languagetext, 'ok'),
                    }).then(result => {
                        window.location.href = '#/login'
                        setTimeout(() => {
                            temp.setState({goLogin: true})
                        }, 500);

                    })
                } else {
                    Swal.fire({
                        title: '',
                        text: lbRegistrationFailed,
                        icon: 'error',
                        confirmButtonColor: '#e60003',
                        confirmButtonText: language(languagetext, 'ok')
                    })
                }
            }
        }).catch(err => {
            Swal.fire({
                title: '',
                text: lbRegistrationFailed,
                icon: 'error',
                confirmButtonColor: '#e60003',
                confirmButtonText: language(languagetext, 'ok'),
            })
        });
    }

    clearData = () => {
        let vali = {
            passWord: false,
            fullName: false,
            email: false,
            country: false,
            isExist: false
        }
        this.setState({
            passWord: '',
            passWordConfirm: '',
            fullName: '',
            email: '',
            countrySelected: {value: 'DE', label: 'Germany'},
            validator: vali
        })
    }

    handleBlurEmail = () => {
        let email = this.state.email;
        let vali = this.state.validator;
        if (email.trim() === '') {
            this.setState({validator: {...vali, emptyEmail: true, email: false}});
        }
    }

    handleBlurGivenName = () => {
        let givenName = this.state.givenName;
        let vali = this.state.validator;
        if (givenName.trim() === '') {
            this.setState({validator: {...vali, emptyGivenName: true}});
        }
    }

    handleBlurLastName = () => {
        let lastName = this.state.lastName;
        let vali = this.state.validator;
        if (lastName.trim() === '') {
            this.setState({validator: {...vali, emptyLastName: true}});
        }
    }

    handleBlurPassword = () => {
        let passWord = this.state.passWord;
        let vali = this.state.validator;
        if (passWord.trim() === '') {
            this.setState({validator: {...vali, emptyPassword: true, passWord: false}});
        }
    }

    handleBlurPasswordConfirm = () => {
        let passWordConfirm = this.state.passWordConfirm;
        let vali = this.state.validator;
        if (passWordConfirm.trim() === '') {
            this.setState({validator: {...vali, emptyPasswordConfirm: true, passWordConfirm: false}});
        }
    }

    handleChangeCountry = value => {
        this.setState({countrySelected: value})
    }

    handleChangeTerm = () => {
        if (this.state.isTerm) {
            this.setState({isTerm: false})
        } else {
            this.setState({showTermsAndConditions: true})
        }
    }

    handleChangeType = (type) => {
        this.setState({type: type})
    }

    goLoginPage = () => {
        this.setState({showConfirmLogin: false}, function () {
            this.setState({goLogin: true})
        })
    }

    inputPassword(e) {
        let strValue = String(e.target.value);
        let vali = this.state.validator;
        vali.emptyPassword = false;
        this.setState({
            passWord: strValue.trim(),
            validator: vali
        })
        if (validator.checkPasswordValid(strValue)) {
            this.setState({validator: {...vali, passWord: false}});
        } else {
            this.setState({validator: {...vali, passWord: true}});
        }
        if (this.state.passWordConfirm && strValue !== this.state.passWordConfirm) {
            this.setState({validator: {...vali, passWordConfirm: true}});
        }
    }

    inputPasswordConfirm(e) {
        let strValue = String(e.target.value);
        let vali = this.state.validator;
        vali.emptyPasswordConfirm = false;
        this.setState({
            passWordConfirm: strValue.trim(),
            validator: vali
        })
        if (this.state.passWord === strValue) {
            this.setState({validator: {...vali, passWordConfirm: false}});
        } else {
            this.setState({validator: {...vali, passWordConfirm: true}});
        }
    }

    inputEmail(e) {
        let strValue = String(e.target.value);
        let vali = this.state.validator;
        vali.emptyEmail = false;
        this.setState({ email: strValue, validator: {...vali, email: false}});
    }

    inputGivenName(e) {
        let strValue = String(e.target.value);
        let vali = this.state.validator;
        this.setState({
            givenName: strValue,
            validator: {...vali, emptyGivenName: false}
        })
    }

    inputLastName(e) {
        let strValue = String(e.target.value);
        let vali = this.state.validator;
        this.setState({
            lastName: strValue,
            validator: {...vali, emptyLastName: false}
        })
    }

    renderEn(){
        return(
            <div>
                <p><b>Terms and Conditions of Use for 'ZwickRoell runs the world'</b></p><br/><br/>
                <p>Welcome to ‘ZwickRoell runs the world’. We are delighted to be able to join with you in undertaking a running challenge for charity. These Terms and Conditions of Use constitute the rules of play – they aim to create a positive, law-abiding community for you. By using ‘ZwickRoell runs the world’, you agree to the following Terms and Conditions of Use.</p>
                <p>ZwickRoell GmbH & Co. KG (referred to as “ZwickRoell”, “we” or “us” for short) provides the site run.zwickroell.com as a platform for carrying out a running challenge (hereinafter referred to as “Service”).</p>
                <p><b>Use of Service and your account</b></p>
                <p>Who can use ‘ZwickRoell runs the world’?</p>
                <p>You must be at least 18 years old to use ‘ZwickRoell runs the world’.</p>
                <p>Your account</p>
                <p>To be able to participate in the running challenge, you must set up a ‘ZwickRoell runs the world’ account (run.zwickroell.com) and it is important that the information associated with your account is/remains correct and up to date.</p>
                <p>You are responsible for ensuring that all activities that take place while you are using your account remain confidential and you must immediately report any actual or suspected case of loss, theft or unauthorized use of your account or your account password to our support team. We are not responsible for any damage that occurs due to unauthorized use of your user name or password.</p>
                <p>If you are resident in the European Union: You have the right to delete your account by contacting the support team. If you decide to permanently delete your account, the non-public, personal data associated with your account will also be deleted.</p><br/><br/>

                <p><b>1. Scope of validity</b></p><br/><br/>
                <p>The following Terms and Conditions of Use contain the basic rules for using the ‘ZwickRoell runs the world’ Internet platform (hereinafter called “run.zwickroell.com”) of ZwickRoell GmbH & Co. KG Ulm, hereinafter “ZwickRoell”.</p><br/><br/>
                <p><b>2. Object of the usage agreement and definitions</b></p><br/><br/>
                <p>2.1. ZwickRoell operates the Internet platform run.zwickroell.com. This platform is an editorial portal on which the User can participate after he or she has registered/created a user account with his/her sports results/data.</p>
                <p>2.2. “User” within the meaning of these Terms and Conditions of Use refers to each person who uses the Internet platform run.zwickroell.com.</p><br/><br/>
                <p><b>3. Obligations of the User</b></p><br/><br/>
                <p>3.1. The User undertakes not to upload any illegal or illegally created content and/or to distribute such content via run.zwickroell.com and/or connected commenting tools. The following content is especially impermissible:</p>
                <ul>
                    <li>Content that may violate the rights of others, such as copyrights and trademark rights or ancillary copyrights, the right to protection of privacy or other personal rights, especially the right to one’s own image, and/or rights that may otherwise result in legal proceedings,</li>
                    <li>Content that contains false allegations, is defamatory or libelous,</li>
                    <li>Content that expresses pornographic, obscene or degrading themes,</li>
                    <li>Content that is not covered by the usage rights of the individual Users.</li>
                </ul>
                <p>3.2. The following obligations are incumbent on the User:</p>
                <ul>
                    <li>Before uploading content, the User must ensure that publication thereof is legally permissible and that all of the necessary usage rights for this purpose are in place.</li>
                    <li>The User must inform ZwickRoell immediately if he/she notices that he/she has uploaded illegal content to run.zwickroell.com or has disseminated such content via run.zwickroell.com.</li>
                    <li>The User must refrain from:<br/>
                        - Taking or promoting anti-competitive actions such as accelerated canvassing using chain, snowball or pyramid schemes,<br/>
                        - Posting content that pursues commercial interests,<br/>
                        - Taking other damaging actions against other Users or employees of ZwickRoell,<br/>
                        - Gaining unauthorized access to the data of third parties,<br/>
                        - Using run.zwickroell.com in a way that may lead to an overloading or disruption to the systems of ZwickRoell.<br/>
                    </li>
                </ul>
                <p>3.3. Should the User breach the aforementioned obligations, ZwickRoell can and will respond in an appropriate manner to prevent this from happening. ZwickRoell is, in particular, entitled to edit, block or delete infringing content and, in exceptional cases, to partially or fully exclude Users from using run.zwickroell.com.</p><br/><br/>
                <p><b>4. Usage rights</b></p><br/><br/>
                <p>4.1. All rights to content published on run.zwickroell.com (text, data, images, videos, databases) are reserved. Specifically, published content may not, without the consent of ZwickRoell, be reproduced, modified, disseminated, made publicly accessible, sent or utilized in another way (in whole or in part in any form), whether this be for private or commercial purposes.</p>
                <p>4.2. The User grants ZwickRoell an exclusive, transferable, spatially and temporally unrestricted usage right to the content posted and/or used by the User, insofar as this is required in the necessary scope to operate run.zwickroell.com. The aforementioned rights are granted by uploading the respective content to run.zwickroell.com, even if this is done using a third-party commenting tool. If the relevant content is deleted, usage rights to it end; otherwise the usage rights are for an indefinite period.</p>
                <p>4.3. This usage right includes, in particular, the following rights in relation to the content uploaded by the User:</p>
                <ul>
                    <li>The right to post this content in run.zwickroell.com, to carry out the reproductions necessary for this and to edit the content where necessary, </li>
                    <li>The right to make the content publicly accessible or to transmit it and especially the right to make the content available such that it can be called by any third party via the Internet,</li>
                    <li>The right to enable third parties to download the content to fixed or mobile end devices and to use it there in accordance with its intended use, and to grant third parties the required usage rights for this,</li>
                    <li>The right, where necessary, to transfer the rights granted by the User in these Terms and Conditions of Use to run.zwickroell.com with ZwickRoell as affiliated undertaking within the meaning of section 15 of the German Stock Corporation Act (AktG).</li>
                </ul>
                <p>4.4. In the event that third parties – irrespective of the legal grounds – assert claims on account of the usage of the content uploaded by the User and these claims are based on culpable conduct by the User, the User shall indemnify ZwickRoell from all resulting claims.</p>
                <p>4.5. Furthermore, ZwickRoell reserves the right to open run.zwickroell.com using a corresponding programming interface (“API”), thus also enabling use of run.zwickroell.com by or within the framework of Internet services of third parties, including, in certain circumstances, access to the works made available by the User. In this case, the User now already grants ZwickRoell the transferable, simple, spatially and temporally unrestricted right to grant the relevant third-party access and the subsequent public accessibility or transmission of the content uploaded by the User.</p><br/><br/>
                <p><b>5. Liability of ZwickRoell</b></p><br/><br/>
                <p>5.1. In the event of intentional or grossly negligent breaches of duty as well as in the case of culpable injury to life and limb or to health, ZwickRoell shall bear unlimited liability for all damage resulting therefrom, unless stipulated otherwise below or by law.</p>
                <p>5.2. In the event of slight negligence, ZwickRoell shall be liable for property and pecuniary damage only if essential contractual obligations are breached. Essential contractual obligations are those obligations whose fulfillment is required for proper fulfillment of the contract in the first place, breach of which jeopardizes the achievement of the purpose of the contract and on compliance with which the contracting party relies or can reasonably expect to be able to rely. Liability is limited to foreseeable damage typical of the contract. This also applies to lost profits and savings that have failed to materialize.</p>
                <p>5.3. Further liability of ZwickRoell to compensate for damages is excluded – regardless of the legal nature of the claim being asserted. This applies in particular to tortious acts according to sections 823 and 831 of the German Civil Code (BGB).</p>
                <p>5.4. Any unrestricted liability of ZwickRoell under the provisions of the German Product Liability Law (ProdHaftG) or due to fraudulent intent, absence of an assured characteristic or due to the provision of a warranty, remains unaffected by the aforementioned limitations of liability.</p><br/><br/>
                <p><b>6. Data privacy</b></p><br/><br/>
                <p>ZwickRoell places great emphasis on the protection and security of the User’s personal data. Further information on this can be found in the Data Privacy Statement.</p><br/><br/>
                <p><b>7. Final provisions</b></p><br/><br/>
                <p>7.1. Applicable law: These Terms and Conditions of Use are governed by German law, excluding the United Nations Convention on Contracts for the International Sale of Goods (CISG) as well as German and European conflict of laws provisions, whereby mandatory consumer protection regulations always have priority over choice of law.</p>
                <p>7.2. Jurisdiction: For traders (within the meaning of the German Commercial Code (HGB)), foundations or public corporations, Berlin is the sole jurisdiction for all disputes that arise from or due to the use of offers from ZwickRoell or from or due to these Terms and Conditions of Use.</p><br/><br/>
                <p>As of: July 2020</p>
            </div>
        )
    }

    renderDe(){
        return(
            <div>
                <p><b>Nutzungsbedingungen ZwickRoell runs the world</b></p><br/><br/>
                <p>Herzlich Willkommen bei ZwickRoell runs the world. Wir freuen uns, gemeinsam mit Ihnen eine Lauf-Challenge für den guten Zweck durchzuführen. Diese Nutzungsbedingungen stellen die Spielregeln dar – sie dienen dazu, eine positive, gesetzestreue Community für Sie zu schaffen. Indem Sie ZwickRoell runs the world benutzen, stimmen Sie allen nachfolgenden Bedingungen zu.</p>
                <p>Die ZwickRoell GmbH & Co. KG (zusammenfassend als „ZwickRoell“, „wir“ oder „uns" bezeichnet), bietet die Seite run.zwickroell.com als Plattform für die Durchführung einer Lauf-Challenge an (nachfolgend Dienstleistung genannt)</p>
                <p><b>Nutzung der Dienstleistungen und Ihres Kontos</b></p>
                <p>Wer kann die ZwickRoell runs the world nutzen?</p>
                <p>Sie müssen mindestens 18 Jahre alt sein, um ZwickRoell runs the world nutzen zu können. </p>
                <p>Ihr Konto</p>
                <p>Sie müssen ein ZwickRoell runs the world-Konto einrichten (run.zwickroell.com), um an der Lauf-Challenge teilnehmen zu können, und es ist wichtig, dass die mit Ihrem Konto verbundenen Informationen richtig und aktuell sind/bleiben.</p>
                <p>Sie sind dafür verantwortlich, dass alle Aktivitäten, die stattfinden, während Sie Ihr Konto nutzen, vertraulich bleiben, und Sie müssen unserem Support-Team umgehend jeden tatsächlich eingetretenen oder vermuteten Fall von Verlust, Diebstahl oder unbefugter Nutzung Ihres Kontos oder Ihres Kontopassworts melden. Wir sind nicht für etwaige Schäden verantwortlich, die durch eine unbefugte Nutzung Ihres Nutzernamens oder Passworts entstehen.</p>
                <p>Bei Wohnsitz in der Europäischen Union: Sie haben das Recht, Ihr Konto bei uns durch Kontaktaufnahme mit dem Support Team zu löschen. Wenn Sie sich für eine dauerhafte Löschung Ihres Kontos entscheiden, werden die nicht-öffentlichen persönlichen Daten, die mit Ihrem Konto verbunden sind, ebenfalls gelöscht.</p><br/><br/>

                <p><b>1. Geltungsbereich</b></p><br/><br/>
                <p>Die nachfolgenden Nutzungsbedingungen enthalten die grundlegenden Regeln für die Nutzung der Internetplattform „ZwickRoell runs the world“(im Folgenden run.zwickroell.com genannt) der ZwickRoell GmbH & C0. KG Ulm, nachfolgend „ZwickRoell“.</p><br/><br/>
                <p><b>2. Nutzungsgegenstand und Begriffsbestimmungen</b></p><br/><br/>
                <p>2.1. ZwickRoell betreibt die Internetplattform run.zwickroell.com Diese Plattform ist ein redaktionelles Portal, auf dem sich der Nutzer nach Registrierung/Anlegen eines Benutzerkontos mit seinen sportlichen Ergebnissen/Daten einbringen kann.</p>
                <p>2.2. „Nutzer“ im Sinne dieser Nutzungsbedingungen ist jede Person, die die Internetplattform run.zwickroell.com nutzt.</p><br/><br/>
                <p><b>3. Pflichten des Nutzers</b></p><br/><br/>
                <p>3.1. Der Nutzer verpflichtet sich, keine rechtswidrigen oder rechtswidrig erstellten Inhalte hochzuladen und/oder über run.zwickroell.com und / oder angebundene Kommentartools zu verbreiten. Unzulässig sind insbesondere Inhalte,</p>
                <ul>
                    <li>die Rechte anderer verletzen können, wie z.B. Urheber- und Marken- oder Leistungsschutzrechte, das Recht auf Schutz der Privatsphäre oder sonstige Persönlichkeitsrechte, insbesondere das Recht am eigenen Bild, und/oder die anderweitig rechtlich geahndet werden können</li>
                    <li>die unwahre Tatsachenbehauptungen beinhalten, ehrverletzend sind oder einen verleumderischen Inhalt haben</li>
                    <li>die pornographische, obszöne oder menschenverachtende Motive ausdrücken</li>
                    <li>die nicht von den Nutzungsrechten der einzelnen Nutzer abgedeckt sind.</li>
                </ul>
                <p>3.2. Der Nutzer ist verpflichtet,</p>
                <ul>
                    <li>sich vor dem Hochladen von Inhalten zu versichern, dass deren Veröffentlichung rechtlich zulässig ist und alle erforderlichen Nutzungsrechte für diesen Zweck vorliegen,</li>
                    <li>ZwickRoell unverzüglich zu informieren, wenn er feststellt, dass er rechtswidrige Inhalte in run.zwickroell.com hochgeladen oder über run.zwickroell.com verbreitet hat,</li>
                    <li>es zu unterlassen,<br/>
                        - wettbewerbswidrige Handlungen vorzunehmen oder zu fördern, wie etwa progressive Kundenwerbung mittels Ketten-, Schneeball- oder Pyramidensystemen<br/>
                        - Inhalte einzustellen, die kommerzielle Interessen verfolgen.<br/>
                        - sonstige belästigende Handlungen gegenüber anderen Nutzern oder Mitarbeitern von ZwickRoell über vorzunehmen, <br/>
                        - unberechtigten Zugriff auf Daten Dritter zu erlangen,<br/>
                        - run.zwickroell.com in einer Weise zu verwenden, die zu einer Überlastung oder Störungen der Systeme von ZwickRoell führen kann.<br/>
                    </li>
                </ul>
                <p>3.3. Sollte der Nutzer gegen vorgenannte Pflichten verstoßen, kann und wird ZwickRoell angemessen darauf reagieren, um dieses Vorgehen zu unterbinden. ZwickRoell ist insbesondere berechtigt, rechtsverletzende Inhalte zu bearbeiten, zu sperren oder zu löschen und in Ausnahmefällen Nutzer ganz oder teilweise von der Nutzung von run.zwickroell.com auszuschließen.</p><br/><br/>
                <p><b>4. Nutzungsrechte</b></p><br/><br/>
                <p>4.1. Sämtliche Rechte an auf run.zwickroell.com veröffentlichten Inhalten (Text, Daten, Bilder, Videos, Datenbanken) bleiben vorbehalten. Insbesondere ist es nicht gestattet, Inhalte ohne Zustimmung von ZwickRoell nach Veröffentlichung ganz oder in Teilen in irgendeiner Form zu vervielfältigen, zu ändern, zu verbreiten, öffentlich zugänglich zu machen, zu senden oder auf andere Art zu nutzen, sei es für private oder gewerbliche Zwecke.</p>
                <p>4.2. Der Nutzer räumt ZwickRoell ein ausschließliches, übertragbares,  räumlich und zeitlich unbeschränktes Nutzungsrecht an den von ihm eingestellten und/oder verwendeten Inhalten ein, soweit diese in dem zum Betrieb von run.zwickroell.com erforderlichen Umfang benötigt werden. Die Einräumung vorgenannter Rechte erfolgt durch das Hochladen des jeweiligen Inhalts auf run.zwickroell.com, auch wenn dies über ein Kommentartool von Dritten erfolgt. Wird der betreffende Inhalt gelöscht, enden die Nutzungsrechte daran, ansonsten sind sie zeitlich unbegrenzt.</p>
                <p>4.3. Dieses Nutzungsrecht umfasst insbesondere, die vom Nutzer hochgeladenen Inhalte</p>
                <ul>
                    <li>in run.zwickroell.com einzustellen, die hierfür erforderlichen Vervielfältigungen vorzunehmen und soweit erforderlich zu bearbeiten, </li>
                    <li>öffentlich zugänglich zu machen oder zu übermitteln und insbesondere die Inhalte zum Abruf durch beliebige Dritte über das Internet bereitzuhalten,</li>
                    <li>Dritten den Download der Inhalte auf feste oder mobile Endgeräte zu gestatten und dort bestimmungsgemäß zu nutzen, und den Dritten die dafür erforderlichen Nutzungsrechte einzuräumen,</li>
                    <li>bei Bedarf die vom Nutzer in diesen Nutzungsbedingungen eingeräumten Rechte auf run.zwickroell.com mit ZwickRoell im Sinne des § 15 AktG verbundene Unternehmen zu übertragen.</li>
                </ul>
                <p>4.4. Für den Fall, dass Dritte – unabhängig vom Rechtsgrund – Ansprüche wegen der Nutzung der vom Nutzer hochgeladenen Inhalte geltend machen und diese Ansprüche auf einem schuldhaften Verhalten des Nutzers beruhen, stellt der Nutzer ZwickRoell von allen sich daraus ergebenden Ansprüchen frei.</p>
                <p>4.5. ZwickRoell behält sich darüber hinaus vor, run.zwickroell.com mittels einer entsprechenden Programmierschnittstelle („API“) zu öffnen, so dass eine Nutzung von run.zwickroell.com auch von bzw. im Rahmen von Internetdiensten Dritter ermöglicht wird, unter Umständen einschließlich des Zugriffs auf die vom Nutzer zur Verfügung gestellten Werke. In diesem Fall räumt der Nutzer ZwickRoell bereits jetzt das übertragbare einfache, räumlich und zeitlich unbeschränkte Recht ein, den betreffenden Dritten den Zugriff und die etwa nachfolgende öffentliche Zugänglichmachung oder Sendung der vom Nutzer hochgeladenen Inhalte zu gestatten.</p><br/><br/>
                <p><b>5. Haftung von ZwickRoell</b></p><br/><br/>
                <p>5.1. Bei vorsätzlichen oder grob fahrlässigen Pflichtverletzungen sowie im Falle der schuldhaften Verletzung des Lebens, des Körpers oder der Gesundheit haftet ZwickRoell für alle darauf zurückzuführenden Schäden uneingeschränkt, soweit nachfolgend bzw. gesetzlich nichts anderes bestimmt ist.</p>
                <p>5.2. Bei leichter Fahrlässigkeit haftet ZwickRoell für Sach- und Vermögensschäden nur bei Verletzung wesentlicher Vertragspflichten. Wesentliche Vertragspflichten sind solche Pflichten, deren Erfüllung die ordnungsgemäße Vertragsdurchführung überhaupt erst ermöglichen, deren Verletzung die Erreichung des Vertragszwecks gefährden und auf deren Einhaltung der Vertragspartner regelmäßig vertraut und vertrauen darf. Die Haftung ist auf den vertragstypisch vorhersehbaren Schaden begrenzt. Dies gilt auch für entgangenen Gewinn und ausgebliebene Einsparungen.</p>
                <p>5.3. Eine weitergehende Haftung auf Schadensersatz ist – ohne Rücksicht auf die Rechtsnatur des geltend gemachten Anspruchs – ausgeschlossen. Dies gilt insbesondere für unerlaubte Handlungen gem. §§ 823, 831 BGB.</p>
                <p>5.4. Eine etwaige uneingeschränkte Haftung von ZwickRoell nach den Vorschriften des deutschen Produkthaftungsgesetzes oder wegen Arglist, Fehlen einer zugesicherten Eigenschaft oder einer Garantieübernahme bleibt von vorstehenden Haftungsbeschränkungen unberührt.</p><br/><br/>
                <p><b>6. Datenschutz</b></p><br/><br/>
                <p>ZwickRoell legt großen Wert auf den Schutz und die Sicherheit der personenbezogenen Daten der Nutzer. Weitere Informationen hierzu enthält die Datenschutzerklärung.</p><br/><br/>
                <p><b>7. Schlussbestimmungen </b></p><br/><br/>
                <p>7.1. Anwendbares Recht: Auf die vorliegenden Nutzungsbedingungen findet deutsches Recht unter Ausschluss des UN-Kaufrechts sowie des deutschen und europäischen Kollisionsrechts Anwendung, wobei zwingende Regelungen des Verbraucherschutzes jedoch stets der Rechtswahl vorgehen.</p>
                <p>7.2. Gerichtsstand: Für Kaufleute (im Sinne des HGB), Stiftungen oder Körperschaften des öffentlichen Rechts ist Berlin der ausschließliche Gerichtsstand für sämtliche Streitigkeiten, die aus oder aufgrund der Nutzung der Angebote von ZwickRoell oder der vorliegenden Nutzungsbedingungen entstehen.</p><br/><br/>
                <p>Stand Juli 2020</p>
            </div>
        )
    }

    render() {
        const {passWord, passWordConfirm, givenName, lastName, email, countrySelected, type, isTerm, validator, goLogin, visibleCPassword, visiblePassword, visibleButton } = this.state;
        let languagetext=this.state.language;
        let lbRegister = language(languagetext, "register");
        let lbEmail = language(languagetext, "email");
        let lbGivenName = language(languagetext, "given_name");
        let lbLastName = language(languagetext, "last_name");
        let lbCountry = language(languagetext, "country");
        let lbUserType = language(languagetext, "user_type");
        let lbCustomer = language(languagetext, "customer");
        let lbStaff = language(languagetext, "staff");
        let lbFamily = language(languagetext, "family");
        let lbSupplier = language(languagetext, "supplier");
        let lbTermsAndConditions = language(languagetext, "label_agree_terms_and_conditions");
        let lbPassword = language(languagetext, "password");
        let lbPasswordConfirmation = language(languagetext, "password_confirmation");
        let lbCancel = language(languagetext, "cancel");
        let lbInvalidEmail = language(languagetext, "invalid_email_address");
        let lbValidatePassword = language(languagetext, "label_password_invalid");
        let lbValidatePasswordConfirmation = language(languagetext, "password_and_confirmation_does_not_match");
        let lbEmailRequired = language(languagetext, "email_is_required");
        let lbEmailExist = language(languagetext, "email_already_exists");
        let lbGivenNameRequired = language(languagetext, "given_name_is_required");
        let lbLastNameRequired = language(languagetext, "last_name_is_required");
        let lbPasswordRequired = language(languagetext, "password_is_required");
        let lbPasswordConfirmationRequired = language(languagetext, "password_confirmation_is_required");
        let lbYes = language(languagetext, "yes_i_agree");
        let lbNo = language(languagetext, "no");
        //let lbTermsAndConditionsHeader = language(languagetext, "terms_and_conditions");

        let disabledButton = false;
        if (passWord.trim() === '' || passWordConfirm.trim() === '' || givenName.trim() === '' || lastName.trim() === '' || countrySelected.value === '' || email.trim() === '' || type === '' || !isTerm
            || validator.email || validator.isExist || validator.passWord || validator.passWordConfirm) {
            disabledButton = true;
        }

        let buttonNotVisibleStyle={
            position:'fixed',
            bottom:'20px',
            left:'0px',
            width:'100%'
        }
        if (goLogin) {
            return (
                <Redirect to={{
                    pathname: '/',
                    state: {email: email}
                }}/>
            )
        } else {
            return (
                <Container id="registerContainer" maxWidth="sm" className="pageRegister pb-3">
                    {
                        this.props.authReducer.loading &&
                        <div className="overlay-loading text-center"><span className="spinner-1 inline-spinner "/>
                        </div>
                    }
                    <SweetAlert
                        show={this.state.showAlert}
                        title={this.state.alertTitle}
                        text={this.state.alertText}
                        onConfirm={() => {
                            this.setState({showAlert: false});
                        }}
                        onEscapeKey={() => this.setState({showAlert: false})}
                        onOutsideClick={() => this.setState({showAlert: false})}
                    />
                    <SweetAlert
                        show={this.state.showConfirmLogin}
                        title={this.state.alertTitle}
                        text={this.state.alertText}
                        //showCancelButton
                        onConfirm={this.goLoginPage}
                        onCancel={() => {
                            this.setState({showConfirmLogin: false});
                        }}
                        onEscapeKey={() => this.setState({showConfirmLogin: false})}
                        onOutsideClick={() => this.setState({showConfirmLogin: false})}
                    />
                    <ReactModal
                        isOpen={this.state.showTermsAndConditions}
                        contentLabel={'Terms and Conditions'}
                        className="visible"
                        overlayClassName="sweet-overlay"
                        style={{
                            content: {
                                display: 'block',
                                marginTop: '50px',
                                backgroundColor: 'initial',
                                outline: 'none'
                            },
                            overlay: {opacity: '1.07', display: 'block', overflow: 'scroll'}
                        }}
                        // style={customStyles}
                        shouldFocusAfterRender={true}
                        shouldReturnFocusAfterClose={false}
                        shouldCloseOnEsc={true}
                        role="dialog">
                        <div className="modal-dialog max-w-100 modal-lg">
                            <div className="modal-content">
                                <div className="modal-body text-justify">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <div className="fade show bg-none bdr-2 term-content" role="alert">
                                                    {languagetext==='EN'?this.renderEn():this.renderDe()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer" style={{display: 'block', textAlign: 'center'}}>
                                    <button type="button" className="btn btn-success text-uppercase"
                                            data-dismiss="modal" onClick={() => this.setState({
                                        showTermsAndConditions: false,
                                        isTerm: true
                                    })}>
                                        {lbYes}
                                    </button>
                                    <button type="button" className="btn text-uppercase btn-gray"
                                            data-dismiss="modal"
                                            onClick={() => this.setState({showTermsAndConditions: false})}>
                                        {lbNo}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ReactModal>
                    <div className="">
                        <div className="app-header-public">
                            <div onClick={this.handleChangeLanguage}>
                                <span className="switch-lang"><img alt="" src={languagetext === 'EN' ? FlagGe : FlagEn}/></span>
                            </div>
                        </div>
                        <div className="col-12 text-center align-items-center px-0 mt-3 mb-3">
                            <h2 className="page-title mb-0 text-uppercase font-weight-600 fz-24 text-center">{lbRegister}</h2>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <InputLabel className="label-input">{lbEmail} <span
                                        className="text-danger">(*)</span></InputLabel>
                                    <Input
                                        type="email"
                                        id="email"
                                        className="form-control"
                                        autoComplete="new-password"
                                        onChange={this.inputEmail}
                                        onBlur={this.handleBlurEmail}
                                        value={this.state.email}
                                        placeholder={lbEmail}
                                    />
                                    {this.state.validator.emptyEmail ?
                                        <small className="d-block invalid-feedback">{lbEmailRequired}</small> : ''}
                                    {this.state.validator.email ?
                                        <small className="d-block invalid-feedback">{lbInvalidEmail}</small> : ''}
                                    {this.state.validator.isExist ?
                                        <small className="d-block invalid-feedback">{lbEmailExist}</small> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <InputLabel className="label-input">{lbGivenName} <span
                                        className="text-danger">(*)</span></InputLabel>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        autoComplete="new-password"
                                        onChange={this.inputGivenName}
                                        onBlur={this.handleBlurGivenName}
                                        value={this.state.givenName}
                                        placeholder={lbGivenName}
                                    />
                                    {this.state.validator.emptyGivenName ?
                                        <small className="d-block invalid-feedback">{lbGivenNameRequired}</small> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <InputLabel className="label-input">{lbLastName} <span
                                        className="text-danger">(*)</span></InputLabel>
                                    <Input
                                        type="text"
                                        autoComplete="new-password"
                                        className="form-control"
                                        onChange={this.inputLastName}
                                        onBlur={this.handleBlurLastName}
                                        value={this.state.lastName}
                                        placeholder={lbLastName}
                                    />
                                    {this.state.validator.emptyLastName ?
                                        <small className="d-block invalid-feedback">{lbLastNameRequired}</small> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <InputLabel className="label-input">{lbCountry} <span
                                        className="text-danger">(*)</span></InputLabel>
                                    <Select
                                        autoComplete="new-password"
                                        options={this.state.optionsCountries}
                                        value={this.state.countrySelected}
                                        onChange={this.handleChangeCountry}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group"
                                     style={{paddingBottom: '0!important', marginBottom: '0!important'}}>
                                    <InputLabel className="label-input">{lbUserType} <span
                                        className="text-danger">(*)</span></InputLabel>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group form-inline">
                                    <div className="form-inline" onClick={() => this.handleChangeType('Customer')}>
                                        <input
                                            type="radio"
                                            className="form-control cbox-register mt-2"
                                            //style={{width: 'auto', marginBottom: '.5rem'}}
                                            name="typeCustomer"
                                            value={'Customer'}
                                            checked={this.state.type === 'Customer'}
                                            onChange={() => this.handleChangeType('Customer')}
                                        />
                                        <InputLabel className="label-input pl-2">
                                            {lbCustomer}
                                        </InputLabel>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group form-inline">
                                    <div className="form-inline"
                                         onClick={() => this.handleChangeType('Family')} style={{flexWrap:'nowrap'}}>
                                        <input
                                            type="radio"
                                            className="form-control cbox-register mt-2"
                                            //style={{width: 'auto', marginBottom: '.5rem'}}
                                            name="typeFamily"
                                            value={'Family'}
                                            checked={this.state.type === 'Family'}
                                            onChange={() => this.handleChangeType('Family')}
                                        />
                                        <InputLabel className="label-input pl-2">
                                            {lbFamily}
                                        </InputLabel>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group form-inline">
                                    <div className="form-inline"
                                         onClick={() => this.handleChangeType('Supplier')} style={{flexWrap:'nowrap'}}>
                                        <input
                                            type="radio"
                                            className="form-control cbox-register mt-2"
                                            //style={{width: 'auto', marginBottom: '.5rem'}}
                                            name="typeSupplier"
                                            value={'Supplier'}
                                            checked={this.state.type === 'Supplier'}
                                            onChange={() => this.handleChangeType('Supplier')}
                                        />
                                        <InputLabel className="label-input pl-2">
                                            {lbSupplier}
                                        </InputLabel>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group form-inline">
                                    <div className="form-inline" onClick={() => this.handleChangeType('Staff')}>
                                        <input
                                            type="radio"
                                            className="form-control cbox-register mt-2"
                                            //style={{width: 'auto', marginBottom: '.5rem'}}
                                            name="typeStaff"
                                            value={'Staff'}
                                            checked={this.state.type === 'Staff'}
                                            onChange={() => this.handleChangeType('Staff')}
                                        />
                                        <InputLabel className="label-input pl-2">
                                            {lbStaff}
                                        </InputLabel>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group form-inline">
                                    <div className="form-inline" onClick={this.handleChangeTerm}
                                         style={{width: "100%"}}>
                                        <input
                                            type="checkbox"
                                            className="form-control cbox-register"
                                            checked={this.state.isTerm}
                                            onChange={()=>{}}
                                        />
                                        <InputLabel className="label-input pl-2"
                                                    style={{width: "93%", justifyContent: "left"}}>
                                            {lbTermsAndConditions} <span className="text-danger">(*)</span>
                                        </InputLabel>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <InputLabel className="label-input">{lbPassword} <span
                                        className="text-danger">(*)</span></InputLabel>
                                    <Input
                                        type={visiblePassword?"text":"password"}
                                        autoComplete="new-password"
                                        className="form-control"
                                        onChange={this.inputPassword}
                                        onBlur={this.handleBlurPassword}
                                        value={this.state.passWord}
                                        placeholder={lbPassword}
                                        endAdornment={
                                            visiblePassword?
                                            <VisibilityOffIcon onClick={()=>this.setState({ visiblePassword: false })} className="color-red" style={{cursor:'pointer'}}/>:
                                            <VisibilityIcon onClick={()=>this.setState({ visiblePassword: true })} className="color-red" style={{cursor:'pointer'}}/>
                                        }
                                    />
                                    {this.state.validator.emptyPassword ?
                                        <small className="d-block invalid-feedback">{lbPasswordRequired}</small> : ''}
                                    {this.state.validator.passWord ?
                                        <small className="d-block invalid-feedback">{lbValidatePassword}</small> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <InputLabel className="label-input">{lbPasswordConfirmation} <span
                                        className="text-danger">(*)</span></InputLabel>
                                    <Input
                                        type={visibleCPassword?"text":"password"}
                                        autoComplete="new-password"
                                        className="form-control"
                                        onChange={this.inputPasswordConfirm}
                                        onBlur={this.handleBlurPasswordConfirm}
                                        value={this.state.passWordConfirm}
                                        placeholder={lbPasswordConfirmation}
                                        endAdornment={
                                            visibleCPassword?
                                            <VisibilityOffIcon onClick={()=>this.setState({ visibleCPassword: false })} className="color-red" style={{cursor:'pointer'}}/>:
                                            <VisibilityIcon onClick={()=>this.setState({ visibleCPassword: true })} className="color-red" style={{cursor:'pointer'}}/>
                                        }
                                    />
                                    {this.state.validator.emptyPasswordConfirm ? <small
                                        className="d-block invalid-feedback">{lbPasswordConfirmationRequired}</small> : ''}
                                    {this.state.validator.passWordConfirm ? <small
                                        className="d-block invalid-feedback">{lbValidatePasswordConfirmation}</small> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 pb-4" style={{height:'62px'}}>
                            <div className="mr-3 d-flex justify-content-center" style={visibleButton?{}:buttonNotVisibleStyle}>
                                <Button type="button" id="btnRegister" variant="contained" color="primary"
                                        className="mr-3 btn-success" disabled={disabledButton}
                                        onClick={this.handleSubmit}>{lbRegister}</Button>
                                <Link to="/" className="btn btn-gray">
                                    {lbCancel}
                                </Link>
                            </div>
                        </div>
                    </div>
                </Container>
            )
        }
    }
}

function mapStateToProps(state) {
    const {authReducer} = state;
    return {authReducer}
}

export default connect(mapStateToProps)(Register);
