import React, {Component} from 'react';
import {connect} from "react-redux";
import { API } from '../../services';
import { Button, TextField, CircularProgress, InputAdornment } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import ImageInput from '../../components/ImageInput';
import { activityAction } from '../../actions/activity-action';
import moment from 'moment';
import language from '../../helpers/language';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import MultiPicker from 'rmc-picker/lib/MultiPicker';
import Picker from 'rmc-picker/lib/Picker';
import 'rmc-picker/assets/index.css'
import ReactModal from 'react-modal';
import IconRuns from "../../assets/icon_runs_the_world.png";
import { popup } from '../../helpers/toastr';

export class ActivityForm extends Component {
    constructor(props) {
        super(props)
        let { activity, event } = props
        let images = []
            if(activity){
                if(activity.draft)
                    images = activity.images
                else
                    images = activity.images.map((item)=>item.url)
            }
        this.state = {
            form:{
                eventId:event?event._id:'',
                images:images,
                date: activity?activity.date:moment(),
                distanceKm: activity?activity.distanceKm:'',
                distanceMile: activity?activity.distanceMile:'',
                notice: activity?activity.notice:''
            },
            tempDistanceKm: activity?activity.distanceKm:'',
            tempDistanceMile: activity?activity.distanceMile:'',
            errors:{},
            toDeleteImages:[],
            deletingImage:false,
            showInputNumberKm: false,
            showInputNumberMile: false
        }
    }

    componentWillUnmount() {
        this.closeModal()
    }

    valid = ()=>{
        const { profile } = this.props
        const { form } = this.state
        let errors = {}
        if(!form.distanceKm)
            errors.distanceKm = language(profile.language,'activityDetail.distance_required')
        if(isNaN(form.distanceKm))
            errors.distanceKm = language(profile.language,'activityDetail.distance_invalid')
        if(!form.distanceMile)
            errors.distanceMile = language(profile.language,'activityDetail.distance_required')
        if(isNaN(form.distanceMile))
            errors.distanceMile = language(profile.language,'activityDetail.distance_invalid')
        this.setState({ errors: errors})
        console.log(this.state)
        return Object.keys(errors).length?false:true
    }

    deleteImage = (index)=>{
        const { images } = this.state.form
        const { activity } = this.props
        let todeleteImage = []
        if(images[index].indexOf('http')>-1){
            let image = activity.images.find((el)=> el.url===images[index])
            todeleteImage = [image._id]
        }
        this.setState({ 
            form:{
                ...this.state.form, 
                images: images.filter((item,i)=> i!==index )},
            toDeleteImages: this.state.toDeleteImages.concat(todeleteImage)
        })
    }

    updateForm = (event,name)=>{
        let value = event.target?event.target.value:isNaN(event)?moment(event):event
        if(name==='distanceKm')
            this.setState({ 
                form:{...this.state.form,[name]: value, distanceMile:parseFloat((value*0.621371192).toFixed(1)) },
                errors:{...this.state.errors,[name]:''}
            })
        else if(name==='tempDistanceKm')
            this.setState({ tempDistanceKm: value, tempDistanceMile:parseFloat((value*0.621371192).toFixed(1)) })
        else if(name==='distanceMile')
            this.setState({ 
                form:{...this.state.form,[name]: value, distanceKm:parseFloat((value*1.609344).toFixed(1)) },
                errors:{...this.state.errors,[name]:''}
            })
        else if(name==='tempDistanceMile')
            this.setState({ tempDistanceMile: value, tempDistanceKm:parseFloat((value*1.609344).toFixed(1)) })
        else if(name==='images'){
            if(this.state.form.images.concat(value).length>3)
                popup(language(this.props.profile.language,'activityDetail.image_maximum_error'),'error')
            else this.setState({ 
                form:{...this.state.form,[name]: this.state.form[name].concat(value) },
                errors:{...this.state.errors,[name]:''}
            })
        }
        else
            this.setState({ 
                form:{...this.state.form,[name]: value },
                errors:{...this.state.errors,[name]:''}
            })
    }

    submitForm = async ()=>{
        const { profile } = this.props
        let updateSuccess = language(profile.language,'activity_updated');
        let updateFailed = language(profile.language, 'activity_update_failed')
        if(!this.valid())
            return
        if(this.props.activity){
            if(this.props.activity.draft)
                this.props.updateDraftActivity({
                    ...this.state.form,
                    _id:this.props.activity._id,
                    date:moment(this.state.form.date).toISOString()
                }, {updateSuccess: updateSuccess, updateFailed: updateFailed})
            else{
                this.setState({ deletingImage:true })
                for(let id of this.state.toDeleteImages){
                    await API.del('/images/'+id)
                }
                this.setState({ toDeletesImages:[], deletingImage:false })
                this.props.updateActivity({
                    ...this.state.form,
                    id:this.props.activity._id,
                    date:moment(this.state.form.date).toISOString(),
                    images:this.state.form.images.filter((s)=>s.indexOf('https://')===-1)
                }, {updateSuccess: updateSuccess, updateFailed: updateFailed})
            }
        }else{
            if(this.props.online)
                this.props.createActivity({...this.state.form,date:moment(this.state.form.date).toISOString()})
            else
                this.props.createDraftActivity({...this.state.form,date:moment(this.state.form.date).toISOString()})
        }
    }

    openModal = (e)=>{
        e.preventDefault()
        e.stopPropagation()
        e.target.blur()
        this.setState({ tempDistanceKm: this.state.form.distanceKm, tempDistanceMile: this.state.form.distanceMile })
        document.getElementById('app-content').style['overflow-y'] = 'hidden';
        document.body.style['position']='fixed';
    }

    closeModal = ()=>{
        document.getElementById('app-content').style['overflow-y'] = 'auto';
        document.body.style['position']='relative';
    }

    confirmModal = ()=>{
        if(this.props.profile.unit==="KM")
            this.updateForm(this.state.tempDistanceKm,'distanceKm')
        else
            this.updateForm(this.state.tempDistanceMile,'distanceMile')
    }

    render() {
        const { loading, event, profile, activity, online } = this.props
        const { deleteImage, updateForm, submitForm, openModal, closeModal, confirmModal } = this
        const { form, errors, deletingImage, showInputNumberKm, showInputNumberMile, tempDistanceKm, tempDistanceMile } = this.state
        let maxDate = moment().isBefore(moment(event.endDate))?moment():moment(event.endDate)
        let minDate = moment(event.startDate)
        let kmValue = [Math.floor(Number(tempDistanceKm)),String(tempDistanceKm).split('.')[1]?Number(String(tempDistanceKm).split('.')[1]):0]
        let kmValues = (event && event.maximumKm<=101) ? [...Array(Math.floor(event.maximumKm)).keys()] : [...Array(101).keys()]
        let mileValue = [Math.floor(Number(tempDistanceMile)),String(tempDistanceMile).split('.')[1]?Number(String(tempDistanceMile).split('.')[1]):0]
        let mileValues = (event && event.maximumMile<=101) ? [...Array(Math.floor(event.maximumMile)).keys()] : [...Array(101).keys()]

        return (
            <div className="container pt-3">
                <h4 className="text-center">{event.name}</h4>
                <div className="d-flex justify-content-center">
                    <img alt="" src={IconRuns} style={{width: '220px', height: '85px', marginBottom: '0.5rem'}}/>
                </div>
                <form className="text-center pb-4">
                    <div className="d-flex">
                        <ReactModal
                            ariaHideApp={false}
                            isOpen={showInputNumberKm}
                            contentLabel="Distance"
                            className="visible"
                            overlayClassName="sweet-overlay"
                            style={{
                                content : {
                                    position: 'relative',
                                    top: '50%',
                                    transform: 'translateY(-50%)'
                                  },
                                overlay: {opacity: '1.07', display: 'block'},
                                zIndex: '100!important'
                            }}
                            shouldFocusAfterRender={false}
                            shouldReturnFocusAfterClose={false}
                            shouldCloseOnEsc={true}
                            role="dialog" >
                            <div className="modal-dialog">
                                <div className="modal-content" style={{alignItems: 'center'}}>
                                    <div className="modal-header border-none">
                                        <h4 className="modal-title">
                                            {language(profile.language, "distance")}
                                        </h4>
                                    </div>
                                    <div className="modal-body w-100 pl-5 pr-5">
                                        <MultiPicker selectedValue={kmValue} onValueChange={(arr)=>updateForm(arr[0]+'.'+arr[1],'tempDistanceKm')}>
                                            <Picker indicatorClassName="my-picker-indicator" className="mr-3">
                                                {kmValues.map((value)=> <Picker.Item key={'int'+value} className="my-picker-view-item" value={value}>{value}</Picker.Item>)}
                                            </Picker>
                                            <Picker indicatorClassName="my-picker-indicator">
                                                {[0,1,2,3,4,5,6,7,8,9].map((value)=> <Picker.Item key={'dec'+value} className="my-picker-view-item" value={value}>{value}</Picker.Item>)}
                                            </Picker>
                                        </MultiPicker>
                                    </div>
                                    <div className="mb-3 mt-3 text-right">
                                        <Button type="button" variant="contained" color="primary" className="mr-3 btn-gray" onClick={()=>{this.setState({showInputNumberKm: false});closeModal() }}>{language(profile.language, "cancel")}</Button>
                                        <Button type="button" variant="contained" color="secondary" className="mr-3 backgroundColorPrimaryOverride" onClick={()=>{this.setState({showInputNumberKm: false});confirmModal();closeModal() }}>{language(profile.language, "ok")}</Button>                                
                                    </div>
                                </div>
                            </div>
                        </ReactModal>
                        <ReactModal
                            ariaHideApp={false}
                            isOpen={showInputNumberMile}
                            contentLabel="Distance"
                            className="visible"
                            overlayClassName="sweet-overlay"
                            style={{
                                content : {
                                    position: 'relative',
                                    top: '50%',
                                    transform: 'translateY(-50%)'
                                  },
                                overlay: {opacity: '1.07', display: 'block'},
                                zIndex: '100!important'
                            }}
                            shouldFocusAfterRender={false}
                            shouldReturnFocusAfterClose={false}
                            shouldCloseOnEsc={true}
                            role="dialog" >
                            <div className="modal-dialog">
                                <div className="modal-content" style={{alignItems: 'center'}}>
                                    <div className="modal-header border-none">
                                        <h4 className="modal-title">
                                            {language(profile.language, "distance")}
                                        </h4>
                                    </div>
                                    <div className="modal-body w-100 pl-5 pr-5">
                                        <MultiPicker selectedValue={mileValue} onValueChange={(arr)=>updateForm(arr[0]+'.'+arr[1],'tempDistanceMile')}>
                                            <Picker indicatorClassName="my-picker-indicator" className="mr-3">
                                                {mileValues.map((value)=> <Picker.Item key={'int'+value} className="my-picker-view-item" value={value}>{value}</Picker.Item>)}
                                            </Picker>
                                            <Picker indicatorClassName="my-picker-indicator">
                                                {[0,1,2,3,4,5,6,7,8,9].map((value)=> <Picker.Item key={'dec'+value} className="my-picker-view-item" value={value}>{value}</Picker.Item>)}
                                            </Picker>
                                        </MultiPicker>
                                    </div>
                                    <div className="mb-3 mt-3 text-right">
                                        <Button type="button" variant="contained" color="primary" className="mr-3 btn-gray" onClick={() => { this.setState({showInputNumberMile: false}); closeModal() }}>{language(profile.language, "cancel")}</Button>
                                        <Button type="button" variant="contained" color="secondary" className="mr-3 backgroundColorPrimaryOverride" onClick={()=> {this.setState({showInputNumberMile: false});confirmModal();closeModal() }}>{language(profile.language, "ok")}</Button>                                
                                    </div>
                                </div>
                            </div>
                        </ReactModal>
                    </div>   
                    <DatePicker id="date-input" label={language(profile.language,'activityDetail.date')} format="DD/MM/YYYY" className="w-100 mt-3" value={form.date} onChange={(e)=>updateForm(e,'date')} minDate={minDate} maxDate={maxDate} 
                    cancelLabel={<Button type="button" variant="contained" color="primary" className="btn-gray">{language(profile.language, "cancel")}</Button>} 
                    okLabel={<Button type="button" variant="contained" color="secondary" className="backgroundColorPrimaryOverride">{language(profile.language, "ok")}</Button>}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <DateRangeIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    {profile.unit==="KM"?<TextField type="number" id="distance-input-km" className="w-100 mt-3" label="Km" disabled
                        //onChange={(e)=>updateForm(e,'distanceKm')} 
                        onClick={(e)=>{ openModal(e);this.setState({showInputNumberKm: true})  }}
                        value={form.distanceKm} error={errors.distanceKm?true:false} helperText={errors.distanceKm}
                        InputProps={{
                            readOnly: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <DirectionsRunIcon />
                                </InputAdornment>
                            ),
                        }}/>:null}                         
                    {profile.unit!=="KM"?<TextField type="tel" id="distance-input-mile" className="w-100 mt-3" label="Mile" disabled
                        //onChange={(e)=>updateForm(e,'distanceMile')}                         
                        onClick={(e)=>{ openModal(e);this.setState({showInputNumberMile: true}); }}
                        value={form.distanceMile} error={errors.distanceMile?true:false} helperText={errors.distanceMile}
                        InputProps={{
                            readOnly: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <DirectionsRunIcon />
                                </InputAdornment>
                            ),
                        }}/>:null}
                    <TextField id="comments-input" label={language(profile.language,'activityDetail.comments')} className="w-100 mt-3 mb-3" onChange={(e)=>updateForm(e,'notice')} value={form.notice}/>
                    <ImageInput onChange={(e)=>updateForm(e,'images')} src={form.images} multi={true} deleteImage={deleteImage} max={3}/>
                    <div>
                        <Button startIcon={(deletingImage || loading)?<CircularProgress size={20}/>:null} variant="contained" color="primary" className="mt-3 backgroundColorPrimaryOverride" onClick={submitForm} disabled={deletingImage || loading || (activity && !online && !activity.draft)}>
                            {(deletingImage || loading)?language(profile.language,'activityDetail.loading'):(activity?language(profile.language,'activityDetail.update'):language(profile.language,'activityDetail.submit'))}
                        </Button>
                    </div>
                </form>
            </div>
        )
    }
}

function mapStateToProps(state,props) {
    let profile = state.authReducer.profile
    let event = profile.events.find((el)=> el._id===props.match.params.event_id)
    let activity = state.activityReducer.activities.find((el)=>el._id===props.match.params.id)
    if(!activity)
        activity = state.activityReducer.drafts.find((el)=>el._id===props.match.params.id)
    return {
        online: state.authReducer.online,
        profile:profile,
        event:event,
        loading:state.activityReducer.loading,
        activity:activity,
    }
}

function mapDispatchToProps(dispatch){
    return {
        createActivity: (data)=> dispatch(activityAction.createActivity(data)),
        createDraftActivity: (data)=> dispatch(activityAction.createDraftActivity(data)),
        updateActivity: (data, mess)=> dispatch(activityAction.updateActivity(data, mess)),
        updateDraftActivity: (data, mess)=> dispatch(activityAction.updateDraftActivity(data, mess))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ActivityForm);