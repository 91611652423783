import  {API} from '.';

export const notificationServices = {
    getNotifications,
    deleteNotification,
    deleteNotificationAll
};
   

// our put method that uses our backend api
// to create new query into our data base
function getNotifications() {
    let url = '/notifications';
    return API.get(url)
}

function deleteNotification(id){
    let url = `/notifications/${id}`;
    return API.del(url);
}

function deleteNotificationAll(){
    let url = `/notifications`;
    return API.del(url);
}

