import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { Paper, BottomNavigation, BottomNavigationAction, Badge } from '@material-ui/core';
import TimelineIcon from '@material-ui/icons/Timeline';
import PersonIcon from '@material-ui/icons/Person';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import FriendsIcon from '@material-ui/icons/PeopleOutline';
import NewsIcon from '@material-ui/icons/Web';
import language from '../helpers/language';

export default class Footer extends Component {

    clearData = ()=>{
        window.localStorage.removeItem('initialScrollY_newsfeed')
    }

    render() {
        const { path, profile, notificationStatus } = this.props;
        let languageText = profile ? profile.language : 'EN';
        return (
            <Paper>
                <BottomNavigation className="backgroundFooter" value={path} showLabels>
                    <BottomNavigationAction label={language(languageText, 'news')} value="/newsfeed" icon={<NewsIcon/>} component={Link} to="/newsfeed" onClick={this.clearData}/>
                    <BottomNavigationAction label={language(languageText, 'progress')} value="/progress" icon={<TimelineIcon/>} component={Link} to="/progress"/>
                    {/*<BottomNavigationAction label={language(languageText, 'ranking')} value="/ranking" icon={<EqualizerIcon/>} component={Link} to="/ranking"/>*/}
                    <BottomNavigationAction label={language(languageText, 'friends')} value="/myfriends" icon={<FriendsIcon/>} component={Link} to="/myfriends"/>
                    <BottomNavigationAction label={language(languageText, 'profile')} value="/profile" icon={<PersonIcon/>} component={Link} to="/profile"/>
                    <BottomNavigationAction label={language(languageText, 'notification')} value="/notification" icon={<Badge color="secondary" variant="dot" invisible={!notificationStatus}><NotificationsNoneIcon/></Badge>} component={Link} to="/notification"/>
                </BottomNavigation>
            </Paper>
        );
    }
}
