import uniqid from 'uniqid';
import * as moment from 'moment';

const defaultState = {
    activities:[],
    drafts:[],
    loading:false
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case "GET_PROFILE_ACTIVITIES_SUCCESS":
        return {...state, 
            activities: action.payload.sort((a,b)=>{
                if(moment(a.date).startOf('day').isBefore(moment(b.date).startOf('day')))
                    return 1
                else if(moment(a.date).startOf('day').isSame(moment(b.date).startOf('day')))
                    return 0
                else return -1
            }), 
            loading:false
        }
        case 'CREATE_ACTIVITY':
            return {...state,loading:true }
        case 'CREATE_ACTIVITY_SUCCESS':{
            let newActivities = [action.payload.data,...state.activities].sort((a,b)=>{
                if(moment(a.date).startOf('day').isBefore(moment(b.date).startOf('day')))
                    return 1
                else if(moment(a.date).startOf('day').isSame(moment(b.date).startOf('day')))
                    return 0
                else return -1
            })
            window.location.href = "#/my-activity/"+action.payload.data.eventId
            return {...state, activities: newActivities, loading:false }
        }case 'CREATE_ACTIVITY_FAIL':
            return {...state, loading:false }
        case 'UPDATE_ACTIVITY':
            return {...state,loading:true }
        case 'UPDATE_ACTIVITY_SUCCESS':{
            let newActivities = state.activities.map((item)=>{
                if(item._id===action.payload.data._id)
                    return action.payload.data
                else return item
            }).sort((a,b)=>{
                if(moment(a.date).startOf('day').isBefore(moment(b.date).startOf('day')))
                    return 1
                else if(moment(a.date).startOf('day').isSame(moment(b.date).startOf('day')))
                    return 0
                else return -1
            })
            window.location.href = "#/my-activity/"+action.payload.data.eventId
            return {...state, activities: newActivities, loading:false }
        }case 'UPDATE_ACTIVITY_FAIL':
            return {...state, loading:false }
        case 'DELETE_ACTIVITY':
            return {...state,loading:true }
        case 'DELETE_ACTIVITY_SUCCESS':{
            let newActivities = state.activities.filter((item)=> item._id !== action.payload.data._id)
            return {...state, activities: newActivities, loading:false }
        }case 'DELETE_ACTIVITY_FAIL':
            return {...state, loading:false }
        case 'CREATE_DRAFT_ACTIVITY':{
            let newDrafts = [{...action.payload.data,_id:uniqid(),draft:true,event_id:action.payload.data.eventId},...state.drafts]
            window.location.href = "#/my-activity/"+action.payload.data.eventId
            return {...state, drafts: newDrafts }
        }
        case 'UPDATE_DRAFT_ACTIVITY':{
            let newDrafts = state.drafts.map((item)=>{
                if(item._id===action.payload.data._id)
                    return {...action.payload.data, draft:true}
                else return item
            })
            window.location.href = "#/my-activity/"+action.payload.data.eventId
            return {...state, drafts: newDrafts }
        }
        case 'DELETE_DRAFT_ACTIVITY':{
            let newDrafts = state.drafts.filter((item)=> item._id !== action.payload.data._id)
            return {...state, drafts: newDrafts }
        }
        case 'SEND_CREATE_DRAFT_ACTIVITY':{
            let newDrafts = state.drafts.map((item)=>{
                if(item._id===action.payload.draft._id)
                    return {...item,loading:true}
                else return item
            })
            return {...state, drafts: newDrafts }
        }
        case 'SEND_CREATE_DRAFT_ACTIVITY_SUCCESS':{
            let newDrafts = state.drafts.filter((item)=> item._id !== action.payload.draft._id)
            let newActivities = [action.payload.data,...state.activities].sort((a,b)=>{
                if(moment(a.date).startOf('day').isBefore(moment(b.date).startOf('day')))
                    return 1
                else if(moment(a.date).startOf('day').isSame(moment(b.date).startOf('day')))
                    return 0
                else return -1
            })
            return {...state, activities: newActivities, drafts: newDrafts }
        }
        case 'SEND_CREATE_DRAFT_ACTIVITY_FAIL':{
            let newDrafts = state.drafts.map((item)=>{
                if(item._id===action.payload.draft._id)
                    return {...item,loading:false}
                else return item
            })
            return {...state, drafts: newDrafts }
        }
        default:
            return state
    }
}
  