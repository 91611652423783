import React, {Component} from 'react';
import { friendServices } from '../../services/friendService';
import { Card, Button, Grid, Avatar } from '@material-ui/core';
import IconAvatar from "../../assets/avatar_icon.png";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import language from '../../helpers/language';
import Swal from 'sweetalert2';

export default class FriendshipItem extends Component {

    handleAcceptFriend = () => {
        this.setState({ showLoading: true })
        let languageText = this.props.languageText ? this.props.languageText : 'EN';
        friendServices.acceptFriendRequest(this.props.data.requestId).then(res => {
            this.setState({ showLoading: false })
            if (res && res.errors) {
                Swal.fire({
                    text: language(languageText,"label_add_friend_failed"),
                    icon: 'error',
                    confirmButtonColor: '#e60003',
                })
            }
            else {
                this.props.addFriendSuccess()
                Swal.fire({
                    text: language(languageText,"label_add_friend_successful"),
                    icon: 'info',
                    confirmButtonColor: '#e60003',
                })
            }
        }).catch(err => {
            this.setState({ showLoading: false })
            Swal.fire({
                text: language(languageText,"label_add_friend_failed"),
                icon: 'error',
                confirmButtonColor: '#e60003',
            })
        })
    }

    handleRejectFriend = () => {
        this.setState({ showLoading: true })
        let languageText = this.props.languageText ? this.props.languageText : 'EN';
        friendServices.rejectFriendRequest(this.props.data.requestId).then(res => {
            this.setState({ showLoading: false })
            if (res && res.errors) {
                this.props.addFriendSuccess()
                Swal.fire({
                    text: language(languageText,this.props.pending?"label_cancel_friend_request_failed":"label_reject_friend_failed"),
                    icon: 'error',
                    confirmButtonColor: '#e60003',
                })
            }
            else {
                this.props.removeFriendItemSuccess(this.props.data.requestId)
                Swal.fire({
                    text: language(languageText,this.props.pending?"label_cancel_friend_request_successful":"label_reject_friend_successful"),
                    icon: 'info',
                    confirmButtonColor: '#e60003',
                })
            }
        }).catch(err => {
            this.props.addFriendSuccess()
            this.setState({ showLoading: false })
            Swal.fire({
                text: language(languageText,this.props.pending?"label_cancel_friend_request_failed":"label_reject_friend_failed"),
                icon: 'error',
                confirmButtonColor: '#e60003',
            })
        })
    }

    checkOutApp = (displayName)=>{
        if(displayName && (displayName.toLowerCase() === 'anonymous' || displayName.toLowerCase() === 'logged out'))
            return true
        else return false
    }

    render() {
        const { data, languageText, pending } = this.props;
        const { handleAcceptFriend, handleRejectFriend, checkOutApp } = this
        return (
            <Card variant="outlined" className="mt-2 p-2">
                <Grid container>
                    <Grid item xs={3}>
                        <Avatar alt="Avatar " src={data.avatar ? data.avatar : IconAvatar} className="newsfeed-avatar mr-2 ml-2" />
                    </Grid>
                    <Grid item xs={pending?5:9} className="flex-column align-self-center text-left">
                        <h6 className="text-left header-title d-inline" style={{wordBreak: 'break-word'}}>
                            {pending?language(languageText,"to"):language(languageText,"from")} : {checkOutApp(data.displayName)?language(languageText,"logged_out"):data.activityShare==='Public'?data.displayName:'Anonymous'}
                        </h6>
                    </Grid>
                    {pending?
                    <Grid item xs={4} className="flex-column align-self-center">
                        <ButtonGroup className="w-100" size="small" color="primary">
                            <Button  className="w-100" onClick={handleRejectFriend}>{language(languageText,"cancel")}</Button>
                        </ButtonGroup>
                    </Grid>:
                    <Grid item xs={12}>
                        <ButtonGroup className="w-100 mt-2" size="small" color="primary">
                            <Button className="w-50" onClick={handleAcceptFriend}>{language(languageText,"accept")}</Button>
                            <Button className="w-50" onClick={handleRejectFriend}>{language(languageText,"decline")}</Button>
                        </ButtonGroup>
                    </Grid>
                    }
                </Grid>
            </Card >
        )
    }
}
