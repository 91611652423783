import React from "react";
import Login from "../pages/Login/Login.js";
import Register from "../pages/Register/Register.js";
import ActivityForm from "../pages/ActivityForm/ActivityForm";
import MyActivity from "../pages/MyActivity/MyActivity";
import NewsFeed from "../pages/NewsFeed/NewsFeed";
import NewFeedDetail from "../pages/NewsFeed/NewsFeedDetail";
import Progress from "../pages/Progress/Progress";
import Ranking from "../pages/Ranking/Ranking";
import Search from "../pages/Search/Search";
import Profile from "../pages/Profile/Profile";
import Competitor from "../pages/Competitor/Competitor";
import MyFriends from "../pages/MyFriends/MyFriends";
import ActiveEvents from "../pages/ActiveEvents/ActiveEvents";
import Teams from "../pages/Teams/Teams";

import RegistrationConfirm from "../pages/RegistrationConfirm/RegistrationConfirm.js";
import ResetPassword from "../pages/ResetPassword/ResetPassword.js";
import ChangePassword from "../pages/ChangePassword/ChangePassword.js";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword.js";
import Notifications from "../pages/Notifications/Notification";
import EventRegistration from "../pages/EventRegistration/eventRegistration";
import NotificationDetail from "../pages/Notifications/NotificationDetail";

import Dashboard from "../pages/Admin/Dashboard/Dashboard";
import ListEvent from "../pages/Admin/Events/EventList";
import EventDetail from "../pages/Admin/Events/EventDetail";
import UserDetail from "../pages/Admin/Users/UserDetail";
import NotificationList from "../pages/Admin/Notifications/NotificationList";
import TeamsAdmin from "../pages/Admin/Teams/Teams";
import Report from "../pages/Admin/Reports/Reports";
import UserList from "../pages/Admin/Users/UserList";
import Term from "../pages/Term/Term";
import RankingSearch from "../pages/RankingSearch/RankingSearch.js";

const routes = [
  {
    path: "/",
    headerTitle: "Login",
    exact: true,
    auth: false,
    main: (props) => <Login {...props} />, //() => <Login />
  },
  {
    path: "/index.html",
    headerTitle: "Login",
    exact: false,
    auth: false,
    main: (props) => <Login {...props} />, //() => <Login />
  },
  {
    path: "/login",
    headerTitle: "Login",
    exact: false,
    auth: false,
    main: (props) => <Login {...props} />, //() => <Login />
  },
  {
    path: "/register",
    headerTitle: "Register",
    exact: false,
    auth: false,
    main: (props) => <Register {...props} />,
  },
  {
    path: "/registrationConfirm/:id",
    exact: false,
    auth: false,
    main: (props) => <RegistrationConfirm {...props} />, //() => <RegistrationConfirm />
  },
  {
    path: "/resetPassword/:id",
    headerTitle: "Password Reset",
    exact: false,
    auth: false,
    main: (props) => <ResetPassword {...props} />, //() => <ResetPassword />
  },
  {
    path: "/forgotPassword",
    headerTitle: "Request Password Reset",
    exact: false,
    auth: false,
    main: (props) => <ForgotPassword {...props} />,
  },
  {
    path: "/changePassword",
    headerTitle: "Password Change",
    exact: false,
    auth: true,
    admin: false,
    main: (props) => <ChangePassword {...props} />,
  },
  {
    path: "/activity-form/:event_id/",
    headerTitle: "activityDetail.title_add",
    back: true,
    exact: true,
    auth: true,
    admin: false,
    main: (props) => <ActivityForm {...props} />,
  },
  {
    path: "/activity-form/:event_id/:id",
    headerTitle: "activityDetail.title_update",
    back: true,
    exact: true,
    auth: true,
    admin: false,
    main: (props) => <ActivityForm {...props} />,
  },
  {
    path: "/my-activity/:event_id",
    headerTitle: "myActivity.title",
    back: true,
    backUrl: "#/progress",
    exact: false,
    auth: true,
    admin: false,
    main: (props) => <MyActivity {...props} />,
  },
  {
    path: "/myfriends",
    headerTitle: "my_friends",
    exact: false,
    auth: true,
    admin: false,
    main: (props) => <MyFriends {...props} />,
  },
  {
    path: "/active-events",
    headerTitle: "active_events",
    exact: false,
    auth: true,
    admin: false,
    main: () => <ActiveEvents />,
  },
  {
    path: "/teams",
    headerTitle: "teams",
    exact: false,
    auth: true,
    admin: false,
    main: () => <Teams />,
  },
  {
    path: "/newsfeed",
    headerTitle: "newsfeed.title",
    exact: true,
    auth: true,
    admin: false,
    main: (props) => <NewsFeed {...props} />,
  },
  {
    path: "/newsfeed/:id",
    headerTitle: "newsfeed.title",
    back: true,
    backUrl: "#/newsfeed",
    exact: true,
    auth: true,
    admin: false,
    main: (props) => <NewFeedDetail {...props} />,
  },
  {
    path: "/progress",
    headerTitle: "progress",
    exact: false,
    auth: true,
    admin: false,
    main: () => <Progress />,
  },
  {
    path: "/ranking",
    headerTitle: "ranking",
    exact: true,
    auth: true,
    admin: false,
    main: (props) => <Ranking {...props} />,
  },
  {
    path: "/ranking/:id",
    headerTitle: "ranking",
    exact: true,
    auth: true,
    admin: false,
    main: (props) => <Ranking {...props} />,
  },
  {
    path: "/search",
    headerTitle: "search",
    exact: false,
    auth: true,
    admin: false,
    main: (props) => <Search {...props} />,
  },
  {
    path: "/ranking-search/:id",
    headerTitle: "search_ranking",
    exact: false,
    auth: true,
    admin: false,
    main: (props) => <RankingSearch {...props} />,
  },
  {
    path: "/competitor/:id?/:event_id?",
    headerTitle: "competitor",
    exact: false,
    auth: true,
    admin: false,
    back: true,
    main: (props) => <Competitor {...props} />,
  },
  {
    path: "/profile",
    headerTitle: "my_profile",
    exact: false,
    auth: true,
    admin: false,
    isProfile: true,
    main: (props) => <Profile {...props} />,
  },
  {
    path: "/term",
    headerTitle: "term",
    exact: false,
    auth: true,
    admin: false,
    main: (props) => <Term {...props} />,
  },
  {
    path: "/notification",
    headerTitle: "Notification",
    exact: false,
    auth: true,
    admin: false,
    isProfile: true,
    main: (props) => <Notifications {...props} />,
  },
  {
    path: "/notificationDetail",
    headerTitle: "Notification Detail",
    exact: false,
    back: true,
    auth: true,
    admin: false,
    isProfile: true,
    main: (props) => <NotificationDetail {...props} />,
  },
  {
    path: "/dashboard",
    headerTitle: "dashboard",
    exact: false,
    auth: true,
    admin: true,
    main: (props) => <Dashboard {...props} />,
  },
  {
    path: "/events",
    headerTitle: "event_management",
    exact: false,
    auth: true,
    admin: true,
    main: (props) => <ListEvent {...props} />,
  },
  {
    path: "/eventRegistration",
    headerTitle: "registration_of_event",
    exact: false,
    auth: true,
    main: (props) => <EventRegistration {...props} />,
  },
  {
    path: "/users",
    headerTitle: "user_management",
    exact: false,
    auth: true,
    admin: true,
    main: (props) => <UserList {...props} />,
  },
  {
    path: "/teams",
    headerTitle: "teams_management",
    exact: false,
    auth: true,
    admin: true,
    main: (props) => <TeamsAdmin {...props} />,
  },
  {
    path: "/report/:event_id/:event_name",
    headerTitle: "report",
    exact: false,
    auth: true,
    admin: true,
    main: (props) => <Report {...props} />,
  },
  {
    path: "/report",
    headerTitle: "report",
    exact: false,
    auth: true,
    admin: true,
    main: (props) => <Report {...props} />,
  },
  {
    path: "/userDetail",
    headerTitle: "",
    exact: false,
    auth: true,
    admin: true,
    back: true,
    main: (props) => <UserDetail {...props} />,
  },
  {
    path: "/communicates",
    headerTitle: "communications",
    exact: false,
    auth: true,
    admin: true,
    main: (props) => <NotificationList {...props} />,
  },
  {
    path: "/eventDetail/:id?/:name?",
    headerTitle: "Event Detail",
    exact: false,
    auth: true,
    admin: true,
    back: true,
    main: (props) => <EventDetail {...props} />,
  },
];

export default routes;
