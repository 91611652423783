import React, { Component } from 'react';
import {connect} from "react-redux";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { ReactComponent as CheckedIcon } from '../../../assets/Ic_check.svg';
import { ReactComponent as UncheckIcon } from '../../../assets/Ic_uncheck.svg';
import { Table, ButtonGroup, Button, Tooltip, TableBody, TableCell, TableHead, TableRow, Typography, CardContent, Paper, IconButton } from '@material-ui/core';
import language from '../../../helpers/language';

class UsersTable extends Component {
  constructor(props) {
      super(props)

      this.state = {
      }
  }

  componentDidMount(){
  }

  render() {
    const { users, page, limit} = this.props;
    let languageText = this.props.languagetext ? this.props.languagetext : 'DE';
    return (
        <CardContent className="">
          <div className="table-content d-block d-lg-none" style={{maxHeight: 'calc(100vh - (220px))'}}>
            {users && users.map(user => {
              return (
                <Paper key={user._id} elevation={3} className="mb-2 p-2 w-100 d-flex justify-content-between">
                  <div>
                    <div>{user.firstName + ' ' + user.lastName}</div>
                    <div>{user.email}</div>
                  </div>
                  <div className="d-flex p-2">
                    <IconButton color="primary" onClick={() => this.props.onClickEditUser(user, false)}><DeleteIcon/></IconButton>
                  </div>
                </Paper>
              )
            })}
            </div>
          <div className="table-content d-none d-lg-block" style={{maxHeight: 'calc(100vh - (220px))'}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{language(languageText,"no")}</TableCell>
                  <TableCell>{language(languageText,"name")}</TableCell>
                  <TableCell>{language(languageText,"email")}</TableCell>
                  <TableCell>{language(languageText,"country")}</TableCell>
                  <TableCell>{language(languageText,"user_type")}</TableCell>
                  <TableCell>{language(languageText,"role")}</TableCell>
                  <TableCell>{language(languageText,"status")}</TableCell>
                  <TableCell>{language(languageText,"actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users && users.map((user, index) => (
                  <TableRow
                    className=''
                    hover
                    key={user._id}
                  >
                    <TableCell>
                      {(page-1)*limit + index +1}
                    </TableCell>
                    <TableCell>
                      <div className=''>
                        <span className="btn-link " onClick={() => (this.props.onClickEditUser(user, false))}>
                          <Typography variant="body1">{ user.firstName + ' ' + user.lastName}</Typography>
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {user.country}
                    </TableCell>
                    <TableCell>{user.userType}</TableCell>
                    <TableCell>
                      {user.role}
                    </TableCell>
                    <TableCell>
                    <div className="d-inline-block text-left" style={{width: "100px"}}>
                      <label className="container-checkbox">
                          <span>Active</span>
                          <input type="checkbox" className="test" name={user._id} checked={user.status}  onChange={() => (this.props.confirmUpdateStatus(user))} />
                          <CheckedIcon className= "checkbox-icon checkbox--checked" width={"35px"} height={"35px"}/>
                          <UncheckIcon className= "checkbox-icon checkbox--uncheck" width={"35px"} height={"35px"}/>
                      </label>
                  </div>
                    </TableCell>
                    <TableCell>
                    <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                      <Tooltip
                          id="tooltip-top"
                          title={language(languageText, "edit_user")}
                          placement="top"
                        >
                        <Button onClick={() => (this.props.onClickEditUser(user, false))}><EditIcon/></Button>
                      </Tooltip>
                      <Tooltip
                        id="tooltip-top"
                        title={language(languageText, "delete_user")}
                        color="secondary"
                        placement="top">
                          <Button onClick={() => this.props.onClickDeleteUser(user._id)}><DeleteIcon/></Button>
                      </Tooltip>
                    </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
    )};
};

function mapStateToProps(state) {
  return {
      userInfo: state.authReducer.userInfo
  };
}

export default connect(mapStateToProps)(UsersTable);
