import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { Button, Input, InputLabel } from '@material-ui/core';
import {connect} from "react-redux";
import Swal from 'sweetalert2';
import { authAction } from '../../actions/auth-action';
import { validator } from "../../helpers";
import FlagEn from "../../assets/flag-english.jpg";
import FlagGe from "../../assets/flag-Germany.jpg";
import language from '../../helpers/language';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconRuns from "../../assets/icon_runs_the_world.png";
import { popup } from '../../helpers/toastr';
import * as moment from 'moment';

class Login extends Component {   
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            passWord: '',
            isKeepLogin: true,
            validator: {
                email: false,
                passWord: false
            },            
            showLoading: false,
            languageText: 'DE',
            visiblePassword:false
        }
    }

    componentDidMount(){
        let pre_language = localStorage.getItem('pre_language');
        console.log("pre_language", pre_language)
        if (!pre_language || pre_language === '') pre_language = 'EN';
        localStorage.setItem('pre_language', pre_language) 
        this.setState({languageText: pre_language === 'EN' ? 'DE' : 'EN'}); 

        if (this.props.location && this.props.location.state && this.props.location.state.email) {
            let emailAutoFill = this.props.location.state.email;
            this.setState({email: emailAutoFill})
        }
    }

    handleLogin = (e) => {
        const {email, passWord, isKeepLogin, languageText} = this.state;
        if(!this.props.online)
            return popup(language(languageText,'you_are_offline'),'error')
        if(email.trim() === '' || passWord.trim() === '') {
            Swal.fire({
                text: 'Please input both Email and Password!',
                icon: 'warning',
                confirmButtonColor: '#e60003',
                confirmButtonText: language(languageText, 'ok')
            })
            return false;
        }
        this.setState({showLoading: true})
        this.props.authUser(email,passWord).then(res => {
            this.setState({showLoading: false})
            if (res.errors) {
                let lbLoginFailed = res.errors.msg;
                Swal.fire({
                    text: language(languageText, lbLoginFailed),
                    icon: 'error',
                    confirmButtonColor: '#e60003',
                    confirmButtonText: language(languageText, 'ok')
                })
            }
            else {
                localStorage.removeItem('pre_language')
                var expireDate = moment().toISOString(); 
                if (isKeepLogin)
                    expireDate = moment().add(100,'day').toISOString(); 
                localStorage.setItem('expireTokenDate',expireDate)
            }
        }).catch(err => {
            let lbLoginFailed = err ? err.replace(/_/g, ' ') : 'Login failed. Please try again.';
            Swal.fire({
                text: lbLoginFailed,
                icon: 'error',
                confirmButtonColor: '#e60003',
                confirmButtonText: language(languageText, 'ok')
            })
        })
    }

    handleChangeKeepLogin = () => {
        this.setState({ isKeepLogin: !this.state.isKeepLogin })
    }

    handleChangeLanguage = ()=>{
        let currentLanguage = this.state.languageText;
        this.setState({languageText:currentLanguage === 'EN' ? 'DE' : 'EN'});
        localStorage.setItem('pre_language', currentLanguage)  
    }

    inputEmail = (e)=> {
        let strValue = String(e.target.value);
        this.setState({
            email: strValue.trim()
        })
        let vali = this.state.validator;
        if (validator.isEmail(strValue)) {
            this.setState({validator: {...vali, email: false}});
        }
        else {
            this.setState({validator: {...vali, email: true}});
        }
    }

    inputPassword = (e)=> {
        let strValue = String(e.target.value);
        this.setState({
            passWord: strValue.trim()
        })
    }

    render() {
        const { languageText, passWord, email, validator, visiblePassword } = this.state;
        const { loading } = this.props;
        let lbLogin = language(languageText, 'login');
        let lbEmail = language(languageText, 'email');
        let lbPassword = language(languageText, 'password');
        let lbForgotPassword = language(languageText, 'forgot_password');
        let lbKeepMeLoggedIn = language(languageText, 'keep_me_logged_in');
        let lbRegister = language(languageText, 'register');
        let lbInvalidEmail = language(languageText, 'invalid_email_address');

        let disabledButton = false;
        if(passWord === '' || email === '' || validator.email) {
            disabledButton = true;
        }
        return (
            <Container maxWidth="sm">
                {
                    loading && 
                    <div className="overlay-loading text-center"><span className="spinner-1 inline-spinner "/>
                    </div>
                }
                <div className="content-login">
                    <div className="app-header-public">
                        <div onClick={this.handleChangeLanguage}>
                            <span className="switch-lang"><img alt="" src={languageText === 'EN' ? FlagGe : FlagEn} /></span>
                            {/*<img src={FlagGe} />*/}
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <img alt="" src={IconRuns} style={{width: '150px', height: '50px', marginBottom: '0.5rem'}}/>
                    </div>
                    <div className="col-12 align-items-center px-0 mt-3 mb-3 text-center">
                        <h6 className="page-title mb-0 text-center" style={{whiteSpace:'pre-wrap'}}>{language(languageText, 'registered_y')}</h6>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <InputLabel className="label-input">{lbEmail} <span className="text-danger">(*)</span>
                                <Input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="form-control mt-2"
                                    onChange={this.inputEmail}
                                    value={this.state.email}
                                    placeholder={lbEmail}
                                />
                                </InputLabel>
                                {this.state.validator.email ? <small className="d-block invalid-feedback">{lbInvalidEmail}</small> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <InputLabel className="label-input">{lbPassword} <span className="text-danger">(*)</span>
                                    <Input
                                        type={visiblePassword?"text":"password"}
                                        id="password"
                                        name="password"
                                        className="form-control mt-2"
                                        onChange={this.inputPassword}
                                        value={this.state.passWord}
                                        placeholder={lbPassword}
                                        endAdornment={
                                            visiblePassword?
                                            <VisibilityOffIcon onClick={()=>this.setState({ visiblePassword: false })} className="color-red" style={{cursor:'pointer'}}/>:
                                            <VisibilityIcon onClick={()=>this.setState({ visiblePassword: true })} className="color-red" style={{cursor:'pointer'}}/>
                                        }
                                    />
                                </InputLabel>
                            </div>
                        </div>  
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-check" style={{width: 'fit-content'}}>
                                <input className="form-check-input" type="checkbox" onChange={this.handleChangeKeepLogin} checked={this.state.isKeepLogin} id="keep-login-check"/>
                                <label className="form-check-label" htmlFor="keep-login-check">
                                    {lbKeepMeLoggedIn}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <div className="form-group div-forgot">
                                <Link to="/forgotPassword" className="" >
                                    {lbForgotPassword}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">         
                        <div className="col-12">
                            <div className="text-center">
                                <Button type="button" id="btnLogin" name="btnLogin" variant="contained" color="primary" className="btnLogin btn-success" style={{minWidth:"200px"}} disabled={disabledButton} onClick={() => this.handleLogin()}>{lbLogin}</Button>
                                {/* <button type="button" className="btn btn-success" onClick={() => this.handleRegister()}>Register</button> */}                                
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 align-items-center px-0 mt-3 mb-3 text-center">
                            <h6 className="page-title mb-0 text-center" style={{whiteSpace:'pre-wrap'}}>{language(languageText, 'registered_n')}</h6>
                        </div>
                        <div className="col-12 text-center">
                            <Link to="/register" className="btn btn-gray" style={{minWidth:"200px"}}>{lbRegister}</Link>
                        </div>
                        
                    </div>
                    <div className="row position-fixed w-100" style={{bottom:'5px'}}>
                        <div className="col-6 text-right">
                            <a className="color-red mt-2" href="https://www.zwickroell.com/de-de/impressum" target="_blank" rel="noopener noreferrer">{language(languageText, 'imprint')}</a>
                        </div>
                        <div className="col-6 text-left">
                            <a className="color-red mt-2"href="https://www.zwickroell.com/de-de/datenschutz" target="_blank" rel="noopener noreferrer">{language(languageText, 'privacy')}</a>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return { ...state.authReducer }
}

function mapDispatchToProps(dispatch) {
    return {
        authUser: (email,passowrd)=> dispatch(authAction.authentication(email,passowrd))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Login);