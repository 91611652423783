import { combineReducers } from 'redux';
import authReducer from './auth-reducer';
import activityReducer from './activity-reducer';
import newsfeedReducer from './newsfeed-reducer';
import rankingReducer from './ranking-reducer';
import searchReducer from './search-reducer';
import eventReducer from './event-reducer';
import friendReducer from './friend-reducer';
import communicateReducer from './communicate-reducer';
import notificationReducer from './notification-reducer';
import teamReducer from './team-reducer';
import reportReducer from './report-reducer';

export default combineReducers({
  authReducer,
  activityReducer,
  newsfeedReducer,
  rankingReducer,
  searchReducer,
  eventReducer,
  friendReducer,
  communicateReducer,
  notificationReducer,
  teamReducer,
  reportReducer
});
