import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

class PrivateRoute extends Component {

    render() {
        const { path, exact, component, authed, profile, token } = this.props
        if(!authed || !token)
            return <Redirect to='/login'/>
        else
            return (
                <div>
                    <div className="app-header">
                        <Header {...this.props}/>
                    </div>
                    <div className="app-content" id="app-content">
                        {profile.email?<Route path={path} exact={exact} component={component} showLabels/>:<div className="position-absolute d-flex flex-column justify-content-center text-center" style={{ top:0, left:0, width:'100vw', height:'100vh' }}>loading your profile...</div>}
                    </div>
                    <div className="app-footer">
                        <Footer {...this.props}/>
                    </div>
                </div>
            )
    }
}

function mapStateToProps(state) {
    return {...state.authReducer,notificationStatus:state.notificationReducer.notificationStatus}
}

export default connect(mapStateToProps)(PrivateRoute);