import React, {Component} from 'react';
import { Card , Grid, Avatar } from '@material-ui/core';
import countryList from 'react-select-country-list';
import { Link } from "react-router-dom";
import IconAvatar from "../../assets/avatar_icon.png";
import language from '../../helpers/language';

export default class SearchItem extends Component {
    countries = countryList()

    checkOutApp = (displayName)=>{
        if(displayName && (displayName.toLowerCase() === 'anonymous' || displayName === 'Logged out'))
            return true
        else return false
    }

    viewName = ()=>{
        const { data, languageText } = this.props;
        let name = data.displayName
        //if(data.activityShare === 'Private')
        //    name = 'Anonymous'
        //if(data.activityShare === 'Friends' && data.isFriend !=='YES')
        //    name = 'Anonymous'
        if(this.checkOutApp(data.displayName))
            name = language(languageText,"logged_out")
        return name
    }

    setData = (data)=>{
        localStorage.setItem('competitor',JSON.stringify({...data}))
    }

    render() {
        const { data } = this.props
        let country = this.countries.getLabel(data.country)
        if (data.country === 'TW') {
            country = 'Taiwan'
        }
        return (
            <Card variant="outlined" className="mt-2 p-2">
                <Link to={"/competitor/"+data._id} className="d-flex" onClick={()=>this.setData(data)}>
                    <Grid container>
                        <Grid container item xs={3} className="flex-column justify-content-center">
                            <Avatar alt="Avatar " src={data.avatar ? data.avatar : IconAvatar} className="newsfeed-avatar mr-2 ml-2" />
                        </Grid>
                        <Grid container item xs={9} alignItems="center">
                            <Grid container item >
        <h6 className="text-left text-dark header-title">{this.viewName()}</h6>
                            </Grid>
                            <Grid container item >
                                <h6 className="text-left text-dark header-title">{country ?country : ''}</h6>
                            </Grid>
                        </Grid>
                    </Grid>
                </Link>
            </Card >
        )
    }
}
