import { notificationConstant } from '../constants/notificationConstant';
import { toastr } from '../helpers/toastr';

const defaultState = {
    notifications: [],
    loadingNotification: false,
    notificationStatus:null
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case notificationConstant.GET_ALL_NOTIFICATION_REQUEST:
            return { ...state, loadingNotification: true }
        case notificationConstant.GET_ALL_NOTIFICATION_SUCCESS:
            return { ...state, notifications: action.payload.data, loadingNotification: false }
        case notificationConstant.GET_ALL_NOTIFICATION_FAILURE:
            return { ...state, loadingNotification: false }
        case notificationConstant.DELETE_NOTIFICATION_REQUEST:
            return { ...state, loadingNotification: true }
        case notificationConstant.DELETE_NOTIFICATION_SUCCESS:
            toastr('success','Notification deleted')
            return { ...state, loadingNotification: false }
        case notificationConstant.DELETE_NOTIFICATION_FAILURE:
            toastr('error','Fail to delete notification')
            return { ...state, loadingNotification: false }
        case 'SET_NOTIFICATION_STATUS':
            return {...state, notificationStatus:action.status }
        case 'DELETE_NOTIFICATION_ALL_SUCCESS':
            return {...state, notifications:[] }
        default:
            return state
    }
}
