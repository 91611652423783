import React, {Component} from 'react';
import { Button, Input, InputLabel, Tooltip, Avatar, IconButton, Link } from '@material-ui/core';
import Swal from 'sweetalert2';
import IconAvatar from "../../assets/icon_add_avatar.png";
import {connect} from "react-redux";
import {userManagementServices} from '../../services/userManagementService';
import {userManagementConstant} from '../../constants/userManagementConstant';
import language from '../../helpers/language';
import DeleteIcon from '@material-ui/icons/Delete';
import { authAction } from '../../actions/auth-action';
import { confirm, popup } from '../../helpers/toastr';
import countryList from 'react-select-country-list';
import Select from 'react-select';

class Profile extends Component {
    constructor(props) {
        super(props)

        this.optionsCountries = countryList().setLabel("TW", "Taiwan").data

        this.state = {
            optionsCountries: this.optionsCountries,
            userInfo: {},
            displayName: '',
            givenName: '',
            lastName: '',
            country: '',
            userType:'',
            gender: null,
            avatarPreview: null,
            language: 'DE',
            company: '',
            typeActivity: 'Running',
            typeUnit: 'KM', //['KM', 'MILE'],
            typeShareActivity: 'Public', //['Public', 'Friends', 'Private']
            typeSharePhoto: 'Friends',//['Public', 'Friends', 'Private']
            isAllowFriendshipRequest: true,
            isChangeAvatar: false,
            showLoading: false,
            emptyDisplayName: false,
            emptyGender: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeGender = this.handleChangeGender.bind(this);
        this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
        this.handleChangeActivity = this.handleChangeActivity.bind(this);
        this.handleChangeUnit = this.handleChangeUnit.bind(this);
        this.handleChangeTypeShareActivity = this.handleChangeTypeShareActivity.bind(this);
        this.handleChangeTypeSharePhoto = this.handleChangeTypeSharePhoto.bind(this);
        this.handleChangeAllowFriendshipRequest = this.handleChangeAllowFriendshipRequest.bind(this);
        this.handleChooseImageFile = this.handleChooseImageFile.bind(this);
        this.handleBlurDisplayName = this.handleBlurDisplayName.bind(this);

        this.inputDisplayName = this.inputDisplayName.bind(this);
        this.inputCompany = this.inputCompany.bind(this);

    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(!prevProps.authReducer.profile.firstName && this.props.authReducer.profile.firstName){
            let userInfo = this.props.authReducer.profile;
            let countries = this.state.optionsCountries;
            let languageText = this.props.profile ? this.props.profile.language : 'EN';
            this.setState({
                userInfo: userInfo,
                displayName: userInfo.displayName ? userInfo.displayName : userInfo.firstName + ' ' + userInfo.lastName,
                givenName: userInfo.firstName,
                lastName: userInfo.lastName,
                country: countries.find((el)=>el.value===userInfo.country),
                userType: { value:userInfo.userType, label:language(languageText, userInfo.userType.toLowerCase()) },
                gender: userInfo.gender,
                language: userInfo.language,
                typeActivity: userInfo.activityType,
                company: userInfo.company ? userInfo.company : '',
                typeUnit: userInfo.unit,
                typeShareActivity: userInfo.activityShare,
                typeSharePhoto: userInfo.activityViewPhotoAndComment,
                isAllowFriendshipRequest: userInfo.acceptFriendship,
                avatarPreview: userInfo && userInfo.avatar ? userInfo.avatar : ''
            })
        }
    }

    componentDidMount() {
        const token = this.props.authReducer.token ? this.props.authReducer.token : '';
        if (token) {
            let userInfo = this.props.authReducer.profile;
            let countries = this.state.optionsCountries;
            let languageText = this.props.profile ? this.props.profile.language : 'EN';
            this.setState({
                userInfo: userInfo,
                displayName: userInfo.displayName ? userInfo.displayName : userInfo.firstName + ' ' + userInfo.lastName,
                givenName: userInfo.firstName,
                lastName: userInfo.lastName,
                country: countries.find((el)=>el.value===userInfo.country),
                userType: { value:userInfo.userType, label:language(languageText, userInfo.userType.toLowerCase()) },
                gender: userInfo.gender,
                language: userInfo.language,
                typeActivity: userInfo.activityType,
                company: userInfo.company ? userInfo.company : '',
                typeUnit: userInfo.unit,
                typeShareActivity: userInfo.activityShare,
                typeSharePhoto: userInfo.activityViewPhotoAndComment,
                isAllowFriendshipRequest: userInfo.acceptFriendship,
                avatarPreview: userInfo && userInfo.avatar ? userInfo.avatar : ''
            })
        }
    }

    handleSubmit = async () => {
        if (this.state.displayName === '') {
            this.setState({emptyDisplayName: true})
            return;
        }
        //if (this.state.gender !== 'female' && this.state.gender !== 'male') {
        //    this.setState({emptyGender: true})
        //    return;
        //}
        this.setState({showLoading: true});
        const { givenName, lastName, displayName, gender, company, typeActivity, typeUnit, typeShareActivity, typeSharePhoto, isAllowFriendshipRequest, country, userType } = this.state;
        let data = {
            firstName: givenName,
            lastName: lastName,
            displayName: displayName,
            country: country.value,
            userType: userType.value,
            language: this.state.language,
            company: company,
            activityType: typeActivity,
            unit: typeUnit,
            activityShare: typeShareActivity,
            activityViewPhotoAndComment: typeSharePhoto,
            acceptFriendship: isAllowFriendshipRequest,
        }
        if(gender && gender!=='null') data.gender = gender
        let languageText = this.state.language;
        let lbUpdateFailed = language(languageText,"update_profile_failed");
        let lbUpdateSuccessful= language(languageText,"update_profile_successful");
        if (this.state.isChangeAvatar) {
            let dataUpload = {
                image: this.state.avatarPreview
            }
            let promise1 = userManagementServices.updateProfile(data);
            let promise2 = userManagementServices.uploadAvatar(dataUpload);
            Promise.all([promise1, promise2]).then(response => {
                this.setState({showLoading: false});
                if((response[0] && response[0].errors) || (response[1] && response[1].errors)) {
                    console.log('error here: ', response)
                    Swal.fire({
                        title: '',
                        text: lbUpdateFailed,
                        icon: 'error',
                        confirmButtonColor: '#e60003',
                        confirmButtonText: language(languageText, 'ok')
                    })
                } else {
                    localStorage.setItem('user_avatar',response[1].avatar)
                    localStorage.setItem('user_name',response[0].displayName ? response[0].displayName : response[0].firstName + ' ' + response[0].lastName)
                    this.props.loadProfile()
                    Swal.fire({
                        title: '',
                        text: lbUpdateSuccessful,
                        icon: 'success',
                        confirmButtonColor: '#e60003',
                        confirmButtonText: language(languageText, 'ok')
                      }).then((result) => {
                        if (result.value) {
                            if (this.props.firstTimeLogin) {
                                this.props.history.push({
                                    pathname: '/active-events'
                                })
                            }
                        }
                    })
                }
            }).catch(err => {
                this.setState({showLoading: false});
                Swal.fire({
                    title: '',
                    text: lbUpdateFailed,
                    icon: 'error',
                    confirmButtonColor: '#e60003',
                    confirmButtonText: language(languageText, 'ok')
                })
                console.log('error is here: ', err)
            });
        }
        else {
            userManagementServices.updateProfile(data).then(response => {
                this.setState({showLoading: false});
                if(response && response.errors) {
                    console.log('error here: ', response)
                    Swal.fire({
                        title: '',
                        text: lbUpdateFailed,
                        icon: 'error',
                        confirmButtonColor: '#e60003',
                        confirmButtonText: language(languageText, 'ok')
                    })
                } else {
                    localStorage.setItem('user_name',response.displayName ? response.displayName : response.firstName + ' ' + response.lastName)
                    this.props.loadProfile()
                    Swal.fire({
                        title: '',
                        text: lbUpdateSuccessful,
                        icon: 'success',
                        confirmButtonColor: '#e60003',
                        confirmButtonText: language(languageText, 'ok')
                      }).then((result) => {
                        if (result.value) {
                            if (this.props.firstTimeLogin) {
                                this.props.history.push({
                                    pathname: '/active-events'
                                })
                            }
                        }
                    })
                }
            }).catch(err => {
                this.setState({showLoading: false});
                Swal.fire({
                    title: '',
                    text: lbUpdateFailed,
                    icon: 'error',
                    confirmButtonColor: '#e60003',
                    confirmButtonText: language(languageText, 'ok')
                })
                console.log('error is here: ', err)
            });
        }
    }

    handleBlurDisplayName = () => {
        let displayName = this.state.displayName;
        if (displayName.trim() === '') {
            this.setState({emptyDisplayName: true});
        }
    }

    handleChangeAllowFriendshipRequest = () => {
        this.setState({ isAllowFriendshipRequest: !this.state.isAllowFriendshipRequest })
    }

    handleChangeGender = (gender) => {
        this.setState({
            emptyGender: false,
            gender: gender
        })
    }

    handleChangeLanguage = (language) => {
        this.setState({language: language})
    }

    handleChangeActivity = (activity) => {
        this.setState({typeActivity: activity})
    }

    handleChangeUnit = (unit) => {
        this.setState({typeUnit: unit})
    }

    handleChangeTypeShareActivity = (typeShare) => {
        this.setState({ typeShareActivity: typeShare})
    }

    handleChangeTypeSharePhoto = (typeShare) => {
        this.setState({ typeSharePhoto: typeShare})
    }

    inputDisplayName(e) {
        let strValue = String(e.target.value);
        this.setState({
            emptyDisplayName: false,
            displayName: strValue
        })
    }

    inputCompany(e) {
        let strValue = String(e.target.value);
        this.setState({
            company: strValue
        })
    }

    handleChangeCountry = (value)=>{
        this.setState({country: value})
    }

    handleChangeUserType = (value)=>{
        this.setState({ userType: value})
    }

    handleChooseImageFile() {
        if (document.getElementById("selectImage")) document.getElementById("selectImage").click()
    }

    handleImageChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
          this.setState({
            avatarPreview: reader.result,
            isChangeAvatar: true
          });
        }
        if (file && file.type.match('image.*')) {
            reader.readAsDataURL(file)
        }
    }

    deleteIamge=()=> {
        if (this.state.avatarPreview) {
            this.setState({
                avatarPreview: null,
                isChangeAvatar: true
            })
        }
    }

    outApp = ()=>{
        confirm(()=>{
            userManagementServices.outUser(this.props.authReducer.userID).then(()=>{
                popup(
                    language(this.state.language,'out_app_success'),
                    'success',
                    ()=>{ this.props.logout('#/register') }
                )
            })
        }, this.props.profile.language)
    }

    render() {
        let disabledButton = false;
        if (this.state.emptyDisplayName || this.state.emptyGender) {
            disabledButton = true;
        }
        const avatarPreview = this.state.avatarPreview;
        let languageText = this.props.profile ? this.props.profile.language : 'EN';
        let optionsUserType = [ 
            {value:'Customer', label:language(languageText, "customer") },
            {value:'Staff', label: language(languageText, "staff") },
            {value:'Supplier', label:language(languageText, "supplier") },
            {value:'Family', label: language(languageText, "family") }
        ]
        return (
            // <Container maxWidth="sm">
            <div className="container pt-4">
                {
                    this.state.showLoading &&
                    <div className="overlay-loading text-center"><span className="spinner-1 inline-spinner "/>
                    </div>
                }
                <div className="">
                    {/* <div className="col-12 text-center align-items-center px-0 mt-3 mb-3">
                        <h2 className="page-title mb-0 text-uppercase font-weight-600 fz-24 text-center">My Profile</h2>
                    </div> */}
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group text-center">
                                <input
                                    //className="d-none"
                                    id='selectImage'
                                    hidden
                                    type="file"
                                    accept="image/x-png,image/jpg,image/jpeg"
                                    onChange={(e)=>this.handleImageChange(e)} />
                                <Tooltip title="Uploading your avatar" className="p-1 ml-5">
                                    <button type="button" className="" style={{ borderRadius: '50%', border: 'none'}} onClick={this.handleChooseImageFile}>
                                        {/* <img src={avatarPreview ? avatarPreview : IconAvatar} className="avatar-profile" alt="Avatar"/> */}
                                        <Avatar className="app-avatar" src={avatarPreview ? avatarPreview : IconAvatar} alt="Avatar"/>
                                    </button>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <IconButton aria-label="delete" onClick={this.deleteIamge}>
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <InputLabel className="label-input">{language(languageText,"display_name")}</InputLabel>
                                <Input
                                    type="text"
                                    id="displayName"
                                    className="form-control"
                                    onChange={this.inputDisplayName}
                                    onBlur={this.handleBlurDisplayName}
                                    value={this.state.displayName}
                                    placeholder="Display name"
                                />
                                <InputLabel className="label-input mt-2">Email: {this.props.profile.email}</InputLabel>
                                {this.state.emptyDisplayName ? <small className="d-block invalid-feedback">{language(languageText,"display_name_is_required")}</small> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <InputLabel className="label-input">{language(languageText,"country")}</InputLabel>
                                <Select
                                    autoComplete="new-password"
                                    options={this.state.optionsCountries}
                                    value={this.state.country}
                                    onChange={this.handleChangeCountry}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <InputLabel className="label-input">{language(languageText,"user_type")}</InputLabel>
                                <Select
                                    autoComplete="new-password"
                                    options={optionsUserType}
                                    value={this.state.userType}
                                    onChange={this.handleChangeUserType}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <InputLabel className="label-input">{language(languageText,"gender")}</InputLabel>
                            <div className="form-inline" style={{ marginBottom: '0', paddingBottom: '0'}}>
                                <div className="form-inline" style={{ marginBottom: '0', paddingBottom: '0'}} onClick={() => this.handleChangeGender('female')}>
                                    <input
                                        type="radio"
                                        className="form-control"
                                        name="typeFemale"
                                        value={'female'}
                                        checked={this.state.gender === 'female'}
                                        onChange={() => this.handleChangeGender('female')}
                                    />
                                    <InputLabel className="label-input pl-2 text-dark">
                                        {language(languageText,"female")}
                                    </InputLabel>
                                </div>
                                <div className="form-inline" style={{ marginBottom: '0', paddingBottom: '0'}} onClick={() => this.handleChangeGender('male')}>
                                    <input
                                        type="radio"
                                        className="form-control ml-4"
                                        name="typeMale"
                                        value={'male'}
                                        checked={this.state.gender === 'male'}
                                        onChange={() => this.handleChangeGender('male')}
                                    />
                                    <InputLabel className="label-input pl-2 text-dark">
                                        {language(languageText,"male")}
                                    </InputLabel>
                                </div>
                                <div className="form-inline" style={{ marginBottom: '0', paddingBottom: '0'}} onClick={() => this.handleChangeGender('non-binary')}>
                                    <input
                                        type="radio"
                                        className="form-control ml-4"
                                        name="typeNonBinary"
                                        value={'non-binary'}
                                        checked={this.state.gender === 'non-binary'}
                                        onChange={() => this.handleChangeGender('non-binary')}
                                    />
                                    <InputLabel className="label-input pl-2 text-dark">
                                        {language(languageText,"non_binary")}
                                    </InputLabel>
                                </div>
                            </div>
                            {this.state.emptyGender ? <small className="d-block invalid-feedback" style={{ marginBottom: '8px', marginTop: '0'}}>{language(languageText,"gender_is_required")}</small> : ''}
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '8px'}}>
                        <div className="col-12">
                            <InputLabel className="label-input">{language(languageText,"language")}</InputLabel>
                            <div className="form-group form-inline">
                                <div className="form-inline" onClick={() => this.handleChangeLanguage('DE')}>
                                    <input
                                        type="radio"
                                        className="form-control"
                                        name="languageDeutsch"
                                        value={'DE'}
                                        checked={this.state.language === 'DE'}
                                        onChange={() => this.handleChangeLanguage('DE')}
                                    />
                                    <InputLabel className="label-input pl-2 text-dark">
                                        {language(languageText, "deutsch")}
                                    </InputLabel>
                                </div>
                                <div className="form-inline" onClick={() => this.handleChangeLanguage('EN')}>
                                    <input
                                        type="radio"
                                        className="form-control ml-4"
                                        name="languageEnglish"
                                        value={'EN'}
                                        checked={this.state.language === 'EN'}
                                        onChange={() => this.handleChangeLanguage('EN')}
                                    />
                                    <InputLabel className="label-input pl-2 text-dark">
                                        {language(languageText,"english")}
                                    </InputLabel>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <InputLabel className="label-input">{language(languageText,"company")}</InputLabel>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={this.inputCompany}
                                    value={this.state.company}
                                    placeholder={language(languageText,"company")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <InputLabel className="label-input">{language(languageText,"my_activity")}</InputLabel>
                            <div className="form-group form-inline">
                                <div className="form-inline" onClick={() => this.handleChangeActivity('Running')}>
                                    <input
                                        type="radio"
                                        className="form-control"
                                        name="activityRunning"
                                        value={'Running'}
                                        checked={this.state.typeActivity === 'Running'}
                                        onChange={() => this.handleChangeActivity('Running')}
                                    />
                                    <InputLabel className="label-input pl-2 text-dark">
                                        {language(languageText,"running")}
                                    </InputLabel>
                                </div>
                                <div className="form-inline" onClick={() => this.handleChangeActivity('Walking')}>
                                    <input
                                        type="radio"
                                        className="form-control ml-4"
                                        name="activityWalking"
                                        value={'Walking'}
                                        checked={this.state.typeActivity === 'Walking'}
                                        onChange={() => this.handleChangeActivity('Walking')}
                                    />
                                    <InputLabel className="label-input pl-2 text-dark">
                                        {language(languageText,"walking")}
                                    </InputLabel>
                                </div>
                                <div className="form-inline" onClick={() => this.handleChangeActivity('Hiking')}>
                                    <input
                                        type="radio"
                                        className="form-control ml-4 "
                                        name="typeBusinessPartner"
                                        value={'Hiking'}
                                        checked={this.state.typeActivity === 'Hiking'}
                                        onChange={() => this.handleChangeActivity('Hiking')}
                                    />
                                    <InputLabel className="label-input pl-2 text-dark">
                                        {language(languageText,"hiking")}
                                    </InputLabel>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <InputLabel className="label-input">{language(languageText,"unit")}</InputLabel>
                            <div className="form-group form-inline">
                                <div className="form-inline" onClick={() => this.handleChangeUnit('KM')}>
                                    <input
                                        type="radio"
                                        className="form-control"
                                        name="typeUnitKm"
                                        value={'KM'}
                                        checked={this.state.typeUnit === 'KM'}
                                        onChange={() => this.handleChangeUnit('KM')}
                                    />
                                    <InputLabel className="label-input pl-2 text-dark">
                                        {language(languageText,"km")}
                                    </InputLabel>
                                </div>
                                <div className="form-inline" onClick={() => this.handleChangeUnit('MILE')}>
                                    <input
                                        type="radio"
                                        className="form-control ml-4"
                                        name="typeUnitMile"
                                        value={'MILE'}
                                        checked={this.state.typeUnit === 'MILE'}
                                        onChange={() => this.handleChangeUnit('MILE')}
                                    />
                                    <InputLabel className="label-input pl-2 text-dark">
                                        {language(languageText,"mile")}
                                    </InputLabel>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*
                    <div className="row">
                        <div className="col-12">
                            <InputLabel className="label-input">{language(languageText,"who_can_see_my_activity")}</InputLabel>
                            <div className="form-group form-inline">
                                <div className="form-inline" onClick={() => this.handleChangeTypeShareActivity('Public')}>
                                    <input
                                        type="radio"
                                        className="form-control cbox-register mt-2"
                                        name="typeAny"
                                        value={'Public'}
                                        checked={this.state.typeShareActivity === 'Public'}
                                        onChange={() => this.handleChangeTypeShareActivity('Public')}
                                    />
                                    <InputLabel className="label-input pl-2">
                                        {language(languageText,"any")}
                                    </InputLabel>
                                </div>
                                <div className="form-inline" onClick={() => this.handleChangeTypeShareActivity('Friends')}>
                                    <input
                                        type="radio"
                                        className="form-control cbox-register ml-4 mt-2"
                                        name="typeOnlyFriends"
                                        value={'Friends'}
                                        checked={this.state.typeShareActivity === 'Friends'}
                                        onChange={() => this.handleChangeTypeShareActivity('Friends')}
                                    />
                                    <InputLabel className="label-input pl-2">
                                        {language(languageText,"only_friends")}
                                    </InputLabel>
                                </div>
                                <div className="form-inline" onClick={() => this.handleChangeTypeShareActivity('Private')}>
                                    <input
                                        type="radio"
                                        className="form-control cbox-register ml-4 mt-2"
                                        name="typeJustMe"
                                        value={'Private'}
                                        checked={this.state.typeShareActivity === 'Private'}
                                        onChange={() => this.handleChangeTypeShareActivity('Private')}
                                    />
                                    <InputLabel className="label-input pl-2">
                                        {language(languageText,"just_me")}
                                    </InputLabel>
                                </div>
                            </div>
                        </div>
                    </div>
                    */}
                    <div className="row">
                        <div className="col-12">
                            <InputLabel className="label-input">{language(languageText,"who_can_see_my_uploaded_photos_and_my_comments")}</InputLabel>
                            <div className="form-group form-inline">
                                <div className="form-inline" onClick={() => this.handleChangeTypeSharePhoto('Public')}>
                                    <input
                                        type="radio"
                                        className="form-control"
                                        name="typeSharePhotoAny"
                                        value={'Public'}
                                        checked={this.state.typeSharePhoto === 'Public'}
                                        onChange={() => this.handleChangeTypeSharePhoto('Public')}
                                    />
                                    <InputLabel className="label-input pl-2 text-dark">
                                        {language(languageText,"any")}
                                    </InputLabel>
                                </div>
                                <div className="form-inline" onClick={() => this.handleChangeTypeSharePhoto('Friends')}>
                                    <input
                                        type="radio"
                                        className="form-control ml-4"
                                        name="typeSharePhotoOnlyFriends"
                                        value={'Friends'}
                                        checked={this.state.typeSharePhoto === 'Friends'}
                                        onChange={() => this.handleChangeTypeSharePhoto('Friends')}
                                    />
                                    <InputLabel className="label-input pl-2 text-dark">
                                        {language(languageText,"only_friends")}
                                    </InputLabel>
                                </div>
                                <div className="form-inline" onClick={() => this.handleChangeTypeSharePhoto('Private')}>
                                    <input
                                        type="radio"
                                        className="form-control ml-4"
                                        name="typeSharePhotoJustMe"
                                        value={'Private'}
                                        checked={this.state.typeSharePhoto === 'Private'}
                                        onChange={() => this.handleChangeTypeSharePhoto('Private')}
                                    />
                                    <InputLabel className="label-input pl-2 text-dark">
                                        {language(languageText,"just_me")}
                                    </InputLabel>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group form-inline">
                                    <div className="form-inline" onClick={this.handleChangeAllowFriendshipRequest}>
                                        <input
                                            type="checkbox"
                                            className="form-control cbox-register mt-2"
                                            //style={{width: 'auto', marginBottom: '.5rem'}}
                                            onChange={this.handleChangeAllowFriendshipRequest}
                                            checked={this.state.isAllowFriendshipRequest}
                                        />
                                        <InputLabel className="label-input pl-2 text-dark">
                                            {language(languageText,"allow_friendship_request")}
                                        </InputLabel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="text-center">
                                <Button type="button" variant="contained" color="primary" className="mr-3 backgroundColorPrimaryOverride" disabled={disabledButton} onClick={this.handleSubmit}>{language(languageText,"save")}</Button>
                            </div>
                        </div>
                        <div className="col-12 text-center mt-3 pb-1">
                            <Link className="mr-3" onClick={this.outApp} style={{cursor:'pointer',color:'#707070'}}>{language(languageText,"out_app")}</Link>
                        </div>
                    </div>
                </div>
            {/* </Container> */}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { authReducer } = state;
    return {
        authReducer: authReducer,
        profile: state.authReducer.profile,
        firstTimeLogin: authReducer.firstTimeLogin
    };
}

function mapDispatchToProps(dispatch){
    return{
        updateProfile: (data)=> dispatch({ type: userManagementConstant.GET_PROFILE_SUCCESS, user:data}),
        loadProfile: ()=> dispatch(authAction.getProfile()),
        logout: (link = null)=> dispatch(authAction.logout(link))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Profile);
