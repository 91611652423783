import { API } from '../services/api';
import { saveState, toastr } from '../helpers/toastr';
import language from '../helpers/language';

const createActivity = (data) =>{
  return (dispatch,getState) => {
    dispatch({ type: 'CREATE_ACTIVITY' })
    let lang = getState().authReducer.profile.language
    return API.post('/activities',null,data).then((res)=>{
      if(res.errors){
        toastr('error',language(lang,'activityDetail.create_activity_failed'))
        dispatch(createActivityFail(res.errors.msg.replace(/_/g, ' ')))
      }else{
        toastr('success',language(lang,'activityDetail.create_activity_success'))
        dispatch(createActivitySuccess(res));
      }
    }).catch((error)=>{
      toastr('error',language(lang,'activityDetail.create_activity_failed'))
      dispatch(createActivityFail('Failed to create activity!'))
    })
  };
}

const createActivitySuccess = (data) => {
  return { type: 'CREATE_ACTIVITY_SUCCESS', payload: { data: data } }
}

const createActivityFail = (error) => {
  return { type: 'CREATE_ACTIVITY_FAIL', payload: { error: error } }
}

const updateActivity = (data, mess) =>{
  return (dispatch,getState) => {
    dispatch({ type: 'UPDATE_ACTIVITY' })
    let lang = getState().authReducer.profile.language
    API.patch('/activities/'+data.id,null,data).then((res)=>{
      if(res.errors){
        toastr('error',language(lang,'activityDetail.update_activity_failed'))
        dispatch(updateActivityFail(res.errors.msg.replace(/_/g, ' ')))
      }else{
        toastr('success',language(lang,'activityDetail.update_activity_success'))
        dispatch(updateActivitySuccess(res, mess));
      }
    }).catch((error)=>{
      toastr('error',language(lang,'activityDetail.update_activity_failed'))
      dispatch(updateActivityFail(mess.updateFailed))
    })
  };
}

const updateActivitySuccess = (data, mess) => {
  return { type: 'UPDATE_ACTIVITY_SUCCESS', payload: { data: data, mess: mess } }
}

const updateActivityFail = (error) => {
  return { type: 'UPDATE_ACTIVITY_FAIL', payload: { error: error } }
}

const deleteActivity = (data) =>{
  return (dispatch,getState) => {
    dispatch({ type: 'DELETE_ACTIVITY' })
    let lang = getState().authReducer.profile.language
    API.del('/activities/'+data._id).then((res)=>{
      if(res.errors){
        toastr('error',language(lang,'activityDetail.delete_activity_failed'))
        dispatch(deleteActivityFail(res.errors.msg.replace(/_/g, ' ')))
      }else{
        toastr('success',language(lang,'activityDetail.delete_activity_success'))
        dispatch(deleteActivitySuccess(data));
      }
    }).catch((error)=>{
      toastr('error',language(lang,'activityDetail.delete_activity_failed'))
      dispatch(deleteActivityFail('Failed to delete activity!'))
    })
  };
}

const deleteActivitySuccess = (data) => {
  return { type: 'DELETE_ACTIVITY_SUCCESS', payload: { data: data } }
}

const deleteActivityFail = (error) => {
  return { type: 'DELETE_ACTIVITY_FAIL', payload: { error: error } }
}

const createDraftActivity = (data) => {
  return (dispatch,getState)=>{
    dispatch({ type:'CREATE_DRAFT_ACTIVITY', payload:{ data:data } })
    saveState(getState())
  }
}

const updateDraftActivity = (data, mess) => {
  return (dispatch,getState)=>{
    dispatch({ type:'UPDATE_DRAFT_ACTIVITY', payload:{ data:data, mess: mess } })
    saveState(getState())
  }
}

const deleteDraftActivity = (data)=> {
  return (dispatch,getState)=>{
    dispatch({ type:'DELETE_DRAFT_ACTIVITY', payload:{ data:data } })
    saveState(getState())
  }
} 

const sendCreateDraftActivity = (data)=>{
  return (dispatch,getState) => {
    data.forEach((el)=>{
      dispatch({ type: 'SEND_CREATE_DRAFT_ACTIVITY', payload:{ draft:el } })
      let activity = {...el}
      delete activity._id
      delete activity.event_id
      API.post('/activities',null,activity).then((res)=>{
        if(res.errors)
          dispatch(createDraftFail(el))
        else{
          dispatch(createDraftSuccess(el,res));
          saveState(getState())
        }
      }).catch((error)=>{
        dispatch(createDraftFail(el))
        saveState(getState())
      })
    })
  };
}

const createDraftSuccess = (draft,activity)=>{
  return { type:'SEND_CREATE_DRAFT_ACTIVITY_SUCCESS', payload:{ draft:draft, data: activity }}
}
const createDraftFail = (draft)=>{
  return { type:'SEND_CREATE_DRAFT_ACTIVITY_FAIL', payload:{ draft:draft }}
}

export const activityAction = {
  createActivity,
  updateActivity,
  deleteActivity,
  createDraftActivity,
  updateDraftActivity,
  deleteDraftActivity,
  sendCreateDraftActivity
};
      