import React, { Component } from 'react';
import {connect} from "react-redux";
import Swal from 'sweetalert2';
import Select from 'react-select';
import { InputLabel } from '@material-ui/core';
import NotificationsTable from './NotificationsTable';
import NotificationsToolbar from './NotificationsToolbar';
import NotificationDialog from './NotificationDialog';
import {communicateAction} from '../../../actions/communicate-action';
import {communicateServices} from '../../../services/communicateService';
import language from '../../../helpers/language';

class NotificationList extends Component {
  constructor(props) {
      super(props)

      this.state = {
        showFormDetail: false,
        listCommunicates: [],
        page: 1,
        limit: 10,
        totalPages: 0,
        totalDocs: 0,
        pageInput: 1,
        limitSelected: {value: 10, label: language('DE', "show_10_per_page")},
        keySearch: '',
      }
  }

  componentDidMount(){
    this.loadDataComunicates(this.state.keySearch, this.state.page, this.state.limit);
  }

  loadDataComunicates = (keySearch, page, limit) => {
    this.setState({showLoading: true});
    const { languagetext } = this.props;
    this.props.dispatch(communicateAction.getAllCommunicates(keySearch, page, limit)).then(response => {
        this.setState({showLoading: false});
        if(response && response.errors) {
            this.setState({
              listCommunicates: [],
                totalDocs: 0,
                totalPages: 0,
                page: 1,
                pageInput: 1,
                limit: 10
            })
        } else {
            let temp = {};
            if (limit === 10) {
                temp = {value: 10, label: language(languagetext, "show_10_per_page")}
            }
            else if (limit === 25) {
                temp = {value: 25, label: language(languagetext, "show_25_per_page")}
            }
            else if (limit === 50) {
                temp = {value: 50, label: language(languagetext, "show_50_per_page")}
            }
            this.setState({
                listCommunicates: response.docs,
                totalDocs: response.totalDocs,
                totalPages: response.totalPages,
                page: page,
                pageInput: page,
                limit: limit,
                limitSelected: temp
            })
        }
    }).catch(err => {
        this.setState({
            showLoading: false,
            listCommunicates: [],
            totalDocs: 0,
            totalPages: 0,
            page: 1,
            pageInput: 1,
            limit: 10
        });
        console.log('error is here: ', err)
    });
  }

  openModalDetail = () => {
    this.setState({showFormDetail: true})
  }

  closeModal = () => {
    this.setState({
        showFormDetail: false
    });
}

  saveCommunicateForm = (data) => {
    this.setState({showLoading: true});
    communicateServices.createCommunicate(data).then(res => {
        this.setState({showFormDetail: false})
        this.loadDataComunicates(this.state.keySearch, 1, this.state.limit)
    })
  }

  confirmDeleteRow = (id) => {
    if (!id || id < 1) return;

    const { languagetext } = this.props;
    Swal.fire({
        title: '',
        text: language(languagetext,"delete_item_confirmation"),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: language(languagetext,"yes"),
        cancelButtonText: language(languagetext,"no"),
        confirmButtonColor: '#e60003',
        cancelButtonColor: '#707070',
    }).then((result) => {
      if (result.value) {
        this.setState({showLoading: true})
          communicateServices.deleteCommunicate(id).then(res => {
            this.loadDataComunicates(this.state.keySearch, 1, this.state.limit)
        })
      }
    })
  }

  handleSearchAction = (e) => {
    e.preventDefault();
    this.loadDataComunicates(this.state.keySearch, 1, this.state.limit);
  }

  inputKeySearch = (e) => {
    let strValue = String(e.target.value);
    this.setState({ keySearch: strValue})
  }

    //Pagination
    PaginationSizeChange = (value) => {
        if (this.state.limit === value) return;

        // update the list with limit
        if (!value || isNaN(value)) value = 10;

        this.loadDataComunicates(this.state.keySearch, 1, value);
    }

    handleOnClickPrevious = (e) => {
        if (this.state.page > 1) {
            var prevPageNum = parseInt(this.state.page) - 1;

        this.loadDataComunicates(this.state.keySearch, prevPageNum, this.state.limit);
        }
    }

    handleOnClickNext = (e) => {
        if (this.state.page < this.state.totalPages) {
            var nextPageNum = parseInt(this.state.page) + 1;

        this.loadDataComunicates(this.state.keySearch, nextPageNum, this.state.limit);
        }
    }

    handlePaginationInputChange = (e) => {
        const { value } = e.target;
        this.setState({
            pageInput: value
        })
    }

    handleKeyPressPaginationInput = (e) => {
        var totalPages = this.state.totalPages

        if (e.key === 'Enter' || e.key === 'enter') {
            if (e.target.value) {
                let value = Number(e.target.value);
                if (value < 1) value = 1;
                else if (value > totalPages) value = totalPages;

                this.loadDataComunicates(this.state.keySearch, value, this.state.limit);
            }
        }
    }

    handlePaginationInput = (e) => {
        var totalPages = this.state.totalPages

        if (e.target.value) {
            let value = Number(e.target.value);
            if (value < 1) value = 1;
            else if (value > totalPages) value = totalPages;

            this.loadDataComunicates(this.state.keySearch, value, this.state.limit);
        }
    }

  render() {
  const { listCommunicates, showLoading, page, limit, totalPages, showFormDetail, keySearch } = this.state;
  const { languagetext } = this.props;
  var preClass = '';
  var nextClass = '';
  if (page < 2) {
      preClass = 'disabled';
  }
  if (page >= totalPages) {
      nextClass = 'disabled';
  }

  return (
    <div className=''>
        {
            showLoading &&
            <div className="overlay-loading text-center"><span className="spinner-1 inline-spinner "/>
            </div>
        }
      <NotificationsToolbar languagetext={languagetext} keySearch={keySearch} inputKeySearch={this.inputKeySearch} handleSearch={this.handleSearchAction} handleClickAdd={this.openModalDetail} />
      <div className="" style={{height: 'auto', maxWidth:"100vw"}}>
        <div className="content-table-top">
            <div className="row">
                <div className="col-xs-12 col-sm-12">
                    <div className="table-top-pagination pagination-top">
                        <div className="pagination-size">
                            <Select
                                name="paginationSize"
                                options={[
                                    {label: language(languagetext, "show_10_per_page"), value: 10},
                                    {label: language(languagetext, "show_25_per_page"), value: 25},
                                    {label: language(languagetext, "show_50_per_page"), value: 50},
                                ]}
                                value={this.state.limitSelected}
                                onChange={(p) => {
                                    this.PaginationSizeChange(p.value);
                                }}
                            />
                        </div>
                        <ul className="pagination">
                            <li><span className="btn p-btn" onClick={this.handleOnClickPrevious}
                                        disabled={(preClass === 'disabled') ? 'disabled' : ''}>&lt;</span>
                            </li>
                            <li className="option-control">
                                <input type="number" name="currentPage"
                                        onChange={this.handlePaginationInputChange}
                                        onKeyPress={this.handleKeyPressPaginationInput}
                                        value={totalPages === 0 ? 0 : this.state.pageInput}
                                        onBlur={this.handlePaginationInput}
                                        className="pagination-input form-control"/> {language(languagetext, "myActivity.of")} {totalPages} {language(languagetext, "pages")}
                                <span className="error"></span>
                            </li>
                            <li><span className="btn p-btn" onClick={this.handleOnClickNext}
                                        disabled={(nextClass === 'disabled') ? 'disabled' : ''}>&gt;</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <NotificationsTable languagetext={languagetext} datas={listCommunicates} page={page} limit={limit} onClickDeleteCommunicate={this.confirmDeleteRow}/>
        {showFormDetail && <NotificationDialog languagetext={languagetext} handleClose={this.closeModal} handleSave={this.saveCommunicateForm} />}
        {
            (!listCommunicates || listCommunicates.length === 0) &&
            <div className="row">
                <div className="col-12">
                    <div className="form-group text-center mt-3">
                        <InputLabel className="label-input">{language(languagetext, "no_record_found")}</InputLabel>
                    </div>
                </div>
            </div>
        }
      </div>
    </div>
  )};
};

function mapStateToProps(state) {
  return { ...state.communicateReducer }
}

export default connect(mapStateToProps)(NotificationList);
