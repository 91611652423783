import React, { Component } from "react"
import { Card, Avatar, Button, Grid, FormLabel, Popover } from "@material-ui/core"
import IconAvatar from "../../assets/avatar_icon.png"
import PersonAdd from "@material-ui/icons/PersonAdd"
import Person from "@material-ui/icons/Person"
import { connect } from "react-redux"
import { friendServices } from "../../services/friendService"
import Swal from "sweetalert2"
import { authAction } from "../../actions/auth-action"
import language from "../../helpers/language"
import numeral from "numeral"

class Competitor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pending: false,
      showLoading: false,
      me: { rank: "n/a" },
      competitor: { rank: "n/a" },
      data: {},
      anchorEl: null
    }
  }

  componentDidMount() {
    if (this.props.profile.language === "DE") numeral.locale("de")
    else numeral.locale("en")
    let competitor = localStorage.getItem("competitor")
    if (competitor) this.setState({ data: JSON.parse(competitor) })
    if (this.props.match.params.event_id)
      friendServices.getCompetitorRanking(this.props.match.params.id, this.props.match.params.event_id).then((res) => {
        let me = this.state.me
        let competitor = this.state.competitor
        if (res["map"]) {
          res.map((el) => {
            if (el && el.creatorId && el.creatorId === this.props.userID) me = el
            if (el && el.creatorId && el.creatorId !== this.props.userID) competitor = el
            return null
          })
          this.setState({ me: me, competitor: competitor })
        }
      })
  }

  handlePopoverClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handlePopoverClose = () => {
    this.setState({ anchorEl: null })
  }

  numberFormat = (num) => {
    if (isNaN(num)) return num
    return numeral(num).format("0,0.[0]")
  }

  handleAddFriend = (id) => {
    this.setState({ showLoading: true })
    let languageText = this.props.profile ? this.props.profile.language : "EN"
    friendServices
      .addFriends(id)
      .then((res) => {
        this.setState({ showLoading: false })
        if (res && res.errors) {
          Swal.fire({
            text: language(languageText, "label_request_friend_failed"),
            icon: "error",
            confirmButtonColor: "#e60003",
            confirmButtonText: language(languageText, "ok")
          })
        } else {
          this.props.getProfileFriendItem()
          this.setState({ pending: true })
          Swal.fire({
            text: language(languageText, "label_request_friend_successful"),
            icon: "info",
            confirmButtonColor: "#e60003"
          })
        }
      })
      .catch((err) => {
        this.setState({ showLoading: false })
        Swal.fire({
          text: language(languageText, "label_request_friend_failed"),
          icon: "error",
          confirmButtonColor: "#e60003",
          confirmButtonText: language(languageText, "ok")
        })
      })
  }

  checkOutApp = (displayName) => {
    if (displayName && (displayName.toLowerCase() === "anonymous" || displayName.toLowerCase() === "logged out"))
      return true
    else return false
  }

  viewName = () => {
    //const { userID } = this.props;
    let languageText = this.props.profile ? this.props.profile.language : "EN"
    const { data } = this.state
    let name = data.displayName ? data.displayName : data.firstName + data.lastName
    //if(data.activityShare === 'Private' && data._id !== userID)
    //    name = 'Anonymous'
    //if(data.activityShare === 'Friends' && data.isFriend !== "YES" && data._id !== userID)
    //    name = 'Anonymous'
    if (this.checkOutApp(data.displayName)) name = language(languageText, "logged_out")
    return name
  }

  render() {
    let languageText = this.props.profile ? this.props.profile.language : "EN"
    const { me, competitor, data } = this.state
    const { numberFormat, checkOutApp, viewName } = this
    return (
      <div className="container text-center pt-4">
        <Card variant="outlined" className="mt-3 p-2">
          <Grid container alignItems="center" className="p-2">
            <Grid container item xs={4}>
              <Avatar alt="Avatar " src={data ? data.avatar : IconAvatar} className="newsfeed-avatar mr-2 ml-2" />
            </Grid>
            <Grid container item xs={8}>
              <div className="info d-flex justify-content-between w-100">
                <h5 className="text-left header-title">{viewName()}</h5>
              </div>
              {this.props.userID === data._id || checkOutApp(data.displayName) ? null : data.isFriend === "YES" ? (
                <Button
                  type="button"
                  variant="contained"
                  color="default"
                  className="float-right"
                  disabled
                  style={{ fontSize: "13px" }}
                >
                  <Person className="mr-2" />
                  {language(languageText, "my_friend")}
                </Button>
              ) : !data.acceptFriendship ? (
                <>
                  <Button
                    id="add-friend-btn"
                    type="button"
                    variant="contained"
                    color="default"
                    className="float-right disabled-button"
                    onClick={this.handlePopoverClick}
                    disabled={false}
                    style={{ fontSize: "13px" }}
                  >
                    <PersonAdd className="mr-2" />
                    {language(languageText, "add_as_friend")}
                  </Button>
                  <Popover
                    id={Boolean(this.state.anchorEl) ? "no-friend-requests" : undefined}
                    open={Boolean(this.state.anchorEl)}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handlePopoverClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                  >
                    <div className="p-2 bg-secondary text-white">
                      {language(languageText, "friendship_request_not_allowed")}
                    </div>
                  </Popover>
                </>
              ) : data.isFriend === "NO" && !this.state.pending ? (
                <>
                  <Button
                    id="add-friend-btn"
                    type="button"
                    variant="contained"
                    color="default"
                    className="float-right"
                    onClick={() => this.handleAddFriend(data._id)}
                    disabled={this.state.showLoading || !data.acceptFriendship}
                    style={{ fontSize: "13px" }}
                  >
                    <PersonAdd className="mr-2" />
                    {language(languageText, "add_as_friend")}
                  </Button>
                </>
              ) : this.state.pending ? (
                <Button
                  type="button"
                  variant="contained"
                  color="default"
                  className="float-right"
                  style={{ fontSize: "13px" }}
                >
                  <Person className="mr-2" />
                  {language(languageText, "friend_request_pending")}
                </Button>
              ) : null}
            </Grid>
          </Grid>
          {this.props.userID === data._id || !this.props.match.params.event_id ? null : (
            <Grid container alignItems="center" className="p-2">
              <Grid container item xs={5}>
                <FormLabel>{language(languageText, "rank") + ": " + competitor.rank}</FormLabel>
              </Grid>
              <Grid container item xs={7}>
                <FormLabel>
                  {language(languageText, "performance")}:{" "}
                  {this.props.profile.unit === "KM"
                    ? numberFormat(competitor.distanceKm)
                    : numberFormat(competitor.distanceMile)}{" "}
                  {this.props.profile.unit === "KM" ? "km" : "mile"}
                </FormLabel>
              </Grid>
            </Grid>
          )}
          {!this.props.match.params.event_id ? null : (
            <Grid container alignItems="center" className="p-2">
              <Grid container item xs={5}>
                <FormLabel>{language(languageText, "my_rank") + ": " + me.rank}</FormLabel>
              </Grid>
              <Grid container item xs={7}>
                <FormLabel>
                  {language(languageText, "performance")}:{" "}
                  {this.props.profile.unit === "KM" ? numberFormat(me.distanceKm) : numberFormat(me.distanceMile)}{" "}
                  {this.props.profile.unit === "KM" ? "km" : "mile"}
                </FormLabel>
              </Grid>
            </Grid>
          )}
        </Card>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  const { authReducer } = state
  return {
    userID: authReducer.userID,
    profile: authReducer.profile
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getProfileFriendItem: () => dispatch(authAction.getProfileFriendItem())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Competitor)
