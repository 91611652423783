import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText, Avatar, Divider, Collapse  } from '@material-ui/core';
import { authAction } from '../actions/auth-action';

import TimelineIcon from '@material-ui/icons/Timeline';
import SearchIcon from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/Person';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import EventNoteIcon from '@material-ui/icons/EventNote';
import RemoveIcon from '@material-ui/icons/Remove';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconAvatar from "../assets/avatar_icon.png";
import NewsIcon from '@material-ui/icons/Web';
import language from '../helpers/language';
import DescriptionIcon from '@material-ui/icons/Description';

export default class Sidebar extends Component {

    constructor(props){
        super(props)
        this.state = {
            open: false
        }
    }
    handleLogout = () => {
        if (this.props.authed) {
            this.props.dispatch(authAction.logout());
        }
    }

    clearData = ()=>{
        window.localStorage.removeItem('initialScrollY_newsfeed')
    }

    render() {
        const { path, profile } = this.props
        const { open } = this.state
        const userAvatar = localStorage.getItem('user_avatar');
        const userName = localStorage.getItem('user_name');
        let languageText = profile ? profile.language : 'EN';
        return (
            <List>
                <ListItem className="text-center flex-column colum-avatar">
                    <Avatar className="app-avatar" src={userAvatar ? userAvatar : IconAvatar}/>
                    <h4><b>{userName ? userName : 'User name'}</b></h4>
                </ListItem>
                <Divider/>
                <ListItem button component={Link} to="/newsfeed" selected={path==="/newsfeed"} onClick={this.clearData}>
                    <ListItemIcon><NewsIcon/></ListItemIcon>
                    <ListItemText>{language(languageText, 'news')}</ListItemText>
                </ListItem>
                <ListItem button component={Link} to="/progress" selected={path==="/progress"}>
                    <ListItemIcon><TimelineIcon/></ListItemIcon>
                    <ListItemText>{language(languageText, 'progress')}</ListItemText>
                </ListItem>
            {/*
                <ListItem button component={Link} to="/ranking" selected={path==="/ranking"}>
                    <ListItemIcon><EqualizerIcon/></ListItemIcon>
                    <ListItemText>{language(languageText, 'ranking')}</ListItemText>
                </ListItem>
            */}
                <ListItem button component={Link} to="/active-events" selected={path==="/active-events"}>
                    <ListItemIcon><EventNoteIcon/></ListItemIcon>
                    <ListItemText>{language(languageText, 'active_events')}</ListItemText>
                </ListItem>
                <ListItem button component={Link} to="/teams" selected={path==="/teams"}>
                    <ListItemIcon><EventNoteIcon/></ListItemIcon>
                    <ListItemText>{language(languageText, 'teams')}</ListItemText>
                </ListItem>
                <ListItem button component={Link} to="/search" selected={path==="/search"}>
                    <ListItemIcon><SearchIcon/></ListItemIcon>
                    <ListItemText>{language(languageText, 'search')}</ListItemText>
                </ListItem>
                <ListItem button selected={path==="/profile"} onClick={()=>this.setState({ open:!open })}>
                    <ListItemIcon><PersonIcon/></ListItemIcon>
                    <ListItemText>{language(languageText, 'profile')}</ListItemText>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button component={Link} to="/profile" selected={path==="/profile"} className="pl-4">
                            <ListItemIcon><RemoveIcon/></ListItemIcon>
                            <ListItemText>{language(languageText, 'my_profile')}</ListItemText>
                        </ListItem>
                        <ListItem button component={Link} to="/changePassword" selected={path==="/changePassword"} className="pl-4">
                            <ListItemIcon><RemoveIcon/></ListItemIcon>
                            <ListItemText>{language(languageText, 'change_password')}</ListItemText>
                        </ListItem>
                    </List>
                </Collapse>
                <a href="https://www.zwickroell.com/de-de/umwelt-soziales/zwick-runs-the-world" target="_blank" rel="noopener noreferrer" style={{color:'inherit',textDecoration: 'none'}}>
                    <ListItem>
                        <ListItemIcon><DescriptionIcon/></ListItemIcon>
                        <ListItemText>{language(languageText, 'faq_about')}</ListItemText>
                    </ListItem>
                </a>
                <ListItem button component={Link} to="/term" selected={path==="/term"}>
                    <ListItemIcon><DescriptionIcon/></ListItemIcon>
                    <ListItemText>{language(languageText, 'term')}</ListItemText>
                </ListItem>
                <a href="https://www.zwickroell.com/de-de/impressum" target="_blank" rel="noopener noreferrer" style={{color:'inherit',textDecoration: 'none'}}>
                    <ListItem>
                        <ListItemIcon><DescriptionIcon/></ListItemIcon>
                        <ListItemText>{language(languageText, 'imprint')}</ListItemText>
                    </ListItem>
                </a>
                <a href="https://www.zwickroell.com/de-de/datenschutz" target="_blank" rel="noopener noreferrer" style={{color:'inherit',textDecoration: 'none'}}>
                <ListItem>
                    <ListItemIcon><DescriptionIcon/></ListItemIcon>
                    <ListItemText>{language(languageText, 'privacy')}</ListItemText>
                </ListItem>
                </a>
                <ListItem onClick={this.handleLogout} style={{cursor:'pointer'}}>
                    <ListItemIcon><PowerSettingsNew/></ListItemIcon>
                    <ListItemText>{language(languageText, 'logout')}</ListItemText>
                </ListItem>
            </List>
        );
    }
}
