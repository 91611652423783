import React, { Component } from 'react';
import { connect } from "react-redux";

class EventDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            eventName: '',
            eventID: ''
        }
    }

    componentDidMount(){
        console.log('Kiem tra props event detail:  ', this.props)
        if (this.props.match && this.props.match.params) {
            let paramID = this.props.match.params.id;
            let paramName = this.props.match.params.name;
            console.log('check id from param: ', paramID, paramName);
            this.setState({
                eventID: paramID ? paramID : '',
                eventName: paramName ? paramName : 'Event Detail'
            })
        }
    }

    render() {
        const {eventName } = this.state;
        return (
            <div className="container text-center pt-4">
                <h3>{eventName}</h3>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(EventDetail);
