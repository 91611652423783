import React, {Component} from 'react';
import {
    Paper,
    Avatar,
    TextField,
    IconButton,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    CircularProgress
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import SendIcon from '@material-ui/icons/Send';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as moment from 'moment';
import language from '../../helpers/language';
import numeral from 'numeral';

export default class Feed extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loadingLike:false,
            loadingComment:false,
            viewLike:false,
            form: {
                activityId: props.newsfeed._id,
                content: ''
            }
        }
    }

    componentDidMount(){
        if(this.props.profile.language === 'DE')
            numeral.locale('de');
        else numeral.locale('en')
    }

    numberFormat = (num)=>{
        return numeral(num).format('0,0.[0]')
    }

    changeContent = (e) => {
        this.setState({form: {...this.state.form, content: e.target.value}})
    }

    throttleLike
    handleLikeClick = ()=>{
        clearTimeout(this.throttleLike)
        this.throttleLike = setTimeout(()=>{
            this.setState({ loadingLike: true })
            if(this.props.newsfeed.isLiked)
                this.props.unlike(this.props.newsfeed._id).then(()=>{
                    this.setState({ loadingLike: false})
                })
            else
                this.props.like(this.props.newsfeed._id).then(()=>{
                    this.setState({ loadingLike: false})
                })
        },200)
    }
    
    submit = () => {
        this.setState({ loadingComment:true })
        this.props.submit(this.state.form).then(()=>{

            this.setState({
                loadingComment:false,
                form: {
                    ...this.state.form,
                    content: ''
                }
            })
        })
    }

    checkOutApp = (displayName)=>{
        if(displayName && (displayName.toLowerCase() === 'anonymous' || displayName.toLowerCase() === 'logged out'))
            return true
        else return false
    }

    renderComments = () => {
        const {newsfeed, profile} = this.props
        const {viewLike} = this.state
        if (!viewLike && newsfeed.comments)
            return newsfeed.comments.map((comment, index) => {
                if (index <= 4)
                    return (
                        <div key={comment._id} className="p-2">
                            <div className="d-flex">
                                <Avatar className="mr-2 newsfeedAvatarBorder" alt={this.checkOutApp(comment.commentator.displayName)?language(profile.language,"newsfeed.like"):comment.commentator.displayName}
                                        src={comment.commentator.avatar}/>
                                <div className="bg-light p-2 rounded text-left text-comment"
                                     style={{wordBreak: 'break-word', color: "#262626"}}>
                                    <b>{this.checkOutApp(comment.commentator.displayName)?language(profile.language,"logged_out"):comment.commentator.displayName}</b><br/>
                                    {comment.content}
                                </div>
                            </div>
                        </div>
                    )
                else return null
            })
        else if(viewLike && newsfeed.likes)
            return newsfeed.likes.map((like, index) => {
                if (index <= 4)
                    return (
                        <div key={like._id} className="p-2">
                            <div className="d-flex">
                                <Avatar className="mr-2 newsfeedAvatarBorder" alt={like.userLike.displayName}
                                        src={like.userLike.avatar}/>
                                <div className="bg-light p-2 rounded text-left text-comment"
                                    style={{wordBreak: 'break-word', color: "#262626"}}>
                                    <b>{this.checkOutApp(like.userLike.displayName)?language(profile.language,"logged_out"):like.userLike.displayName}</b><br/>
                                    <ThumbUpAltOutlinedIcon className="color-red"/>
                                </div>
                            </div>
                        </div>
                    )
                else return null
            })
    }

    renderCommentsMore = () => {
        const { newsfeed, profile } = this.props
        const { viewLike } = this.state
        if (!viewLike && newsfeed.comments && newsfeed.comments.length > 5)
            return (
                <ExpansionPanel className="m-0">
                    <ExpansionPanelSummary className="textLoadmore" expandIcon={<ExpandMoreIcon/>}>More
                        comments</ExpansionPanelSummary>
                    <ExpansionPanelDetails className="p-0 d-block">
                        {newsfeed.comments.map((comment, index) => {
                            if (index > 4)
                                return (
                                    <div key={comment._id} className="p-2">
                                        <div className="d-flex">
                                            <Avatar className="mr-2 newsfeedAvatarBorder"
                                                    alt={this.checkOutApp(comment.commentator.displayName)?language(profile.language,"logged_out"):comment.commentator.displayName}
                                                    src={comment.commentator.avatar}/>
                                            <div className="bg-light p-2 rounded text-left text-comment"
                                                 style={{wordBreak: 'break-word', color: "#262626"}}>
                                                <b>{this.checkOutApp(comment.commentator.displayName)?language(profile.language,"logged_out"):comment.commentator.displayName}</b><br/>
                                                {comment.content}
                                            </div>
                                        </div>
                                    </div>
                                )
                            else return null
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            )
        if (viewLike && newsfeed.likes && newsfeed.likes.length > 5)
            return (
                <ExpansionPanel className="m-0">
                    <ExpansionPanelSummary className="textLoadmore" expandIcon={<ExpandMoreIcon/>}>More
                        likes</ExpansionPanelSummary>
                    <ExpansionPanelDetails className="p-0 d-block">
                        {newsfeed.likes.map((like, index) => {
                            if (index > 4)
                                return (
                                    <div key={like._id} className="p-2">
                                        <div className="d-flex">
                                            <Avatar className="mr-2 newsfeedAvatarBorder"
                                                    alt={like.userLike.displayName}
                                                    src={like.userLike.avatar}/>
                                            <div className="bg-light p-2 rounded text-left text-comment"
                                                 style={{wordBreak: 'break-word', color: "#262626"}}>
                                                <b>{like.userLike.displayName}</b><br/>
                                                <ThumbUpAltOutlinedIcon className="color-red"/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            else return null
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            )
    }

    viewPhotoAndComment =()=>{
        const { newsfeed, profile, userID } = this.props
        let alownType = newsfeed.creatorId.activityViewPhotoAndComment
        let isMe = newsfeed.creatorId._id === userID
        let isFriend = profile.friends.find((el)=>{ return (newsfeed.creatorId._id===el.receiver._id || newsfeed.creatorId._id===el.sender._id) })
        if(alownType === "Friends")
            return isMe || isFriend
        else if(alownType === "Public")
            return true
        else return isMe
    }

    render() {
        const { newsfeed, profile, showFull } = this.props
        const { submit, changeContent, renderComments, renderCommentsMore, handleLikeClick, viewPhotoAndComment, numberFormat } = this
        const { form, loadingComment, loadingLike } = this.state
        return (
            <Paper elevation={3} className="mt-2 p-2">
                <div className="d-flex justify-content-between">
                    <div style={{flexGrow:1,maxWidth:'70%'}}>
                        <div to={"newsfeed/"+newsfeed._id}>
                            <div className="d-flex">
                                <div className="d-flex">
                                    <Link to={"newsfeed/"+newsfeed._id} style={{pointerEvents:showFull?'none':'',color: 'inherit', textDecoration: 'inherit'}}>
                                        <Avatar alt="Remy Sharp" src={newsfeed.creatorId.avatar?newsfeed.creatorId.avatar:''}
                                            className="newsfeed-avatar newsfeedAvatarBorder mr-2"/>
                                    </Link>
                                    <div style={{flexGrow: 1}}>
                                        <Link to={"newsfeed/"+newsfeed._id} style={{pointerEvents:showFull?'none':'',color: 'inherit', textDecoration: 'inherit'}}>
                                            <div className="d-flex justify-content-between">
                                                <div className="mr-2" style={{flexGrow:1}}>
                                                    <div className="d-flex justify-content-between pt-1">
                                                        <h6 className="text-left" style={{wordBreak:'break-word'}}><b>{this.checkOutApp(newsfeed.creatorId.displayName)?language(profile.language,"logged_out"):newsfeed.creatorId.displayName}</b></h6>
                                                    </div>
                                                    <div className="d-flex" >
                                                        <h6 className="text-left mr-3" style={{fontSize:"12px"}}>{moment(newsfeed.date).format("DD/MM/YYYY")}</h6>
                                                        <div className="d-flex">
                                                            {profile.unit === "KM" ? <h6 style={{whiteSpace:'nowrap',fontSize:"12px"}}>{numberFormat(Number(newsfeed.distanceKm))} km</h6> : null}
                                                            {profile.unit !== "KM" ? <h6 style={{whiteSpace:'nowrap',fontSize:"12px"}}>{numberFormat(Number(newsfeed.distanceMile))} mile</h6> : null}
                                                        </div>
                                                    </div>
                                                    <h6 className="text-left" style={{wordBreak:'break-word',fontSize:"14px"}}>{newsfeed.notice}</h6>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!showFull?
                        <Link to={"newsfeed/"+newsfeed._id} style={{pointerEvents:showFull?'none':'',color: 'inherit', textDecoration: 'inherit'}}>
                            <div className="d-flex pl-5" style={{fontSize:"14px",marginLeft:'10px'}}>
                                <div className="color-red mr-3" onClick={()=>this.setState({ viewLike: true })} style={{cursor:"pointer",fontSize:"11px",whiteSpace:'nowrap',height:'30px'}}><b>{newsfeed.totalLike}</b> {language(profile.language,"newsfeed.like")}</div>
                                <div className="color-red mr-3" onClick={()=>this.setState({ viewLike: false })} style={{cursor:"pointer",fontSize:"11px",whiteSpace:'nowrap',height:'30px'}}><b>{newsfeed.comments?newsfeed.comments.length:0}</b> {language(profile.language,"newsfeed.comment")}</div>
                            </div>
                        </Link>:
                        <div className="d-flex pl-5" style={{fontSize:"14px",marginLeft:'10px'}}>
                            <div className="color-red mr-3" onClick={()=>this.setState({ viewLike: true })} style={{cursor:"pointer",fontSize:"11px",whiteSpace:'nowrap',height:'30px'}}><b>{newsfeed.totalLike}</b> {language(profile.language,"newsfeed.like")}</div>
                            <div className="color-red mr-3" onClick={()=>this.setState({ viewLike: false })} style={{cursor:"pointer",fontSize:"11px",whiteSpace:'nowrap',height:'30px'}}><b>{newsfeed.comments?newsfeed.comments.length:0}</b> {language(profile.language,"newsfeed.comment")}</div>
                        </div>
                        }
                    </div>
                    <div style={{width:'100px',height:'100px',overflow:'hidden'}}>
                        {viewPhotoAndComment()?newsfeed.images.map((img)=>{
                            return <div className="position-relative" key={img._id}>
                                <a data-fancybox={"gallery"+newsfeed._id} href={img.original?img.original:img.url} >
                                    <img className="image-gal-item" alt="" src={img.url}/>
                                </a>
                                {newsfeed.images.length>1?<span className="image-overlay">+{newsfeed.images.length-1}</span>:null}
                            </div>
                        }):null}
                    </div>
                </div>
                {(showFull && viewPhotoAndComment())?renderComments():null}
                {(showFull && viewPhotoAndComment())?renderCommentsMore():null}
                {viewPhotoAndComment() && !this.props.isEventEnded ?
                <div className="d-flex mt-2">
                    <div className="text-center mr-2">
                        {loadingLike?<IconButton><CircularProgress size={20}/></IconButton>:
                        <IconButton color={newsfeed.isLiked?"primary":"default"} className={newsfeed.isLiked?"color-red":""} onClick={handleLikeClick}><ThumbUpAltOutlinedIcon fontSize='small'/></IconButton>
                        }
                    </div>
                    <div style={{flexGrow: 1,alignSelf:'center'}}>
                        <TextField className="widthFull custom-padding-input" variant="filled" value={form.content} onChange={changeContent} size="small" placeholder={language(profile.language,'newsfeed.input_holder')}/>
                    </div>
                    <div className="text-center">
                        {loadingComment?<IconButton><CircularProgress size={20}/></IconButton>:
                        <IconButton className="color-red" onClick={submit}><SendIcon fontSize='small'/></IconButton>
                        }
                    </div>
                </div>:null
                }
            </Paper>
        )
    }
}