import React, {Component} from 'react';
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import {userManagementServices} from "../../services/userManagementService";
import FlagEn from "../../assets/flag-english.jpg";
import FlagGe from "../../assets/flag-Germany.jpg";
import language from '../../helpers/language';

class RegistrationConfirm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            language: 'DE',
            id: '',
            labelVerify: '',
            isSuccess: false,
            showLoading: false
        }

    }

    componentDidMount() {
        let pre_language = localStorage.getItem('pre_language');
        if (!pre_language || pre_language === '') pre_language = 'EN';
        localStorage.setItem('pre_language', pre_language) 
        this.setState({language: pre_language === 'EN' ? 'DE' : 'EN'}); 

        if (this.props.match && this.props.match.params) {
            const id = this.props.match.params.id;
            let lbRegisterSuccess = 'registration_successful';//language(languagetext, "registration_successful");
            if(!id || id.trim() === '') {
                this.setState({
                    isSuccess: false
                })
                return;
            }
            let data = {
                id: id
            }
            this.setState({showLoading: true})
            userManagementServices.verifyEmail(data, language==='EN'?'en':'de').then(response => {
                if (response.errors && response.errors.msg) {
                    this.setState({
                        showLoading: false,
                        isSuccess: false,
                        labelVerify: response.errors.msg//response.errors.msg.replace(/_/g, ' ')
                    })
                }
                else {
                    this.setState({
                        showLoading: false,
                        isSuccess: true,
                        labelVerify: lbRegisterSuccess
                    })
                }
            })
            .catch(err => {
                this.setState({isSuccess: false})
            });
        }
    }

    handleChangeLanguage = ()=>{
        let currentLanguage = this.state.language;
        this.setState({language:currentLanguage === 'EN' ? 'DE' : 'EN'});
        localStorage.setItem('pre_language', currentLanguage)  
    }

    render() {
        let labelVerify = this.state.labelVerify;
        let languagetext=this.state.language;
        if (labelVerify === '') {
            labelVerify = "verification_in_progress";
        }
        labelVerify = language(languagetext, labelVerify);
        let lbLogin = language(languagetext, "login");
        return (
            <Container maxWidth="sm">
                {
                    this.state.showLoading && 
                    <div className="overlay-loading text-center"><span className="spinner-1 inline-spinner "/>
                    </div>
                }
                <div className="content-login">
                    <div className="app-header-public">
                        <div onClick={this.handleChangeLanguage}>
                            <span className="switch-lang"><img alt="" src={language === 'EN' ? FlagGe : FlagEn} /></span>
                        </div>
                    </div>
                    <div className="row mt-3 mb-3">
                        <div className="col-12">
                            <div className="form-group text-center">
                                <h2>{labelVerify}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className=" mr-3 text-center">
                            <Link to="/" className="btn btn-success" >
                                {lbLogin}
                            </Link>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(RegistrationConfirm);