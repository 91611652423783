const defaultState = {
    teams:[],
    loading:false
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'GET_TEAMS':
            return {...state,loading:true }
        case 'GET_TEAMS_SUCCESS':{
            return {...state, teams: action.payload.data.docs, loading:false }
        }case 'GET_TEAMS_FAIL':
            return {...state, loading:false }
        case 'CREATE_TEAM':
            return {...state,loading:true}
        case 'CREATE_TEAM_SUCCESS':
            return {...state,teams:state.teams.concat(action.payload.data),loading:false}
        case 'CREATE_TEAM_FAIL':
            return {...state,loading:false}
        case 'UPDATE_TEAM':
            return {...state,loading:true}
        case 'UPDATE_TEAM_SUCCESS':
            return {
                ...state,
                teams:state.teams.map((el)=>{
                    if(el._id===action.payload.data._id)
                        return action.payload.data
                    else return el
                }),
                loading:false
            }
        case 'UPDATE_TEAM_FAIL':
            return {...state,loading:false}
        case 'DELETE_TEAM':
            return {...state,loading:true}
        case 'DELETE_TEAM_SUCCESS':
            return {...state,teams:state.teams.filter((el)=> el._id!==action.payload.data._id),loading:false}
        case 'DELETE_TEAM_FAIL':
            return {...state,loading:false}
        default:
            return state
    }
}
  