import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import {connect} from "react-redux";
import language from '../../../helpers/language';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
  row: {
    height: '42px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

function EventsToolbar(props) {
  const { onClickAddNew, className, ...rest} = props;

  const classes = useStyles();
  let languageText = props.languagetext ? props.languagetext : 'DE';
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        {/* <SearchInput
          className={classes.searchInput}
          placeholder={language(languagetext, "search_event")}
        /> */}
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={onClickAddNew}
        >
          {language(languageText, "add_new")}
        </Button>
      </div>
    </div>
  );
};

EventsToolbar.propTypes = {
  className: PropTypes.string
};

function mapStateToProps(state) {
  return {
      userInfo: state.authReducer.userInfo
  };
}

export default connect(mapStateToProps)(EventsToolbar);
